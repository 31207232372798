<template>
    <div>
        <v-row>
            <v-col>
                <span>To be able to make a request to the action endpoints, the following headers should be specified.</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <api-page-description 
                :items="items"
                :title="'Request Headers:'"
                />
            </v-col>
            <v-col>
                <api-page-example
                :items="items" 
                :title="'Headers Example:'"
                />                
            </v-col>
        </v-row>
    </div>
</template>

<script>
import APIPageDesc from './default/API.Page.description.vue'
import APIPageExm from './default/API.Page.Example.vue' 
export default {
    components:{
        'api-page-description':APIPageDesc,
        'api-page-example':APIPageExm
    },
    data(){
        return {
            items:[
                { 
                    id:1,
                    title:'Authorization',
                    name:'Authorization',
                    description:'The header containing the authentication token. It should have the following structure: "Bearer [Access Token]".',
                    type:'String',
                    value:'Bearer [Access Token]'
                }
            ],
        }
    }
}
</script>