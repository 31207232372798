
const debitorFullName = ({name, lastname}) => {
    if(name && lastname){
        if(name.length + lastname.length < 70) {
            return true;
        } else {
            throw new Error('Debitor full name cannot have more than 70 characters')
        }
    } else {
        return true;
    }
}

const address = ({street}) => {
    if(street.length < 70) {
        return true;
    } else {
        throw new Error('Address cannot have more than 70 characters')
    }
}
const fieldRequired = ({name,street,zip,city,country,currency,language})=>{
    if(!name ||!street ||!city || !zip || !country || !currency || !language ){
        throw new Error('Field Required')
    }else{
        return true;
    }

}
const languages = ({language})=> {
    if(!language){
        throw new Error('Field Required')
    }else{
        return true;
    }
}
const zip = ({zip}) => {
    if(zip) {
        if(zip.length <= 16) {
            return true
        } else {
            throw new Error('ZIP Code cannot have more than 16 characters')
        }
    } else {
        return true;
    }
};

const nameMissingZip = ({name, zip}) => {
    if(name.length > 0) { 
        if(!!zip) {
            return true
        } else {
            throw new Error(false)
        }
    } else {
        return true
    }
}

const debitorCountryRules = ({name, country}) => {
    if(name.length > 0) {
        if(!!country) {
            return true
        } else {
            throw new Error(false)
        }
        
    } else {
        return true;
    }
}

const city = ({city})=>{
        if(city.length <= 35)
        {
            return true
        }else{
            throw new Error("City is required")
        }

}
const productsRequired = ({products_json}) =>{
    if(products_json){
        let productsJSON = JSON.parse(products_json);
        if(productsJSON.length == 0) throw new Error("Product is required")
        if(productsJSON.length > 15) throw new Error("Invalid amount of products, max. is 15")
        return true
    }
    else{
        throw new Error("Product is required")
    }
    
}
const invoiceDateRequired = ({billing_information}) =>{
    if(billing_information){
        let invoiceDate = billing_information.invoice_date;
        if(!invoiceDate) throw new Error(`Invalid invoice date`);
    }
    return true;
}
export {
    debitorFullName,
    address,
    zip,
    nameMissingZip,
    debitorCountryRules,
    city,
    fieldRequired,
    productsRequired,
    invoiceDateRequired,
    languages
}