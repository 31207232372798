<template>
    <div>
        <v-row>
            <v-col cols="10" class="pl-0">
                <v-card class="pa-0" flat>
                    <v-card-title class="pt-0">
                        <v-row>
                            <v-col cols="12" class="pt-0 pb-0">
                             {{$t("Admin.general_product.title")}}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="pl-0">
                        <v-data-table
                        :no-data-text="$t('qrGenerator.productsTable.data_available')"
                        :headers="headers.headers"
                        :items="membershipItems"
                        :loading="membershipLoading"
                        hide-default-footer
                        multi-sort	
                        >
                        <template v-slot:item.actions="{item}">
                            <v-icon
                            @click="editMembership(item)"
                            >mdi-pencil</v-icon> 
                        </template>
                        </v-data-table>
                        <v-dialog v-model="enableEditDialog" width="800px">
                            <v-card width="800px">
                                <v-card-title>{{memEditTitle}}</v-card-title>
                                <v-card-text>
                                    <v-form ref="memForm">
                                        
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                outlined
                                                dense
                                                :label="$t('Admin.general_product.invoices')"
                                                v-model="membershipEditedItem.number_credits"
                                                type="number"
                                                :rules="[rules.isGIZero,rules.isEmpty]"
                                                ></v-text-field>
                                            </v-col>
                                            
                                            <v-col cols="6">
                                                <v-text-field
                                                outlined
                                                dense
                                                :label="$t('Admin.general_product.profiles_limit')"
                                                v-model="membershipEditedItem.profilesLimits"
                                                type="number"
                                                :rules="[rules.isGIZero,rules.isEmpty]"
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-text-field
                                                outlined
                                                dense
                                                :label="$t('Admin.general_product.standard_price')"
                                                v-model="membershipEditedItem.price"
                                                type="number"
                                                :rules="[rules.isGIZero,rules.isEmpty]"
                                                ></v-text-field>                                                
                                            </v-col>

                                            <v-col cols="6">
                                                <v-text-field
                                                outlined
                                                dense
                                                :label="$t('Admin.general_product.discount_price')"
                                                v-model="membershipEditedItem.discount_price"
                                                type="number"
                                                :rules="[rules.isGIZero,rules.isEmpty]"
                                                ></v-text-field>                                                
                                            </v-col>

                                        </v-row>

                                        <v-row>
                                            <v-col cols="12">
                                                <v-card flat :color="$vuetify.theme.themes.light.brown.primary">
                                                    <v-card-title class="pb-0 pt-0">{{$t('Admin.general_product.single_prices')}}</v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="4">
                                                                <v-text-field
                                                                outlined
                                                                dense
                                                                :label="$t('Admin.general_product.single_treshold')"
                                                                v-model="membershipEditedItem.SUTP"
                                                                type="number"
                                                                :rules="[rules.isGIZero,rules.isEmpty]"                                                
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="4">
                                                                <v-text-field
                                                                outlined
                                                                dense
                                                                :label="$t('Admin.general_product.single_saturated')"
                                                                v-model="membershipEditedItem.SUSP"
                                                                type="number"
                                                                :rules="[rules.isGIZero,rules.isEmpty]"                                                
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="4">
                                                                <v-text-field
                                                                outlined
                                                                dense
                                                                :label="$t('Admin.general_product.single_limits')"
                                                                v-model="membershipEditedItem.limit"
                                                                type="number"
                                                                :rules="[rules.isGIZero,rules.isEmpty]"                                                                   
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-divider></v-divider>
                                        <v-row>
                                            <v-col cols="8"></v-col>
                                            <v-col cols="2">
                                                <v-btn
                                                :color="$vuetify.theme.themes.light.blue.primary"
                                                dark
                                                @click="enableEditDialog = false"
                                                >{{$t("Globals.cancel")}}</v-btn>
                                            </v-col>
                                            <v-col cols="1">
                                                <v-btn
                                                dark
                                                :color="$vuetify.theme.themes.light.blue.primary"
                                                @click="membershipCreateEditProceed()"
                                                :loading="DOMElements.membershipCreateEditProceed.loading"
                                                >{{$t('Admin.general_product.proceed')}}</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col cols="3 pt-10 pb-0">
                <v-form ref="form">
                    <v-text-field
                    outlined
                    :disabled="disableTaxRate"
                    v-model="globalTaxRate"
                    type="number"
                    :rules="[rules.isGIZero,rules.isEmpty]"
                    :label="$t('Admin.general_product.tax_rate')"
                    dense
                    suffix="%"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="1 pr-10 pt-0">
                <v-btn
                :disabled="disableMutateButton"
                :color="$vuetify.theme.themes.light.blue.primary"
                :dark="enableDarkMutateButton"
                @click="mutateButtonTax"
                >{{$t('Settings.Buttons.btn_mutate')}}</v-btn>
            </v-col>
            <v-col cols="1 pr-0 pl-5 pt-0">
                <v-btn
                :disabled="disableSaveButton"
                :color="$vuetify.theme.themes.dark.green.secondary"
                :dark="enableDarkSaveButton"
                @click="saveButtonTax"
                :loading="DOMElements.saveButtonTax.loading"
                >{{$t("Globals.save")}}</v-btn>
            </v-col>
            <v-col cols="2 pt-0">
                <v-btn
                :disabled="disableAbortButton"
                :color="$vuetify.theme.themes.dark.brown.secondary"
                :dark="enableDarkAbortButton"
                @click="abortButtonTax"
                >{{$t("Globals.cancel")}}</v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col>
                <h3>{{$t("Admin.general_product.additional_services")}}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                :items="additional_services_items"
                :headers="headers.addtional_services_headers"
                :loading="membershipLoading"
                hide-default-footer
                multi-sort	       
                :items-per-page="30"         
                :no-data-text="$t('qrGenerator.productsTable.data_available')"
                >
                    <template v-slot:item.name="{item}">
                        {{calculateNameTranslation(item)}}
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon @click="openEditWindow(item)">mdi-pencil</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <div>
            <v-dialog persistent width="500px" v-model="showASEdit">
                <v-card  width="500px">
                    <v-card-title>{{$t("Admin.general_product.edit")}}</v-card-title>
                    <v-card-text>
                        <v-form ref="additional_services">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                    :label="$t('Admin.general_product.qr_treshold')"
                                    v-model="additonal_service_item.QRTP"
                                    :rules="[rules.isEmpty,rules.isGIZero]"
                                    type="number"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                    :label="$t('Admin.general_product.qr_saturated')"
                                    v-model="additonal_service_item.QRSP"
                                    :rules="[rules.isEmpty,rules.isGIZero]"
                                    type="number"
                                    ></v-text-field>                               
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                    :label="$t('Admin.general_product.post_treshold')"
                                    v-model="additonal_service_item.POTP"
                                    :rules="[rules.isEmpty,rules.isGIZero]"
                                    type="number"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                    :label="$t('Admin.general_product.post_saturated')"
                                    v-model="additonal_service_item.POSP"
                                    :rules="[rules.isEmpty,rules.isGIZero]"
                                    type="number"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                        :dark="closeASEditBtn.dark"
                        :disabled="closeASEditBtn.disabled"
                        :color="$vuetify.theme.themes.light.blue.primary"
                        @click="closeASEdit()"
                        >{{$t("Globals.btn_close")}}</v-btn>
                        <v-btn
                        :dark="saveASEditBtn.dark"
                        :disabled="saveASEditBtn.disabled"
                        :loading="saveASEditBtn.loading"
                        :color="$vuetify.theme.themes.light.blue.primary"
                        @click="saveASEdit()"                       
                        >{{$t("Admin.general_product.proceed")}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <h3>{{$t("Admin.general_product.parcel_weights")}}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                :items="parcel_weights_items"
                :headers="headers.parcel_weights_headers"
                :loading="membershipLoading"
                hide-default-footer
                multi-sort	       
                :items-per-page="15"         
                :no-data-text="$t('qrGenerator.productsTable.data_available')"
                >
                    <template v-slot:item.name="{item}">
                        {{calculateParcelTranslation(item)}}
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon @click="openParcelEditWindow(item)">mdi-pencil</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <div>
            <v-dialog persistent width="500px" v-model="showPWEdit">
                <v-card  width="500px">
                    <v-card-title>{{$t("Admin.general_product.edit_parcel")}}</v-card-title>
                    <v-card-text>
                        <v-form ref="parcel_weights">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                    :label="$t('Admin.general_product.parcel_weights')"
                                    v-model="parcel_weights_item.weight"
                                    :rules="[rules.isEmpty,rules.isGIZero]"
                                    type="number"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            :dark="closePWEditBtn.dark"
                            :disabled="closePWEditBtn.disabled"
                            :color="$vuetify.theme.themes.light.blue.primary"
                            @click="closePWEdit()"
                        >{{$t("Globals.btn_close")}}</v-btn>
                        <v-btn
                            :dark="savePWEditBtn.dark"
                            :disabled="savePWEditBtn.disabled"
                            :loading="savePWEditBtn.loading"
                            :color="$vuetify.theme.themes.light.blue.primary"
                            @click="savePWEdit()"                       
                        >{{$t("Admin.general_product.proceed")}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name:`GeneralProductSettings`,
    data(){
        return{
            closeASEditBtn:{
                dark:true,
                disabled:false
            },
            saveASEditBtn:{
                dark:true,
                disabled:false,
                loading:false
            },
            currentASItemIndex:null,
            additonal_service_item:{
                id:null,
                name:'',
                QRTP:null,
                QRSP:null,
                POTP:null,
                POSP:null,
            },
            default_additonal_service_item:{
                id:null,
                name:'',
                QRTP:null,
                QRSP:null,
                POTP:null,
                POSP:null,            
            },
            showASEdit:false,
            additional_services_items:[],
            parcel_weights_items:[],
            closePWEditBtn:{
                dark:true,
                disabled:false
            },
            savePWEditBtn:{
                dark:true,
                disabled:false,
                loading:false
            },
            currentPWItemIndex:null,
            parcel_weights_item:{
                id:null,
                name:'',
                weight:null,
            },
            default_parcel_weights_item:{
                id:null,
                name:'',
                weight:null,
            },
            showPWEdit:false,
            disableAbortButton:true,
            enableDarkAbortButton:false,
            disableSaveButton:true,
            enableDarkSaveButton:false,
            disableMutateButton:false,
            enableDarkMutateButton:true,
            disableTaxRate:true,
            globalTaxRate:0,
            membershipLoading:false,
            enableEditDialog:false,
            enableSUPDialog:false,
            membershipItems:[],
            membershipItemIndex:-1,
            membershipEditedItem:{
                id:null,
                name:'',
                profilesLimits:0,
                number_credits:0,
                price:0,
                discount_price:0,
                SUTP:0,
                SUSP:0,
                limit:0
            },
            defaultMembershipEditedItem:{
                id:null,
                name:'',
                profilesLimits:0,
                number_credits:0,
                price:0,    
                SUTP:0,
                SUSP:0,
                limit:0            
            },
            rules:{
                isMemName: (v) =>{
                    return !!v || this.$t("rules.nameRules");
                },
                isGIZero: (v) =>{
                    return v >= 0 || this.$t("rules.isGreater"); 
                },
                isEmpty: (v) =>{
                    return v !== undefined && v !== null && v !== '' || this.$t("rules.required");
                }
            },
            DOMElements: {
                membershipCreateEditProceed: {
                    loading: false,
                },
                saveButtonTax: {
                    loading: false,
                }
            }
        }
    },
    computed:{
        headers(){
            return{
                headers:[
                    {text:`ID`,value:`id`},
                    {text:this.$t('Admin.general_product.name'),value:'name'},
                    {text:this.$t('Admin.general_product.credits'),value:`number_credits`},
                    {text:this.$t('Admin.general_product.standard_price'),value:`price`},
                    {text:this.$t('Admin.general_product.discount_price'),value:`discount_price`},
                    {text:this.$t('Admin.general_product.profiles_limit'),value:`profilesLimits`},
                    {text:this.$t('Admin.general_product.single_treshold'),value:`SUTP`},
                    {text:this.$t('Admin.general_product.single_saturated'),value:`SUSP`},
                    {text:this.$t('Admin.general_product.single_limits'),value:`limit`},
                    {text:this.$t('Admin.Users.actions') ,value:`actions`,sortable:false}
                ],
                parcel_weights_headers:[
                    {text:`ID`,value:`id`},
                    {text:this.$t('Admin.general_product.name'),value:'name'},
                    {text:this.$t('Admin.general_product.weight'),value:'weight'},
                    {text:this.$t('Admin.Users.actions'),value:'actions',sortable:false}
                ],
                addtional_services_headers:[
                    {text:`ID`,value:`id`},
                    {text:this.$t('Admin.general_product.name'),value:'name'},
                    {text:this.$t('Admin.general_product.qr_treshold'),value:'QRTP'},
                    {text:this.$t('Admin.general_product.qr_saturated'),value:'QRSP'},
                    {text:this.$t('Admin.general_product.post_treshold'),value:'POTP'},
                    {text:this.$t('Admin.general_product.post_saturated'),value:'POSP'},
                    {text:this.$t('Admin.Users.actions'),value:'actions',sortable:false}
                ],
            }
        },
        memEditTitle(){
            if(this.membershipItemIndex > -1){
                return this.$t("Admin.general_product.edit_membership");
            }else{
                return this.$t("Admin.general_product.new_membership");
            }
        }
    },
    mounted(){
        this.membershipLoading = true;
        this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/gps`)
        .then(async(response)=>{
            if(response){
                this.globalTaxRate = response.data.tax;
                if(response.data.memberships){
                    for(let i = 0;i<response.data.memberships.length;i++){
                        let currentMembership = response.data.memberships[i];
                        this.membershipItems.push({
                            id:currentMembership.id,
                            profilesLimits:currentMembership.profilesLimits ? currentMembership.profilesLimits : null,
                            name:currentMembership.name,
                            number_credits:currentMembership.number_credits,
                            price:currentMembership.price/100,
                            discount_price:currentMembership.discount_price/100,
                            SUTP:currentMembership.singleUseCredits ? currentMembership.singleUseCredits.SUTP/100 : 0,
                            SUSP:currentMembership.singleUseCredits ? currentMembership.singleUseCredits.SUSP/100 : 0,
                            limit:currentMembership.singleUseCredits ? currentMembership.singleUseCredits.limit : 0
                        })
                    }
                }
                if(response.data.additional_services){
                    for(let i = 0;i<response.data.additional_services.length;i++){
                        let currentAS = response.data.additional_services[i];
                        this.additional_services_items.push({
                            id:currentAS.id,
                            name:currentAS.name,
                            QRTP:currentAS.QRTP ? currentAS.QRTP/100 : 0,
                            QRSP:currentAS.QRSP ? currentAS.QRSP/100 : 0,
                            POTP:currentAS.POTP ? currentAS.POTP/100 : 0,
                            POSP:currentAS.POSP ? currentAS.POSP/100 : 0,
                        });
                    }
                }
                if(response.data.parcel_weights){
                    for(let i = 0;i<response.data.parcel_weights.length;i++){
                        let currentPW = response.data.parcel_weights[i];
                        this.parcel_weights_items.push({
                            id:currentPW.id,
                            name:currentPW.name,
                            weight:currentPW.weight ? currentPW.weight : 0,
                        });
                    }
                }
                return this.membershipLoading = false;
            }
        })
        .catch(this.$handleCatch)
    },
    methods:{
        editMembership(subs){
            this.membershipEditedItem = Object.assign({},subs);
            this.membershipItemIndex = this.membershipItems.indexOf(subs);
            this.enableEditDialog = true;
        },
        async membershipCreateEditProceed(){
            if(!this.$refs.memForm.validate()){
                return this.$store.commit(`global/setSnackbar`,{message:this.$t("snack_confirm.invalid_inputs")});
            }
            this.DOMElements.membershipCreateEditProceed.loading = true;
            await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/membership`,{
                method:'POST',
                body:JSON.stringify(this.membershipEditedItem),
                headers:{
                    'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                }
            })
            .then((response)=>{
                if(response){
                    if(this.membershipItemIndex > -1) {
                        Object.assign(this.membershipItems[this.membershipItemIndex], this.membershipEditedItem);
                    } else {
                        this.membershipItems.push({
                            id:response.membership.id,
                            profilesLimits:this.membershipEditedItem.profilesLimits,
                            number_credits:response.membership.number_credits,
                            price:response.membership.price,
                            discount_price:response.membership.discount_price,
                            SUTP:this.membershipEditedItem.SUTP,
                            SUSP:this.membershipEditedItem.SUSP
                        })
                    }
                    this.enableEditDialog = false;
                    return this.$store.commit(`global/setSnackbar`,{ message: response.message});
                }
            })
            .catch(this.$handleCatch)
            this.DOMElements.membershipCreateEditProceed.loading = false;
        },
        newMembership(){
            Object.assign(this.membershipEditedItem,this.defaultMembershipEditedItem);
            this.membershipItemIndex = -1
            this.enableEditDialog = true;
        },
        mutateButtonTax(){
            this.disableTaxRate = false;
            this.disableMutateButton = true;
            this.enableDarkMutateButton = false;
            this.disableSaveButton = false;
            this.enableDarkSaveButton = true;
            this.disableAbortButton = false;
            this.enableDarkAbortButton = true;
        },
        abortButtonTax(){
            if(this.disableMutateButton){
                this.$root.$confirm(this.$t("Globals.cancel"),this.$t("snack_confirm.cancel_process"),{color:`red`}).then((response)=>{
                    if(response){
                        this.disableTaxRate = true;
                        this.disableMutateButton = false;
                        this.enableDarkMutateButton = true;
                        this.disableSaveButton = true;
                        this.enableDarkSaveButton = false;
                        this.disableAbortButton = true;
                        this.enableDarkAbortButton = false;            
                    }
                })
            }
        },
        async saveButtonTax(){
            if(this.disableMutateButton){
                this.$root.$confirm(this.$t('Globals.confirm'),`Are you sure you want to save the changes?`)
                .then(async (response)=>{
                    if(response){
                        this.DOMElements.saveButtonTax.loading = true;
                        await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/tax`,{
                            method:'POST',
                            body:JSON.stringify({
                                taxPercentage:this.globalTaxRate ? Number(this.globalTaxRate) : 0
                            }),
                            headers:{
                                'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                            }
                        })
                        .then((response) => {
                            if(response){
                                this.disableTaxRate = true;
                                this.disableMutateButton = false;
                                this.enableDarkMutateButton = true;
                                this.disableSaveButton = true;
                                this.enableDarkSaveButton = false;
                                this.disableAbortButton = true;
                                this.enableDarkAbortButton = false;                                     
                                this.$store.commit(`global/setSnackbar`,{message:response.message})
                            }
                        })
                        .catch(this.handleCatch)
                        this.DOMElements.saveButtonTax.loading = false;
                    }
                })
            }
        },
        calculateNameTranslation(item){
            return this.$t(`Admin.additional_services.${item.name}`) || item.name;
        },
        calculateParcelTranslation(item){
            return this.$t(`Admin.parcel_weights.${item.name}`) || item.name;
        },
        async openEditWindow(item){
            Object.assign(this.additonal_service_item,this.default_additonal_service_item);
            this.showASEdit = true;
            this.currentASItemIndex = await this.additional_services_items.findIndex(element => element.id === item.id);
            Object.assign(this.additonal_service_item,item);
        },
        async openParcelEditWindow(item){
            Object.assign(this.parcel_weights_item,this.default_parcel_weights_item);
            this.showPWEdit = true;
            this.currentPWItemIndex = await this.parcel_weights_items.findIndex(element => element.id === item.id);
            Object.assign(this.parcel_weights_item,item);
        },
        saveASEdit(){
            this.$root.$confirm(this.$t("Globals.confirm"),`Are you sure you want to change this service?`)
            .then(async (response)=>{
                if(response){
                    this.closeASEditBtn.dark = false;
                    this.closeASEditBtn.disabled = true;
                    this.saveASEditBtn.dark = false;
                    this.saveASEditBtn.disabled = true;
                    this.saveASEditBtn.loading = true;
                    try{
                        let serverResponse = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/additional-services`,{
                            method:'POST',
                            body:JSON.stringify(this.additonal_service_item),
                            headers:{
                                'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                            }                        
                        });
                        if(serverResponse){
                            if(serverResponse.status == 200){
                                Object.assign(this.additional_services_items[this.currentASItemIndex],this.additonal_service_item);
                            }
                            this.closeASEditBtn.dark = true;
                            this.closeASEditBtn.disabled = false;
                            this.saveASEditBtn.dark = true;
                            this.saveASEditBtn.disabled = false;  
                            this.saveASEditBtn.loading = false;
                            this.closeASEdit();                              
                            return this.$store.commit(`global/setSnackbar`,serverResponse.message);
                        }else{
                            this.closeASEditBtn.dark = true;
                            this.closeASEditBtn.disabled = false;
                            this.saveASEditBtn.dark = true;
                            this.saveASEditBtn.disabled = false;
                            this.saveASEditBtn.loading = false;
                            this.closeASEdit();        
                            return this.$store.commit(`global/setSnackbar`,this.$t("Globals.unable_server"));
                        }
                    }catch(err){
                        this.closeASEditBtn.dark = true;
                        this.closeASEditBtn.disabled = false;
                        this.saveASEditBtn.dark = true;
                        this.saveASEditBtn.disabled = false;
                        this.saveASEditBtn.loading = false;
                        this.$store.commit(`global/setSnackbar`,err.response.message);
                    }
                }
            })
            .catch(this.handleCatch)
        },
        savePWEdit(){
            this.$root.$confirm(this.$t("Globals.confirm"),`Are you sure you want to change this weight?`)
            .then(async (response)=>{
                if(response){
                    this.closePWEditBtn.dark = false;
                    this.closePWEditBtn.disabled = true;
                    this.savePWEditBtn.dark = false;
                    this.savePWEditBtn.disabled = true;
                    this.savePWEditBtn.loading = true;
                    try{
                        let serverResponse = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/parcel-weights`,{
                            method:'POST',
                            body:JSON.stringify(this.parcel_weights_item),
                            headers:{
                                'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                            }                        
                        });
                        if(serverResponse){
                            if(serverResponse.status == 200){
                                Object.assign(this.parcel_weights_items[this.currentPWItemIndex],this.parcel_weights_item);
                            }
                            this.closePWEditBtn.dark = true;
                            this.closePWEditBtn.disabled = false;
                            this.savePWEditBtn.dark = true;
                            this.savePWEditBtn.disabled = false;  
                            this.savePWEditBtn.loading = false;
                            this.closePWEdit();                              
                            return this.$store.commit(`global/setSnackbar`,serverResponse.message);
                        }else{
                            this.closePWEditBtn.dark = true;
                            this.closePWEditBtn.disabled = false;
                            this.savePWEditBtn.dark = true;
                            this.savePWEditBtn.disabled = false;
                            this.savePWEditBtn.loading = false;
                            this.closePWEdit();        
                            return this.$store.commit(`global/setSnackbar`,this.$t("Globals.unable_server"));
                        }
                    }catch(err){
                        this.closePWEditBtn.dark = true;
                        this.closePWEditBtn.disabled = false;
                        this.savePWEditBtn.dark = true;
                        this.savePWEditBtn.disabled = false;
                        this.savePWEditBtn.loading = false;
                        this.$store.commit(`global/setSnackbar`,err.response.message);
                    }
                }
            })
            .catch(this.handleCatch)
        },
        closeASEdit(){
            this.showASEdit = false;
            this.currentASItemIndex = null;
        },
        closePWEdit(){
            this.showPWEdit = false;
            this.currentPWItemIndex = null;
        }
    },
}
</script>

<style>

</style>