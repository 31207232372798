<template>
  <div>
    <h3 class="mb-4" style="align-self: center; color: black !important">
    {{$t("QrSeries.step_four.emailDispatch.title")}}

    </h3>
    <v-form ref="formTable">
      <v-data-table 
        :custom-sort="customSort"
        :headers="headers.headers"
        :items="items"
        class="elevation-1"
        pagination.sync="pagination"
        loading-text="Loading... Please wait"
        :items-per-page="5"
      >
        <template v-slot:[`item.email`]="{ item }">
          <div class="styleEmail d-flex align-center">
            <div style="width: 100%; text-align: left; color: red;" class="d-flex align-center">
                  <v-text-field 
                    :rules="[rules.isRequired,rules.email]"
                    v-model="item.email"
                    class="mr-2"
                    @input="cleanError(item)"
                  > 
                    {{ item.email }}
                  </v-text-field>
                

                  <v-chip  v-if="!item.checkEmail"  label color="transparent;">
                    <v-icon style="color: red">mdi-alert-outline</v-icon> 
                  </v-chip>

                  <v-chip
                    v-else
                    style="background: transparent !important"
                    label
                    color="transparent;"
                  >
                    <v-icon style="color: green">mdi-check</v-icon>
                  </v-chip>
            </div>

          </div>

        </template>
        <template v-slot:[`item.position`]="{ item }">
          <v-chip
            style="
              background: transparent !important;
              text-align-last: center !important;
            "
            label
            color="transparent;"
            class="hoverChip"
            @click="cancel(item)"
          >
            <v-icon style="color: red">{{ item.position }}</v-icon>
          </v-chip>
        </template>
      </v-data-table>
    </v-form>
    <div
      class="text-center pt-2 mt-2 mr-2"
      style="float: right"
    >
      <v-btn
        tile
        elevation="11"
        color="#109fde"
        style="color: white"
        class="mr-2"
        @click="nextStep"
      >
        {{$t("QrSeries.step_four.emailDispatch.next")}}
      </v-btn>
      <v-btn
        tile
        elevation="11"
        color="#995015"
        style="color: white"
        @click="cancelBtn"
      >
        {{$t("QrSeries.step_four.emailDispatch.cancel")}}
      </v-btn>
    </div>
  </div>
</template>

<script>
import rules from '@/mixins/rules';
export default {
   emits:['closefun'],
  mixins: [rules],
  data() {
    return {
      // items: [],
    };
  },
  props: {
    debtors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  async mounted() {
   this.items = this.debtors.map((element) => {
      return {
        ...element,
        checkEmail: false,
        position: "mdi-close-thick",
        error:"",
      };
    });
    
    await this.checkEmail();
  },
  computed:{
    items: {
      get() {
        return this.$store.state.qrSeries.items
      },
      set(value) {
        this.$store.state.qrSeries.items = value;
      }
    },
    headers(){
      return{
        headers: [
            { text: "ID", value: "id_b", align: "start", class: "styleHeader", width: '10%',sortable: false},
            {
              text: this.$t("QrSeries.step_four.emailDispatch.client_no"),
              value: "client_number",
              align: "center",
              sortable: false,
              width: '10%',
              class: "styleHeader",
            },
            {
              text: this.$t("QrSeries.step_four.emailDispatch.first_name"),
              value: "name",
              align: "center",
              sortable: false,
              class: "styleHeader",
              width: '10%'
            },
            {
              text: this.$t("QrSeries.step_four.emailDispatch.last_name"),
              value: "last_name",
              align: "start",
              sortable: false,
              class: "styleHeader",
              width: '10%'
            },
            {
              text: this.$t("QrSeries.step_four.emailDispatch.street"),
              value: "street",
              sortable: false,
              align: "start",
              width: '10%',
              class: "styleHeader",
            },
            {
              text: this.$t("QrSeries.step_four.emailDispatch.number"),
              value: "number",
              align: "start",
              sortable: false,
              class: "styleHeader",
              width: '10%'
            },
            { text: this.$t("QrSeries.step_four.emailDispatch.postal_code"), value: "zip", align: "start", sortable: false, class: "styleHeader",width: '10%' },
            { text: this.$t("QrSeries.step_four.emailDispatch.city"), value: "city", align: "start", sortable: false, class: "styleHeader"},
            { text: this.$t("QrSeries.step_four.emailDispatch.country"), value: "country", align: "start", class: "styleHeader", sortable: false, sortable: false, },
            { text: this.$t("QrSeries.step_four.emailDispatch.email"), value: "email", align: "start", class: "styleHeader", sortable: false, width: '300px' },
            {
              text: this.$t("QrSeries.step_four.emailDispatch.remove_item"),
              value: "position",
              align: "center",
              sortable: false,
              width: '100px',
              class: "styleHeader",
            },
        ],
      }
    }
  },
  methods: {
     customSort(items) {
      items.sort((a) => {
        if(a.checkEmail == false){
          return -1;
        }
      });
      this.checkEmail();
      return items;
    },    
  async checkEmail() {
      this.items.forEach((element) => {
        if (element.email !== "") {
          var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          var flag = re.test(element.email);
          if (flag) {
            element.checkEmail = flag;
          } else {
            element.checkEmail = flag;
          }
        }
      });
    },
    cancelBtn(){
      this.$root
        .$confirm(this.$t("Settings.confirm_title"), this.$t('QrSeries.step_four.emailDispatch.cancelProcess'))
        .then(async (response) => {
          if (response) {
            this.$emit('closefun');

            this.items = this.debtors.map((element) => {
                return {
                  ...element,
                  checkEmail: false,
                  position: "mdi-close-thick",
                  error:"",
                };
            });

            await this.checkEmail();
          } 
        });
    },
   

    async cleanError(item){
       if(item.checkEmail){
          let indexItem = this.items.findIndex(element => element.id_b == item.id_b );
          
          if(indexItem !=-1){
              this.items[indexItem].checkEmail=false
          }
       }
    },

    async cancel(item){
      if(this.items.length < 2){
           return this.$store.commit(`global/setSnackbar`, {
            message: "List Cannot be Empty",
          });
      }else{

        let indexElementToDelete = this.items.findIndex( element => item.id_b == element.id_b);

        if(indexElementToDelete != -1){
          this.items.splice(indexElementToDelete,1); 
        }
        
      }
    },

    async nextStep() {
      
      if(this.items.length == 0){

          return this.$store.commit(`global/setSnackbar`, {
            message: this.$t('QrSeries.step_four.emailDispatch.checkEmail'),
          });

      }else{
        
          let someEmailIsWrong = this.items.some( element => !element.checkEmail);
          if(someEmailIsWrong){
            return this.$store.commit(`global/setSnackbar`, {
              message: this.$t('QrSeries.step_four.emailDispatch.checkEmail') ,
            });
          }

          const data = this.items.map( (element) => {
              return {
                  id_b: element.id_b,
                  email: element.email.toLowerCase()
              }
          });


          try{
              const response = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/debtors/update-email`, {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify({ debtors:data }),
                    headers: {
                      "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                    },
                });
                
                if(response.status == 200){

                  for(let i  = 0 ; i < this.items.length ; i++){

                      let indexDebtor = this.debtors.findIndex(element => element.id_b == this.items[i].id_b);
                      if(indexDebtor != -1){
                          this.debtors[indexDebtor].email = this.items[i].email;
                      }

                  }
                  this.$store.state.qrSeries.stepperEmail = 2;
                }

          }catch(err){
              console.log(err);
          } 
      }
    },
    cancelStep1() {
      this.$root
        .$confirm(
          this.$t("snack_confirm.cancel_title"),
          this.$t("snack_confirm.cancel_process")
        )
        .then((response) => {
          
          if (response) {
            Object.assign(this.$refs.qrseries1, {
              ProfileSelected: "standard",
              OtherProfileSelected: "",
              templateType: "",
              createComplete: "",
              SelectionIntegration: "",
              payableSelected: "",
              payableDate: "",
              templatesItems: [],
            });
            this.$router.push({ name: "qr-table" });
          }
        });
    },
  },
 
};
</script>

<style>
.styleHeader {
  background-color: white;
  color: black !important;
  border-bottom: grey 1px solid !important;
  font-size: 10px !important;
}
/* .v-data-table-header th {
  white-space: nowrap;
} */
.hoverChip:hover {
  cursor: pointer;
}
.styleEmail {
  display: flex !important;
  place-content: space-between !important;
}
</style>
