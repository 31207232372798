import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        themes:{
            light:{
                green:{
                    primary: '#CFEE76',
                    secondary:'#E7F6BC',
                },
                brown:{
                    primary:'#DDD9C3',
                    secondary:'#F8F7F2',
                },
                blue:{
                    primary:'#009FE3',
                    secondary:'#A7E4FF',
                },
            },
            dark:{
                green:{
                    primary:'#51690D',
                    secondary:'#95C11F',
                },
                brown:{
                    primary:'#4A452A',
                    secondary:'#948A54',
                },
            }
        }
    }
});

