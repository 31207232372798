<template>
  <div>
    <v-card
      v-if="!maxSizeDocument"
      ref="cardParent"
      :style="cardStyles"
      :key="key"
      :flat="cardFlat"
    >
      <div
        ref="wrapper"
        v-if="!loading"
        :style="`width: 111%; height: 103%; overflow: auto`"
      >
        <canvas ref="pdf"></canvas>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-center align-center"
        :style="`width:100%; height:100%`"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-card>

    <v-card v-if="maxSizeDocument" height="100%" :key="key" :flat="cardFlat">
      <div ref="wrapper" v-if="!loading">
        <canvas ref="pdf"> </canvas>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-center align-center"
        :style="`width:100%; height:100%`"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-card>
  </div>
</template>

<script>
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import {
  base64ToArrayBuffer,
  base64toBlob,
  debounce,
  fillParagraph,
  getDate,
  getPicDimensions,
  isBase64,
  wrap,
  wrapText,
} from "@/mixins/functions";
import { mapGetters, mapMutations, mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import en from "./i18n/english.json";
import de from "./i18n/german.json";
import fr from "./i18n/french.json";
import it from "./i18n/italian.json";
import rm from "./i18n/romansh.json";

const { mapFields } = createHelpers({
  getterType: "templateEditor/getField",
  mutationType: "templateEditor/updateField",
});

const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.943/pdf.worker.js";
export default {
  data() {
    return {
      message: "",
      pdfBytes: "",
      pdfSizes: {
        height: 841.89,
        width: 595.28,
      },
      cardStyles: {
        width: 0,
        height: 0,
      },
      customFont: {},
      fontBytes: {},
      canRender: true,
      itemsLength: 0,
      loading: false,
      key: 0,
      lang: {
        en,
        de,
        fr,
        it,
        rm,
      },
    };
  },
  created() {
    PDFJS.workerSrc = "pdfjs-dist/build/pdf.worker.js";
  },
  async mounted() {
    this.$nextTick(async () => {
      this.loading = true;
      if (this.idTemplate !== null && this.idTemplate !== undefined) {
        const { template, logo64, extension } = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/getTemplate/?id=${this.idTemplate}`
        );
        const { json_settings, template_name } = template;

        const {
          font,
          greeting,
          contactDetails,
          currency,
          logo,
          closing,
          subject,
          hiddeProducts,
          customerNotes,
        } = JSON.parse(json_settings);

        this.setStoreTemplate({
          font,
          greeting,
          contactDetails,
          currency,
          closing,
          subject,
          template_name,
          hiddeProducts,
          customerNotes,
        });

        this.setStoreLogo({
          data: logo,
          img: {
            logo64,
            extension,
          },
        });
      }

      let final = Object.assign({}, await this.perfiles());

      const invoiceObj = {
        company: this.username.company || "",
        first_name: this.username.name || "",
        last_name: this.username.last_name || "",
        //street: this.username.street || '',
        street: final.street,
        //street_number: this.username.number || '',  // Informacion de settings
        street_number: final.houseNumber,
        //country: this.username.country || '',
        country: final.selectedCountry,
        //zip: this.username.zip || '',
        zip: final.zipCode,
        //city: this.username.city || '',
        city: final.city,
        legal_form: this.username.legal_form || "",
      };

      if (this.profile) {
        const profile = this.profiles.find(
          (_profile) => _profile.id === this.profile
        );
        Object.assign(invoiceObj, {
          first_name: profile.firstName,
          last_name: profile.lastName,
          street: profile.street,
          street_number: profile.houseNumber,
          country: profile.selectedCountry,
          zip: profile.zipCode,
          city: profile.city,
        });
      }

      this.setInvoiceAddress(invoiceObj);
      this.init();
      this.loading = false;
    });
  },
  watch: {
    subject() {
      this.init();
    },
    test(newVal, oldVal) {
      this.init();
    },
    debtor: {
      handler(newVal, oldVal) {
        this.init();
      },
      deep: true,
    },
    logo: {
      handler() {
        this.init();
      },
      deep: true,
    },
    presentation() {
      this.init();
    },
    contactDetails() {
      this.init();
    },
    bottomComment() {
      this.init();
    },
    items: {
      handler() {
        this.init();
      },
      deep: true,
    },
    calculations: {
      handler() {
        this.init();
      },
      deep: true,
    },
    qrCode() {
      this.init();
    },
    idTemplate() {
      this.init();
    },
    myFont(newValue, oldValud) {
      this.$store.commit("templateEditor/setFont", newValue);
      this.init();
    },
    hiddeProducts(newValue, oldValud) {
      this.$store.commit("templateEditor/setHiddeTable", newValue);
      this.init();
    },
    invoice_date() {
      this.init();
    },
    payable_until() {
      this.init();
    },
    onlyQR() {
      this.init();
    },
    zoom(newV, oldV) {
      this.init();
    },
    currency(newValue, oldValue) {
      this.init();
    },
  },
  computed: {
    ...mapGetters({
      profiles: "qrSeries/getProfiles",
    }),
    ...mapGetters({
      fields: "templateEditor/fields",
      fonts: "templateEditor/fonts",
      dummyInfo: "templateEditor/dummyInfo",
      cityAndDate: "templateEditor/cityAndDate",
      username: "User/username",
      creditor: "User/creditor",
    }),
    ...mapState({
      font: (state) => state.templateEditor.fields.font,
    }),
    ...mapFields({
      logoData: "logoData",
    }),
    userHasWir() {
      return this.$store.state.User.wir_active;
    },
    profile: {
      get() {
        return this.$store.state.qrSeries.profile;
      },
      set(value) {
        this.$store.commit("qrSeries/setProfile", value);
      },
    },
    text() {
      return this.lang[this.debtor.language || this.$i18n.locale || "de"];
    },
  },
  methods: {
    async perfiles() {
      let defaultP = {};
      // Search all the profiles
      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/profiles`).then(
        (response) => {
          if (response) {
            this.subscriptionLimit = response.subscriptionLimit;
            if (response.profiles.length > 0) {
              let getProfilesLength = response.profiles.length;
              //Get all the information from 1 user
              for (let i = 0; i < getProfilesLength; i++) {
                let currentMap = {
                  id: response.profiles[i].id,
                  accountNumber: response.profiles[i].iban,
                  accountQRNumber: response.profiles[i].qr_iban,
                  accountWirNumber: response.profiles[i].iban_wir,
                  accountWirQRNumber: response.profiles[i].qr_iban_wir,
                  UIDNumber: response.profiles[i].uid_number,
                  reference: response.profiles[i].reference,
                  firstName: response.profiles[i].first_name,
                  selectedCurrecy: response.profiles[i].currency,
                  lastName: response.profiles[i].last_name,
                  street: response.profiles[i].street,
                  houseNumber: response.profiles[i].house_number,
                  zipCode: response.profiles[i].zip_code,
                  city: response.profiles[i].city,
                  selectedCountry: response.profiles[i].country,
                  defaultProfile: response.profiles[i].default,
                  customID: response.profiles[i].custom_id,
                };
                //If my user is the default user, we assign it to defaultP
                if (currentMap.defaultProfile == true)
                  Object.assign(defaultP, currentMap);
              }
            }
          }
        }
      );
      return defaultP;
    },

    init: debounce(function () {
      this.setSettingsPDF();
    }, 600),

    async setSettingsPDF() {
      this.loading = true;
      new Promise(async (resolve, reject) => {
        this.pdfDoc = await PDFDocument.create();
        await this.setFonts(); // generate all the fonts that are needed and PDF-Lib doesnt support them.
        this.timesRomanFont = await this.pdfDoc.embedFont(
          StandardFonts.TimesRoman
        );
        this.page = this.pdfDoc.addPage();
        let pageHeight = 841.89;
        this.renderQR === false && (pageHeight -= 317.48);
        this.page.setSize(595.28, pageHeight);
        const { width, height } = this.page.getSize();
        this.pdfSizes.height = height;
        this.pdfSizes.width = width;
        await this.generatePage();
        const pdfBytes = await this.pdfDoc.save({ useObjectStreams: true }); // it is suppoused that useObjectStreams will make this process faster
        var bytes = new Uint8Array(pdfBytes);
        var blob = new Blob([bytes], { type: "application/pdf" });
        this.renderPDF(URL.createObjectURL(blob));
        resolve(true);
      }).then(() => {
        this.loading = false;
      });
    },
    async setFonts() {
      this.pdfDoc.registerFontkit(fontkit);
      await Promise.all(
        Object.keys(this.fonts).map(async (font) => {
          let fontBytes = null;
          if (!(font in this.customFont)) {
            fontBytes = await fetch(this.fonts[font]).then((res) =>
              res.arrayBuffer()
            );
            this.fontBytes[font] = fontBytes;
          }
          if (font in this.customFont) {
            fontBytes = this.fontBytes[font];
          }
          this.customFont[font] = await this.pdfDoc.embedFont(fontBytes);
        })
      );
    },
    async renderPDF(URL) {
      this.loading = true;
      const loadingTask = PDFJS.getDocument(URL);
      const pdf = await loadingTask.promise;
      // Load information from the first page.
      this.PDFJSpage = await pdf.getPage(1);
      const scale = 3 + this.zoom;
      const viewport = this.PDFJSpage.getViewport(scale);
      if (this.renderTask) return;
      // Apply page dimensions to the <canvas> element.
      const canvas = this.$refs.pdf;
      if (canvas) {
        const wrapper = this.$refs.wrapper;
        const context = canvas.getContext("2d");
        canvas.height = viewport.height + (this.userHasWir ? 850 : 0);
        canvas.width = viewport.width + (this.userHasWir ? 150 : 0);
        const newZoom = this.zoom * 100 + 98;
        canvas.style.width = `${newZoom}%`;
        canvas.style.height = `${newZoom}%`;

        this.cardStyles = {
          height: this.pdfSizes.height * 1.15 + "px",
          width: this.pdfSizes.width * 1.15 + "px",
        };

        // Render the page into the <canvas> element.
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        // await this.PDFJSpage.render(renderContext);
        this.renderTask = this.PDFJSpage.render(renderContext);
        this.renderTask
          .then(() => {
            this.destroyRenderTask();
            this.loading = false;
          })
          .catch((error) => {
            // TODO: maybe display to the user the pdf couldn't be rendered???
            this.destroyRenderTask();
            this.loading = false;
          });
      }
    },
    destroyRenderTask() {
      if (!this.renderTask) return;
      // RenderTask#cancel
      // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
      this.renderTask.cancel();
      delete this.renderTask;
    },
    setContactDetails() {
      const contactDetails = this.fields.biller.contactDetails;
      this.renderText(
        {
          ...contactDetails,
          content: this.contactDetails || contactDetails.content,
        },
        { x: 20, y: 62, maxWidth: 190 }
      );
    },
    setSubject() {
      const subject = this.fields.centerSection.subject;
      this.renderText(
        {
          ...subject,
          content: this.subject || subject.content || this.text.invoice,
        },
        { x: 18, y: 87 }
      );
    },
    async setCommentsTable(test) {
      const customerNotes = this.fields.centerSection.customerNotes;
      let newTL = this.test || customerNotes.content || "";

      //variables used to calculate the space occupied by the text
      //-------------------------------------------------------------------------------
      let textWidth = 0;
      let { italic, bold, size } = { ...customerNotes };
      let font = this.font;
      if (bold === true) {
        font += "Bold";
      }
      if (italic === true) {
        font += "Italic";
      }
      font = this.customFont[font];
      //------------------------------------------------------------------------------
      let aux = null;
      let flag = null;
      let y = test;
      let currentY = test;
      // console.log('test=> ', test, currentY)
      let splittedTL = newTL.split("\n");
      // console.log('newTL0', newTL, splittedTL)
      for (let i = 0; i < splittedTL.length; i++) {
        let currentTL = splittedTL[i];

        //Perform automatic line feed if over limit is exceeded
        // if (i == 0) {
        flag = true;
        aux = "";
        for (let i = 0; i < currentTL.length; i++) {
          aux += currentTL[i];
          if (flag) {
            textWidth = font.widthOfTextAtSize(aux, size || 5);
            if (textWidth >= 480) {
              // console.log('textWidth0', textWidth)
              for (let j = i - 1; j > 0; j--) {
                // console.log('aux[j]', aux[j])
                //performs a line break and runs the word to the next line
                if (aux[j] == " ") {
                  // console.log('entre')
                  y = y + 1;
                  currentY = currentY + 5;
                  await this.renderText(
                    {
                      ...customerNotes,
                      content: aux.substring(0, j > 0 ? j : 0),
                    },
                    {
                      x: 18,
                      y: currentY,
                    }
                  );

                  aux = aux.substring(j + 1);
                  break;
                }

                //if the word is too big and spans a full line, cut the word

                if (j - 1 == 0) {
                  y = y + 1;
                  currentY = currentY + 103;
                  await this.renderText(
                    {
                      ...customerNotes,
                      content: aux.substring(0, i > 0 ? i : 0),
                    },
                    {
                      x: 18,
                      y: currentY,
                    }
                  );

                  aux = aux.substring(i);
                  break;
                }
              }

              // flag = false;
            }
          }
        }

        currentTL = aux.trimStart();
        //verify that the second line does not exceed the allowable limit
        textWidth = font.widthOfTextAtSize(currentTL, size || 5);

        if (textWidth >= 480) {
          this.setFlagValidationCenterSectionMessage(true);
        } else {
          this.setFlagValidationCenterSectionMessage(false);
        }
        // }

        // validation so that the user does not enter another line
        //break, already when it was added.
        if (newTL.includes("\n") && !flag) {
          this.setFlagValidationBreakLine(true);
        } else {
          this.setFlagValidationBreakLine(false);
        }

        //if there is a line break inserted by the user, verify that the second
        //line does not exceed the length of the first line.
        // if (i == 1) {
        //   textWidth = font.widthOfTextAtSize(currentTL, size || 5);
        //   if (textWidth >= 480) {
        //     console.log('entre en el 2 if', textWidth)

        //     this.setFlagValidationCenterSectionMessage(true);
        //   } else {
        //     console.log('entre en el 2 else', textWidth)
        //     this.setFlagValidationCenterSectionMessage(false);
        //   }
        // }

        currentY = currentY + 5;
        await this.renderText(
          {
            ...customerNotes,
            content: currentTL,
          },
          {
            x: 18,
            y: currentY,
          }
        );
      }
      return currentY;
    },
    async setTextLine() {
      const textLine = this.fields.centerSection.textLine;
      let newTL = this.presentation || textLine.content || this.text.first_line;

      //variables used to calculate the space occupied by the text
      //-------------------------------------------------------------------------------
      let textWidth = 0;
      let { italic, bold, size } = { ...textLine };
      let font = this.font;
      if (bold === true) {
        font += "Bold";
      }
      if (italic === true) {
        font += "Italic";
      }
      font = this.customFont[font];
      //------------------------------------------------------------------------------
      let aux = null;
      let flag = null;
      let y = null;
      let currentY = 0;

      let splittedTL = newTL.split("\n");
      for (let i = 0; i < splittedTL.length; i++) {
        let currentTL = splittedTL[i];

        //Perform automatic line feed if over limit is exceeded
        if (i == 0) {
          flag = true;
          aux = "";
          for (let i = 0; i < currentTL.length; i++) {
            aux += currentTL[i];
            if (flag) {
              textWidth = font.widthOfTextAtSize(aux, size || 5);
              if (textWidth >= 480) {
                for (let j = i - 1; j > 0; j--) {
                  //performs a line break and runs the word to the next line
                  if (aux[j] == " ") {
                    y = 1;
                    currentY = 103;
                    await this.renderText(
                      {
                        ...textLine,
                        content: aux.substring(0, j > 0 ? j : 0),
                      },
                      {
                        x: 18,
                        y: currentY,
                      }
                    );

                    aux = aux.substring(j + 1);
                    break;
                  }

                  //if the word is too big and spans a full line, cut the word
                  if (j - 1 == 0) {
                    y = 1;
                    currentY = 103;
                    await this.renderText(
                      {
                        ...textLine,
                        content: aux.substring(0, i > 0 ? i : 0),
                      },
                      {
                        x: 18,
                        y: currentY,
                      }
                    );

                    aux = aux.substring(i);
                    break;
                  }
                }

                flag = false;
              }
            }
          }

          currentTL = aux.trimStart();
          //verify that the second line does not exceed the allowable limit
          textWidth = font.widthOfTextAtSize(currentTL, size || 5);
          if (textWidth >= 480) {
            this.setFlagValidationCenterSectionMessage(true);
          } else {
            this.setFlagValidationCenterSectionMessage(false);
          }
        }

        // validation so that the user does not enter another line
        //break, already when it was added.
        if (newTL.includes("\n") && !flag) {
          this.setFlagValidationBreakLine(true);
        } else {
          this.setFlagValidationBreakLine(false);
        }

        //if there is a line break inserted by the user, verify that the second
        //line does not exceed the length of the first line.
        if (i == 1) {
          textWidth = font.widthOfTextAtSize(currentTL, size || 5);
          if (textWidth >= 480) {
            this.setFlagValidationCenterSectionMessage(true);
          } else {
            this.setFlagValidationCenterSectionMessage(false);
          }
        }

        currentY = 103 + (i + y) * 4;
        await this.renderText(
          {
            ...textLine,
            content: currentTL,
          },
          {
            x: 18,
            y: currentY,
          }
        );
      }
      return currentY;
    },
    setCityAndDate() {
      let date = "";
      const _city = this.fields.invoiceDetails.location.content;
      if (this.invoice_date) {
        const [year, month, day] = this.invoice_date.split("-");
        date = `, ${day}. ${this.text.months[month]} ${year}`;
      } else {
        date = "";
      }
      this.renderText(
        {
          content: `${_city}${date}`,
          size: 11,
        },
        { x: 120, y: 25 }
      );
    },
    setDebtorInfo() {
      const {
        company,
        first_name,
        last_name,
        address_suffix,
        street,
        street_number,
        zip,
        city,
      } = this.debtor;
      let str = "";
      if (company) {
        str += company + "\n";
      } else {
        str += `${first_name || ""} ${last_name || ""}\n`;
      }
      if (address_suffix) str += `${address_suffix} \n`;
      if (street || street_number)
        str += `${street || ""} ${street_number || ""} \n`;
      if (zip || city) str += `${zip || ""} ${city || ""}`;

      this.setStoreDebtor(str);
    },
    setLastComment(PAYMENT_DETAILS_FINAL_Y) {
      const bottomComment = this.fields.commentsSection.textLine;
      this.renderText(
        {
          ...bottomComment,
          content: this.bottomComment || bottomComment.content,
        },
        {
          x: 18, // 20,
          y: PAYMENT_DETAILS_FINAL_Y + 6,
          size: 12,
          lineHeight: 12,
          // maxWidth: 480,
        }
      );
    },
    async generatePage() {
      // if just want to be render QR/payment part this part should not be rendered
      if (!this.onlyQR) {
        await this.setLogo();
        this.setContactDetails();
        this.renderText(this.fields.biller.invoiceAddress, {
          x: 20,
          y: 42,
          maxWidth: this.mmToPoints(80),
        });
        this.setSubject();
        this.renderText(
          {
            // SALUTATION + LAST NAME
            content: this.getSalutation(this.debtor),
            bold: true,
            size: 11,
          },
          { x: 18, y: 96 }
        );
        const size_y_message = await this.setTextLine();
        this.setCityAndDate();
        this.setDebtorInfo(); // <-- This code print the information from data master to the invoice
        // this.renderText(this.fields.customerSection, { x: 120, y: 35 });

        let PAYMENT_DETAILS_FINAL_Y = size_y_message;
        //If the value of Hidde product table is true, the table is gonna be hidde
        if (!this.fields.hiddeProducts.value) {
          // header fields
          const INVOICE_INFORMATION_FINAL_Y =
            this.setInvoiceInformation().finalY;
          this.renderLine({
            x: 17,
            y: INVOICE_INFORMATION_FINAL_Y + 5,
            width: 170,
          });
          this.renderText(
            {
              content: this.text.services,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 20, y: INVOICE_INFORMATION_FINAL_Y + 2 }
          );
          this.renderText(
            {
              content: this.text.quantity,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 95, y: INVOICE_INFORMATION_FINAL_Y + 2 }
          );
          this.renderText(
            {
              content: this.text.unit_price,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 114, y: INVOICE_INFORMATION_FINAL_Y + 2 }
          );
          this.renderText(
            {
              content: this.text.vatPercent,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 135, y: INVOICE_INFORMATION_FINAL_Y + 2 }
          );
          this.renderText(
            {
              content: this.text.vat,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 159, y: INVOICE_INFORMATION_FINAL_Y + 2 }
          );
          this.renderText(
            {
              content: this.text.total,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 181, y: INVOICE_INFORMATION_FINAL_Y + 2 }
          );

          // Product List
          const ITEMS_FINAL_Y = this.setItems(
            INVOICE_INFORMATION_FINAL_Y + 7
          ).posY;
          PAYMENT_DETAILS_FINAL_Y = this.setPaymentDetails(ITEMS_FINAL_Y).posY;
        } else {
          PAYMENT_DETAILS_FINAL_Y = await this.setCommentsTable(
            PAYMENT_DETAILS_FINAL_Y + 3
          );
        }

        this.setLastComment(PAYMENT_DETAILS_FINAL_Y + 10);
      }
      if (this.renderQR && this.itemsLength <= 5) {
        await this.setQR();
      }
    },
    getSalutation(debtor) {
      if (debtor) {
        if (debtor.salutation === "mr" || debtor.salutation === "mrs") {
          let newSalutation = `${
            debtor.salutation
              ? this.text.Salutations[`${debtor.salutation}`]
              : ""
          }`;
          let currentLastname = debtor.last_name ? debtor.last_name : "";
          newSalutation += newSalutation ? " " : "";
          newSalutation += currentLastname ? currentLastname : "";
          return newSalutation;
        } else if (
          debtor.salutation === "mr_2" ||
          debtor.salutation === "mrs_2"
        ) {
          let currentLanguage =
            this.debtor.language || this.$i18n.locale || "de";
          let newSalutation = `${
            debtor.salutation
              ? this.text.Salutations[`${debtor.salutation}`]
              : ""
          }`;
          let currentName = debtor.first_name ? debtor.first_name : "";
          newSalutation += newSalutation ? " " : "";
          newSalutation += currentName ? currentName : "";
          if (
            (currentLanguage === "fr" ||
              currentLanguage === "it" ||
              currentLanguage === "en") &&
            !!newSalutation
          )
            newSalutation += ",";
          return newSalutation;
        } else {
          return `${this.text.Salutations.keine}`;
        }
      }
      return ``;
    },
    mmToPoints(mm) {
      return mm * 2.83465;
    },
    pxToMm(px) {
      return px * 0.2645833333;
    },
    async setLogo() {
      let { url, type, positionX, positionY, ranger } = this.fields.logo;
      if (typeof url === "array" && !(url.length > 0)) {
        // detect if the logo is coming from the local user system file
        return;
      }
      if (typeof url === "string" && !(url.length > 0)) {
        // detect if the logo is comming from the server
        return;
      }
      positionX = positionX || 0;
      positionY = positionY || 0;
      const POS_INIT_X = this.mmToPoints(20);
      const POS_INIT_Y = this.page.getSize().height - this.mmToPoints(39);
      const imagePostionX = POS_INIT_X + Number(positionX);
      const imagePostionY = POS_INIT_Y + Number(positionY);

      const POS_RECTANGULE_FINAL_X = POS_INIT_X + this.mmToPoints(45);
      const POS_RECTANGULE_FINAL_Y = POS_INIT_Y + this.mmToPoints(30);
      const MAX_WIDTH = 200;
      const MAX_HEGHT = 200;
      let imgAsDataURL = null;

      // get the data URL of the image to get its pic dimensions (width, height)
      // if the image is comming from the local user system file
      if (url instanceof File) {
        imgAsDataURL = window.URL.createObjectURL(url);
      } else {
        // if the image is not comming from the local user system file means is comming from the server
        try {
          // get imageDataURL if the image is png
          const blob = base64toBlob(url, "image/png");
          imgAsDataURL = window.URL.createObjectURL(blob);
        } catch (error) {
          // if the image is not png means is jpeg
          const blob = base64toBlob(url, "image/jpeg");
          imgAsDataURL = window.URL.createObjectURL(blob);
        }
      }

      const { width: picWidth, height: picHeight } = await getPicDimensions(
        imgAsDataURL
      );
      const aspectW = picWidth / MAX_WIDTH;
      const aspectH = picHeight / MAX_HEGHT;
      let imageBytes = null;
      let image = null;

      // convert the image to the format in bytes that the library requires to render
      if (isBase64(url)) {
        imageBytes = base64ToArrayBuffer(url);
      } else {
        imageBytes = URL.createObjectURL(url);
        imageBytes = await fetch(imageBytes).then((res) => res.arrayBuffer());
      }

      if (url.type === "image/png" || type === ".png") {
        image = await this.pdfDoc.embedPng(imageBytes);
      }
      if (url.type === "image/jpeg" || type === ".jpg" || type === ".jpeg") {
        image = await this.pdfDoc.embedJpg(imageBytes);
      }
      const maxWidth = this.mmToPoints(45);
      const maxHeight = this.mmToPoints(30);

      // render a red box to indicate the limit edges
      if (this.renderLogoRectangle) {
        this.page.drawRectangle({
          y: POS_INIT_Y,
          x: POS_INIT_X,
          width: maxWidth,
          height: maxHeight,
          borderColor: rgb(1, 0, 0),
        });
      }

      const pngDims = image.scale(0.5);
      const imageWidth = pngDims.width * (ranger / 50 / aspectW);
      const imageHeight = pngDims.height * (ranger / 50 / aspectW);
      const xFinal = imagePostionX + imageWidth;
      const yFinal = imagePostionY + imageHeight;
      const xDiff = POS_RECTANGULE_FINAL_X - xFinal;
      const yDiff = POS_RECTANGULE_FINAL_Y - yFinal;
      Object.assign(this.logoData, {
        xDiff,
        yDiff,
        imageWidth,
        imageHeight,
        maxWidth,
        maxHeight,
      });
      this.page.drawImage(image, {
        y: imagePostionY,
        x: imagePostionX,
        width: imageWidth,
        height: imageHeight,
      });
    },
    async setQR() {
      //check if the debto has productos with 2 currencies
      let everyProductsAreCHW = this.items.every((element) => {
        return element.percentage_wir && element.percentage_wir == 100;
      });

      let everyProductsAreCHF = this.items.every((element) => {
        return !element.percentage_wir || element.percentage_wir == 0;
      });

      let bothCurrency = false;
      if (!everyProductsAreCHW && !everyProductsAreCHF) {
        bothCurrency = true;
      }

      let imageBytes = null;
      let image = null;
      if (this.qrCode) {
        const readFile = await PDFDocument.load(this.qrCode);
        const pages = readFile.getPages();
        const LastPage = pages[0];

        const preamble = await this.pdfDoc.embedPage(LastPage);
        const preambleDims = preamble.scale(1);
        let page = await this.pdfDoc.addPage();

        let xSize = 595.28;
        let ySize = this.onlyQR ? 500 : 841.89;
        page.setSize(xSize, ySize);

        //only for wir
        let marginTop = 0;
        if (everyProductsAreCHW || everyProductsAreCHF) {
          marginTop = 550;
        } else {
          marginTop = 200;
        }

        this.page.drawPage(preamble, {
          ...preambleDims,
          x: 0,
          y: this.onlyQR
            ? 525 - (bothCurrency && this.userHasWir ? 300 : 0)
            : 317.48 -
              preambleDims.height -
              30 +
              (this.userHasWir ? marginTop : 0),
        });
      }
    },
    setItems(posY) {
      const items = [];
      if (this.showDummyItems) {
        items.push(...this.fields.items);
      } else if (this.items.length > 0) {
        items.push(...this.items);
      }
      this.itemsLength = items.length || 0;
      let i = 0;
      let yPosition = 0;

      let displayItemsWithWir =
        this.checkIfUserHasProductsWithWir() && this.userHasWir;

      //adjust margin top
      posY -= 1;

      for (let [
        index,
        {
          item,
          quantity,
          prize,
          vatPercent,
          vat,
          total,
          amountCHW,
          amountCHF,
          percentage_wir,
        },
      ] of items.entries()) {
        if (index === 25) {
          break;
        } 
        //if user has wir we need to add more space
        if (displayItemsWithWir && i != 0) {
          yPosition += 8;
          //redraw headers
          this.renderText(
            {
              content: this.text.quantity,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 95, y: yPosition - 3 }
          );
          this.renderText(
            {
              content: this.text.unit_price,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 114, y: yPosition - 3 }
          );
          this.renderText(
            {
              content: this.text.vatPercent,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 135, y: yPosition - 3 }
          );
          this.renderText(
            {
              content: this.text.vat,
              size: 8,
              bold: true,
              italic: true,
            },
            { x: 159, y: yPosition - 3 }
          );
        } else {
          yPosition = posY + i * 6;
        }

        //Aqui esta el name del item
        this.renderText(item.replace(/<\/?[^>]+(>|$)/g, "").replace(/\s+/g, " ").trim(), {
          x: 20,
          y: yPosition,
          size: 10,
        });

        this.renderText(quantity.toString(), {
          x: 88,
          y: yPosition,
          size: 10,
          boxWidth: 45,
          positionText: "right",
        });

        this.renderText(
          typeof prize === "number" ? prize.toFixed(2) : prize.toString(),
          {
            x: 109,
            y: yPosition,
            size: 10,
            boxWidth: 45,
            positionText: "right",
          }
        );

        this.renderText(vatPercent.toString(), {
          x: 127,
          y: yPosition,
          size: 10,
          boxWidth: 45,
          positionText: "right",
        });

        this.renderText(
          typeof vat === "number" ? vat.toFixed(2) : vat.toString(),
          {
            x: 147,
            y: yPosition,
            size: 10,
            boxWidth: 47,
            positionText: "right",
          }
        );
        this.renderText(
          typeof total === "number" ? total.toFixed(2) : total.toString(),
          {
            x: 167.5,
            y: yPosition + (displayItemsWithWir ? 2 : 0),
            size: 10,
            boxWidth: 55,
            positionText: "right",
          }
        );

        if (displayItemsWithWir) {
          //line separate values

          this.renderLine({
            x: 88,
            y: yPosition + 3.5,
            width: 80,
          });

          //show subheaders
          yPosition += 4.5;

          this.renderText(
            {
              content: this.text.percentageWir,
              size: 8,
              bold: true,
              italic: true,
            },
            {
              x: 88,
              y: yPosition,
              boxWidth: 45,
              positionText: "right",
            }
          );

          this.renderText(
            {
              content: "CHW",
              size: 8,
              bold: true,
              italic: true,
            },
            {
              x: 120,
              y: yPosition,
              boxWidth: 45,
              positionText: "right",
            }
          );

          this.renderText(
            {
              content: "CHF",
              size: 8,
              bold: true,
              italic: true,
            },
            {
              x: 147,
              y: yPosition,
              boxWidth: 47,
              positionText: "right",
            }
          );

          yPosition += 2.5;

          //show values
          this.renderText(`${percentage_wir}`, {
            x: 88,
            y: yPosition,
            size: 10,
            boxWidth: 45,
            positionText: "right",
          });

          this.renderText(`${amountCHW}`, {
            x: 120,
            y: yPosition,
            size: 10,
            boxWidth: 45,
            positionText: "right",
          });

          this.renderText(`${amountCHF}`, {
            x: 147,
            y: yPosition,
            size: 10,
            boxWidth: 47,
            positionText: "right",
          });
        }

        if (i + 1 < items.length) {
          this.renderLine({
            x: 17,
            y: yPosition + 4,
            width: 170,
            dashArray: [2],
          });
        }

        i++;
      }
      return {
        posY: posY + i * 6 + (displayItemsWithWir ? i * 8.5 : 0),
      };
    },

    checkIfUserHasProductsWithWir() {
      //check if the debto has productos with 2 currencies
      let everyProductsAreCHW = this.items.every((element) => {
        return element.percentage_wir && element.percentage_wir == 100;
      });

      let everyProductsAreCHF = this.items.every((element) => {
        return !element.percentage_wir || element.percentage_wir == 0;
      });

      return everyProductsAreCHW || !everyProductsAreCHF;
    },

    setPaymentDetails(posY) {
      const relativeY = posY - 2;
      const obj = {};

      let flagWir =
        this.userHasWir &&
        this.creditor.accountWir &&
        this.checkIfUserHasProductsWithWir();

      if (Object.keys(this.calculations).length > 0) {
        Object.assign(obj, this.calculations);
      } else {
        Object.assign(obj, {
          subTotal: this.fields.paymentDetails.values.subTotal,
          vatTotal: this.fields.paymentDetails.values.vat,
          total: this.fields.paymentDetails.values.total,
        });
      }

      this.renderLine({
        x: 17,
        y: relativeY,
        width: 170,
        thickness: this.mmToPoints(0.25),
      });
      this.renderLine({
        x: 17,
        y: relativeY + 14 + (flagWir ? 4 : 0),
        width: 170,
        thickness: this.mmToPoints(0.25),
      });

      // TODO: set details
      this.renderText(
        {
          content: this.text.amount,
          bold: true,
          size: 10,
        },
        {
          x: 20,
          y: relativeY + 1,
        }
      );

      this.renderText(
        {
          content: this.text.subtotal,
          bold: true,
          size: 10,
        },
        {
          x: flagWir ? 125 : 148,
          y: relativeY + 1,
          boxWidth: 45,
          positionText: "right",
        }
      );
      this.renderText(
        {
          content: this.text.vat,
          bold: true,
          size: 10,
        },
        {
          x: flagWir ? 125 : 148,
          y: relativeY + 5,
          boxWidth: 45,
          positionText: "right",
        }
      );

      this.renderText(
        {
          content: `${this.text.total} ${this.currency}`,
          size: 10,
          bold: true,
        },
        {
          x: flagWir ? 171 : 148,
          y: flagWir ? relativeY + 1 : relativeY + 10,
          boxWidth: 45,
          positionText: "right",
        }
      );

      const subTotalContent =
        typeof obj.subTotal === "number" && !isNaN(obj.subTotal)
          ? obj.subTotal.toFixed(2)
          : typeof obj.subTotal === "object"
          ? obj.vatTotal.content
          : "";

      const vatTotalContent =
        typeof obj.vatTotal === "number" && !isNaN(obj.vatTotal)
          ? obj.vatTotal.toFixed(2)
          : typeof obj.vatTotal === "object"
          ? obj.vatTotal.content
          : "";

      const totalContent =
        typeof obj.total === "number" && !isNaN(obj.total)
          ? obj.total.toFixed(2)
          : typeof obj.total === "object"
          ? obj.total.content
          : "";

      this.renderText(
        {
          content: subTotalContent,
          size: 10,
        } || "0",
        {
          x: flagWir ? 140 : 171,
          y: relativeY + 1,
          positionText: "right",
          boxWidth: 45,
        }
      );
      this.renderText(
        {
          content: vatTotalContent,
          size: 10,
        } || "0",
        {
          x: flagWir ? 140 : 171,
          y: relativeY + 5,
          positionText: "right",
          boxWidth: 45,
        }
      );
      this.renderText(
        {
          content: totalContent,
          size: 10,
        } || "0",
        {
          x: 171,
          y: flagWir ? relativeY + 5 : relativeY + 10,
          positionText: "right",
          boxWidth: 45,
        }
      );

      if (flagWir) {
        const totalAmountCHF =
          typeof obj.total === "number" && !isNaN(obj.amountCHF)
            ? obj.amountCHF.toFixed(2)
            : typeof obj.amountCHF === "object"
            ? obj.amountCHF.content
            : "";

        const totalAmountCHW =
          typeof obj.total === "number" && !isNaN(obj.amountCHW)
            ? obj.amountCHW.toFixed(2)
            : typeof obj.amountCHW === "object"
            ? obj.amountCHW.content
            : "";

        this.renderLine({
          x: 115,
          y: relativeY + 9,
          width: 72,
          thickness: this.mmToPoints(0.25),
        });

        this.renderText(
          {
            content: this.text.amountCHW,
            size: 10,
          } || "0",
          {
            x: 140,
            y: relativeY + 10,
            positionText: "right",
            boxWidth: 45,
          }
        );

        this.renderText(
          {
            content: this.text.amountCHF,
            size: 10,
          } || "0",
          {
            x: 140,
            y: relativeY + 14,
            positionText: "right",
            boxWidth: 45,
          }
        );

        //calculate subtotal CHW AND CHF

        this.renderText(
          {
            content: totalAmountCHW,
            size: 10,
          } || "0",
          {
            x: 171,
            y: relativeY + 10,
            positionText: "right",
            boxWidth: 45,
          }
        );

        this.renderText(
          {
            content: totalAmountCHF,
            size: 10,
          } || "0",
          {
            x: 171,
            y: relativeY + 14,
            positionText: "right",
            boxWidth: 45,
          }
        );
      }

      return {
        posY: relativeY + 10 + (flagWir ? 4 : 0),
      };
    },

    setInvoiceInformation() {
      const sectionY = 112;
      const sectionFinalY = sectionY + 12;
      this.renderLine({
        x: 17,
        y: sectionY,
        width: 170,
      });
      this.renderLine({
        x: 17,
        y: sectionFinalY,
        width: 170,
      });
      this.renderText(
        {
          content: this.text.invoiceDetails.headers.invoiceNumber,
          size: 10,
        },
        {
          x: 18,
          y: sectionY + 2,
          boxWidth: this.mmToPoints(168),
        }
      );
      this.renderText(
        {
          content: this.text.invoiceDetails.headers.customerNumber,
          size: 10,
        },
        {
          x: 18,
          y: sectionY + 2,
          boxWidth: this.mmToPoints(168),
          positionText: "center",
        }
      );
      this.renderText(
        {
          content: this.text.invoiceDetails.headers.payableUntil,
          size: 10,
        },
        {
          x: 18,
          y: sectionY + 2,
          boxWidth: this.mmToPoints(168),
          positionText: "right",
        }
      );
      let auxMap = {
        content: this.text.invoiceDetails.values.invoiceNumber,
        size: 10,
      };
      if (this.debtor) {
        if (this.debtor.invoice_number) {
          Object.assign(auxMap, {
            content: `${this.debtor.invoice_number}`,
          });
        }
      }
      this.renderText(auxMap, {
        x: 18,
        y: sectionY + 6,
        boxWidth: this.mmToPoints(168),
        positionText: "left",
      });
      let auxMap2 = {
        content: this.text.invoiceDetails.values.customerNumber,
        size: 10,
      };
      if (this.debtor) {
        if (this.debtor.client_number) {
          Object.assign(auxMap2, {
            content: `${this.debtor.client_number}`,
          });
        }
      }
      this.renderText(auxMap2, {
        x: 18,
        y: sectionY + 6,
        boxWidth: this.mmToPoints(168),
        positionText: "center",
      });
      let payable_until = "";
      if (this.payable_until) {
        const [year, month, day] = this.payable_until.split("-");
        payable_until = `${day}.${month}.${year}`;
      } else {
        payable_until = this.text.invoiceDetails.values.payableUntil;
      }
      this.renderText(
        {
          size: 10,
          content: payable_until,
          lineHeight: 12,
        },
        {
          x: 18,
          y: sectionY + 6,
          boxWidth: this.mmToPoints(168),
          positionText: "right",
        }
      );
      return {
        initY: sectionY,
        finalY: sectionFinalY,
      };
    },
    renderTable({
      x = null,
      y = null,
      width = null,
      borderWidth = 0,
      height = null,
      color = rgb(0, 0, 0),
      opacity = 1,
    } = {}) {
      const { width: widthPage, height: heightPage } = this.page.getSize();

      this.page.drawRectangle({
        x: this.mmToPoints(x),
        y: heightPage - this.mmToPoints(y) - this.mmToPoints(height),
        width: this.mmToPoints(width),
        height: this.mmToPoints(height),
        color,
        borderWidth,
        opacity,
      });
    },
    renderLine({
      x = 0,
      y = 0,
      width = 0,
      thickness = 0,
      color = rgb(0, 0, 0),
      opacity = 1,
      dashArray = undefined,
    } = {}) {
      const { height } = this.page.getSize();
      const start = {
        x: this.mmToPoints(x),
        y: height - this.mmToPoints(y),
      };
      const end = {
        x: this.mmToPoints(width) + this.mmToPoints(x),
        y: height - this.mmToPoints(y),
      };
      this.page.drawLine({
        start,
        end,
        thickness,
        color,
        opacity,
        dashArray,
      });
    },
    renderText(
      field,
      {
        y = null,
        x = null,
        size = null,
        positionText = "left",
        boxWidth = 30,
        renderBox = false,
        maxWidth,
        maxLimitWidth = null,
        maxCharWidth = null,
      } = {}
    ) {
      let { italic, bold, size: _size, lineHeight, content } = field || "";
      size = _size || size || 5;
      const { width, height } = this.page.getSize();
      let font = this.font;
      if (bold === true) {
        font += "Bold";
      }
      if (italic === true) {
        font += "Italic";
      }
      font = this.customFont[font];
      let textContent = "";
      if (content) textContent = content;
      if (typeof field === "string" && field.length > 0) textContent = field;
      let _positionText = 0;
      const textWidth = font.widthOfTextAtSize(textContent, size);
      const boxHeight = font.heightAtSize(size);
      if (positionText === "left") {
        _positionText = this.mmToPoints(x);
      }

      if (positionText === "center") {
        _positionText = this.mmToPoints(x) + boxWidth / 2 - textWidth / 2;
      }
      if (positionText === "right") {
        _positionText = this.mmToPoints(x) + boxWidth - textWidth;
      }
      if (renderBox) {
        this.page.drawRectangle({
          y: height - this.mmToPoints(y) - size + 3,
          x: this.mmToPoints(x),
          width: boxWidth,
          height: boxHeight,
          borderColor: rgb(1, 0, 0),
        });
      }
      if (typeof maxLimitWidth === "number" && maxLimitWidth > 0) {
        textContent = fillParagraph(textContent, font, size, maxLimitWidth);
        reference = textContent;
      }
      if (typeof maxCharWidth === "number" && maxCharWidth > 0) {
        textContent = wrapText(textContent, maxCharWidth);
      }

      this.page.drawText(textContent, {
        y: height - this.mmToPoints(y) - size + 3,
        x: _positionText,
        size,
        font,
        lineHeight,
        maxWidth,
      });
    },
    ...mapMutations({
      setStoreTemplate: "templateEditor/setTemplate",
      setStoreFont: "templateEditor/setFont",
      setStoreLogo: "templateEditor/setLogo",
      setInvoiceAddress: "templateEditor/setInvoiceAddress",
      setStoreDebtor: "templateEditor/setDebtor",
      setFlagValidationCenterSectionMessage:
        "templateEditor/setFlagValidationCenterSectionMessage",
      setFlagValidationBreakLine: "templateEditor/setFlagValidationBreakLine",
      reinitializeData: "templateEditor/reinitializeData",
    }),
    reinitialize() {
      this.key = this.key++;
    },
    destroyPage(page) {
      // PDFPageProxy#_destroy
      // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
      if (page) page._destroy();
      this.destroyRenderTask();
    },
  },
  beforeDestroy() {
    this.destroyPage(this.PDFJSpage);
    this.reinitializeData();
  },
  props: {
    idTemplate: {},
    renderQR: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    calculations: {
      type: Object,
      default: () => {
        return {};
      },
    },
    debtor: {
      type: Object,
      default: () => {
        return {};
      },
    },
    logo: {
      type: Object,
      default: () => {
        return {
          url: "",
          positionX: 0,
          positionY: 0,
        };
      },
    },
    subject: {
      type: String,
      default: "",
    },
    test: {
      type: String,
      default: "",
    },
    presentation: {
      type: String,
      default: "",
    },
    bottomComment: {
      type: String,
      default: "",
    },
    contactDetails: {
      type: String,
      default: "",
    },
    qrCode: {
      default: null,
    },
    cardFlat: {
      type: Boolean,
      default: false,
    },
    myFont: {
      type: String,
      default: "ArialNarrow",
    },
    hiddeProducts: {
      type: Boolean,
      default: false,
    },
    showDummyItems: {
      type: Boolean,
      default: false,
    },
    invoice_date: {
      type: String,
      default: null,
    },
    payable_until: {
      type: String,
      default: null,
    },
    renderLogoRectangle: {
      type: Boolean,
      default: true,
    },
    onlyQR: {
      default: false,
      type: Boolean,
    },
    zoom: {
      type: Number,
      default: 0,
    },
    noScroll: {
      type: Boolean,
      default: true,
    },
    maxSizeDocument: {
      default: true,
      type: Boolean,
    },
    currency: {
      default: "CHF",
      type: String,
    },
  },
};

//
</script>

<style></style>