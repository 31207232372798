<template>
    <div>
        <v-row>
            <v-col>
                <span>This method can be used to generate references.</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <api-page-description 
                :items="items"
                :title="'Request Arguments:'"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <api-page-description 
                :items="items_example"
                :title="'Response Arguments:'"
                />
            </v-col>
            <v-col>
                <api-page-example
                :items="items_example" 
                :title="'Response Example:'"
                />                    
            </v-col>
        </v-row>
    </div>
</template>

<script>
import APIPageDesc from './default/API.Page.description.vue'
import APIPageExm from './default/API.Page.Example.vue' 
export default {
    name:'APIRG',
    components:{
        'api-page-description':APIPageDesc,
        'api-page-example':APIPageExm
    },
    data(){
        return {
            updateKey:0,
            items:[
                {   
                    key:1,
                    name:"type",
                    value:"[QRR/SCOR]",
                    title:'Type',
                    requirement:'Mandatory',
                    type:'String',
                    description:'The type of reference to generate.\nThe possible values are: ["QRR","SCOR"].'
                },
                {
                    key:2,
                    name:"id",
                    value:"[number]",
                    title:'Custom id',
                    requirement:'Optional',
                    type:'Number',
                    description:`The id to use to generate reference. \n i
                     if the reference type is QRR the maximum length of this id is 10, other hand if is SCOR the length is 6`
                },
                {
                    key:3,
                    name:"date",
                    value:"[date]",
                    title:'Date',
                    requirement:'Optional',
                    type:'Date',
                    description:`The date to use to generate reference. \n The format of date is DD/MM/YYYY`
                }
            ],
            items_example:[
                {
                    key:1,
                    name:'status',
                    value:'200',
                    title:'Status',
                    type:'Number',
                    description:'The http response status.'
                },
                {
                    key:2,
                    name:'data',
                    title:'Data',
                    type:'Object',
                    description:'The response object containing parameters useful to the user.',
                    children:[
                        { 
                            key:31,
                            name:'reference',
                            title:'Reference',
                            description:'The reference generated',
                            value:'number',
                            type:'String'
                        },
                    ]
                }
            ],
        }
    }
}
</script>
