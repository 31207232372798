<template>
    <div>
        <v-card height="100%" :key="key" :flat="cardFlat">
            <div ref="wrapper" v-if="!loading">
                <canvas ref="pdf"></canvas>
            </div>
            <div v-if="loading" class="d-flex justify-center align-center" :style="`width:100%; height:100%`" >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
        </v-card>
    </div>
</template>

<script>
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from '@pdf-lib/fontkit';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { base64ToArrayBuffer, base64toBlob, debounce, fillParagraph, getDate, getPicDimensions, isBase64, wrap, wrapText } from '@/mixins/functions';
const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc =
  '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.943/pdf.worker.js'
export default {
    data(){
        return {
            loading:true,
            myClimateLogo: require('../../../../assets/images/default/myclimate_gross.jpg'),
            qrLogo: require('../../../../assets/images/default/qrLogoComplete.png'),
            roundedRectangle: require('../../../../assets/img/rounded_rectangle.png'),
            AStamp: require('../../../../assets/images/default/A_stamp.png'),
            BStamp: require('../../../../assets/images/default/B_stamp.png'),
            pdfBytes: "",
            fontBytes: {},
            customFont: {},
            key: 0,
            pdfSizes: {
                height: 180,
                width: 225
            },        
            timesRomanFont:null    
        }
    },
    created() {
        PDFJS.workerSrc = 'pdfjs-dist/build/pdf.worker.js';
    },
    watch:{
        cardFlat(){
            this.init();
        },
        p_logo(newValue,oldValue){
            if(newValue){
                if(newValue.type === 'image/png' || newValue.type === 'image/jpeg'){
                    this.init();
                }
            }else{
                this.init();
            }
        },
        address_message(){
            this.init();
        },
        enable_myclimate(){
            this.init();
        },
        font_size(){
            this.init();
        },
        font(){
            this.init();
        },
        hideQRLogo(){
            this.init();
        },
        selectedStamp(){
            this.init();
        }
    },
    computed:{
      ...mapGetters({
        fonts: 'templateEditor/fonts',
      }),     
    },
    props:{
        cardFlat:{
            type: Boolean,
            default:false
        },
        p_logo:{
            type:File,
            default:null
        },
        address_message:{
            type:String,
            default:''
        },
        enable_myclimate:{
            type:Boolean,
            default:true
        },
        font_size:{
            type:Number,
            default:12
        },
        font:{
            type:String,
            default:'PlayFair'
        },
        hideQRLogo:{
            type:Boolean,
            default:false,
        },
        selectedStamp:{
            type:String,
            default:''
        }
    },
    methods:{
        init: debounce(function() { this.setSettingsPDF(); }, 600),
        async setSettingsPDF() {
            this.loading = true;
            new Promise(async (resolve, reject) => {
                this.pdfDoc = await PDFDocument.create();
                await this.setFonts(); // generate all the fonts that are needed and PDF-Lib doesnt support them.
                this.timesRomanFont = await this.pdfDoc.embedFont(
                    StandardFonts.TimesRoman
                );
                this.page = this.pdfDoc.addPage();
                this.page.setSize(this.pdfSizes.width,this.pdfSizes.height);
                const { width, height } = this.page.getSize();
                this.pdfSizes.height = height;
                this.pdfSizes.width = width;
                await this.generatePage();
                const pdfBytes = await this.pdfDoc.save({ useObjectStreams: true }); // it is suppoused that useObjectStreams will make this process faster
                var bytes = new Uint8Array(pdfBytes);
                var blob = new Blob([bytes], { type: "application/pdf" });
                this.renderPDF(URL.createObjectURL(blob));
                resolve(true)
            })
            .then(() => {
                this.loading = false;
            })
        },
        async generatePage() {
            this.page.drawLine({
                start: { x:0, y:0},
                end: { x:0, y:this.pdfSizes.height },
                thickness:2
            });
            this.page.drawLine({
                start: { x:0, y:this.pdfSizes.height },
                end: { x:this.pdfSizes.width, y:this.pdfSizes.height},
                thickness:2
            });
            this.page.drawLine({
                start: { x:this.pdfSizes.width, y:this.pdfSizes.height },
                end: { x:this.pdfSizes.width, y:0},
                thickness:2
            });
            this.page.drawLine({
                start: { x:this.pdfSizes.width, y:0 },
                end: { x:0, y:0},
                thickness:2
            });
            let documentY = this.pdfSizes.height - this.calculateNewScale(10);
            if(this.p_logo){
                let imageURL = URL.createObjectURL(this.p_logo);
                const UserLogoBytes = await fetch(imageURL).then((res) => res.arrayBuffer());
                let pdfUserLogo = null;
                if(this.p_logo.type === 'image/png') pdfUserLogo =  await this.pdfDoc.embedPng(UserLogoBytes);
                if(this.p_logo.type === 'image/jpeg') pdfUserLogo = await this.pdfDoc.embedJpg(UserLogoBytes);
                if(pdfUserLogo){
                    let { width, height } = pdfUserLogo.size();
                    let newScale = this.getNewScale(width,height,50);
                    let UserLogoDims = pdfUserLogo.scale(newScale);
                    documentY = documentY - UserLogoDims.height;
                    this.page.drawImage(pdfUserLogo, {
                        x: this.calculateNewScale(10),
                        y: documentY,
                        width: UserLogoDims.width,
                        height: UserLogoDims.height,
                    });      
                }
                
            }else{
                if(!this.hideQRLogo){
                    const QrLogoBytes = await fetch(this.qrLogo).then((res) => res.arrayBuffer());
                    const pdfQrLogo = await this.pdfDoc.embedPng(QrLogoBytes);
                    let { width, height } = pdfQrLogo.size();
                    let newScale = this.getNewScale(width,height,50);         
                    const QrLogoDims = pdfQrLogo.scale(newScale);
                    documentY = documentY - QrLogoDims.height;
                    this.page.drawImage(pdfQrLogo, {
                        x: this.calculateNewScale(10),
                        y: documentY,
                        width: QrLogoDims.width,
                        height: QrLogoDims.height,
                    });    
                }
            }
            let selectedFont = this.customFont[this.font] ? this.customFont[this.font] : this.customFont['PlayFair'];
            let selectedFontSize = this.font_size ? this.font_size : 12;
            selectedFontSize = selectedFontSize <= 0 ? 12 : selectedFontSize;
            if(this.address_message){
                let currentSpacing = (8/12) * selectedFontSize;
                let splitMessage = this.address_message.split('\n');
                for(let i = 0;i<splitMessage.length;i++){
                    documentY = documentY - currentSpacing; 
                    let currentMessage = splitMessage[i];
                    this.page.drawText(currentMessage, {
                        y: documentY,
                        x: this.calculateNewScale(10), 
                        lineHeight: 7,
                        size: selectedFontSize/2.5,
                        font: selectedFont
                    });
                }
            }

            // LOAD MY CLIMATE LOGO
            if(this.enable_myclimate){
                const myClimateLogoBytes = await fetch(this.myClimateLogo).then((res) => res.arrayBuffer());
                const myClimateLogo = await this.pdfDoc.embedJpg(myClimateLogoBytes);
                let { width, height } = myClimateLogo.size();
                let newScale = this.getNewScale(width,height,50);  
                const MyClimateDims = myClimateLogo.scale(newScale);
                this.page.drawImage(myClimateLogo, {
                    x: this.calculateNewScale(10),
                    y: this.calculateNewScale(10),
                    width: MyClimateDims.width,
                    height: MyClimateDims.height,
                });
            }

            if(this.selectedStamp === 'a' || this.selectedStamp === 'b'){
                let currentStamp = this.selectedStamp === 'a' ? this.AStamp : this.BStamp;
                const myStampBytes = await fetch(currentStamp).then((res) => res.arrayBuffer());
                const myStampLogo = await this.pdfDoc.embedPng(myStampBytes);
                let { width, height } = myStampLogo.size();
                let newScale = this.getNewScale(width,height,107);
                const myRectangleDims = myStampLogo.scale(newScale);
                this.page.drawImage(myStampLogo, {
                    x: this.calculateNewScale(107),
                    y: this.calculateNewScale(145),
                    width: myRectangleDims.width,
                    height: myRectangleDims.height,
                }); 
            }

            const myRectangleLogoBytes = await fetch(this.roundedRectangle).then((res) => res.arrayBuffer());
            const myRectangleLogo = await this.pdfDoc.embedPng(myRectangleLogoBytes);
            let { width, height } = myRectangleLogo.size();
            let newScale = this.getNewScale(width,height,107);
            const myRectangleDims = myRectangleLogo.scale(newScale);
            this.page.drawImage(myRectangleLogo, {
                x: this.calculateNewScale(107),
                y: this.calculateNewScale(70),
                width: myRectangleDims.width,
                height: myRectangleDims.height,
            });               
        },
        async setFonts() {
            this.pdfDoc.registerFontkit(fontkit);
            await Promise.all(Object.keys(this.fonts).map(async font => {
                let fontBytes = null;
                if(!(font in this.customFont)) {
                    fontBytes = await fetch(this.fonts[font]).then(res => res.arrayBuffer());
                    this.fontBytes[font] = fontBytes;
                }
                if(font in this.customFont) {
                    fontBytes = this.fontBytes[font];
                }
                this.customFont[font] = await this.pdfDoc.embedFont(fontBytes);
            }))
        },
        async renderPDF(URL) {
            this.loading = true;
            const loadingTask = PDFJS.getDocument(URL);
            const pdf = await loadingTask.promise;
            // Load information from the first page.
            this.PDFJSpage = await pdf.getPage(1);
            const scale = 3;
            const viewport = this.PDFJSpage.getViewport(scale);
            if(this.renderTask) return;
            // Apply page dimensions to the <canvas> element.
            const canvas = this.$refs.pdf;
            if(canvas){
                const wrapper = this.$refs.wrapper;
                const context = canvas.getContext("2d");
                canvas.height = viewport.height; 
                canvas.width = viewport.width;       
                this.cardStyles = {
                    height: `${this.pdfSizes.height}px`,
                    width: `${this.pdfSizes.width}px`,
                }
                // Render the page into the <canvas> element.
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };
                // await this.PDFJSpage.render(renderContext);
                this.renderTask = this.PDFJSpage.render(renderContext);
                this.renderTask
                .then(() => {
                    this.destroyRenderTask();
                    this.loading = false;
                })
                .catch(error => {
                    // TODO: maybe display to the user the pdf couldn't be rendered???
                    this.destroyRenderTask();
                    this.loading = false;
                })

            }
        },
        getNewScale(x,y,limit){
            let highestNumber = x >= y ? x : y;
            if(highestNumber <= limit) return 1;
            let absDif = Math.abs(limit - highestNumber);
            return (highestNumber - absDif) / highestNumber;
        },
        calculateNewScale(value){
            return ((0.353 * value * 649.13)/this.pdfSizes.width);
        },
        destroyRenderTask() {
            if (!this.renderTask) return;
            // RenderTask#cancel
            // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
            this.renderTask.cancel();
            delete this.renderTask;
        },
        reinitialize() {
            this.key = this.key++;
        },
        destroyPage(page) {
            // PDFPageProxy#_destroy
            // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
            if (page) page._destroy();
            this.destroyRenderTask();
        },
    }
}
</script>