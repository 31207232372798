<template>
    <div>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>{{$t("Admin.GeneralUsageInfo.users")}}</v-card-title>
                    <v-card-text>
                        <h2>{{$t("Admin.GeneralUsageInfo.total_users")}}: {{totalUsers}}</h2>
                        <v-row>
                            <v-col>
                                <h2 class="pt-0">{{$t("Admin.GeneralUsageInfo.users_status")}}:</h2>
                                <div id="users-status-counts"></div>
                            </v-col>
                            <v-col>
                                <h2 class="pt-0">{{$t("Admin.GeneralUsageInfo.users_product")}}:</h2>
                                <div id="users-memberships-counts"></div>
                            </v-col>
                            <v-col>
                                <h2 class="pt-0">{{$t("Admin.GeneralUsageInfo.users_language")}}:</h2>
                                <div id="users-language-counts"></div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>{{$t("Admin.GeneralUsageInfo.invoices")}}</v-card-title>
                    <v-card-text>
                        <h2>{{$t("Admin.GeneralUsageInfo.total_invoices")}}: {{totalInvoices}}</h2>
                        <v-row>
                            <v-col>
                                <h2 class="pt-0">{{$t("Admin.GeneralUsageInfo.invoices_product")}}:</h2>
                                <div id="invoices-memberships-counts"></div>
                            </v-col>
                            <v-col>
                                <h2 class="pt-0">{{$t("Admin.GeneralUsageInfo.invoices_language")}}:</h2>
                                <div id="invoices-language-counts"></div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>{{$t("Admin.GeneralUsageInfo.month_transactions")}}</v-card-title>
                    <v-card-text>
                        <v-data-table
                        :no-data-text="$t('qrGenerator.productsTable.data_available')"
                        :headers="headers.transactionsHeaders"
                        :items="transactionsItems"
                        :loading="loadState"
                        
                        multi-sort
                        ></v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>{{$t("Admin.GeneralUsageInfo.expired_qrbills")}}</v-card-title>
                    <v-card-text>
                        <v-data-table
                        :no-data-text="$t('qrGenerator.productsTable.data_available')"
                        :headers="headers.qrBillsHeaders"
                        :items="qrBillsItems"
                        :loading="loadStateBills"
                        :custom-sort="SortUsers"
                        multi-sort
                        ></v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>{{$t("Admin.GeneralUsageInfo.arrears")}}</v-card-title>
                    <v-card-text>
                        <v-data-table
                        :no-data-text="$t('qrGenerator.productsTable.data_available')"
                        :headers="headers.remindersHeaders"
                        :items="remindersItems"
                        :loading="loadStateBills"
                        multi-sort
                        ></v-data-table>
                    </v-card-text>
                </v-card>                 
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name:`GeneralUsageInfo`,
    data(){
        return {
            loadState:false,
            loadStateBills:false,
            totalInvoices:0,
            totalUsers:0,

            transactionsItems:[],
            qrBillsItems:[],
            remindersItems:[]
        }
    },
    computed:{
        headers(){
            return{
                transactionsHeaders:[
                    {text:'ID',value:'id'},
                    {text:this.$t('Admin.GeneralUsageInfo.subject'),value:'subject'},
                    {text:this.$t('Admin.GeneralUsageInfo.status'),value:'status'},
                    {text:this.$t('Admin.GeneralUsageInfo.amount'),value:'amount'},
                    {text:this.$t('Admin.GeneralUsageInfo.date'),value:'date'},
                    {text:this.$t('Admin.GeneralUsageInfo.user'),value:'user'}
                ],
                qrBillsHeaders:[
                    {text:'ID',value:'id'},
                    {text:this.$t('Admin.GeneralUsageInfo.bill_name'),value:'name'},
                    {text:this.$t('Admin.GeneralUsageInfo.creation_date'),value:'creation_date'},
                    {text:this.$t('Admin.GeneralUsageInfo.expiration_date'),value:'expiration_date'},
                    {text:this.$t('Admin.GeneralUsageInfo.user'),value:'user'}
                ],
                remindersHeaders:[
                    {text:'ID',value:'id'},
                    {text:this.$t('Admin.GeneralUsageInfo.first_name'),value:'name'},
                    {text:this.$t('Admin.GeneralUsageInfo.last_name'),value:'last_name'},
                    {text:this.$t('Admin.GeneralUsageInfo.email'),value:'email'},
                    {text:this.$t('Admin.GeneralUsageInfo.reminders'),value:'reminder'},
                    {text:this.$t('Admin.GeneralUsageInfo.bill_name'),value:'bill_name'}
                ],
            }
        }
    },
    methods:
    {
    SortUsers(items,index,isDesc){
    
     for(let i = 0;i<index.length;i++){
        let currentIndex = index[i];
        let currentIsDesc = isDesc[i];
        items.sort((a,b)=>{
          if(currentIndex === 'creationDate' || currentIndex === 'expirationDate'){
            if(currentIsDesc) return new Date(`${b[currentIndex]} UTC`) - new Date(`${a[currentIndex]} UTC`);
            return new Date(`${a[currentIndex]} UTC`) - new Date(`${b[currentIndex]} UTC`);
          }else if(currentIndex == 'id'){
            if(currentIsDesc) return b[currentIndex] - a[currentIndex];
            return a[currentIndex] - b[currentIndex];
          }else{
            if(currentIsDesc) return a[currentIndex].toLowerCase().localeCompare(b[currentIndex].toLowerCase());
            return b[currentIndex].toLowerCase().localeCompare(a[currentIndex].toLowerCase());
          }
        });
      }
      return items;
    },

    },
    mounted(){
        this.loadState = true;
        this.loadStateBills = true;
        this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/gui`)
        .then(async(response)=>{
            if(response){
                this.totalUsers = response.users.total;
                if(response.users.status){
                    if(response.users.status.length > 0){
                        let userStatusElement = document.getElementById('users-status-counts');
                        let htmlString = '<ul>';
                        for(let i = 0;i<response.users.status.length;i++){
                            let currentStatus = await response.users.status[i];
                            htmlString += await `<li><h3>${currentStatus.name}: <span>${currentStatus.amount}</span></h3></li>`
                        }
                        htmlString += `</ul>`
                        userStatusElement.innerHTML = htmlString;
                    }
                }
                if(response.users.memberships){
                    if(response.users.memberships.length > 0){
                        let userMembershipsElement = document.getElementById('users-memberships-counts');
                        let htmlString = '<ul>';
                        for(let i = 0;i<response.users.memberships.length;i++){
                            let currentlanguage = await response.users.memberships[i];
                            htmlString += await `<li><h3>${currentlanguage.name}: <span>${currentlanguage.amount}</span></h3></li>`
                        }
                        htmlString += '</ul>';
                        userMembershipsElement.innerHTML = htmlString;
                    }
                }
                if(response.users.languages){
                    if(response.users.languages.length > 0){
                        let userLanguageElement = document.getElementById('users-language-counts');
                        let htmlString = '<ul>';
                        for(let i = 0;i<response.users.languages.length;i++){
                            let currentMembership = await response.users.languages[i];
                            htmlString += await `<li><h3>${currentMembership.name}: <span>${currentMembership.amount}</span></h3></li>`                                
                        }
                        htmlString += '</ul>';
                        userLanguageElement.innerHTML = htmlString;
                    }
                }
                if(response.transactions){
                    if(response.transactions.length > 0){
                        for(let i = 0;i<response.transactions.length;i++){
                            let currentTransactions = response.transactions[i];
                            let currentDate = new Date(currentTransactions.date);
                            await this.transactionsItems.push({
                                id:currentTransactions.id,
                                subject:currentTransactions.subject,
                                status:currentTransactions.status,
                                amount:currentTransactions.amount ? `CHF ${currentTransactions.amount/100}.00` : `0`,
                                date:`${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`,
                                user:currentTransactions.user
                            })
                        }
                    }
                    this.loadState = false;
                }
                if(response.bills){
                    if(response.bills.length > 0){
                        for(let i = 0;i<response.bills.length;i++){
                            let currentBill = response.bills[i];
                            //CreationDate
                            let newCDate = new Date(currentBill.creation_date);
                            let newCDates = `${newCDate.getDate()}`.padStart(2,'0');
                            let newCDateMonth = `${newCDate.getMonth() + 1}`.padStart(2,'0');
                            let newCDateHours = `${newCDate.getHours()}`.padStart(2,'0');
                            let newCdateMinutes =  `${newCDate.getMinutes()}`.padStart(2,'0');

                            //ExprationDate
                                let newEDate = new Date(currentBill.expiration_date);
                                let newEDates = `${newEDate.getDate()}`.padStart(2,'0');
                                let newEdateMonth = `${newEDate.getMonth() + 1}`.padStart(2,'0');
                                let newEdateHours =  `${newEDate.getHours()}`.padStart(2,'0');
                                let newEdateMinutes = `${newEDate.getMinutes()}`.padStart(2,'0');

                            await this.qrBillsItems.push({
                                id:currentBill.id,
                                name:currentBill.name,
                               
                               creation_date:`${newCDates }/${newCDateMonth}/${newCDate.getFullYear()} ${newCDateHours}:${newCdateMinutes}`,
                                expiration_date:`${newEDates }/${newEdateMonth}/${newEDate.getFullYear()} ${newEdateHours}:${newEdateMinutes}`,
                                user:currentBill.user
                            });
                        }
                    }
                    this.loadStateBills = false;
                }
                if(response.reminders){
                    if(response.reminders.length > 0){
                        for(let i = 0;i<response.reminders.length;i++){
                            let currentReminder = response.reminders[i];
                            this.remindersItems.push({
                                id:currentReminder.id,
                                name:currentReminder.name,
                                last_name:currentReminder.last_name,
                                email:currentReminder.email,
                                reminder:currentReminder.reminder,
                                bill_name:currentReminder.bill
                            })
                        }
                    }
                }
                if(response.invoices){
                    if(response.invoices.total){
                        this.totalInvoices = response.invoices.total;
                    }
                    if(response.invoices.membership){
                        let invoicesMembershipElement = document.getElementById('invoices-memberships-counts');
                        if(response.invoices.membership.length > 0){
                            let htmlString = '<ul>';
                            for(let i = 0;i<response.invoices.membership.length;i++){
                                let currentlanguage = await response.invoices.membership[i];
                                htmlString += await `<li><h3>${currentlanguage.name}: <span>${currentlanguage.amount}</span></h3></li>`
                            }
                            htmlString += '</ul>';
                            invoicesMembershipElement.innerHTML = htmlString;                                
                        }
                    }
                    if(response.invoices.languages){
                        let invoicesLanguageElement = document.getElementById('invoices-language-counts');
                        if(response.invoices.languages.length > 0){
                            let htmlString = '<ul>';
                            for(let i = 0;i<response.invoices.languages.length;i++){
                                let currentlanguage = await response.invoices.languages[i];
                                htmlString += await `<li><h3>${currentlanguage.name}: <span>${currentlanguage.amount}</span></h3></li>`
                            }
                            htmlString += '</ul>';
                            invoicesLanguageElement.innerHTML = htmlString;                                
                        }
                    }
                }
            }
        })
        .catch(this.$handleCatch)
    }
}
</script>

<style>

</style>
