const { createCanvas, loadImage } = require('canvas');
const languageData = require('../../../languages.json')
const canvas = createCanvas(196.53543307, 359.05511811, 'pdf')
const User = {
    namespaced: true,
    state: {
        creditor: {
            name: "",
            last_name: "",
            address: {
              street: '',
              number: '',
              zip: '',
              city: "",
              country: "CH"
            },
            account: "",
            accountWir:"",
            qr_account: '',
            qr_account_wir:'',
            account_name: '',
            numberCounter: 16,
            addressType: '',
            typeReference: '',
            referenceMask: '',
            referenceCounter: 32,
            qrIBAN: '',
            accountIBAN: '',
        },
        account: '',
        qr_account: '',
        numberuid: '',
        referen: '',
        reference: '',
        login: Number,
        username: undefined,
        items:[],
        subscription : '',
        subscriptionName:'',
        activeAccount:'',
        language:'de',
        languageData: languageData,
        languageChangePermission: false,
        oldTypeReference: '',
        process_guided: false,
        wir_active:false,
        user: {
            email: '',
            email2: '',
            password: '',
            password2: '',
            addressSuffixText: '',
            street: '',
            number: '',
            zip: '',
            city: '',
            country: '',
            legalFormSelected: '',
            legalFormOthersText: '',
            name: '',
            lastname: '',
            industrySelected: '',
            industryOthersText: '',
            numberMembersSelected: '',
            numberEmployeesSelected: '',
            UIDText: '',
            referenceText: '',
            salutationText: '',
            companyText: '',
            paymentSelected: '',

            legalFormOthersStatus: false,
            nameStatus: false,
            industryStatus: false,
            industryOthersStatus: false,
            
            numberMembersStatus: false,
            numberEmployeesStatus: false,
            UIDStatus: false,
            salutationStatus: false,
            companyStatus: false,
            
            checkout:'',
        },
    },
    mutations: {
        setOldTypeReference: (state,payload) => {
            state.oldTypeReference = payload;
        },
        setLogin(state, payload) {
            state.login = payload.code;
            state.username = payload.user;
        },

        setAccountWir(state,payload){
            state.creditor.accountWir = payload;
        },

        setWir(state,payload){
            state.wir_active = payload;
        },
        setItems(state,payload){
            state.items = payload.items;
        },
        setSubscription(state,payload){
            state.subscription = payload.value;
        },
        setSubscriptionName(state,payload){
            state.subscriptionName = payload || state.subscriptionName;
        },
        setActiveAccount(state,payload){
            state.activeAccount = payload || state.activeAccount;
        },
        setLanguage(state, payload) {
            state.language = payload || 'de';
        },
        setCheckout(state, payload) {
            state.user.checkout = payload;
        },
        setAccountName(state, payload) {
            state.creditor.account_name = payload || state.creditor.account_name;
        },
        setReferenceMask(state, payload) {
            state.creditor.referenceMask = payload || '';
        },
        setReferenceCounter(state, payload) {
            state.creditor.referenceCounter = payload || 32;
        },
        setTypeReference(state,payload){
            state.creditor.typeReference = payload
        }
    },
    getters: {
        creditor: state => state.creditor,
        user: state => state,
        creditorReceiptPartHTML: state => {
            let htmlCreditor = { 
                fullName: '',
                streetAndNumber: '',
                zipAndTown: '',
                country: '',
            };
            var ctx = canvas.getContext("2d");
            ctx.font = "8pt Helvetica";
            let maxLength = 196.535433;
            // Joining both properties in only one line
            let fullName = '';
            if(state.creditor.name !== undefined && state.creditor.name !== '' && state.creditor.name !== null){
                if(state.creditor.last_name !== undefined && state.creditor.last_name !== '' && state.creditor.last_name !== null){
                    fullName = `${state.creditor.name} ${state.creditor.last_name}`
                }else{
                    fullName = `${state.creditor.name} `
                }
            }else{
                if(state.creditor.last_name !== undefined && state.creditor.last_name !== '' && state.creditor.last_name !== null){
                    fullName = `${state.creditor.last_name} `
                }
            }
            if(ctx.measureText(fullName).width > (maxLength * 2)){
                if(state.creditor.last_name !== undefined && state.creditor.last_name !== '' && state.creditor.last_name !== null){
                    fullName = `${state.creditor.last_name} `
                }else{
                    fullName = `${state.creditor.name} `
                }
            }
            let streetAndNumber = '';
            streetAndNumber = state.creditor.address.street ? `${state.creditor.address.street}` :'';
            streetAndNumber += streetAndNumber ? ` ` : '';
            streetAndNumber += state.creditor.address.number ? `${state.creditor.address.number}` : ''
            let zipAndTown = '';
            zipAndTown = state.creditor.address.zip ? `${state.creditor.address.zip}` : '';
            zipAndTown += zipAndTown ? ' ' : '';
            zipAndTown += state.creditor.address.city ? `${state.creditor.address.city}` : '';
            let country = state.creditor.address.country != null ? `<tspan x="0mm" dy="8pt" class="value-r" >${state.creditor.address.country}</tspan>` : '';
            // These varialbes will be used to concat the html tag with their content
            let newStreetAndNumber = '';
            let newZipAndTown = '';

            // Split the string in characters of 29, the result will be arrays of strings.
            //let chunksFullName = fullName.match(/.{1,36}/g);
            let chunksStreetAndNumber = streetAndNumber.match(/.{1,35}/g) ? streetAndNumber.match(/.{1,35}/g) : [];
            let chunksZipAndTown = zipAndTown.match(/.{1,35}/g) ? zipAndTown.match(/.{1,35}/g) : [];
            // TODO: remove street & number when full name its more than 2 lines
            // adding the html tags to its value
            let nameArray = fullName.split(' ');
            let auxName = '';
            let finalNameString = '';
            nameArray.forEach((element,index)=>{
                let auxiliar = '';
                if(index == 0){
                    if(ctx.measureText(element).width > maxLength){
                        let elementChunk = element.split('');
                        elementChunk.forEach(chunk=>{
                            auxiliar = `${auxName}${chunk}`
                            if(ctx.measureText(auxiliar).width > maxLength){
                                finalNameString += `<tspan x="0mm" dy="9pt"  class="is-value-r" >${auxName}</tspan>`;
                                auxName = chunk;
                            }else{
                                auxName += `${chunk}`;
                            }
                            
                        })
                    }else{
                        auxName += `${element}`
                    }
                }else{
                    auxiliar = `${auxName} ${element}`;
                    if(ctx.measureText(auxiliar).width > maxLength){
                        finalNameString += `<tspan x="0mm" dy="8pt"  class="is-value-r" >${auxName}</tspan>`;
                        auxName = `${element}`;
                    }else{
                        auxName += ` ${element}`;
                    }
                    if(index == (nameArray.length - 1)){
                        finalNameString += `<tspan x="0mm" dy="8pt"  class="is-value-r" >${auxName}</tspan>`;
                    }

                }
            })
            if(ctx.measureText(fullName).width < (maxLength * 2)) {
                chunksStreetAndNumber.forEach(chunk => {
                    newStreetAndNumber += `<tspan x="0mm" dy="8pt" class="value-r" >${chunk}</tspan>`; 
                })
            }

            chunksZipAndTown.forEach(chunk => {
                newZipAndTown += `<tspan x="0mm" dy="8pt" class="value-r" >${chunk}</tspan>`; 
            })

            htmlCreditor.fullName = finalNameString;
            htmlCreditor.streetAndNumber = newStreetAndNumber;
            htmlCreditor.zipAndTown = newZipAndTown;
            htmlCreditor.country = country;

            return htmlCreditor;
        },

        creditorPaymentPartHTML: state => {
            let htmlCreditor = { 
                fullName: '',
                streetAndNumber: '',
                zipAndTown: '',
                country: '',
            };

            // TODO: When the surname + name > 3 lines, we can remove the 
            // name (but not the address), only on the right side which is 
            // the payment part.

            // Joining both properties in only one line
            let fullName = '';
            fullName = state.creditor.name ? `${state.creditor.name}` : '';
            fullName += fullName ? ' ' : '';
            fullName += state.creditor.last_name ? `${state.creditor.last_name}` : '';

            let streetAndNumber = '';
            streetAndNumber = state.creditor.address.street ? `${state.creditor.address.street}` :'';
            streetAndNumber += streetAndNumber ? ` ` : '';
            streetAndNumber += state.creditor.address.number ? `${state.creditor.address.number}` : ''

            let zipAndTown = '';
            zipAndTown = state.creditor.address.zip ? `${state.creditor.address.zip}` : '';
            zipAndTown += zipAndTown ? ' ' : '';
            zipAndTown += state.creditor.address.city ? `${state.creditor.address.city}` : '';

            let country = state.creditor.address.country != null ? `<tspan x="0mm" dy="11pt" class="is-value-p" >${state.creditor.address.country}</tspan>` : '';

            // These varialbes will be used to concat the html tag with their content
            let newFullName = '';
            let newStreetAndNumber = '';
            let newZipAndTown = '';

            // Split the string in characters of 29, the result will be arrays of strings.
            let chunksFullName = fullName.match(/.{1,35}/g) ? fullName.match(/.{1,35}/g) : [];
            let chunksFirtstName = state.creditor.name.match(/.{1,35}/g) ? state.creditor.name.match(/.{1,35}/g) : [];
            let chunksStreetAndNumber = streetAndNumber.match(/.{1,37}/g) ? streetAndNumber.match(/.{1,37}/g) : [];
            let chunksZipAndTown = zipAndTown.match(/.{1,37}/g) ? zipAndTown.match(/.{1,37}/g) : [];

            
            if(chunksFullName.length < 2) {
                chunksFullName.forEach(chunk => {
                    newFullName += `<tspan x="0mm" dy="11pt" class="is-value-p" >${chunk}</tspan>`;
                });
            } else {
                newFullName += `<tspan x="0mm" dy="11pt" class="is-value-p" >${state.creditor.name}</tspan>
                    <tspan x="0mm" dy="11pt" class="is-value-p" >${state.creditor.last_name}</tspan>`
                // chunksFirtstName.forEach(chunk => {
                // });
            }

            // adding the html tags to its value
            chunksStreetAndNumber.forEach(chunk => {
                newStreetAndNumber += `<tspan x="0mm" dy="11pt" class="is-value-p" >${chunk}</tspan>`; 
            })

            chunksZipAndTown.forEach(chunk => {
                newZipAndTown += `<tspan x="0mm" dy="11pt" class="is-value-p" >${chunk}</tspan>`; 
            })

            htmlCreditor.fullName = newFullName;
            htmlCreditor.streetAndNumber = newStreetAndNumber;
            htmlCreditor.zipAndTown = newZipAndTown;
            htmlCreditor.country = country;

            return htmlCreditor;
        },

        items: state => state.items,
        activeAccount: state=> state.activeAccount,
        subscriptionName: state=> state.subscriptionName,
        subscription: state => state.subscription,
        getLanguage: state => state.language,
        getLanguageData: state => state.languageData[state.language],
        getLanguageDataFree: state => state.languageData[state.language || 'german'].qrGeneratorFree,
        user: state => state.user,
        username: state => state.username,
    }
}

export default User;
