<template>
    <svg version="1.2"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="210mm" :height=" withWir ? '130mm' : '110mm' " >
        <line y1="5mm" y2="5mm" x1="0mm" x2="210mm" stroke="black" height="0.5mm" stroke-dasharray="5,5" d="M5 20 l215 0" ></line>
        <text x="25mm" y="5mm" text-anchor="middle" style="transform: rotate(45deg) rotate(-41.8deg);transform-origin: 0% 0%;">✂</text>            
        <line x1="62mm" x2="62mm" y1="5mm" y2="110mm" stroke="black" height="0.5mm" stroke-dasharray="5,5" d="M5 20 l215 0" ></line>
        <text :x="withWir?'8mm':'3mm'" :y="withWir?'56mm':'50mm'" text-anchor="middle" style="transform: rotate(45deg) rotate(-136.5deg);transform-origin: 21% 28.9%;">✂</text>  
        <svg x="5mm" y="10mm" width="52mm" height="63mm" >
            <text dy="5mm" class="title-r/p" >{{$t('qrCard.receipt.main_title')}}</text>
            <text y="9mm" x="0mm">
                <tspan x="0mm" dy="18pt" class="heading-r" >{{$t('qrCard.receipt.payableTo_title')}}</tspan>
                <tspan x="0mm" dy="16pt" class="value-r"   >{{  withWir ? creditor.accountWir : creditor.account }}</tspan>
                <tspan x="0mm" dy="11pt" class="value-r" v-html="creditorReceiptPartHTML.fullName" ></tspan>
                <tspan x="0mm" dy="11pt" class="value-r" v-if="vIF.creditor.streetAndNumber" v-html="creditorReceiptPartHTML.streetAndNumber"></tspan>
                <tspan x="0mm" dy="11pt" class="value-r" v-html="creditorReceiptPartHTML.zipAndTown"></tspan>
                <tspan x="0mm" dy="18pt" class="heading-r" v-if="reference">{{$t('qrCard.receipt.reference_title')}}</tspan>
                <tspan x="0mm" dy="11pt" class="value-r" >{{ reference }}</tspan>
                <tspan x="0mm" dy="18pt" class="heading-r" v-if="infoDebtor" >{{$t('qrCard.receipt.payableBy_title')}}</tspan>
                <tspan x="0mm" dy="13pt" class="value-r" v-html="debitorReceiptPartHTML.fullName" ></tspan>
                <tspan x="0mm" dy="11pt" class="value-r" v-if="vIF.debtor.streetAndNumber"  v-html="debitorReceiptPartHTML.streetAndNumber"></tspan>
                <tspan x="0mm" dy="11pt" class="value-r" v-html="debitorReceiptPartHTML.zipAndTown"></tspan>                
            </text>
        </svg>

                
        <!-- CORNER MARKS => [DEBTOR] RECEIPT PART -->
        <template v-if="infoDebtor == false">
            <text class="heading-r" x="5mm" :y="`${positionYCornerMarkReceiptPart - 2}mm`">  {{$t('qrCard.receipt.payableByCornerMarks_title')}}</text>    
                <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="5mm" :y="`${positionYCornerMarkReceiptPart}mm`" style="enable-background:new 0 0 113.4 42.5;" >
                <!-- Corner top - left  -->
                <g>
                    <g>
                        <rect x="0" width="3mm" height="0.75pt"/>
                    </g>
                    <g>
                        <rect width="0.75pt" height="3mm"/>
                    </g>
                </g>
                <!-- Corner  bottom - left-->
                <g>
                    <g>
                        <rect x="0" y="20mm" width="3mm" height="0.75pt"/>
                    </g>
                    <g>
                        <rect y="17mm" width="0.75pt" height="3mm"/>
                    </g>
                </g>
                <!-- Corner top - right -->
                <g>
                    <g>
                        <rect x="49.25mm" width="3mm" height="0.75pt"/>
                    </g>
                    <g>
                        <rect x="52mm" y="0" width="0.75pt" height="3mm"/>
                    </g>
                </g>
                <!-- Corner bottom - right -->
                <g>
                    <g>
                        <rect x="49.25mm" y="20mm" width="3mm" height="0.75pt"/>
                    </g>
                    <g>
                        <rect x="52mm" y="17mm" width="0.75pt" height="3mm"/>
                    </g>
                </g>
            </svg>
        </template>
        <svg id="amount-section" y="80mm" x="5mm" width="52mm" height="14mm" >
            <text x="0mm" dy="15pt" >
                <tspan x="0mm" dy="9pt" class="heading-r" >{{$t('qrCard.receipt.currency_title')}}</tspan>
                <tspan x="0mm" dy="9pt" class="value-p">{{ currency }}</tspan>
            </text>
            <text x="0mm" y="9pt" >
                <tspan x="21.5mm" dy="0pt" class="heading-r" >{{$t('qrCard.receipt.amount_title')}}</tspan>
                <tspan x="21.5mm" dy="9pt" class="value-p" v-if="!stateAmount">{{ amount }}</tspan>
            </text>
        </svg>

        <!-- CORNER MARKS => [AMOUNT] RECEIPT PART -->
        <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="26.5mm" y="84mm"
	 style="enable-background:new 0 0 113.4 42.5;" v-if="stateAmount" >
            <!-- Corner top - left  -->
            <g>
                <g>
                    <rect x="0" width="3mm" height="0.75pt"/>
                </g>
                <g>
                    <rect width="0.75pt" height="3mm"/>
                </g>
            </g>
            <!-- Corner  bottom - left-->
            <g>
                <g>
                    <rect x="0" y="10mm" width="3mm" height="0.75pt"/>
                </g>
                <g>
                    <rect y="7mm" width="0.75pt" height="3mm"/>
                </g>
            </g>
            <!-- Corner top - right -->
            <g>
                <g>
                    <rect x="27mm" width="3mm" height="0.75pt"/>
                </g>
                <g>
                    <rect x="30mm" y="0" width="0.75pt" height="3mm"/>
                </g>
            </g>
            <!-- Corner bottom - right -->
            <g>
                <g>
                    <rect x="27.25mm" y="10mm" width="3mm" height="0.75pt"/>
                </g>
                <g>
                    <rect x="30mm" y="7mm" width="0.75pt" height="3mm"/>
                </g>
            </g>
        </svg>

        <template v-if="withWir">
             <image 
                x="38mm"
                y="78mm"
                width="20mm" 
                height="10mm" 
                :href="getImage"
              /> 
        </template> 

        <template v-if="withWir">
            <svg y="90mm" x="1mm" width="52mm" height="50mm" > 
                <text x="4mm" y="5mm" style="fill:red; font-size:11px" v-html="infoWir">

                </text>
            </svg>
        </template>

        <template v-else>
            <svg id="acceptance" y="95mm" x="5mm" width="52mm" height="18mm" > -->
                <text y="3mm" :x="custom_config.acceptance_point" class="acceptance-point">{{$t('qrCard.receipt.acceptancePoint_title')}}</text> 
            </svg> 
        </template>
        
        <svg x="67mm" y="10mm" width="46mm" height="95mm" >
            <text dy="5mm" class="title-r/p" >{{$t('qrCard.paymentPart.main_title')}}</text>
        </svg>

        <svg width="46mm" height="46mm" x="67mm" y="22mm" v-if="this.$store.state.QR.qrSkeleton">
            <g>
                <rect width="46mm" height="46mm" style="fill:#FAFAFA;" />
            </g>
            <g v-if="this.$store.state.QR.processing">
                 <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
                <svg xmlns="http://www.w3.org/2000/svg" x="18mm" y="19mm" width="44" height="44" viewBox="0 0 44 44" stroke="#000" >
                <g fill="none" fill-rule="evenodd" stroke-width="2">
                    <circle cx="22" cy="22" r="17.6202">
                        <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
                        <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
                    </circle>
                    <circle cx="22" cy="22" r="19.9822">
                        <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
                        <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
                    </circle>
                </g>
                </svg>
            </g>
            
        </svg>

        <svg width="46mm" height="46mm" x="67mm" y="22mm" v-html="(bothCurrencies && withWir)? qrSVG2 :qrSVG" >
            <!-- QR -->
            <!-- QR -->
        </svg>

        <svg x="67mm" y="73mm" width="46mm" height="22mm" >
            <text  >
                <tspan x="0mm" dy="9pt" class="heading-p" >{{$t('qrCard.paymentPart.currency_title')}}</tspan>
                <tspan x="0mm" dy="13pt" class="amount-p">{{ currency }}</tspan>
            </text>
            <text >
                <tspan x="20mm" dy="9pt" class="heading-p" >{{$t('qrCard.paymentPart.amount_title')}}</tspan>
                <tspan x="20mm" dy="13pt" class="amount-p" v-if="!stateAmount">{{ amount }}</tspan>
            </text>
        </svg>

        <!-- CORNER MARKS => [AMOUNT] PAYMENT PART -->
        <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="76mm" y="78mm"
	 style="enable-background:new 0 0 113.4 42.5;" v-if="stateAmount" >
            <!-- Corner top - left  -->
            <g>
                <g>
                    <rect x="0" width="3mm" height="0.75pt"/>
                </g>
                <g>
                    <rect width="0.75pt" height="3mm"/>
                </g>
            </g>
            <!-- Corner  bottom - left-->
            <g>
                <g>
                    <rect x="0" y="15mm" width="3mm" height="0.75pt"/>
                </g>
                <g>
                    <rect y="12mm" width="0.75pt" height="3mm"/>
                </g>
            </g>
            <!-- Corner top - right -->
            <g>
                <g>
                    <rect x="37mm" width="3mm" height="0.75pt"/>
                </g>
                <g>
                    <rect x="40mm" y="0" width="0.75pt" height="3mm"/>
                </g>
            </g>
            <!-- Corner bottom - right -->
            <g>
                <g>
                    <rect x="37.25mm" y="15mm" width="3mm" height="0.75pt"/>
                </g>
                <g>
                    <rect x="40mm" y="12mm" width="0.75pt" height="3mm"/>
                </g>
            </g>
        </svg>
        <svg x="118mm" y="10mm" width="87mm" height="85mm">
            <text y="5mm" x="0mm" class="information-section">
                <tspan x="0mm" dy="0pt" class="is-heading-p" >{{$t('qrCard.paymentPart.payableTo_title')}}</tspan>
                <tspan x="0mm" dy="16pt" class="is-value-p" >{{  withWir ? creditor.accountWir : creditor.account }}</tspan>
                <tspan x="0mm" dy="11pt" class="is-value-p" v-html="creditorPaymentPartHTML.fullName"></tspan>
                <tspan x="0mm" dy="11pt" class="is-value-p" v-if="vIF.creditor.streetAndNumber" v-html="creditorPaymentPartHTML.streetAndNumber"></tspan>
                <tspan x="0mm" dy="11pt" class="is-value-p" v-html="creditorPaymentPartHTML.zipAndTown"></tspan>
                <tspan x="0mm" dy="18pt" class="is-subtitle-p" v-if="reference">{{$t('qrCard.paymentPart.reference_title')}}</tspan>
                <tspan x="0mm" dy="11pt" class="is-value-p" >{{ reference }}</tspan>
                <tspan x="0mm" dy="18pt" class="is-subtitle-p" v-if="structuredMessage.plain || unstructuredMessage.plain">{{$t('qrCard.paymentPart.additionalInformation_title')}}</tspan>
                <tspan x="0mm" dy="11pt" class="is-value-p" v-html="unstructuredMessage.html" ></tspan>
                <tspan x="0mm" dy="11pt" class="is-value-p" v-html="structuredMessage.html" ></tspan>
                <tspan x="0mm" dy="18pt" class="is-subtitle-p" v-if="infoDebtor" >{{$t('qrCard.paymentPart.payableBy_title')}}</tspan>
                <tspan x="0mm" dy="16pt" class="is-value-p" v-html="debitorPaymentPartHTML.fullName" ></tspan>
                <tspan x="0mm" dy="11pt" class="is-value-p" v-if="vIF.debtor.streetAndNumber" v-html="debitorPaymentPartHTML.streetAndNumber"></tspan>
                <tspan x="0mm" dy="11pt" class="is-value-p" v-html="debitorPaymentPartHTML.zipAndTown"></tspan>
            </text>
        </svg>

        <!-- CORNER MARKS => [DEBTOR] RECEIPT PART -->
        <template v-if="infoDebtor == false">
            <text class="is-subtitle-p" x="118mm" :y="`${positionYCornerMarkPaymentPart - 2}mm`">  {{$t('qrCard.paymentPart.payableByCornerMarks_title')}}</text>    
                <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="118mm" :y="`${positionYCornerMarkPaymentPart}mm`" style="enable-background:new 0 0 113.4 42.5;" >
                <!-- Corner top - left  -->
                <g>
                    <g>
                        <rect x="0" width="3mm" height="0.75pt"/>
                    </g>
                    <g>
                        <rect width="0.75pt" height="3mm"/>
                    </g>
                </g>
                <!-- Corner  bottom - left-->
                <g>
                    <g>
                        <rect x="0" y="25mm" width="3mm" height="0.75pt"/>
                    </g>
                    <g>
                        <rect y="22mm" width="0.75pt" height="3mm"/>
                    </g>
                </g>
                <!-- Corner top - right -->
                <g>
                    <g>
                        <rect x="62.25mm" width="3mm" height="0.75pt"/>
                    </g>
                    <g>
                        <rect x="65mm" y="0" width="0.75pt" height="3mm"/>
                    </g>
                </g>
                <!-- Corner bottom - right -->
                <g>
                    <g>
                        <rect x="62.25mm" y="25mm" width="3mm" height="0.75pt"/>
                    </g>
                    <g>
                        <rect x="65mm" y="22mm" width="0.75pt" height="3mm"/>
                    </g>
                </g>
            </svg>
        </template>

    </svg>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
const BankWIR_Logo_CMYK_IT = require ("../../../public/BancaWIR_Logo_CMYK_IT.png");
const BankWIR_Logo_CMYK_FR = require ("../../../public/BanqueWIR_Logo_CMYK_FR.png");
const BankWIR_Logo_CMYK_EN_ED_RM = require("../../../public/BankWIR_Logo_CMYK_EN_ED_RM.png"); 
export default {
    name: 'qrSVG',
    data() {
        return {
            custom_config: {
                acceptance_point: '33mm'
            },
        }
    },
    errorCaptured (err, vm, info) {
      console.log(`${err.stack}\n\nfound in ${info} of component`)
    },
    computed: {
        ...mapState({
            debitor: state => state.QR.debitor,
            //reference: state => state.QR.reference,
            qrSVG: state => state.QR.qrSVG,
            qrSVG2: state => state.QR.qrSVG2,
            bothCurrencies: state => state.QR.bothCurrencies,
            stateAmount: state => state.QR.stateAmount
        }),
        ...mapGetters({
            additionalInformation: 'QR/additionalInformation',
            // amount: 'QR/amount',
            structuredMessage: 'AI/structuredMessage',
            unstructuredMessage: 'AI/unstructuredMessage',
            creditor: 'User/creditor',
            getLanguage: 'User/getLanguage',
            creditorReceiptPartHTML: 'User/creditorReceiptPartHTML',
            creditorPaymentPartHTML: 'User/creditorPaymentPartHTML',
            debitorReceiptPartHTML: 'QR/debitorReceiptPartHTML',
            debitorPaymentPartHTML: 'QR/debitorPaymentPartHTML',
            reference: 'QR/reference',
        }),
        infoWir(){

            let info = this.$t("qrCard.receipt.wirInformation");
            let arrWords = info.split(" ");
            let text = "";
            let line = "";
            let heightLine = 4;

            
            for(let i = 0 ; i < arrWords.length ;i++){

                 //check if word fits in the line
                 if ( line.length < 30){
                       line += `${arrWords[i]} `;
                 }else{
                      //if the word not fits in the line we need to add new line with this word
                      text += `<tspan x="4mm" y="${ heightLine }mm" >${line}</tspan>`
                      heightLine += 4;
                      line = `${arrWords[i]} `;
                 }
            
            }
            
            //if the line has words that were not added to the text we must add the words
            if(line){
                 text += `<tspan x="4mm" y="${ heightLine }mm" >${line}</tspan>`
            }

            

            return text;

        },
        getImage(){

              let language = this.getLanguage;
              if(language == "it"){
                  return BankWIR_Logo_CMYK_IT;
              }else if(language == "fr"){
                  return BankWIR_Logo_CMYK_FR;
              }
            
             return  BankWIR_Logo_CMYK_EN_ED_RM;
                
        },
        amount() {

            if(this.$store.state.QR.bothCurrencies && this.withWir ){
                
                //calculate the amountCHW
                let amountCHW = (this.$store.state.QR.wirPercentage / 100 ) * this.$store.state.QR.amount;
                let result = 0;
                
                let decimalCHW  =  Math.floor((amountCHW - Math.floor(amountCHW)) * 100);
                let centesimaCHW = decimalCHW % 10;

                //rounder the amountCHW
                if( centesimaCHW >= 1 && centesimaCHW < 5 ){

                    let decimals =  Math.floor(decimalCHW / 10) / 10 + 0.05;
                    result = Math.floor(amountCHW) +  Math.floor(decimals * 100) / 100 ;
                    amountCHW = result;

                }else if (centesimaCHW > 5 && centesimaCHW <= 9  ){
                    result = Math.floor(amountCHW) + ((decimalCHW - centesimaCHW)  + 10) / 100;
                    amountCHW = result;
                }else{
                 
                    result = (Math.floor(amountCHW) +  Math.floor((amountCHW - Math.floor(amountCHW)) * 100) / 100)
                    amountCHW =  result;
                }

                 //we need to save the value of amountCHW because we will use in the calculation of the CHF
                 this.$store.commit("QR/setAmountCHW",amountCHW);
                 return  amountCHW.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ");          
           
           }else if (this.$store.state.QR.currency == "CHF/CHW"){ //calculate CHF
                
                 const   amount =  this.$store.state.QR.amount - this.$store.state.QR.amountCHW;       
                 return  amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ");
            
            }else{
                return  this.$store.state.QR.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ");
            }  
        },
        currency(){

            if( this.$store.state.QR.bothCurrencies && this.withWir ){
                return "CHW";    
            }else if(this.$store.state.QR.currency == "CHF/CHW"){
                return "CHF";
            }
                return this.$store.state.QR.currency
           
        },
        vIF() {
            return {
                creditor: {
                    streetAndNumber() {
                        try {
                            return creditor.address.street.replace(/\s/g, '') != '' ||  creditor.address.number.toString().replace(/\s/g, '') != ''
                        } catch(error) {
                            return false;
                        }
                    }
                },
                debtor: {
                    streetAndNumber() {
                        try {
                            return debitor.address.street.replace(/\s/g, '') != '' ||  debitor.address.number.toString().replace(/\s/g, '') != ''
                        } catch (error) {
                            return false;
                        }
                    }
                }
            }
        },
        
        /** 
         * the number return in string means the position in milimeters of the corner mark
         */
        positionYCornerMarkReceiptPart() {
            if(this.reference == '' || this.reference == undefined || this.reference == null) {
                return '50'
            } else if(this.reference != '') {
                return '58'
            }
        },
        positionYCornerMarkPaymentPart() {
            if(this.reference == '' || this.reference == undefined || this.reference == null) {
                return '65'
            } else if(this.reference != '') {
                return '70'
            }
        },
        /**
         * returns true/false depending if a field was filled in debtor section
         */
        infoDebtor() {
            if(this.debitor.name != ''
            || this.debitor.last_name != ''
            || this.debitor.address.street != ''
            || this.debitor.address.number != ''
            || this.debitor.address.zip != ''
            || this.debitor.address.city != ''
            ) {
                return true 
            } else {
                return false
            }
        },
    },
    props:{
        withWir:{
            type:Boolean,
            default:true
        }
    }
    
}

</script>

<style type="text/css">.st0{clip-path:url(#SVGID_2_);fill:none;stroke:#000000;stroke-width:0.75;stroke-dasharray:2.9913,2.9913;}
 .st1{fill:#FFFFFF;}
 .st2{font-family:'ZapfDingbatsITC';}
 .st3{font-size:26px;}

 .st0{clip-path:url(#SVGID_2_);fill:none;stroke:#000000;stroke-width:0.75;stroke-dasharray:2.9913,2.9913;}
 .st1{fill:#FFFFFF;}
 .st2{font-family:'ZapfDingbatsITC';}
 .st3{font-size:26px;}

.title-r\/p {
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 11pt;
}
.heading-r {
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 6pt;
}

.value-r {
    font-family: 'Helvetica';
    font-size: 8pt;
}

.value-p {
    font-family: 'Helvetica';
    font-size: 8pt;
}

.acceptance-point {
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 6pt;
}

.is-heading-p {
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 8pt;
}

.is-value-p {
    font-family: 'Helvetica';
    font-size: 10pt;
}

.heading-p {
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 8pt;
}

.amount-p {
    font-family: 'Helvetica';
    font-size: 10pt;
}

.is-subtitle-p {
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 8pt;
}

.further-information-bold {
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 7pt;
}

.further-information {
    font-family: 'Helvetica';
    font-size: 7pt;
}

 </style>