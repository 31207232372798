

const qrSeries = {
    namespaced: true,
    state: {
        profiles: [],
        items: [] ,
        tableKey: 0 ,
        typeOfProfile: 'standard',
        optionGeneratePaymentPart:"single",
        profile: '',
        OrderID:"",
        LUCCToken:"",
        checkboxAddress: true,
        checkboxReference: true,
        checkboxAmount: true,
        checkboxMessage: true,
        checkboxBilling: false,
        templateData: null,
        selected_template_settings:null,
        stepperEmail: 1
    },
    mutations: {
        setProfiles: (state, payload) => {
            state.profiles = payload
        },
        setOptionGeneratePaymentPart:(state,payload) => {
            state.optionGeneratePaymentPart = payload;
        },
        settableKey: (state,payload) =>{
            state.tableKey = payload;
        },
        setItems: (state, payload)=>{
            state.items = payload
        },
        setProfile: (state, payload) => {
            state.profile = payload
        },
        setCheckbox: (state, { type, value }) => {
            switch (type) {
                case 'address':
                    state.checkboxAddress = value
                    break;
                case 'reference': 
                    state.checkboxReference = value
                    break;
                case 'amount:':
                    state.checkboxAmount = value
                    break;
                case 'message':
                    state.checkboxMessage = value
                    break;
                case 'billing': 
                    state.checkboxBilling = value
                    break;
                default:
                    break;
            }
        },
        setStepperEmail: (state, payload) =>{
            state.stepperEmail = payload
        },
        reset: (state) => {
            Object.assign(state, {
                profiles: [],
                items: [],
                tableKey: 0 ,
                typeOfProfile: 'standard',
                profile: '',
                OrderID:"",
                LUCCToken:"",
                checkboxAddress: true,
                checkboxReference: true,
                checkboxAmount: true,
                checkboxMessage: true,
                checkboxBilling: false,
                templateData: null,
                stepperEmail: ""
            })
        },
    },
    getters: {
        getProfiles: state => state.profiles,
        gettableKey: state => state.tableKey,
        getItems: state => state.profile,
        getProfileData: state => {
            if(state.typeOfProfile === 'standard') {
                return state.profiles.find(profile => profile.defaultProfile === true)
            }

            if(state.typeOfProfile !== 'standard') {
                return state.profiles.find(profile => profile.id === state.profile)
            }
        },
        getOrderID: state => state.OrderID,
        getLUCCToken: state => state.LUCCToken,
        getCheckboxs: state => ({
            address: state.checkboxAddress, 
            reference: state.checkboxReference,
            amount: state.checkboxAmount,
            message: state.checkboxMessage,
            billing: state.checkboxBilling
        }),
        getStepperEmail: state => state.stepperEmail
    },
}

export default qrSeries 