<template>
<v-card>
    <v-card-title>
        Set selection criteria
    </v-card-title>
    <v-card-text class="containerAll">
        <v-row>
            <v-col>
                <h3>Select period</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha1"
                  label="From"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fecha1" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.menu1.save(fecha1)">OK</v-btn>
              </v-date-picker>
            </v-menu>
            </v-col>
            <v-col>
                <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha2"
                  label="Until"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fecha2" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(fecha2)">OK</v-btn>
              </v-date-picker>
            </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h3>Select invoice status</h3>
            </v-col>

        </v-row>
        <v-row>
            <v-col>
                <v-select
                    label="Status"
                    :items="['Draft','Pending','Partial','Paid','Overdue', 'All']"
                    multiple
                ></v-select>
            </v-col>
            
        </v-row>
    </v-card-text>
    <v-card-actions class="justify-end">
          <v-btn
            :color="$vuetify.theme.themes.light.blue.primary"
            dark
            @click="hideDialog"
            >{{$t("Debtors.btn_cancel")}}</v-btn
          >
          <v-btn
            :color="$vuetify.theme.themes.light.blue.primary"
            dark
            :loading="showDialogSaveLoader"
            @click="saveDialogChanges"
            >{{$t("Debtors.btn_save")}}</v-btn
          >
        </v-card-actions>
</v-card>
    
</template>


<script>
export default {
    name: "bexioview",
    async mounted(){
        // let holaMundo = await this.$fetch(
        //   `${process.env.VUE_APP_SERVER_URL}/api/bexio/initial_connection`,
        //   {
        //     method: "GET",
        //     credentials: "include",
        //   }
        // );
        // console.log(holaMundo);
    },
    data(){
        return {
            menu1: false,
            fecha1: '',
            menu2: false,
            fecha2: ''
        }
    }

}
</script>

<style scoped>
/* .containerAll{
    max-width: 100%;
    padding: 30px;
    margin: 15px;
} */

.containerLoader{
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ball {
 --size: 20px;
 --timing: 0.7s;
 --displace: 70px;
 border-radius: 50%;
 width: var(--size);
 height: var(--size);
 background: #000;
 margin-right: var(--displace);
 animation: animation321 var(--timing) infinite alternate
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes animation321 {
 0% {
  background: #0d2f3b;
  transform: translateX(var(--displace));
 }

 100% {
  background: #bbdc00;
  transform: translateX(00px);
 }
}

</style>