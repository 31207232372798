import { mapGetters } from "vuex";


export default {
    computed: {
        ...mapGetters({
            creditor: "User/creditor",
            debitor: "QR/debitor",
            structuredMap: "AI/structuredMap",
            productsItems: "User/items",
        }),
        accountName() {
            return this.$store.state.User.creditor.account_name;
        },
        reference: {
            get() {
                return this.$store.state.QR.reference;
            },
            set(reference) {
                this.$store.commit("QR/setReference", reference);
            }
        },
    }
}