var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"6","lg":"6","cols":"12"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("qrGenerator.payableTo.main_title")))]),_c('v-card-text',[_c('v-form',{ref:"form.creditor"},[_c('qr-premium-creditor-selection',{ref:"creditor.selection"}),_c('qr-form-creditor',{ref:"qrFormCreditor",attrs:{"isPremium":true}})],1)],1)],1)],1),_c('v-col',{attrs:{"sm":"6","lg":"6","cols":"12"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-form',{ref:"form.debtor",attrs:{"onSubmit":"return false;"}},[_c('qr-premium-debtor-selection',{ref:"debtor.selection"}),_c('qr-form-debtor',{ref:"qrFormDebtor",on:{"qrWithWir":function (flag) { _vm.qrWir = flag },"qrBothCurrency":function (flag) { _vm.qrWithBothCurrency = flag }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('qr-form-billing',{ref:"qrBilling"})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-form',{ref:"form.productTable"},[_c('qr-product-table',{ref:"qrProductTable",on:{"filePermission":function (data) { return _vm.filePermission = !data; }}})],1)],1)],1)],1),_c('v-row',[(_vm.qrWithBothCurrency && _vm.userHasWir)?_c('v-col',{attrs:{"cols":"12"}},[_c('qr-card')],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('qr-card',{attrs:{"withWir":_vm.qrWir && _vm.userHasWir}})],1)],1),_c('v-row',[_c('qr-buttons',{ref:"qrButtons",attrs:{"filePermission":_vm.filePermission}})],1),_c('v-row',[_c('window-credits',{key:_vm.updateBuyCredits,attrs:{"propsMap":{
            popup: _vm.popup,
            price: _vm.price,
            origin: 'single',
            creditsinsuf: _vm.creditsinsuf,
            titlepopup: _vm.titlepopup,
            subtitlepopup: _vm.subtitlepopup,
            remainingcredits: _vm.remainingcredits,
            total: _vm.total
        }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }