<template>
  <div>
    <v-row>
      <v-col cols="7" class="py-0">
        <v-container
          id="scroll-target"
          style="max-height: 500px"
          class="overflow-y-auto py-0"
        >
          <v-row
            v-scroll:#scroll-target="onScroll"
            class="py-0"
            align="center"
            justify="center"
          >
            <v-form ref="emailDis" style="display: flex">
              <v-col cols="6" class="py-0">
                <h1 style="font-size: 1rem; margin-left: -10px">
                  {{ $t("QrSeries.step_four.emailDispatch.specify") }}
                </h1>
                <v-row class="mt-1">
                  <div style="display: flex; width: 100%" class="mb-2">
                    <!-- <v-icon class="countStyle">mdi-numeric-2-circle</v-icon> -->
                    <div style="width: 100%">
                      <template>
                        <span
                          >{{ $t("QrSeries.step_four.emailDispatch.sender")
                          }}<strong class="red--text"> *</strong></span
                        >
                      </template>
                      <v-text-field
                        v-model="absender"
                        style="font-size: 0.8rem"
                        dense
                        outlined
                        :rules="[rules.isRequired, rules.email]"
                        :hint="$t('QrSeries.step_four.emailDispatch.message')"
                        persistent-hint
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <div style="display: flex; width: 100%" class="mb-2">
                    <!-- <v-icon class="countStyle">mdi-numeric-3-circle</v-icon> -->
                    <div style="width: 100%">
                      <template>
                        <span
                          >{{
                            $t(
                              "QrSeries.step_four.emailDispatch.payable_until"
                            )
                          }}<strong class="red--text"> *</strong></span
                        >
                      </template>
                      <v-row style="width: 100% !important; display: contents">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="new_payable_until"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateText"
                              readonly
                              dense
                              :rules="[rules.isRequired]"
                              style="font-size: 0.8rem"
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="new_payable_until"
                            @change="cleanValidation"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              {{
                                $t("QrSeries.step_four.emailDispatch.cancel")
                              }}
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="parseDate(new_payable_until)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-row>
                    </div>
                  </div>
                  <div style="display: flex; width: 100%" class="mb-2">
                    <!-- <v-icon class="countStyle">mdi-numeric-4-circle</v-icon> -->
                    <div style="width: 100%">
                      <template>
                        <span>{{
                          $t("QrSeries.step_four.emailDispatch.free_text")
                        }}</span>
                      </template>
                      <v-textarea
                        v-model="freitext"
                        no-resize
                        rows="1"
                        outlined
                        :hint="$t('QrSeries.step_four.emailDispatch.max_rows')"
                        persistent-hint
                      ></v-textarea>
                    </div>
                  </div>
                  <div style="display: flex; width: 100%" class="mb-2">
                    <!-- <v-icon class="countStyle">mdi-numeric-5-circle</v-icon> -->
                    <div style="width: 100%">
                      <template>
                        <span>{{
                          $t("QrSeries.step_four.emailDispatch.greetings")
                        }}</span>
                      </template>
                      <v-text-field
                        class="mb-2"
                        dense
                        style="font-size: 0.8rem"
                        outlined
                        v-model="greeting.text"
                        hide-details
                      >
                      </v-text-field>
                      <v-text-field
                        class="mb-2"
                        style="font-size: 0.8rem"
                        outlined
                        v-model="greeting.name"
                        dense
                        hide-details
                      >
                      </v-text-field>
                      <v-text-field
                        style="font-size: 0.8rem"
                        outlined
                        v-model="greeting.job"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </div>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="6" class="py-0">
                <div>
                  <h1
                    style="
                      margin-bottom: 5px;
                      text-align: left;
                      font-size: 1rem;
                    "
                  >
                    {{ $t("QrSeries.step_four.emailDispatch.contact_queries") }}
                  </h1>
                  <div style="display: flex; width: 100%" class="mb-3">
                    <!-- <v-icon class="countStyle">mdi-numeric-6-circle</v-icon> -->
                    <div style="width: 100%">
                      <template>
                        <span
                          >{{ $t("QrSeries.step_four.emailDispatch.email")
                          }}<strong class="red--text"> *</strong></span
                        >
                      </template>
                      <v-text-field
                        v-model="contact.email"
                        dense
                        class="mb-2"
                        :rules="[rules.isRequired, rules.email]"
                        style="font-size: 0.8rem"
                        outlined
                      >
                      </v-text-field>
                      <template>
                        <span>{{
                          $t("QrSeries.step_four.emailDispatch.phone")
                        }}</span>
                      </template>
                      <v-text-field
                        dense
                        v-model="contact.phone"
                        class="mb-2"
                        style="font-size: 0.8rem"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                      <template>
                        <span>{{
                          $t("QrSeries.step_four.emailDispatch.postal_address")
                        }}</span>
                      </template>
                      <v-text-field
                        dense
                        v-model="contact.address"
                        style="font-size: 0.8rem"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </div>
                  </div>
                </div>
                <div>
                  <h1 style="text-align: start; font-size: 1.19rem">
                    {{ $t("QrSeries.step_four.emailDispatch.shipping_insert") }}
                  </h1>
                  <div style="display: flex; width: 100%" class="mb-3">
                    <!-- <v-icon class="countStyle">mdi-numeric-7-circle</v-icon> -->
                    <div style="width: 100%">
                      <v-checkbox
                        style="margin-top: 0px !important"
                        class="mb-3"
                        v-model="flagSendFiles"
                        hide-details
                        :label="
                          $t('QrSeries.step_four.emailDispatch.include_ship')
                        "
                      ></v-checkbox>
                      <div v-if="flagSendFiles">
                        <v-overflow-btn
                          dense
                          segmented
                          :label="
                            $t('QrSeries.step_four.emailDispatch.selectOption')
                          "
                          :items="actionButtonItems"
                          item-text="text"
                          item-value="value"
                          v-model="currentMailAttachment"
                          class="mb-3"
                        ></v-overflow-btn>

                        <v-input
                          v-if="file"
                          :messages="dateLastModifiedFile"
                          prepend-icon="mdi-file-pdf-box"
                        >
                          {{ file.name }}
                        </v-input>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="5">
        <v-container
          id="scroll-target"
          style="max-height: 500px"
          class="overflow-y-auto"
        >
          <v-row
            v-scroll:#scroll-target="onScroll"
            align="center"
            justify="center"
          >
            <v-form style="display: flex">
              <v-col cols="12">
                <h1 style="font-size: 1.2rem">
                  {{ $t("QrSeries.step_four.emailDispatch.preview") }}
                </h1>
                <v-row class="mt-1">
                  <div style="display: flex; width: 100%" class="mb-2">
                    <div style="width: 100%">
                      <div>
                        <v-row
                          style="
                            display: flex;
                            justify-content: space-between;
                            padding-left: 1rem;
                            padding-right: 2rem;
                          "
                        >
                          <v-col cols="9">
                            <label style="font-size: 12px"
                              >{{
                                $t("QrSeries.step_four.emailDispatch.your_qr")
                              }}
                              {{ creditorName }} {{ creditorLName }}
                              {{
                                $t("QrSeries.step_four.emailDispatch.your_qr2")
                              }}
                              {{ invoice_date_format }},
                              {{
                                $t(
                                  "QrSeries.step_four.emailDispatch.payable_until"
                                )
                              }}
                              {{ dateText }}</label
                            >
                          </v-col>

                          
                        </v-row>
                        <div style="padding: 0rem 3rem 3rem 3rem">
                          <div class="duplicateImage"></div>
                          <v-form>
                            <div style="padding-bottom: 1rem">
                              <label>
                                {{ $t("QrSeries.step_four.emailDispatch.salutation") }} 
                                </label
                              >
                              <!--{{ debtor.salutation }} {{ debtor.name }} {{ debtor.last_name }} -->
                            </div>
                            <div style="padding-bottom: 1rem">
                              <label
                                >{{
                                  $t(
                                    "QrSeries.step_four.emailDispatch.insert_contains"
                                  )
                                }}
                              </label>
                            </div>
                            <div
                              style="font-size: 1.3rem; padding-bottom: 1rem"
                            >
                              <label
                                >{{ creditorName }} {{ creditorLName }}</label
                              >
                            </div>
                            <div>
                              <div style="padding-bottom: 0.5rem">
                                <label>
                                  {{
                                    $t(
                                      "QrSeries.step_four.emailDispatch.your_qr2"
                                    )
                                  }}
                                  {{ invoice_date_format }}.
                                  {{
                                    $t(
                                      "QrSeries.step_four.emailDispatch.insert_contains2"
                                    )
                                  }}
                                  {{ dateText }}.
                                  {{
                                    $t(
                                      "QrSeries.step_four.emailDispatch.insert_contains3"
                                    )
                                  }}
                                </label>
                              </div>
                              <div
                                style="
                                  margin-left: 1rem;
                                  padding-bottom: 0.5rem;
                                "
                              >
                                <v-row>
                                  <v-col cols="3">
                                    <label
                                      >{{
                                        $t(
                                          "QrSeries.step_four.emailDispatch.email"
                                        )
                                      }}:</label
                                    >
                                  </v-col>
                                  <v-col>
                                    <label>{{ contact.email }}</label>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="3">
                                    <label
                                      >{{
                                        $t(
                                          "QrSeries.step_four.emailDispatch.phone"
                                        )
                                      }}:</label
                                    >
                                  </v-col>
                                  <v-col>
                                    <label v-if="!!contact.phone">{{
                                      contact.phone
                                    }}</label>
                                    <label v-else> {{ "N.A." }}</label>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="3">
                                    <label
                                      >{{
                                        $t(
                                          "QrSeries.step_four.emailDispatch.postal_address"
                                        )
                                      }}:</label
                                    >
                                  </v-col>
                                  <v-col>
                                    <label v-if="!!contact.address">{{
                                      contact.address
                                    }}</label>
                                    <label v-else>{{ "N.A." }}</label>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>

                            <hr />
                            <div style="padding-bottom: 0.7rem">
                              <label>{{ freitext }}</label>
                            </div>

                            <div
                              style="
                                display: grid;
                                width: 100%;
                                padding-bottom: 0.7rem;
                              "
                            >
                              <label> {{ greeting.text }} </label>
                              <label> {{ greeting.name }}</label>
                              <label> {{ greeting.job }} </label>
                            </div>
                            <hr />
                            <div style="display: flex; padding-top: 1rem">
                              <div class="bottomImage"></div>
                              <div
                                style="
                                  display: grid;
                                  align-content: center;
                                  padding-left: 1rem;
                                "
                              >
                                <label>{{
                                  $t("QrSeries.step_four.emailDispatch.this_qr")
                                }}</label>
                                <label
                                  ><a href="">{{
                                    $t("Cockpit.QrModule")
                                  }}</a></label
                                >
                              </div>
                            </div>
                          </v-form>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-row>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <div
      class="text-center pt-2 mt-2 mr-2"
      style="float: right"
    >
      <v-btn
        tile
        elevation="11"
        color="#109fde"
        :loading="btnLoading"
        style="color: white"
        class="mr-2"
        @click="sendEmails"
      >
        {{ $t("Recover.btn_find") }}
      </v-btn>
      <v-btn
        tile
        elevation="11"
        color="#995015"
        style="color: white"
        @click="cancelBTN"
      >
        {{ $t("QrSeries.step_four.emailDispatch.cancel") }}
      </v-btn>
      <progress-bar ref="progressBar"> </progress-bar>
    </div>

    <!-- Dialog show the table of attachments custom -->
    <v-dialog persistent v-model="showDialogAttachments">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-form ref="formAttachmentEmailDispatch">
            <table-custom-attachments :debtors="debtors" />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            @click="showDialogAttachments = false"
            :color="$vuetify.theme.themes.light.blue.primary"
            >{{ $t("Globals.btn_close") }}</v-btn
          >
          <v-btn
            dark
            @click="saveDebtorsFiles"
            :color="$vuetify.theme.themes.light.blue.primary"
            >{{ $t("Globals.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showDialogWarningFile">
      <v-card>
        <v-toolbar dense dark color="#95c11f">
          <v-toolbar-title>
            {{ $t("QrSeries.step_four.emailDispatch.attention") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn @click="showDialogWarningFile = false" dark text>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          {{ $t("QrSeries.step_four.emailDispatch.warningFiles") }}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            @click="showDialogWarningFile = false"
            :color="$vuetify.theme.themes.light.blue.primary"
            >{{ $t("QrSeries.step_four.emailDispatch.btn_cancel") }}</v-btn
          >
          <v-btn
            dark
            @click="
              showDialogWarningFile = false;
              showDialogAttachments = false;
            "
            :color="$vuetify.theme.themes.light.blue.primary"
            >{{ $t("QrSeries.step_four.emailDispatch.btn_continue") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- this dialog show the drag and drop file  -->
    <drag-drop-file
      @changeFile="onChangeFile"
      @closeDialog="showDialogUploadFile = false"
      :showDialog="showDialogUploadFile"
    />
  </div>
</template>

<script>
import rules from "@/mixins/rules";
import { mapState } from "vuex";
import ProgressBar from "@/components/globals/progressBar.vue";
import tableEmails from "@/components/email-dispatch/tableEmails.vue";
import tableCustomAttachments from "./tableCustomAttachments.vue";
import DragDropFile from "@/components/email-dispatch/dragDropFile.vue";

export default {
  emits: ["closefun"],
  components: {
    "progress-bar": ProgressBar,
    "table-emails": tableEmails,
    tableCustomAttachments: tableCustomAttachments,
    "drag-drop-file": DragDropFile,
  },
  mixins: [rules],
  props: {
    debtors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  mounted() {
    this.debtor.name = this.debtors[0].name;
    this.debtor.last_name = this.debtors[0].last_name;
    this.debtor.salutation = this.debtors[0].salutation;
    let creditorInfo = this.$store.getters["User/creditor"];
    this.creditorName = creditorInfo.name;
    this.creditorLName = creditorInfo.last_name;
    if (this.payable_until) {
      let [year, month, day] = this.payable_until.split("-");
      this.dateText = `${day}.${month}.${year}`;
    }

    if (this.invoice_date) {
      let [year, month, day] = this.invoice_date.split("-");
      this.invoice_date_format = `${day}.${month}.${year}`;
    }
  },
  data() {
    return {
      offsetTop: 0,
      flagSendFiles: false,
      new_payable_until: "",
      dateText: "",
      btnLoading: false,
      currentMailAttachment: "",
      file: null,
      invoice_date_format: "",
      showDialogAttachments: false,
      showDialogWarningFile: false,
      showDialogUploadFile: false,
      errorRequiredDate: "",
      creditorName: "",
      creditorLName: "",
      menu: false,
      absender: "",
      greeting: {
        text: "",
        name: "",
        job: "",
      },
      debtor: {
        name: "",
        last_name: "",
        salutation: "",
      },
      contact: {
        email: "",
        phone: "",
        address: "",
      },
      dateRangeText: "",
      dateStart: "",
      dateEnd: "",
      freitext: "",
      freitextHint: "Freitext Rechnungssteller",
      mailContact: "info@mail.ch",
      telContact: "044 123 45 67",
      addressContact: "Pinselweg 12, 1000 Genf",
    }
  },
  methods: {
    cancelBTN() {
      this.$root
        .$confirm(
          this.$t("Settings.confirm_title"),
          this.$t("QrSeries.step_four.emailDispatch.cancelProcess")
        )
        .then(async (response) => {
          if (response) {
            this.$emit("closefun");
            this.itemsArray = this.debtors.map((element) => {
              return {
                ...element,
                checkEmail: false,
                position: "mdi-close-thick",
                error: "",
              };
            });
            this.$store.state.qrSeries.tableKey += 1;
          }
        });
    },
    cleanValidation() {
      this.errorRequiredDate = "";
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    async sendEmails() {
      if (!this.$refs.emailDis.validate()) {
        return this.$store.commit(`global/setSnackbar`, {
          message: this.$t("QrSeries.step_four.emailDispatch.checkInputs"),
        });
      }

      this.$refs.progressBar.progressBarValue = 50;
      this.$refs.progressBar.showProgressBar = true;
      this.btnLoading = true;
      this.btnLoading = true;

      //convert file to base 64
      let auxDebtors = [];
      let debtor = null;

      for (let i = 0; i < this.itemsArray.length; i++) {
        debtor = {
          ...this.itemsArray[i],
        };

        if (this.itemsArray[i].file && this.flagSendFiles) {
          debtor.file = {
            name: this.itemsArray[i].file.name,
            base64: await this.readFile(this.itemsArray[i].file),
          };
        } else {
          delete debtor.file;
        }

        auxDebtors.push(debtor);
      }

      //formats dates
      let new_payable_until_format = "";
      if (this.new_payable_until) {
        let [year, month, day] = this.new_payable_until.split("-");
        new_payable_until_format = `${day}.${month}.${year}`;
      } else if (this.payable_until) {
        //if the date of payable_until is prefilled save the value to send to backend
        new_payable_until_format = this.dateText;
      }

      const data = {
        remitter: this.absender,
        qrGenerated: this.debtors.length,
        id_profile: this.profile,
        dates: {
          invoice_date: this.invoice_date_format,
          payable_until: new_payable_until_format,
        },
        text: this.freitext,
        greeting: {
          text: this.greeting.text,
          name: this.greeting.name,
          job: this.greeting.job,
        },
        contact: {
          email: this.contact.email,
          phone: this.contact.phone,
          postal_address: this.contact.address,
        },
        debtors: auxDebtors,
      };

      if (this.file && this.flagSendFiles) {
        data.fileUnique = {
          name: this.file.name,
          base64: await this.readFile(this.file),
        };
      }

      //this property contains a  zip with all the fields
      //fileZip:this.fileZip

      try {
        this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/user/debtors/email-dispatch`,
          {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ info: data }),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        ).then((response) => console.log(response));
        this.btnLoading = false;
        this.$refs.progressBar.showProgressBar = false;
        this.$emit("closefun");
      } catch (err) {
        this.btnLoading = false;
        this.$refs.progressBar.showProgressBar = false;
        console.log(err);
      }
    },
    parseDate(new_payable_until) {
      try {
        this.$refs.menu.save(new_payable_until);
        if (!new_payable_until)
          throw "Error when user selected the range of date";
        const [yearStart, monthStart, dayStart] = new_payable_until.split("-");
        this.dateText = `${dayStart}.${monthStart}.${yearStart}`;
      } catch (err) {
        console.log(err);
      }
    },
    showDialogAttachmentsCustom() {
      //clean the variable file because the user can use only a one option custom file or no custom file
      this.file = null;
      this.showDialogAttachments = true;
    },
    checkDebtorsFiles() {
      let debtorWithOutFile = this.debtors.some((element) => !element.file);
    },
    async saveDebtorsFiles() {
      if (this.$refs.formAttachmentEmailDispatch.validate()) {
        let debtorWithOutFile = this.itemsArray.some(
          (element) => !element.file
        );
        if (debtorWithOutFile) {
          this.showDialogWarningFile = true;
        } else {
          this.showDialogAttachments = false;
        }
      }
    },

    async readFile(file) {
      if (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (res) => resolve(res.target.result);
          reader.onerror = (err) => reject(err);
          reader.readAsDataURL(file);
        });
      }
    },
    onChangeFile(file) {
      this.file = file;
    },
  },
  computed: {
    itemsArray: {
      get() {
        return this.$store.state.qrSeries.items;
      },
      set(value) {
        this.$store.state.qrSeries.items = value;
      },
    },
    items() {
      return {
        items: [
          this.$t("QrSeries.step_four.emailDispatch.impersonal_ship"),
          this.$t("QrSeries.step_four.emailDispatch.personalized_ship"),
        ],
      };
    },
    ...mapState({
      invoice_date: (state) => state.QR.invoice_date,
      payable_until: (state) => state.QR.payable_until,
    }),
    profile() {
      return this.$store.state.qrSeries.profile;
    },
    dateLastModifiedFile() {
      let aux = "Last File Modification:";
      let date = this.file.lastModifiedDate || new Date(this.file.lastModified);

      let year = date.getFullYear();
      let month = `${date.getMonth() + 1}`.padStart(2, 0);
      let day = `${date.getDate()}`.padStart(2, 0);
      let hours = `${date.getHours()}`.padStart(2, 0);
      let minutes = `${date.getMinutes()}`.padStart(2, 0);

      let formateDate = `${aux} ${day}.${month}.${year} ${hours}:${minutes}`;

      return formateDate;
    },
    actionButtonItems(){
      return [
          {
            text: this.$t("QrSeries.step_four.emailDispatch.optionsAttachments")[0].text,
            value: this.$t("QrSeries.step_four.emailDispatch.optionsAttachments")[0].value,
            callback: () => {  this.showDialogUploadFile = true }
          },
          {
            text: this.$t("QrSeries.step_four.emailDispatch.optionsAttachments")[1].text,
            value: this.$t("QrSeries.step_four.emailDispatch.optionsAttachments")[1].value,
            callback: () => this.showDialogAttachmentsCustom(),
          },
        ]
    }
  }
  
};
</script>

<style>
.countStyle {
  font-size: 2.5rem !important;
  color: #96c319 !important;
  display: contents !important;
}
.duplicateImage {
  margin-left: auto;
  height: 4rem;
  width: 100px;
  background-image: url("../../assets/QR_Rechnung.png");
  background-size: 100px;
  background-repeat: repeat;
}
.bottomImage {
  background-image: url("../../assets/logo.png");
  height: 4rem;
  width: 100px;
  background-size: 100px;
}
</style>
