<template>
    <div>
        <v-row>
            <v-col>
                <h1>Reactivation</h1>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <h2>{{ message }}</h2>
            </v-col>
        </v-row>
    
        <v-row>
            <v-col>
                <v-btn href='/login'>Go to login page </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    data(){
        return{
            message: '',
        }
    },
    beforeMount(){
        this.message = new URL(location.href).searchParams.get('message');
    }
}
</script>