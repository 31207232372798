<template>
  <div>
    <v-row>
      <v-col class="pb-0" cols="6">
        <h3>{{ $t("QrSeries.step_two.upload_debtors") }}</h3>
        <v-row>
          <v-col>
            <v-btn
              width="100%"
              dark
              :color="$vuetify.theme.themes.light.blue.primary"
              @click="$refs.debtorImportFile.showImportFileDialogFlag = true"
              >{{ $t("QrSeries.step_two.btn_start_upload") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              width="100%"
              height="50px"
              dark
              :color="$vuetify.theme.themes.dark.brown.secondary"
              @click="cancelDebtorProcess"
            >
              {{ $t("QrSeries.step_two.btn_cancel_process") }}<br />{{
                $t("QrSeries.step_two.btn_cancel_process2")
              }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pb-0" cols="6">

        <!-- agregar  checkbox para habilitar opcion de  1 Qr payment part por producto -->
        <v-select
          v-model="optionGeneratePaymentPart"
          :items="$t('QrSeries.step_two.OptionsGeneratePaymentsParts')"
          item-text="text"
          item-value="value"
          :label="$t('QrSeries.step_two.labelOptionsGeneratePaymentsParts')"
        ></v-select>

        <v-checkbox v-if="optionGeneratePaymentPart == 'separate'"
          dense
          v-model="debtorsChecks.addData"
          @change="setCheckboxAddData"
          :label="$t('QrSeries.step_two.add_data')"
          class="pa-0 ma-0"
        ></v-checkbox>

        <h3>{{ $t("QrSeries.step_two.specify_optional") }}</h3>
        <v-checkbox
          v-model="debtorsChecks.address"
          dense
          @change="setCheckboxAddress"
          :label="$t('QrSeries.step_two.payable_by')"
          class="pa-0 ma-0 pt-1"
        ></v-checkbox>
        <v-checkbox
          dense
          v-model="debtorsChecks.reference"
          @change="setCheckboxReference"
          :label="$t('QrSeries.step_two.reference_number')"
          class="pa-0 ma-0"
        ></v-checkbox>
        <v-checkbox
          dense
          v-model="debtorsChecks.amount"
          @change="setCheckboxAmount"
          :label="$t('QrSeries.step_two.amount')"
          class="pa-0 ma-0"
        ></v-checkbox>
        <v-checkbox
          dense
          v-model="debtorsChecks.message"
          @change="setCheckboxMessage"
          :label="$t('QrSeries.step_two.Unstructured_message')"
          class="pa-0 ma-0"
        ></v-checkbox>
        <v-checkbox
          dense
          v-model="debtorsChecks.billing"
          @change="setCheckboxBilling"
          :label="$t('QrSeries.step_two.billing_information')"
          class="pa-0 ma-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <debtor-table ref="debtorTable"></debtor-table>
      </v-col>
    </v-row>
    <debtor-import-file ref="debtorImportFile" />
  </div>
</template>

<script>
import DebtorTable from "@/components/cockpit/archieve/debtors/debtors.table.vue";
import DebtorImportFile from "@/components/cockpit/archieve/debtors/debtors.importFile.vue";
import { mapMutations } from "vuex";
export default {
  name: "qr-massive-debtors",
  components: {
    "debtor-table": DebtorTable,
    "debtor-import-file": DebtorImportFile,
  },
  data() {
    return {
      search: "",
      customerItemsList: [
        { text: "All", value: "all" },
        { text: "Select a list", value: "list" },
      ],
      selectedCustomerItems: "",
      debtorsChecks: {
        address: true,
        reference: true,
        amount: true,
        message: true,
        billing: false,
        addData:false
      },
    };
  },
  methods: {
    updateDebtorList() {
      this.$refs.debtorTable.loadDebtorsList();
    },
    cancelDebtorProcess() {
      this.$root
        .$confirm(
          this.$t("Settings.confirm_title"),
          this.$t("snack_confirm.cancel_process"),
          { color: "red" }
        )
        .then((confirmation) => {
          if (confirmation) {
            return this.$router.push({ name: "debtors" });
          }
        });
    },
    setCheckboxAddress(value) {
      this.$emit("set-checkbox-address", value);
      this.setCheckbox({
        type: "address",
        value,
      });
    },
    setCheckboxReference(value) {
      this.$emit("set-checkbox-reference", value);
      this.setCheckbox({
        type: "reference",
        value,
      });
    },
    setCheckboxAmount(value) {
      this.$emit("set-checkbox-amount", value);
      this.setCheckbox({
        type: "amount",
        value,
      });
    },
    setCheckboxMessage(value) {
      this.$emit("set-checkbox-message", value);
      this.setCheckbox({
        type: "message",
        value,
      });
    },
    setCheckboxBilling(value) {
      this.$emit("set-checkbox-billing", value);
      this.setCheckbox({
        type: "billing",
        value,
      });
    },
    setCheckboxAddData(value) {
      this.$emit("set-checkbox-addData", value);
      this.setCheckbox({
        type: "addData",
        value,
      })
    },
    ...mapMutations({
      setCheckbox: "qrSeries/setCheckbox",
    }),
  },
  computed:{
        optionGeneratePaymentPart:{
            get(){
                return this.$store.state.qrSeries.optionGeneratePaymentPart;
            },
            set(value){
                this.$store.commit("qrSeries/setOptionGeneratePaymentPart",value);
            }
        }
  }
};
</script>