<template>
    <div>
        <v-row>
            <v-col>
                <span>This method can be used to get the access token you use to authenticate yourself on the action Endpoints</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <api-page-description 
                :items="items"
                :title="'Request Arguments:'"
                />
            </v-col>
            <v-col>
                <api-page-example
                :items="items" 
                :title="'Request Example:'"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <api-page-description 
                :items="item_response"
                :title="'Response Arguments:'"
                />
            </v-col>
            <v-col>
                <api-page-example
                :items="item_response" 
                :title="'Response Example:'"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import APIPageDesc from './default/API.Page.description.vue'
import APIPageExm from './default/API.Page.Example.vue'
export default {
    name:"APIAT",
    components:{
        'api-page-description':APIPageDesc,
        'api-page-example':APIPageExm
    },
    data(){
        return {
            items:[
                { 
                    id:1,
                    title: 'CLIENT ID', 
                    requirement:'mandatory',
                    description:'This parameter is you account\'s Client ID you receive when you create your API User',
                    type:'String',
                    name:'client_id',
                    value:`[Account's Client ID]`
                },
                {
                    id:2,
                    title:'CLIENT SECRET',
                    requirement:'mandatory',
                    description:'This parameter is you account\'s Client Secret you receive when you create your API User',
                    type:'String',
                    name:'client_secret',
                    value:`[Account's Client Secret]`

                },
                {
                    id:3,
                    title:'Duration',
                    requirement:'optional',
                    description:`The token\'s duration in seconds. The value must be between 1 - 2592000 seconds (30 days).\n
                    If not specified the default value is set to 86400 seconds (1 day).`,
                    type:'String',
                    name:`duration`,
                    value:`[Token's Duration in seconds]`

                }
            ],
            item_response:[
                {
                    id:1,
                    title:'Status',
                    description:`The http response status.`,
                    type:'number',
                    name:'status',
                    value:'200'
                },
                {
                    id:2,
                    title:'Message',
                    description:'The respose associated message.',
                    type:'String',
                    name:'message',
                    value:'Success'
                },
                {
                    id:3,
                    title:'Data',
                    description:'The response object containing parameters useful to the user.',
                    type:'Object',
                    name:'data',
                    children:[
                        {
                            id:31,
                            title:'Access Token',
                            description:'The token to use on action requests.',
                            type:'String',
                            name:'access_token',
                            value:'[ACCESS TOKEN]'
                        },
                        {
                            id:32,
                            title:'Duration',
                            description:'The duretion of the generated token.',
                            name:'duration',
                            value:`[Tokens' duration in seconds]`,
                            type:'Number',
                        }
                    ]
                }
            ],
        }
    }
}
</script>
