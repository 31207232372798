const addressInfo = {
    namespaced: true,
    state: {
        addressArea:'',
        climateSelected: true,
        logoArea:null,
        collectiveAdress:'',
        fontSize:12,
        fontSelected:'Roboto',
        specialInstructions: '',
        hideQRLogo:false,
    },
    mutations: {
        setAddressInfo: (state, payload) =>{
            state.addressArea = payload
        },
        setLogoArea: (state,payload) =>{
            state.logoArea = payload
        },
        setClimate: (state,payload) => {
            state.climateSelected = payload
        },
        setColectiveAddress:(state,payload)=>{
            state.collectiveAdress = payload
        },
        setSpecialInstructions:(state,payload)=>{
            state.specialInstructions = payload
        }
    }
};
export default addressInfo;