<template>
<v-form ref="form" :disabled="show">
  <div>
    <v-row>
      <v-col class="py-0">
        <v-text-field
          :label="$t('qrGenerator.payableTo.IBAN_field_CHF')"       
          v-model="creditor.account"
          v-mask="'AA## #### #XXX XXXX XXXX X'"
          :rules="[rules.isIBAN, rules.ibanCounter, rules.isValidIBANNumber, rules.noInvalidCharacters]" 
          @input="IBANValidation"
          counter="26"
          validate-on-blur
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="userHasWir"> <!-- v-if="showFieldsWir" -->
      <v-col class="py-0">
        <v-text-field
          :label="$t('qrGenerator.payableTo.IBAN_field_CHW')" 
          v-model="creditor.accountWir"
          v-mask="'AA## #### #XXX XXXX XXXX X'"
          :rules="[rules.isIBAN, rules.ibanCounter, rules.isValidIBANNumber, rules.noInvalidCharacters]"
          @input="IBANValidation"
          counter="26"
          validate-on-blur
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="py-0">
        <v-text-field
          :label="$t('qrGenerator.payableTo.firstName_field')"
          v-model="creditor.name"
          :rules="[rules.isName,rules.creditorFullName, rules.noInvalidCharacters]"
          counter="70"
          :counter-value="() => (this.creditor.name.length + this.creditor.last_name.length)"
          :maxLength="70 - this.creditor.last_name.length"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          :label="$t('qrGenerator.payableTo.lastName_field')"
          v-model="creditor.last_name"
          :rules="[rules.creditorFullName, rules.noInvalidCharacters]"
          counter="70"
          :counter-value="() => (this.creditor.name.length + this.creditor.last_name.length)"
          :maxLength="70 - this.creditor.name.length"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="4" class="py-0">
        <v-text-field
          :label="$t('qrGenerator.payableTo.street_field')"
          col="8"
          v-model="creditor.address.street"
          :rules="[rules.address, rules.noInvalidCharacters]"
          @input="debitorAddressType"
          counter="60"
          maxLength="60"
        ></v-text-field>
      </v-col>
      <v-col col="4" class="py-0">
        <v-text-field
          :label="$t('qrGenerator.payableTo.houseNumber_field')"
          col="4"
          v-model.number="creditor.address.number"
          counter="16"
          maxLength="16"
          @input="debitorAddressType"
          :rules="[rules.noInvalidCharacters]"
        ></v-text-field>
        <!-- debitor.numberCounter -->
      </v-col>
      <v-col col="4" class="py-0">
        <v-text-field
          :label="$t('qrGenerator.payableTo.postalCode_field')"
          v-model="creditor.address.zip"
          :rules="[rules.zip, rules.zipRequired, rules.noInvalidCharacters]"
          counter="16"
          maxLength="16"
          @input="debitorAddressType"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="6" class="py-0">
        <v-text-field
          :label="$t('qrGenerator.payableTo.city_field')"
          v-model="creditor.address.city"
          :rules="[rules.city, rules.cityRequire, rules.noInvalidCharacters]"
          @input="debitorAddressType"
          counter="30"
          maxLength="30"
        ></v-text-field>
      </v-col>
      <v-col col="6" class="py-0">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
         :items="this.$t('countryList')"
          item-text="name"
          item-value="code"
          :label="$t('qrGenerator.payableTo.country_field')"
          v-model="creditor.address.country"
          :rules="[rules.country, rules.noInvalidCharacters]"
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-0">
        <v-card-title>{{  $t("qrGenerator.payableTo.reference_title") }}</v-card-title>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="this.$t('typeReference')"
          item-text="type"
          item-value="value"
          :label="$t('qrGenerator.payableTo.typeOfReference_field')"
          v-model="creditor.typeReference"
          :error="errorState"
          :error-messages="errorMessage"
          :rules="[rules.typeReferenceRules, rules.noInvalidCharacters]"
          @change="setInputReference"
        ></v-select>
      </v-col>
    </v-row>

    <div v-if="isPremium" >
      <v-row v-if="creditor.typeReference == 'QRR'">
        <v-col>
          <v-tabs 
            v-model="tabQRR" 
            fixed-tabs
            @change="tabsQRRChanged"
          >
            <v-tab>Manual</v-tab>
            <v-tab>{{$t("qrGenerator.automatic")}}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabQRR">
            <v-tab-item>
              <v-row>
                <v-col cols="12" class="py-0" >
                  <v-text-field
                    :label="$t('qrGeneratorFree.payableTo.reference_field')"
                    v-model="reference"
                    :value="e => $event.target.value"
                    v-mask="referenceMask"
                    :rules="[rules.referenceLength, rules.validationReference, rules.noInvalidCharacters]"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row no-gutters >
                <v-col cols="3">
                  <v-checkbox
                   :label="$t('qrGenerator.date')"
                    v-model="QRRCheckboxs.date"
                    @change="recalculateRandomQRR"
                  >
                  </v-checkbox>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="8" v-if="QRRCheckboxs.date" >
                  <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        :label="$t('qrGenerator.date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        {{$t("Globals.cancel")}}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="saveQRRDate(date)"
                      >
                        {{$t("Globals.ok")}}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

              </v-row>
              <!-- <v-row no-gutters >
                <v-col cols="4" >
                  <v-checkbox
                    class="py-0"
                    label="User ID"
                    v-model="QRRCheckboxs.id"
                  ></v-checkbox>
                </v-col>
              </v-row> -->
              <v-row no-gutters>
                <v-col v-if="QRRCheckboxs.date" cols="2" >
                  <v-text-field
                    :label="$t('qrGenerator.date')"
                    v-model="QRRFields.date"
                    :rules="[rules.QRRDateLength, rules.noSpecialCharacters, rules.noInvalidCharacters]"
                    v-mask="'## ####'"
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col v-if="QRRCheckboxs.id" >
                  <v-text-field
                    label="User ID"
                    v-model="QRRFields.id"
                    :rules="[rules.QRRIdLength, rules.noSpecialCharacters]"
                    counter="10"
                  >
                  </v-text-field>
                </v-col> -->
                <v-col >
                  <v-text-field
                    v-model="QRRFields.reference"
                    :label="$t('qrGeneratorFree.payableTo.reference_field')"
                    :rules="[rules.QRRReferenceLength, rules.noInvalidCharacters]"
                    v-mask="automaticQRReferenceMask"
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field 
                    v-model="QRRCheckDigit"
                    :label="$t('qrGenerator.check_digit')"
                    disabled
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

          </v-tabs-items>
        </v-col>
      </v-row>
      
      <v-row v-if="creditor.typeReference == 'SCOR'">
        <v-col>
          <v-tabs 
            v-model="tabSCOR" 
            fixed-tabs
            @change="tabsSCORChanged"
          >
            <v-tab>Manual</v-tab>
            <v-tab>{{$t("qrGenerator.automatic")}}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabSCOR">
            <v-tab-item>
              <v-row>
                <v-col cols="12" class="py-0" >
                  <v-text-field
                    :label="$t('qrGeneratorFree.payableTo.reference_field')"
                    v-model="reference"
                    :value="e => $event.target.value"
                    v-mask="referenceMask"
                    :rules="[rules.referenceLength, rules.validationReference, rules.noInvalidCharacters]"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row no-gutters >
                <v-col cols="3">
                  <v-checkbox
                    :label="$t('qrGenerator.date')"
                    v-model="SCORCheckboxs.date"
                    @change="recalculateRandomSCOR"
                  ></v-checkbox>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="8" v-if="SCORCheckboxs.date" >
                  <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        :label="$t('qrGenerator.date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >{{$t("Globals.cancel")}}</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="saveSCORDate(date)"
                      >{{$t("Globals.ok")}}</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

              </v-row>
              <v-row no-gutters >
                <!-- <v-col cols="4" >
                  <v-checkbox
                    class="py-0"
                    label="User ID"
                    v-model="SCORCheckboxs.id"
                  >
                  </v-checkbox>
                </v-col> -->
              </v-row>
              <v-row no-gutters>
                <v-col cols="2" >
                  <v-text-field
                    value="RF"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="2" >
                  <v-text-field 
                    v-model="SCORCheckDigit"
                    :label="$t('qrGenerator.check_digit')"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="2" v-if="SCORCheckboxs.date" >
                  <v-text-field
                    :label="$t('qrGenerator.date')"
                    v-model="SCORFields.date"
                    :rules="[rules.SCORDatelength, rules.noSpecialCharacters, rules.noInvalidCharacters]"
                    v-mask="'XXXX XX'"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- <v-col v-if="SCORCheckboxs.id" >
                  <v-text-field
                    label="User ID"
                    v-model="SCORFields.id"
                    :rules="[rules.SCORIdLength, rules.noSpecialCharacters]"
                    counter="6"
                  >
                  </v-text-field>
                </v-col> -->
                <v-col >
                  <v-text-field
                    v-model="SCORFields.reference"
                    :rules="[rules.SCORReferenceLength, rules.noSpecialCharacters, rules.noInvalidCharacters]"
                    :label="$t('qrGeneratorFree.payableTo.reference_field')"
                    v-mask="automaticSCORMask"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

          </v-tabs-items>
        </v-col>
      </v-row>
    </div>

    <div v-else >
      <v-row>
        <v-col cols="12" class="py-0" >
          <v-text-field
            :label="$t('qrGeneratorFree.payableTo.reference_field')"
            v-model="reference"
            :value="e => $event.target.value"
            v-mask="referenceMask"
            :rules="[rules.referenceLength, rules.validationReference, rules.noInvalidCharacters]"
            validate-on-blur
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mask } from "vue-the-mask";
import countryList from '@/countrylist.json';
import userMixin from '@/mixins/user.mixin';
import { QRR } from "@/functions/reference";
import ISO11649 from "iso-11649";
import { createHelpers } from "vuex-map-fields";
import {validateCharac} from "../../functions/invalidCharacters";

const { mapFields } = createHelpers({
  getterType: "QR/getField",
  mutationType: "QR/updateField",
});

export default {
    mixins: [userMixin],
    directives: {
        mask,
    },
    async mounted(){

        //get the partners that has the type wir 
        try{
          const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/partners/wir`);
          const {data} = await response.json();
          this.wirPartners = data.partners;
          this.$store.commit("QR/setPartnersWir",this.wirPartners);
        }catch(err){
          console.log(err);
        }
      
       //this.checkMathPartner()

    },
    data() {
      return {
        automaticQRReferenceMask:'## ##### ##### ##### ##### ####',
        automaticSCORMask:'XXXX XXXX XXXX XXXX XXXX X',
        rules: {
          isIBAN: value => {   
             return  !!value || this.$t("qrGeneratorFree.rules.isIBAN")
           } ,
          ibanCounter: value => value && value.length <= 26 || this.$t("qrGeneratorFree.rules.ibanCounter"),
          isValidIBANNumber: value => isValidIBANNumber(value) === 1 ? true : this.$t("rules.isValidQRIBAN"),
          isName: value => !!value || this.$t("qrGeneratorFree.rules.isName"),
          creditorFullName: value =>
          (this.creditor.name.length + this.creditor.last_name.length <= 70)
          ||this.$t("qrGeneratorFree.rules.creditorFullName"),
          address: value => (value.length <= 60) || this.$t("qrGeneratorFree.rules.address"),
          zip: value => {
              if (typeof value !== "string")
                  return (
                  String(value).length <= 16 ||
                 this.$t("qrGeneratorFree.rules.zip")
                  );
              return (
                  value.length <= 16 || this.$t("qrGeneratorFree.rules.zip")
              );
          },
          zipRequired: value => !!this.creditor.address.zip  || this.$t("qrGeneratorFree.rules.zipRequired"),
          city: value => (value.length <= 30) || this.$t("qrGeneratorFree.rules.city"),
          cityRequire: value => !!this.creditor.address.city || this.$t("qrGeneratorFree.rules.cityRequired"),
          country: value => !!value || this.$t("qrGeneratorFree.rules.country"),
          typeReferenceRules: value => {
            if (!this.typeOfReference) {
                return this.$t("qrGeneratorFree.rules.typeReferenceRules");
            } else {
                if (this.typeReferenceStatus) 
                    return true; 
                return false;
            }

          },
          referenceLength: value => {
            if(
                !this.SCORTabSelected && !this.QRRTabSelected 
                || this.SCORTabSelected === 'MANUAL' || this.QRRTabSelected === 'MANUAL'
              )
              {
                let reference = "";
                if(this.reference){
                  reference =  this.reference.replace(/ /g, "");
                }
                
                if (this.creditor.typeReference === "QRR") {
                  if (reference.length !== 27) {
                    return this.$t("qrGeneratorFree.rules.referenceLength.QRR");
                  } else {
                    return true;
                  }
                } else if (this.creditor.typeReference === "SCOR") {
                  if (!(reference.length >= 5 && reference.length <= 25)) {
                    return this.$t("qrGeneratorFree.rules.referenceLength.SCOR");
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              } else {
                return true;
              }
          },
          validationReference: value => {
            if(
                !this.SCORTabSelected && !this.QRRTabSelected 
                || this.SCORTabSelected === 'MANUAL' || this.QRRTabSelected === 'MANUAL'
              )
              {
                let reference = this.reference ? this.reference.replace(/ /g, "") : " ";
                if (this.creditor.typeReference === "QRR") {
                  // Get the digit that should be the last digit in the reference
                  let lastDigit = QRR(reference);
                  if (reference.charAt(27) != lastDigit) {
                    return this.$t("qrGeneratorFree.rules.reference.QRR");
                  } else {
                    return true;
                  }
                } else if (this.creditor.typeReference === "SCOR") {
                  if (reference.length >= 5 && reference.length <= 25) {
                    try {
                      let validation = ISO11649.validate(reference);
                      return validation || this.$t("qrGeneratorFree.rules.invalid_reference");
                    } catch(e) {
                      return this.$t("qrGeneratorFree.rules.reference.SCOR.invalid")
                    }
                  } else {
                    return this.$t("qrGeneratorFree.rules.reference.SCOR.invalidLength");
                  }
                }
                return true;
              } else {
                return true
              }
            },
            SCORDatelength: value => {
              if(this.SCORTabSelected == 'AUTOMATIC') {
                let newValue = value.replace(/\s/g,'');
                if(newValue) {
                  if(newValue.length > 6 && this.SCORCheckboxs.date) {
                    return false
                  }
                }
                if(!newValue && this.SCORCheckboxs.date) {
                  return false
                }
              }
              return true;
            },
            noSpecialCharacters: value => {
              if(this.SCORTabSelected == 'AUTOMATIC') {
                if(value) {
                  let newValue = value.replace(/\s/g,'')
                  return (/^[a-zA-Z0-9]+$/i).test(newValue);
                }
              }
              return true;
            },
            SCORIdLength: value => {
              if(this.SCORTabSelected == 'AUTOMATIC') {
                if(value) {
                  if(value.length > 6 && this.SCORCheckboxs.id) {
                    return false
                  }
                }
                if(!value && this.SCORCheckboxs.id) {
                  return false;
                }
              }
              return true
            },
            SCORReferenceLength: value => {
              if(this.SCORTabSelected == 'AUTOMATIC') {
                if(value) {
                  let newValue = value.replace(/\s/g,'');
                  let checkLimit = this.SCORCheckboxs.date ? 15 : 21;
                  if(newValue.length === checkLimit) return true;
                  return this.$t("qrGeneratorFree.rules.reference.SCOR.invalidLength");;
                }
                if(!value) {
                  return false;
                }
              }
              return true;
            },
            QRRDateLength: value => {
              if(this.QRRTabSelected == 'AUTOMATIC') {
                let newValue = value.replace(/\s/g,'');
                if(newValue) {
                  if(newValue.length > 6 && this.QRRCheckboxs.date) {
                    return false
                  }
                }
                if(!newValue && this.QRRCheckboxs.date) {
                  return false;
                }
              }
              return true;
            },
            QRRIdLength: value => {
              if(this.QRRTabSelected == 'AUTOMATIC') {
                if(value) {
                  if(value.length > 10 && this.QRRCheckboxs.id) {
                    return false
                  }
                }
                if(!value && this.QRRCheckboxs.id) {
                  return false
                }
              }
              return true;
            },
            QRRReferenceLength: value => {
              if(this.QRRTabSelected == 'AUTOMATIC') {
                if(value) {
                  let newValue = value.replace(/\s/g,'');
                  let checkLimit = this.QRRCheckboxs.date ? 20 :26;
                  if(newValue.length === checkLimit) return true;
                  return this.$t("qrGeneratorFree.rules.referenceLength.QRR");
                }
                if(!value) {
                  return false
                }
              }
              return true;
            },
            noInvalidCharacters: value =>{
              if(validateCharac(value)){
                return this.$t("Globals.error_character");
              }else{
                return true;
              }
            }

        },
        profilesArray: [],
        show:false,
        select_master: 0,
        countryList,
        errorState: false,
        errorMessage: "",
        showReference: false,
        wirPartners:null,
        userInputIbanWir:false,
        tab: null,
        tabSCOR: null,
        tabQRR: null,
        date: null,
        menu: false,
        modal: '',
        typeReferenceStatus:true
      }
    },
    errorCaptured (err, vm, info) {
      console.log(`${err.stack}\n\nfound in ${info} of component`)
    },
    beforeDestroy(){
      this.creditor.reference = '';
      this.reference = '';
      this.QRRFields.reference = '';
      this.SCORFields.reference = '';
    },
    props: {
      isPremium: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      

      validateIBAN(account){
          // the mask/format that its used for each type of reference
          let QRRMask = "## ##### ##### ##### ##### #####";
          let SCORMask = "AA## XXXX XXXX XXXX XXXX XXXX X";
          this.tabSCOR = 0;
          this.tabQRR = 0;
          this.QRRTabSelected = '';
          this.SCORTabSelected = '';
         if (this.$store.state.User.creditor.typeReference == "QRR") {
            this.QRRTabSelected = 'MANUAL';
            this.SCORTabSelected = '';
            this.tabSCOR = 0;
            this.tabQRR = 0;
            this.referenceMask = QRRMask;
            this.referenceCounter = 32;
            if (account?.length > 0) {
              if (account.charAt(5) != 3) {
                this.errorState = true;
                this.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                this.typeReferenceStatus = false;
              } else {
                this.errorState = false;
                this.errorMessage = "";
                this.typeReferenceStatus = true;
              }
            }
  
            // if the user select SCOR - Reference
          } else if (this.$store.state.User.creditor.typeReference == "SCOR") {
            this.SCORTabSelected = 'MANUAL';
            this.QRRTabSelected = '';
            this.tabSCOR = 0;
            this.tabQRR = 0;
            this.referenceMask = SCORMask;
            this.referenceCounter = 25;
            if (account?.length > 0) {
              if (account.charAt(5) == 3) {
                this.errorState = true;
                this.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                this.typeReferenceStatus = false;
              } else {
                this.errorState = false;
                this.errorMessage = "";
                this.typeReferenceStatus = true;
              }
            }
            // if the user select NON - Reference
          } else if (this.$store.state.User.creditor.typeReference == "NON") {
            this.SCORTabSelected = '';
            this.QRRTabSelected = ''
            this.tabSCOR = 0;
            this.tabQRR = 0;
            this.referenceMask = "";
            this.referenceCounter = 0;
            if (account?.length > 0) {
              if (account.charAt(5) == 3) {
                this.errorState = true;
                this.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                this.typeReferenceStatus = false;
              } else {
                this.errorState = false;
                this.errorMessage = "";
                this.typeReferenceStatus = true;
              }
            }
          }
      },

      IBANValidation() {

        //depending if user has wir o not we need get the correct iban to validate
        const fieldsToValidate = [];
        if(this.userHasWir){
          fieldsToValidate.push(this.creditor.account);
          fieldsToValidate.push(this.creditor.accountWir);
        }else{
           fieldsToValidate.push(this.creditor.account);
        }

        for(let i = 0; i < fieldsToValidate.length ; i++){
          
          if(fieldsToValidate[i]?.length > 4) {

            if(fieldsToValidate[i].charAt(5) == 3) {
                
                if(this.creditor.typeReference === "QRR") {
                  this.errorState = false;
                  this.errorMessage = "";
                  this.typeReferenceStatus = true;
                } else {
                  
                  this.errorState = true;
                  this.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                  this.typeReferenceStatus = false;
                  break;
                }
            } else {
                  if(this.creditor.typeReference === "QRR") {
                    this.errorState = true;
                    this.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                    this.typeReferenceStatus = false;
                    break;
                  } else {
                    this.errorState = false;
                    this.errorMessage = "";
                    this.typeReferenceStatus = true;
                  }
            }

          }else if(fieldsToValidate[i]?.length === 0) {
              this.errorState = false;
              this.errorMessage = "";
              this.typeReferenceStatus = true;
          }
        }

      },

      debitorAddressType() {
          // if the user hasnt entered all these information will be considered as combined address
          let structured =
              this.debitor.address.steet != "" &&
              this.debitor.address.number != "" &&
              this.debitor.address.zip != "" &&
              this.debitor.address.city != "";
          if (structured) {
              this.debitor.addressType = "S";
              this.debitor.numberCounter = 16;
          } else {
              (this.debitor.addressType = "K"), (this.debitor.numberCounter = 70);
          }
      },

      setInputReference() {
    
        //we need validate reference depending if the user has a wir o not 
        this.$nextTick(()=>{

          //we need validate reference depending if the user has a wir o not 
          if(this.userHasWir){
              this.validateIBAN(this.creditor.accountWir);
              this.validateIBAN(this.creditor.account);
          }else{
              this.validateIBAN(this.creditor.account)
          }

        });
      },

      saveQRRDate(date) {
        this.$refs.dialog.save(date);
        this.QRRFields.date = date
          .split('-')
          .join('')
          .substring(2, 8);
      },
      saveSCORDate(date) {
        this.$refs.dialog.save(date);
        this.SCORFields.date = date
          .split('-')
          .join('')
          .substring(2, 8);
      },
      getQRCheckDigit(reference) {
        let digitsArray = [
          [0,9,4,6,8,2,7,1,3,5,0],
          [9,4,6,8,2,7,1,3,5,0,9],
          [4,6,8,2,7,1,3,5,0,9,8],
          [6,8,2,7,1,3,5,0,9,4,7],
          [8,2,7,1,3,5,0,9,4,6,6],
          [2,7,1,3,5,0,9,4,6,8,5],
          [7,1,3,5,0,9,4,6,8,2,4],
          [1,3,5,0,9,4,6,8,2,7,3],
          [3,5,0,9,4,6,8,2,7,1,2],
          [5,0,9,4,6,8,2,7,1,3,1]
        ];
        let splittedReference = reference.split('');
        let carry = 0;
        for(let i = 0;i<splittedReference.length;i++){
          let currentDigit = Number(splittedReference[i]);
          carry = digitsArray[carry][currentDigit];
        }
        return digitsArray[carry][10];
      },

      getSCORCheckDigit(reference){
        let splitted = reference.split('');
        let currentStringValue = '';
        for(let i = 0;i<splitted.length;i++){
          currentStringValue +=`${parseInt(splitted[i],36)}`;
        }    
        let numbersArray = [...Array(100).keys()];
        let matchedValue = numbersArray.find((element)=>{
          let currentNumber = `${element}`.length == 1 ? `0${element}` : `${element}`;
          let currentNumberValue = BigInt(`${currentStringValue}${currentNumber}`);
          return currentNumberValue % 97n == 1n
        });
        return matchedValue;
      },
      tabsQRRChanged(e) {
        const states = ['MANUAL', 'AUTOMATIC'];
        this.QRRTabSelected = states[e];
        this.SCORTabSelected = '';
        this.tabSCOR = 0;
        if(this.QRRTabSelected === 'AUTOMATIC'){
          this.recalculateRandomQRR();
        }else{
          // TODO: get the reference from the debtor
          const myReference = this.$store.state.QR.debitor.reference
          if(myReference) {
            if(this.detectTypeReference(myReference) === 'QRR') {
              return this.reference = myReference
            } 
          }
          return this.reference = this.$store.getters['QR/actualReference'];
        }
      },
      tabsSCORChanged(e) {
        const states = ['MANUAL', 'AUTOMATIC'];
        this.SCORTabSelected = states[e];
        this.QRRTabSelected = '';
        this.tabQRR = 0;
        if(this.SCORTabSelected === 'AUTOMATIC'){
          this.recalculateRandomSCOR();
        }else{
          // TODO: get the reference from the debtor
          const myReference = this.$store.state.QR.debitor.reference
          if(myReference) {
            if(this.detectTypeReference(myReference) === 'SCOR') {
              return this.reference = myReference;
            } 
          }
          return this.reference = this.$store.getters['QR/actualReference'];
        }
      },
      detectTypeReference(reference){
        if(reference){
          let spacelessReference = reference.replace(/\s/g,'');
          let isSCOR = /([a-zA-z]){2}([0-9]){2}([0-9a-zA-z]){21}$/.test(spacelessReference);
          let isQRR = /([0-9]){2}([0-9]){25}$/.test(spacelessReference);
          if(isSCOR) return 'SCOR';
          if(isQRR) return 'QRR';
        }
        return 'NON'
      },
      randomQRReference(base,base2){
        let firstRandom = ((Math.random() * base2) + base).toFixed(0);
        let secondRandom = ((Math.random()* base2) + base).toFixed(0);
        let currentReference = `${firstRandom}${secondRandom}`;
        let auxReference = currentReference;
        if(this.QRRCheckboxs.date){
          currentReference = `${auxReference.substring(0,1)}`;
          currentReference += ` ${auxReference.substring(1,6)}`;
          currentReference += ` ${auxReference.substring(6,11)}`;
          currentReference += ` ${auxReference.substring(11,16)}`;
          currentReference += ` ${auxReference.substring(16,20)}`;
        }else{
          currentReference = `${auxReference.substring(0,2)}`;
          currentReference += ` ${auxReference.substring(2,7)}`;
          currentReference += ` ${auxReference.substring(7,12)}`;
          currentReference += ` ${auxReference.substring(12,17)}`;
          currentReference += ` ${auxReference.substring(17,22)}`;
          currentReference += ` ${auxReference.substring(22,26)}`;
        }
        return currentReference;
      },
      async recalculateRandomQRR(){
        let newQRRMask = '## ##### ##### ##### ##### ####';
        let base = 1000000000000;
        let base2 = 8999999999999;
        if(this.QRRCheckboxs.date){
          base = 1000000000;
          base2 = 8999999999;
          newQRRMask = '# ##### ##### ##### ####';
        }else{
          this.QRRFields.date = '';
          this.date = '';
        }
        this.automaticQRReferenceMask = newQRRMask
        this.QRRFields.reference = await this.randomQRReference(base,base2);
        this.reference = this.$store.getters['QR/actualReference'];
      },
      async recalculateRandomSCOR(){
        let newSCORMask = 'XXXX XXXX XXXX XXXX XXXX X';
        let base1 = 10000000000;
        let base2 = 1000000000;
        let multiplicativeBase1 = 89999999999;
        let multiplicativeBase2 = 8999999999;
        if(this.SCORCheckboxs.date){
          base1 = 10000000;
          base2 = 1000000;
          multiplicativeBase1 = 89999999;
          multiplicativeBase2 = 8999999;
          newSCORMask = 'XX XXXX XXXX XXXX X';
        }else{
          this.SCORFields.date = '';
          this.date = '';
        }
        this.automaticSCORMask = newSCORMask
        this.SCORFields.reference = await this.randomSCORReference(
          base1,
          base2,
          multiplicativeBase1,
          multiplicativeBase2
        );
        this.reference = this.$store.getters['QR/actualReference'];
      },
      randomSCORReference(base1,base2,multiplicativeBase1,multiplicativeBase2){
        let firstRandom = ((Math.random() * multiplicativeBase1) + base1).toFixed(0);
        let secondRandom = ((Math.random()* multiplicativeBase2) + base2).toFixed(0);
        let currentReference = `${firstRandom}${secondRandom}`;
        let auxReference = currentReference;
        if(this.SCORCheckboxs.date){
          currentReference = `${auxReference.substring(0,2)}`;
          currentReference += ` ${auxReference.substring(2,6)}`;
          currentReference += ` ${auxReference.substring(6,10)}`;
          currentReference += ` ${auxReference.substring(10,14)}`;
          currentReference += ` ${auxReference.substring(14,15)}`;
        }else{
          currentReference = `${auxReference.substring(0,4)}`;
          currentReference += ` ${auxReference.substring(4,8)}`;
          currentReference += ` ${auxReference.substring(8,12)}`;
          currentReference += ` ${auxReference.substring(12,16)}`;
          currentReference += ` ${auxReference.substring(16,20)}`;
          currentReference += ` ${auxReference.substring(20,21)}`;
        }
        return currentReference;       
      },
    },
    computed: {
        ...mapGetters({
            debitor: "QR/debitor",
            creditor: 'User/creditor',
            user: 'User/user',
            SCORCheckDigit: 'QR/SCORCheckDigit',
            QRRCheckDigit: 'QR/QRRCheckDigit',
            QRRReferenceCounter: 'QR/QRRReferenceCounter',
            SCORReferenceCounter: 'QR/SCORReferenceCounter',
        }),

        ...mapFields({
          QRRCheckboxs: 'checkboxs.QRR',
          SCORCheckboxs: 'checkboxs.SCOR',
          QRRFields: 'automaticReferences.QRR',
          SCORFields: 'automaticReferences.SCOR',
        }),
        SCORTabSelected: {
          get() {
            return this.$store.state.QR.SCORTabSelected
          },
          set(value) {
            this.$store.state.QR.SCORTabSelected = value;
          }
        },

        userInputIbanWithWir:{
            get(){
              return this.userInputIbanWir;
            },
            set(value){
               //save in data
               this.userInputIbanWir = value;
               //save in the store
               //this.showFieldsWir = value;
            }
        },
        
        /*showFieldsWir:{
           get(){
              return this.$store.state.QR.showFieldsWir;
           },
           set(userInputIbanWir){
              let condition = this.userHasWir &&  userInputIbanWir;
              //we need to reset currencie to CHF because the user doesnt input  a iban with wir
              if(!condition){

                 //we need to clean the field iban wir   
                 this.$store.commit("User/setAccountWir","");
                 //set defaul currency
                 this.$store.commit("QR/setCurrency", "CHF");
                 //select the currency depending of wir percentage
                 this.$store.commit("QR/setPercentage",0);

              }

              return  this.$store.commit("QR/setShowFieldsWir",condition);
           }
        },*/

        userHasWir(){
            return this.$store.state.User.wir_active;
        },

        QRRTabSelected: {
          get() {
            return this.$store.state.QR.QRRTabSelected
          },
          set(value) {
            this.$store.state.QR.QRRTabSelected = value
          }
        },
        reference: {
          get() {
            return this.$store.state.QR.reference;
          },
          set(reference) {
            this.$store.commit("QR/setReference", reference);
          }
        },
        referenceMask: {
          get() {
            return this.$store.state.User.creditor.referenceMask;
          },
          set(value) {
            this.$store.commit('User/setReferenceMask', value);
          }
        },
        referenceCounter: {
            get() {
                return this.$store.state.User.creditor.referenceCounter;
            },
            set(value) {
                this.$store.commit('User/setReferenceCounter', value)
            }
        },
        typeOfReference:{
          get(){
            return this.$store.state.User.creditor.typeReference;
          },
          set(value){
            return this.$store.state.User.creditor.typeReference = value;
          }
        }, 
    }
}

function formatIBAN(iban) {
    let newIBAN = iban;
    let newFormat = "";
    if (iban !== "") {
    newFormat =
        newIBAN.substring(0, 4) +
        " " +
        newIBAN.substring(4, 8) +
        " " +
        newIBAN.substring(8, 12) +
        " " +
        newIBAN.substring(12, 16) +
        " " +
        newIBAN.substring(16, 20) +
        " " +
        newIBAN.charAt(20);
    }
    return newFormat;
}

function isValidIBANNumber(input) {
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26
  };
  var iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits);
}

function mod97(string) {
  var checksum = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}
</script>

<style>
</style>