<template>
    <v-form>
        <v-row>
            <v-col cols="12">
                <p style="font-weight:bold;" class="py-0">  {{$t('StepByStep.ChooseProduct.product_title')}}</p>
                <v-select
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    :items="$t('StepByStep.ChooseProduct.product_entry')"
                    v-model="subscription"
                ></v-select>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
export default {
    props:['propsMap', 'URLSubscription'],
    data() {
        return {
            subscription: '',
        }
    },
    watch: {
        URLSubscription(newValue, oldValue) {
            this.subscription = newValue
        }
    },
    created() {
        this.subscription = this.URLSubscription
    }
}
</script>