<template>
  <div >
    <v-card class="d-flex">
      <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" app clipped permanent right>
        <v-list>
          <v-list-item icon @click.stop="mini = !mini">
            <v-icon>mdi-menu</v-icon>
          </v-list-item>
          <template v-for="item in items">
            <template v-if="!item.items">
              <v-list-item :key="item.title" :to="item.to" @click="Logout(item.title)">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-else-if="item.items">
              <v-list-group :key="item.title" :prepend-icon="item.icon">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="subItem in item.items"
                  :key="subItem.title"
                  @click="subItem.active = !subItem"
                  :to="subItem.to"
                >
                  <v-list-item-icon>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="subItem.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>
          </template>
        </v-list>
      </v-navigation-drawer>
    </v-card>
      <v-container class="pt-0" fluid>
            <router-view></router-view>
      </v-container>
  </div>
</template>

<script>
export default {
  created(){
    this.$store.commit(`global/setPageTitle`,'Admin Dashboard');
  },
  destroyed(){
    this.$store.commit(`global/setPageTitle`,'');
  },
  data() {
    return {
      Text: null,
      mini: false,
      drawer: false,
    };
  },
  computed:{
    items(){
      return[
        { title:this.$t('Admin.Cockpit.users'),icon: 'mdi-account-multiple',to: '/admin/dashboard/users'},
        { title:this.$t('Admin.Cockpit.partners'),icon: 'mdi-account-group',to: '/admin/dashboard/partners'},
        { title:this.$t('Admin.Cockpit.general_product'),icon:'mdi-card-account-details-star',to:'/admin/dashboard/general-product-settings'},
        { title:this.$t('Admin.Cockpit.general_usage'),icon:'mdi-chart-areaspline',to:'/admin/dashboard/general-usage-info'},
        { title:this.$t('Admin.Cockpit.scheduled_charges'),icon:'mdi-credit-card-clock',to:'/admin/dashboard/scheduled-charges'},
        { title:this.$t('Admin.Cockpit.Logout'), icon: "mdi-power" }
     ]
      
   }
  },
  methods: {
    Logout: function(item) {
      //Sens a requesto to the server through the "/api/logout/" enpoint to
      //End the user's session in the server
      if (item === "Logout") {
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/logout`, {
          method: "POST",
          credentials: "include"
        })
          .then(result => {
            return result.json();
          })
          .then(() => {
            document.cookie = '_csrf=;';
            this.$store.commit("User/setLogin", 0);
            this.$store.commit("User/setSubscriptionName", "");
            this.$router.push({ name: "admin" });
          })
          .catch(err => {
            this.$store.commit("global/setSnackbar", { message: err });
          });
      }
    }
  },
};
</script>