<template>
  <div class="pa-0">
    <v-row>
      <v-card class="pt-0" flat>
        <v-card-title class="pt-0 pb-0">
          {{ $t("Admin.Partners.partners") }}
        </v-card-title>

        <v-data-table
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          dense
          :headers="headers.headers"
          :items="items"
          :search="search"
          :loading="DOMElements.loadPartnerTableData.loading"
          :loading-text="$t('CreditorFiles.loading')"
          multi-sort
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            itemsPerPageText:$t('Admin.Partners.itemsPerPage')
          }"
          calculate-widths
        >
        

          <template v-slot:top>
            <v-toolbar class="justify-end pt-0" dense flat color="white">
              <v-toolbar-title>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('Admin.Partners.search')"
                  single-line
                  hide-details
                  class="pt-0"
                ></v-text-field>
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                persistent
                v-model="showMasterDialog"
                :width="calculateWidth"
              >
                <template v-slot:activator="{ on }">
                  <v-col class="pl-1" cols="3">
                    <v-overflow-btn
                      segmented
                      overflow
                      dense
                      :label="$t('Admin.Partners.actions')"
                      class="mb-2"
                      :items="headers.actionButtonItems"
                      item-text="text"
                      :loading="enableActionBtnLoading"
                    ></v-overflow-btn>
                  </v-col>
                </template>

                <v-card :width="calculateWidth">
                  <v-card-title>{{ setMasterDialogTitle }}</v-card-title>
                  <v-card-text class="pb-0">
                    
                    <!-- NEW/EDIT PARTNER !-->
                    <div v-if="dialog">
                      <v-form ref="form">
                        <v-row>
                          <v-col cols="6 pb-0 pt-0">
                            <v-text-field
                              :label="
                                $t('Admin.dashboardPartners.partner_name')
                              "
                              v-model="partner.name"
                              :rules="[rules.isRequired]"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6 pb-0 pt-0">
                            <v-select
                              :items="$t('Admin.Partners.itemsTypePartner')"
                              :label="
                                $t('Admin.dashboardPartners.type_partner')
                              "
                              :disabled="isEditPartner"
                              v-model="partner.type"
                              @change="clearValueType"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row>

                           <v-col cols="6 pb-0 pt-0">
                            <v-text-field
                              :label="
                                $t('Admin.dashboardPartners.contact_name')
                              "
                              v-model="partner.contact"
                              :rules="[rules.isRequired]"
                            ></v-text-field>
                          </v-col>

                           <v-col  v-if="partner.type == '' || partner.type=='Financial Institution' "   cols="6 pt-1 pb-0">
                            <v-text-field
                              :label="$t('Admin.dashboardPartners.iid')"
                              type="text"
                              v-model="partner.iid"
                              valpoifiigbidate-on-blur
                              :rules="[rules.isRequired, rules.onlyNumber, rules.iidLength]"
                            ></v-text-field>
                          </v-col>  

                           <v-col v-else cols="6 pt-1 pb-0">
                            <v-text-field
                              :label="$t('Admin.dashboardPartners.deep_link')"
                              v-model="partner.deep_link"
                              :disabled="isEditPartner"
                              :rules="[rules.isRequired,rules.onlyLetters,rules.deepLink]"
                            ></v-text-field>
                          </v-col> 

                        </v-row>
                        
                        <v-row>


                           <v-col cols="6 pt-1 pb-0">
                            <v-text-field
                              :label="$t('Admin.dashboardPartners.email')"
                              v-model="partner.email"
                              :rules="[rules.isRequired, rules.email]"
                            ></v-text-field>
                          </v-col>


                           <v-col cols="6 pb-0 pt-0">
                            <v-select
                              :items="$t('Admin.Partners.partnerModelItems')"
                              :label="
                                $t('Admin.dashboardPartners.partner_model')
                              "
                              v-model="partner.model"
                              :rules="[rules.partnerModel]"
                              :disabled="isEditPartner"
                            ></v-select>
                          </v-col>  

                        </v-row>

              
                        <v-row>
                           
                           
                          <v-col cols="6 pt-1 pb-0">
                            <v-text-field
                              :label="$t('Admin.dashboardPartners.street')"
                              v-model="partner.street"
                              :rules="[
                                (v) => !!v || $t('rules.street_rule'),
                              ]"
                            ></v-text-field>
                          </v-col> 

                          <v-col cols="6 pt-1 pb-0">
                            <v-text-field
                              :label="
                                $t('Admin.dashboardPartners.number')
                              "
                              v-model="partner.number"
                            ></v-text-field>
                          </v-col> 

                        </v-row>

                        <v-row>

                            <v-col cols="3 pt-1 pb-0">
                            <v-text-field
                              :label="
                                $t('Admin.dashboardPartners.city')
                              "
                              v-model="partner.city"
                              :rules="[
                                (v) => !!v || $t('rules.city_rule'),
                              ]"
                            ></v-text-field>
                          </v-col>

                           <v-col cols="3 pt-1 pb-0">
                            <v-text-field
                              :label="$t('Admin.dashboardPartners.zip')"
                              type="number"
                              v-model.number="partner.zip"
                              :rules="[
                                rules.onlyNumber,
                                rules.zipLength,
                              ]"
                              validate-on-blur
                            ></v-text-field>
                          </v-col> 


                          <v-col cols="6 pt-1 pb-0">
                            <v-select
                              :no-data-text="
                                $t('qrGenerator.productsTable.data_available')
                              "
                              :items="$t('countryList')"
                              item-text="name"
                              item-value="code"
                              :label="$t('Admin.dashboardPartners.country')"
                              v-model="partner.country"
                              
                              :rules="[
                                (v) => !!v || $t('rules.entry_rule'),
                              ]"
                            ></v-select>
                          </v-col>

                        </v-row>

                      </v-form>
                    </div>
                    
                    
                  </v-card-text>

                  <v-card-actions class="justify-end">
                  
                    
                    <!-- NEW/EDIT PARTNER !-->
                    <div v-if="dialog">
                      <v-col class="pt-0 pb-0">
                        <v-row>
                          <v-col cols="6">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              dark
                              @click="close"
                              >{{ $t("Globals.cancel") }}</v-btn
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              dark
                              @click="save"
                              :loading="DOMElements.save.loading"
                              >{{ $t("Globals.save") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                    
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>


          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="deleteItem(item)"
              :loading="DOMElements.deleteItem.loading"
              v-if="true"
            >
              mdi-delete
            </v-icon>
          </template>
         
        </v-data-table> 

      </v-card>
    </v-row>
  </div>
</template>

<script>

import { mask } from "vue-the-mask";
export default {

  directives: {
    mask,
  },

  data() {
    return {
      partner:{
         name:"",
         contact:"",
         city:"",
         street:"",
         number:"",
         iid:"",
         email:"",
         zip:"",
         deep_link:"",
         type:"Financial Institution",
         model:"",
      },
      isEditPartner:false,
      ordersItems: [],
      showMasterDialog: false,
      enableActionBtnLoading: false,
      cardCountyCode: "",
      showCardData: false,
      enableCardDialog: false,
      hiddenFieldsArray: [],
      search: "",
      dialog: false,
      items: [],
      editedIndex: -1,
      showULogsDialog:false,
      editedItem: {
        name: "",
        email: "",
        contact:"",
        street: "",
        number: "",
        zip: "",
        city: "",
        country: "",
        type:"",
        iid:"",
        deep_link:""
      },
      defaultItem: {
        name: "",
        contact:"",
        email: "",
        street: "",
        number: "",
        zip: "",
        city: "",
        country: "",
        type:"",
        iid:"",
        deep_link:""
      },
      DOMElements: {
        deleteItem: {
          loading: false,
        },
        save: {
          loading: false,
        },
        loadPartnerTableData: {
          loading: false,
        }
      },
      rules: {
        
        isRequired: (value) => {
             return !!value || this.$t("rules.required")
          },
        
        partnerModel:(value) => {
            return !!value || this.$t("rules.required")
        },

        onlyNumber: value => {   
           //check that all characters are digits
           let cad = value.toString();
           let matches = cad.match(/[0-9]/gi);
           return cad?.length ==  matches?.length || 'Only numbers allowed'
        },

        onlyLetters: value => {
           
           if(typeof value !== 'string'){
              return 'Only letters allowed';
           }

           if(!value.match(/[a-zA-Z]{3}/i)){
              return 'Only letters allowed';
           }
           
           return true; 
           
        },

        zipLength: value => {
          return value.toString().length === 4 || 'ZIP has to be 4 characters length'
        },

        iidLength:value => {
          return value.toString().length === 5 || 'IID has to be 5 characters length'
        },

        deepLink: value  => {
          return  value.toString().length === 3 ||  'DEEP LINK has to be 3 characters length'
        },

        notEmpty: value => !!value || 'Field must not be empty',
        
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$t('signup.rules.email');
          
        },

      }
    
    };
  },
  computed: {
    headers() {
      return {
        headers: [
          { text: "ID", value: "id" },
          { text: this.$t("Admin.Partners.name"), value: "name" },
          { text: this.$t("Admin.Partners.contact"), value: "contact" },
          { text: this.$t("Admin.Partners.email"), value: "email" },
          { text: this.$t("Admin.Partners.street"), value: "street" },
          { text: this.$t("Admin.Partners.number"), value: "number" },
          { text: this.$t("Admin.Partners.zip"), value: "zip" },
          { text: this.$t("Admin.Partners.city"), value: "city" },
          { text: this.$t("Admin.Partners.country"), value: "country" },
          { text: this.$t("Admin.Partners.type"), value: "type" },
          { text: this.$t("Admin.Partners.partner_model"), value: "model" },
          { text: this.$t("Admin.Partners.iid"), value: "iid" },
          { text: this.$t("Admin.Partners.deeplink"), value: "deep_link" },
          {
            text: this.$t("Admin.Partners.creation_date"),
            value: "creation_date",
          },
          {
            text: this.$t("Admin.Partners.actions"),
            value: "actions",
            sortable: false,
          },
        ],
        actionButtonItems: [
          {
            text: this.$t("Admin.Partners.new_partner"),
            callback: () => this.openUserDialog(),
          },
          {
            text: this.$t("Admin.Partners.export"),
            callback: () => this.exportParents(),
          },
        ],
      };
    },
    setMasterDialogTitle() {
      if (this.dialog) {
        if (this.editedIndex === -1) {
          this.passwordAvailable = true;
          this.isEditPartner = false;
          return this.$t("Admin.Partners.new_partner");
        } else {
          this.passwordAvailable = false;
          return this.$t('Admin.Partners.editPartner');
        }
      } else {
        return "";
      }
    },

    calculateWidth() {
      if (this.showULogsDialog) {
        return "";
      } else if (this.enableCardDialog) {
        return "500px";
      } else if (this.enableMembershipDialog) {
        return "500px";
      } else if (this.showBillsDialog) {
        return "";
      } else if (this.dialog) {
        return "";
      } else if (this.profileDialog) {
        return "";
      } else if (this.lockUserFlag) {
        return "";
      } else {
        return "";
      }
    }
    
  },
  mounted() {
    this.loadPartnersTableData();
  },
  destroyed() {
    this.partner.name = "";
    this.partner.street = "";
    this.partner.number = "";
    this.partner.zip = "";
    this.partner.city = "";
    this.partner.country = "";
    this.partner.type = "";
    this.partner.iid = "";
    this.partner.deep_link = "";
  },
  methods: {
    
    async deleteItem(item) {
      //This function deletes partner form the data base
      //If confirmed by the admin, a request is send to the server through the "/api/deletePartner" endpoint
      //this request deletes the partner
      const index = this.items.indexOf(item);
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          this.$t('Admin.Partners.messageDelete')
        )
        .then(async (deletePartner) => {
          if (deletePartner) {
            this.DOMElements.deleteItem.loading = true;
            await this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/admin/partner`,
              {
                method: "DELETE",
                credentials: "include",
                body: JSON.stringify({
                  id: item.id,
                }),
                headers: {
                  "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                },
              }
            )
              .then((response) => {
                this.items.splice(index, 1);
                return this.$store.commit("global/setSnackbar", {
                  message: response.message,
                });
              })
              .catch(this.$handleCatch);
            this.DOMElements.deleteItem.loading = false;
          }
        });
    },
    //////////////////////////Partner data table functions//////////////////////////////////
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.partner.email = item.email;
      this.partner.name = item.name;
      this.partner.contact = item.contact;
      this.partner.street = item.street;
      this.partner.number = item.number;
      this.partner.zip = item.zip;
      this.partner.city = item.city;
      this.partner.country = item.country;
      this.partner.type = item.type;
      this.partner.model = item.model;
      this.partner.iid = item.iid;
      this.partner.deep_link = item.deep_link;
  
      //Opens dialog
      this.isEditPartner = true;
      this.dialog = true;
      this.showMasterDialog = true;
      this.$nextTick(() => {
        //this.$refs.signUpRef.legalFormFunction();
      });
    },
    
    openUserDialog() {
      this.hiddenFieldsArray = new Array();
      //Opens dialog
      this.dialog = true;
      this.showMasterDialog = true;
      this.$nextTick(() => {
        //this.$refs.signUpRef.legalFormFunction();
      });
    },
    close() {
      this.dialog = false;
      this.showMasterDialog = false;
      this.hiddenFieldsArray = new Array();
      this.$nextTick(() => {

        this.partner.email = "";
        this.partner.name = "";
        this.partner.street = "";
        this.partner.number = "";
        this.partner.contact = "";
        this.partner.zip = "";
        this.partner.city = "";
        this.partner.country = "";
        this.partner.type = "Financial Institution";
        this.partner.iid = "";
        this.partner.deep_link = "";
        
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    /////////////////////////////////////////////////////////////////////////////////////
    async save() {
      //This function creates or edit a partner 
      //If the request has an ID and if it matches one on the DB
      //then a partner is edited, if not then the partner is created
      
      if (!this.$refs.form.validate()) {
        return this.$store.commit(`global/setSnackbar`, {
          message: this.$t("snack_confirm.invalid_inputs"),
        });
      }

      let date = new Date();
      let formatDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`

      let data = {
        id: this.editedItem.id ? this.editedItem.id : null,
        name: this.partner.name,
        contact: this.partner.contact,
        email: this.partner.email,
        street: this.partner.street,
        number: this.partner.number,
        zip: this.partner.zip,
        city: this.partner.city,
        country: this.partner.country,
        type: this.partner.type,
        iid: this.partner.iid,
        deep_link: this.partner.deep_link,
        model:this.partner.model,
        creation_date:formatDate
      };

     
  
      this.DOMElements.save.loading = true;
      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/partner`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data),
        headers: {
          "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
        },
      })
        .then((response) => {
          if (response) {
            if (this.editedIndex > -1) {
              Object.assign(this.items[this.editedIndex], {
                name: data.name,
                contact:data.contact,
                email: data.email,
                street: data.street,
                number: data.number,
                zip: data.zip,
                city: data.city,
                country: data.country,
                type: data.type,
                iid : data.iid,
                deep_link : data.deep_link,
                model: data.model
              });
            } else {
              
              this.items.push({
                id: response.partner.id,
                name: response.partner.name,
                contact: response.partner.contact,
                email: response.partner.email,
                street: response.partner.street,
                number: response.partner.number,
                zip: response.partner.zip,
                city: response.partner.city,
                country: response.partner.country,
                type: response.partner.type,
                iid:response.partner.iid,
                deep_link: response.partner.deep_link,
                model:response.partner.model,
                creation_date: response.partner.creation_date?.split('T')[0]
              });
            }
            
            this.$store.commit("global/setSnackbar", {
              message: response.message,
            });
            return this.close();
          }
        })
        .catch((err)=>{
          console.log(err);
            this.$store.commit("global/setSnackbar", {
                  message: err.response.message,
            });
        })
           
        
         this.DOMElements.save.loading = false;
    },
  
    async loadPartnersTableData() {
      //When the component is created loads the data from every user inside the Data Base
      this.items = new Array();
      this.DOMElements.loadPartnerTableData.loading = true;
      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/partners`)
        .then(async (response) => {
          if (response) {
            let partnersArray = response.data.partners;
            for (let i = 0; i < partnersArray.length; i++) {
               
              let tempObj = {
                id: partnersArray[i].id,
                name: partnersArray[i].name || "",
                contact: partnersArray[i].contact || "",
                email: partnersArray[i].email || "",
                street: partnersArray[i].street || "",
                number: partnersArray[i].number || "",
                zip: partnersArray[i].zip || "",
                city: partnersArray[i].city || "",
                country: partnersArray[i].country || "",
                iid: partnersArray[i].iid || "",
                deep_link: partnersArray[i].deep_link || "",
                type: partnersArray[i].type || "",
                model: partnersArray[i].model || "",
                creation_date: partnersArray[i].creation_date?.split('T')[0] || "",
              };
              this.items.push(tempObj);
            }
            return (this.loadState = false);
          }
        })
        .catch((err) => {
          this.loadState = false;
          this.$handleCatch(err);
        });
      this.DOMElements.loadPartnerTableData.loading = false;
    },
    
    closeLogUserDialog() {
      this.showULogsDialog = false;
      this.showMasterDialog = false;
    },
   
    exportParents() {
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          `Are you sure you want to export the users as a CSV file?`
        )
        .then((confirmation) => {
          if (confirmation) {
            this.enableActionBtnLoading = true;
            this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/admin/partner/export`,
              {
                method: "GET",
                credentials: "include",
              }
            )
              .then((response) => {
                if (response) {
                  if (response.status == 200) {
                    if (response.data) {
                      let base64String = `data:text/csv;base64,${response.data}`;
                      fetch(base64String)
                        .then((resultFile) => resultFile.blob())
                        .then((responseFile) => {
                          let url = window.URL.createObjectURL(responseFile);
                          let a = document.createElement("a");
                          a.href = url;
                          a.download = `partners.csv`;
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                          return (this.enableActionBtnLoading = false);
                        })
                        .catch((err) => {
                          return this.$store.commit(`global/setSnackbar`, {
                            message: err,
                          });
                        });
                    }
                  }
                } else {
                  return this.$store.commit(`global/setSnackbar`, {
                    message: this.$t("Globals.unable_server"),
                  });
                }
              })
              .catch((err) => {
                this.enableActionBtnLoading = false;
                return this.$store.commit(`global/setSnackbar`, {
                  message: err,
                });
              });
          }
        });
    },

    clearValueType(){
       if(this.partner.type === "Financial Institution"){
          this.partner.deep_link = ""; 
       }else{
          this.partner.iid = "";
       }
    }
  },
  errorCaptured(err, vm, info) {
    console.log(`${err.stack}\n\nfound in ${info} of component`);
    // return false
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
};
</script>