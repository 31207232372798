<template>
    <div>
        <v-card flat>
            <v-card-title>API Documentation</v-card-title>
            <v-card-subtitle>
                <v-row>
                    <v-col>
                        <span>Base URL: <span class="cardSubtitleAPIDoc">{{serverURL}}</span></span>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col>
                        <span>Authorization Endpoints</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-expansion-panels >
                        <v-expansion-panel>
                            <v-expansion-panel-header> POST /access_token</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <APIAT/>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Headers</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <Headers/>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        </v-expansion-panels>                        
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <span>Action Endpoints</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-expansion-panels>

                            <v-expansion-panel>
                                <v-expansion-panel-header>POST /qr_bill</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <APIQRP/>
                                </v-expansion-panel-content>                            
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>GET /qr_bill</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <APIQRG/>
                                </v-expansion-panel-content>                            
                            </v-expansion-panel>

                         <v-expansion-panel>
                                <v-expansion-panel-header>GET /reference</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <APIRG/>
                               </v-expansion-panel-content>     
                          </v-expansion-panel>   


                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
        
    </div>
</template>

<script>
import APIAT from './pages/API.Access_token.vue';
import APIQRP from './pages/API.qr_bill.post.vue';
import APIQRG from './pages/API.qr_bill.get.vue';
import APIRG from './pages/API.reference.get.vue';
import Headers from './pages/API.Headers.vue'


export default {
    name:'Documentation',
    components:{
        APIAT,
        APIQRP,
        APIQRG,
        APIRG,
        Headers
    },
    data(){
        return{
            serverURL:`https://api.qrmodul.ch/v1`
        }
    }
}
</script>

<style>
    .cardSubtitleAPIDoc{
        font-weight: bold;
        font-size: 15px;
        color:firebrick;
    }
</style>