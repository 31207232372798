var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-0",attrs:{"flat":"","height":"100%"}},[_c('v-card-title',{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.$t("qrGeneratorFree.payableTo.main_title")))]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('AA## #### #XXX XXXX XXXX X'),expression:"'AA## #### #XXX XXXX XXXX X'"}],attrs:{"label":_vm.$t('StepByStep.UserInformation.iban_field'),"rules":[
              _vm.rules.notEmpty,
              _vm.rules.ibanCounter,
              _vm.rules.isIBAN,
              _vm.rules.isValidIBANNumber
            ],"counter":"26"},on:{"input":_vm.checkMathPartner},model:{value:(_vm.creditor.account),callback:function ($$v) {_vm.$set(_vm.creditor, "account", $$v)},expression:"creditor.account"}})],1)],1),(_vm.showFieldIbanWir)?_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('AA## #### #XXX XXXX XXXX X'),expression:"'AA## #### #XXX XXXX XXXX X'"}],attrs:{"label":_vm.$t('StepByStep.UserInformation.iban_field_wir'),"rules":[
                _vm.rules.notEmpty,
                _vm.rules.ibanCounter,
                _vm.rules.isIBAN,
                _vm.rules.isValidIBANNumber
              ],"counter":"26"},model:{value:(_vm.creditor.accountWir),callback:function ($$v) {_vm.$set(_vm.creditor, "accountWir", $$v)},expression:"creditor.accountWir"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('AA## #### #XXX XXXX XXXX X'),expression:"'AA## #### #XXX XXXX XXXX X'"}],attrs:{"label":_vm.$t('StepByStep.UserInformation.qriban_field'),"rules":[
              _vm.rules.ibanCounter,
              _vm.rules.isQRIBAN,
              _vm.rules.isValidQRIBAN
            ],"counter":"26"},model:{value:(_vm.creditor.qr_account),callback:function ($$v) {_vm.$set(_vm.creditor, "qr_account", $$v)},expression:"creditor.qr_account"}})],1)],1),(_vm.showFieldIbanWir)?_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('AA## #### #XXX XXXX XXXX X'),expression:"'AA## #### #XXX XXXX XXXX X'"}],attrs:{"label":_vm.$t('StepByStep.UserInformation.qriban_field_wir'),"rules":[
              _vm.rules.ibanCounter,
              _vm.rules.isQRIBAN,
              _vm.rules.isValidQRIBAN
            ],"counter":"26"},model:{value:(_vm.creditor.qr_account_wir),callback:function ($$v) {_vm.$set(_vm.creditor, "qr_account_wir", $$v)},expression:"creditor.qr_account_wir"}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }