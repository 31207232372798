<template>
 <div>
    <v-card shaped class="mx-auto my-12"
    max-width="374">
     <div   class="d-flex justify-center">
            <v-img
          max-width="200"
          max-height="300"
          :src="images.logo"
    > <div class="fill-height repeating-gradient"></div></v-img>
    </div>
      <v-card-title> {{$t('Change_Password.title')}} </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="change_password">
            <v-row>
              <v-col cols="12">
                <v-text-field
                 :label="$t('Change_Password.new_password')"
                  type="password"
                  v-model="change_password.new_password"
                  outlined
                  dense
                  :rules="[rules.passwordLength]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                 :label="$t('Change_Password.confirm_password')"
                 outlined
                 dense
                  type="password"
                  v-model="change_password.new_password2"
                 :error-messages="passwordError()"
                  
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pr-6">
        <v-spacer></v-spacer>
        <v-btn ripple @click="btnCancelPassword()">{{$t('Change_Password.btn_cancel')}}</v-btn>
        <v-btn
          :color="$vuetify.theme.themes.light.blue.primary"
          dark
          ripple
          @click="btnSaveNewPassword"
          :loading="DOMElements.btnSaveNewPassword.loading"
          >{{$t('Change_Password.btn_save_new')}}</v-btn
        >
      </v-card-actions>
    </v-card> 
  </div>  
</template>

<script>
export default {
  data(){
    return{
        images: {
                logo: require('../../../../assets/images/default/qrLogoComplete.png')
            },
      change_password:{
        new_password: "",
        new_password2: "",
      },
      token: '',
      language: '',
      DOMElements: {
        btnSaveNewPassword: {
          loading: false,
        }
      },
    rules: {
      passwordLength: value =>{
        return String(value).length > 7 || this.$t("rules.passwordLength");
      }
    },
    }
  },

  beforeMount(){
    // Save the token and configure the language
    if (!!this.$route.query.language){
      this.language = this.$route.query.language;
      localStorage.setItem('User/setLanguage',`{"language":"${this.language}"}`);
    }
    else{
      this.language = localStorage.getItem('User/setLanguage') !== undefined ? JSON.parse(localStorage.getItem('User/setLanguage')).language : 'de';
    }
    if (!!this.$route.query.token){
      this.token = this.$route.query.token;
    }

    // Reload page with correct language
    let app = this.$parent.$parent.$parent;
    this.$i18n.locale = this.language;
    this.$store.state.User.language = this.language;
    app.languageSelector = this.language;
    app.languageSelectorLabel = this.$t("app.languageSelector_field");

    app.languagesArray.forEach((lang) => {
      lang.text = this.$t(`app.languages.${lang.value}`);
    });
    const { params, path } = this.$route;
    params.language = this.language;
    if (path === "/") {
      this.$router.push({ params, path: `/${this.language}` });
    } else {
      this.$router.push({ params });
    }
  },
  watch:{
    locale(newValue,oldValue){
      this.$store.commit("global/setPageTitle",this.$t("Change_Password.main_title"))
    }
  },
  computed:{
    locale(){
      return this.$store.state.User.language;
    },
  },
  mounted(){
    //This commit  changes the title on the dashboard
    this.$store.commit("global/setPageTitle", this.$t("Change_Password.main_title"));
    window.addEventListener('keypress',this.onKeypress,false);
  },

  destroyed(){
    window.removeEventListener('keypress',this.onKeypress,false);
  },
  
  methods:{
    onKeypress(e){
      if(e.key === "Enter"){
        return this.btnSaveNewPassword();
      }
    },

    async btnSaveNewPassword() {
      // Check for errors
      if (this.change_password.new_password == "" || this.change_password.new_password2 == ""){
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("rules.notempty"),
        });
      }
      if (this.change_password.new_password != this.change_password.new_password2) {
        return this.$store.commit("global/setSnackbar", {
          message:this.$t("rules.passwordConfirmationRule"),
        });
      }
      if(this.change_password.new_password.length <= 7)
      {
        return this.$store.commit("global/setSnackbar",{
          message: this.$t("rules.passwordLength")
        });
      }
      if (this.token == '')
      {
        return this.$store.commit("global/setSnackbar",{
          message: this.$t("Change_Password.token_lost")
        });
      }
      this.DOMElements.btnSaveNewPassword.loading = true;

      // Call the endpoint, passing the new password and the token
      await fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/restorePassword?lang=${this.$i18n.locale}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          password: this.change_password.new_password,
          token: this.token
        })
      })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.status == 200){
          this.$router.push({ name: "Login" });
        }
        return this.$store.commit("global/setSnackbar", {
          message: result.message,
        });
      })
      .catch((err) => {
        this.$store.commit("global/setSnackbar", { message: err });
      });
      this.DOMElements.btnSaveNewPassword.loading = false;
    },

    passwordError() {
      return this.change_password.new_password ===
        this.change_password.new_password2
        ? ""
        : this.$t("rules.passwordConfirmationRule");
    },

    btnCancelPassword() {
      this.$router.push({ name: "Login" });
    },
     
  }
}
</script>