import { QR_SVG } from '@/store/static';
import { getField, updateField } from "vuex-map-fields";
import { detectTypeReference } from './../../detectTypeReference.js';

import store from '../index';
const { createCanvas, loadImage } = require('canvas');
const canvas = createCanvas(196.53543307, 359.05511811, 'pdf');


const initialDebtorsFail = [
  {
    "id_b": 601,
    "id": 1,
    "salutation": "mr",
    "name": "Hans",
    "last_name": "Müller",
    "address_suffix": "",
    "street": "Feldweg",
    "number": "12",
    "zip": "8001",
    "city": "Hausen am Albis",
    "country": "CH",
    "currency": "CHF",
    "products_json": "[{\"name\":\"Mitgliederbeitrag 2021\",\"quantity\":\"1\",\"unitPrice\":\"200\",\"grossPriceProduct\":\"200.00\",\"vat\":\"0.00\",\"vatProduct\":\"200.00\",\"netPriceProduct\":\"0.00\"}]",
    "language": "de",
    "reference": "RF16210511000000000000003",
    "message": "",
    "client_number": "",
    "email": "hans@mueller.ch",
    "client_reference": "",
    "qr_status": null,
    "first_warning": null,
    "second_warning": null,
    "last_modified_date": "2021-05-13 23:19",
    "billing_information": {
      "invoice_no": "",
      "invoice_date": "",
      "vat_date": "",
      "client_reference": "",
      "UID": "",
      "VAT": null,
      "discount": null,
      "payment_deadline": null
    },
    "error": false
  },
  {
    "id_b": 603,
    "id": 3,
    "salutation": "mrs",
    "name": "Sarah",
    "last_name": "Beispiel",
    "address_suffix": "",
    "street": "Mustergasse",
    "number": "1",
    "zip": "1001",
    "city": "Musterhausen",
    "country": "CH",
    "currency": "CHF",
    "products_json": "[{\"name\":\"Mitgliederbeitrag 2021 Einzelperson\",\"quantity\":\"1\",\"unitPrice\":\"50\",\"grossPriceProduct\":\"50.00\",\"vat\":\"7.70\",\"vatProduct\":\"46.43\",\"netPriceProduct\":\"3.57\"},{\"name\":\"Donation\",\"quantity\":\"1\",\"unitPrice\":\"20\",\"vat\":\"7.70\",\"vatProduct\":\"18.57\",\"netPriceProduct\":\"1.43\",\"grossPriceProduct\":\"20.00\"},{\"name\":\"Service description\",\"quantity\":\"0\",\"unitPrice\":\"0\",\"vat\":\"0.00\",\"vatProduct\":\"0.00\",\"netPriceProduct\":\"0.00\",\"grossPriceProduct\":\"0.00\"}]",
    "language": "de",
    "reference": "RF80210511000000000000055",
    "message": "Mitgliederbeitrag 2021",
    "client_number": "2020.05.12345",
    "email": "sarah@beispiel.ch",
    "client_reference": "140.000-01",
    "qr_status": null,
    "first_warning": null,
    "second_warning": null,
    "last_modified_date": "2021-05-13 23:19",
    "billing_information": {
      "invoice_no": "10-20140-1",
      "invoice_date": "2020-06-30",
      "vat_date": "",
      "client_reference": "",
      "UID": "",
      "VAT": null,
      "discount": null,
      "payment_deadline": null
    },
    "error": false
  }
]

function getSCORCheckDigit(reference){
  let splitted = reference.split('');
  let currentStringValue = '';
  for(let i = 0;i<splitted.length;i++){
    currentStringValue +=`${parseInt(splitted[i],36)}`;
  }    
  let numbersArray = [...Array(100).keys()];
  let matchedValue = numbersArray.find((element)=>{
    let currentNumber = `${element}`.length == 1 ? `0${element}` : `${element}`;
    let currentNumberValue = BigInt(`${currentStringValue}${currentNumber}`);
    return currentNumberValue % 97n == 1n
  });
  return matchedValue;
};

function getQRCheckDigit(reference){
  let newReference = reference.replace(/\s/g,'');
  let digitsArray = [
    [0,9,4,6,8,2,7,1,3,5,0],
    [9,4,6,8,2,7,1,3,5,0,9],
    [4,6,8,2,7,1,3,5,0,9,8],
    [6,8,2,7,1,3,5,0,9,4,7],
    [8,2,7,1,3,5,0,9,4,6,6],
    [2,7,1,3,5,0,9,4,6,8,5],
    [7,1,3,5,0,9,4,6,8,2,4],
    [1,3,5,0,9,4,6,8,2,7,3],
    [3,5,0,9,4,6,8,2,7,1,2],
    [5,0,9,4,6,8,2,7,1,3,1]
  ];
  let splittedReference = newReference.split('');
  let carry = 0;
  for(let i = 0;i<splittedReference.length;i++){
    let currentDigit = Number(splittedReference[i]);
    carry = digitsArray[carry][currentDigit];
  }
  return digitsArray[carry][10];
};


const QR = {
  namespaced: true,
  state: {
    payable_until: "",
    invoice_date:"",
    language:"",
    debitor: {
      name: "",
      last_name: '',
      address: {
        street: '',
        number: '',
        zip: '',
        city: "",
        country: ""
      },
      numberCounter: 16,
      addressType: '',
      reference:'',
      client_number:'',
      invoice_number:''
    },
    // debtorsFail:[],
    debtorsFail: initialDebtorsFail,
    reference: "",
    automaticReferences: {
      QRR: {
        date: '',
        id: '',
        reference: '',
        checkDigit: '',
      },
      SCOR: {
        date: '',
        id: '',
        reference: '',
        checkDigit: '',
      }
    },
    checkboxs: {
      QRR: {
        date: false,
        id: false,
      },
      SCOR: {
        date: false,
        id: false,
      }
    },
    SCORTabSelected: '',
    QRRTabSelected: '',
    currency: "CHF",
    stateAmount: false,
    amount: 0,
    amountCHW: 0,
    partnersWir:[],
    wirPercentage: 0,
    //showFieldsWir:false ,
    bothCurrencies:false,
    qrSVG: QR_SVG,
    qrSVG2: QR_SVG,
    qrSkeleton: true,
    processing: false,
    oldReference: '' 
  },
  mutations: {
    updateField,
    setOldReference: (state,payload) => {
      state.oldReference = payload;
    },
    /*setShowFieldsWir(state,payload){
       state.showFieldsWir = payload;
    },*/
    qrSVG: (state, payload) => {
      state.qrSVG = payload.qrSVG
    },
    qrSVG2: (state, payload) => {
      state.qrSVG2 = payload.qrSVG2
    },
    setAmount: (state, payload) => {
      state.amount = payload
    },
    setPercentage:(state,payload) => {
      state.wirPercentage = payload;
    },
    setPartnersWir(state,payload){
      state.partnersWir = payload;
    },
    setBothCurrencies:(state,payload) => {
       state.bothCurrencies = payload;
    },
    setReference: (state, payload) => {
      state.reference = payload
    },
    setPayableUntil:(state,payload)=>{
      state.payable_until = payload
    },
    setCurrency: (state, payload) => {
      state.currency = payload
    },
    setProcessing: (state, payload) => {
      state.processing = payload;
    },
    setAmountCHW:(state,payload)=> {
      state.amountCHW = payload;
    },
    setSkeleton: (state, payload) => {
      state.qrSkeleton = payload;
    },
    setStateAmount: (state, payload) => {
      state.stateAmount = payload
      if(payload == true) {
        store.commit('global/setSnackbar', { message: `The amount has been disabled`  })
      } else if(payload == false) {
        store.commit('global/setSnackbar', { message: `The amount has been enabled`  })
      }
    },
    setDebitor: (state, payload) => {
      Object.assign(state.debitor, payload)
    },
    clearDebitor: (state, payload) => {
      Object.assign(state.debitor, {
        name: "",
        last_name: '',
        address: {
          street: '',
          number: '',
          zip: '',
          city: "",
          country: ""
        },
        numberCounter: 16,
        addressType: '',
        reference:''
      })
    }
  },
  getters: {
    getField,
    asignCorrectDebtorReference: (state) =>{
      let correctReference = '';
      if(state.debitor.reference){
        let getTypeOfReference = detectTypeReference(state.debitor.reference);
        let creditor = store.getters['User/creditor'];
        if(creditor.typeReference === getTypeOfReference){
          correctReference = state.debitor.reference;
        }

      }
      return correctReference;
      // if(state.debitor.reference){
      //   if(creditor.typeReference !== 'NON'){
      //     let spacelessReference = state.debitor.reference.replace(/\s/g,'');
      //     let isSCOR = /^[a-zA-Z0-9]+$/i.test(spacelessReference); 
      //   }
      // }
      
    },
    actualReference: (state,getters) =>{
      let currentReference = state.reference.replace(/\s+/g, "");
      let creditor = store.getters['User/creditor'];
      if(creditor.typeReference === 'QRR'){
        currentReference = !!state.automaticReferences.QRR.reference ? `${state.automaticReferences.QRR.reference}${getters.QRRCheckDigit}` : currentReference;
        let newQRRDate = state.automaticReferences.QRR.date ? state.automaticReferences.QRR.date : '';
        if(!!newQRRDate && !!currentReference && state.checkboxs.QRR.date){
          currentReference = `${newQRRDate}${currentReference}`;
        }
      }else if(creditor.typeReference === 'SCOR'){
        currentReference = !!state.automaticReferences.SCOR.reference ? `RF${getters.SCORCheckDigit} ${state.automaticReferences.SCOR.reference}` : currentReference;
        let newSCORDate = state.automaticReferences.SCOR.date ? state.automaticReferences.SCOR.date : '';
        if(!!newSCORDate && !!currentReference && state.checkboxs.SCOR.date){
          currentReference = `RF${getters.SCORCheckDigit} ${newSCORDate}${state.automaticReferences.SCOR.reference}`;
        }
      }
      return currentReference;
    },
    amount: state => state.amount,
    datePickers: state => state.datePickers,
    global: state => {
      return {
        reference: state.reference,
        currency: state.currency,
        amount: state.amount
      }
    },
    debitor: state =>  state.debitor,
    debitorReceiptPartHTML: state => {
      let htmlDebitor = {
        fullName: '',
        streetAndNumber: '',
        zipAndTown: '',
        country: '',
      };
      var ctx = canvas.getContext("2d");
      ctx.font = "8pt Helvetica";
      let maxLength = 196.535433;
      // Joining both properties in only one line
      let fullName = '';
      if(state.debitor.name !== undefined && state.debitor.name !== '' && state.debitor.name !== null){
          if(state.debitor.last_name !== undefined && state.debitor.last_name !== '' && state.debitor.last_name !== null){
              fullName = `${state.debitor.name} ${state.debitor.last_name}`
          }else{
              fullName = `${state.debitor.name} `
          }
      }else{
          if(state.debitor.last_name !== undefined && state.debitor.last_name !== '' && state.debitor.last_name !== null){
              fullName = `${state.debitor.last_name} `
          }
      }
      if(ctx.measureText(fullName).width > (maxLength * 2)){
          if(state.debitor.last_name !== undefined && state.debitor.last_name !== '' && state.debitor.last_name !== null){
              fullName = `${state.debitor.last_name} `
          }else{
              fullName = `${state.debitor.name} `
          }
      }
      let streetAndNumber = `${state.debitor.address.street} ${state.debitor.address.number}`;
      let zipAndTown = `${state.debitor.address.zip} ${state.debitor.address.city}`;
      let country = state.debitor.address.country != null ? `<tspan x="0mm" dy="8pt" class="value-r" >${state.debitor.address.country}</tspan>` : '';


      // These varialbes will be used to concat the html tag with their content
      let newStreetAndNumber = '';
      let newZipAndTown = '';

      // Split the string in characters of 29, the result will be arrays of strings.
      let chunksStreetAndNumber = streetAndNumber.match(/.{1,35}/g);
      let chunksZipAndTown = zipAndTown.match(/.{1,35}/g);

      let nameArray = fullName.split(' ');
      let auxName = '';
      let finalNameString = '';
      nameArray.forEach((element,index)=>{
        let auxiliar = '';
        if(index == 0){
            if(ctx.measureText(element).width > maxLength){
                let elementChunk = element.split('');
                elementChunk.forEach(chunk => {
                    auxiliar = `${auxName}${chunk}`
                    if(ctx.measureText(auxiliar).width > maxLength) {
                        finalNameString += `<tspan x="0mm" dy="9pt"  class="is-value-r" >${auxName}</tspan>`;
                        auxName = chunk;
                    } else {
                        auxName += `${chunk}`;
                    }
                })
            }else{
                auxName += `${element}`
            }
        }else{
            auxiliar = `${auxName} ${element}`;
            if(ctx.measureText(auxiliar).width > maxLength){
                finalNameString += `<tspan x="0mm" dy="8pt"  class="is-value-r" >${auxName}</tspan>`;
                auxName = `${element}`;
            }else{
                auxName += ` ${element}`;
            }
            if(index == (nameArray.length - 1)){
                finalNameString += `<tspan x="0mm" dy="8pt"  class="is-value-r" >${auxName}</tspan>`;
            }

        }
    })
      // adding the html tags to its value
      //chunksFullName.forEach(chunk => {
      //  newFullName += `<tspan x="0mm" dy="9pt" class="value-r" >${chunk}</tspan>`;
      //});
      if(ctx.measureText(fullName).width < (maxLength * 2)){
        chunksStreetAndNumber.forEach(chunk => {
          newStreetAndNumber += `<tspan x="0mm" dy="8pt" class="value-r" >${chunk}</tspan>`;
        })

      }

      chunksZipAndTown.forEach(chunk => {
        newZipAndTown += `<tspan x="0mm" dy="8pt" class="value-r" >${chunk}</tspan>`;
      })

      htmlDebitor.fullName = finalNameString;
      htmlDebitor.streetAndNumber = newStreetAndNumber;
      htmlDebitor.zipAndTown = newZipAndTown;
      htmlDebitor.country = country;

      return htmlDebitor;
    },
    debitorPaymentPartHTML: state => {
      let htmlDebitor = {
        fullName: '',
        streetAndNumber: '',
        zipAndTown: '',
        country: '',
      };

      // Joining both properties in only one line
      let fullName = `${state.debitor.name} ${state.debitor.last_name}`;
      let streetAndNumber = `${state.debitor.address.street} ${state.debitor.address.number}`;
      let zipAndTown = `${state.debitor.address.zip} ${state.debitor.address.city}`
      let country = state.debitor.address.country != null ? `<tspan x="0mm" dy="11pt" class="is-value-p" >${state.debitor.address.country}</tspan>` : '';
      
      // These varialbes will be used to concat the html tag with their content
      let newFullName = '';
      let newStreetAndNumber = '';
      let newZipAndTown = '';

      // Split the string in characters of 29, the result will be arrays of strings.
      let chunksFullName = fullName.match(/.{1,29}/g);
      let chunksStreetAndNumber = streetAndNumber.match(/.{1,29}/g);
      let chunksZipAndTown = zipAndTown.match(/.{1,29}/g);

      // adding the html tags to its value
      chunksFullName.forEach(chunk => {
        newFullName += `<tspan x="0mm" dy="11pt" class="is-value-p" >${chunk}</tspan>`;
      });

      chunksStreetAndNumber.forEach(chunk => {
        newStreetAndNumber += `<tspan x="0mm" dy="11pt" class="is-value-p" >${chunk}</tspan>`;
      })

      chunksZipAndTown.forEach(chunk => {
        newZipAndTown += `<tspan x="0mm" dy="11pt" class="is-value-p" >${chunk}</tspan>`;
      })

      htmlDebitor.fullName = newFullName;
      htmlDebitor.streetAndNumber = newStreetAndNumber;
      htmlDebitor.zipAndTown = newZipAndTown;
      htmlDebitor.country = country;

      return htmlDebitor;
    },
    additionalInformation: state => state.additionalInformation,
    automaticReferences: (state, getters) => {
      let QRRDate = state.automaticReferences.QRR.date;
      let QRRId = state.automaticReferences.QRR.id;
      let QRRRef = state.automaticReferences.QRR.reference;
      const checkboxQRRId = state.checkboxs.QRR.id;

      QRRDate = QRRDate ? QRRDate.toString() : '';
      QRRId = QRRId ? QRRId.toString() : '';
      QRRRef = QRRRef ? QRRRef.toString() : '';

      let QRR = QRRDate;
      checkboxQRRId && (QRR += QRRId);
      QRR += QRRRef + getters.QRRCheckDigit;

      let SCORDate = state.automaticReferences.SCOR.date;
      let SCORId = state.automaticReferences.SCOR.id;
      let SCORRef = state.automaticReferences.SCOR.reference;
      const checkboxSCORId = state.checkboxs.SCOR.id;

      SCORDate = SCORDate ? SCORDate.toString() : '';
      SCORId = SCORId ? SCORId.toString() : '';
      SCORRef = SCORRef ? SCORRef.toString() : '';

      const SCORCheckDigit = getters.SCORCheckDigit;
      let SCOR = '';
      if(SCORDate || SCORId || SCORRef)
      {
        SCOR += 'RF';
        SCOR += SCORCheckDigit
      }
      SCOR += ` ${SCORDate}`; 
      checkboxSCORId && (SCOR += SCORId);
      SCOR += SCORRef;

      return {
        QRR: {
          string: QRR,
          ...state.automaticReferences.QRR,
        },
        SCOR: {
          string: SCOR,
          ...state.automaticReferences.SCOR,
        }
      }
    },
    reference: (state, getters) => {
      const { QRR, SCOR } = getters.automaticReferences;
      const QRRStringRef = QRR.string;
      const SCORStringRef = SCOR.string;

      const { SCORTabSelected, QRRTabSelected, reference } = state;

      if(!SCORTabSelected && !QRRTabSelected
          || SCORTabSelected === 'MANUAL' || QRRTabSelected === 'MANUAL'
      ) {
        return reference
      }

      if(SCORTabSelected === 'AUTOMATIC') {
        return SCORStringRef;
      }

      if(QRRTabSelected === 'AUTOMATIC') {
        return QRRStringRef;
      }

    },
    checkboxs: state => state.checkboxs,
    SCORCheckDigit: (state, getters) => {
      const { SCOR } = state.automaticReferences;
      const { date, id, reference } = SCOR;
      const checkboxSCORId = state.checkboxs.SCOR.id;
      let string = date;
      if(checkboxSCORId) string += id;
      string += reference;
      if(date || id || reference) {
        string += 'RF';
      }
      string = string.replace(/\s/g,'');
      if(string) {
        if(!(/^[a-zA-Z0-9]+$/i).test(string)) {
          return store.commit('global/setSnackbar', 'Invalid characters on SCOR reference fields');
        }
        return getSCORCheckDigit(string.toString()).toString().padStart(2, 0) || ''
      }
      return '';
    },
    QRRCheckDigit: (state, getters) => {
      const { QRR } = state.automaticReferences;
      const { date, id, reference } = QRR;
      const checkboxQRRId = state.checkboxs.QRR.id;

      let string = date;
      if(checkboxQRRId) string += id;
      string += reference;
      string = string.replace(/\s/g,'');
      if(string) {
        if(!(/^[0-9]+$/i).test(string)) {
          return store.commit('global/setSnackbar', 'Invalid characters on QRR reference fields');
        }
        let checkDigit = getQRCheckDigit(string.toString());
        if(checkDigit !== undefined && checkDigit !== null && checkDigit !== '') return checkDigit;
        return '';
      }
      return ''
    },
    QRRReferenceCounter: state => {
      let counter = 26;
      if(state.checkboxs.QRR.date) {
        counter -= 6;
      }

      if(state.checkboxs.QRR.id) {
        counter -= 10;
      }
      return counter;
    },
    SCORReferenceCounter: state => {
      let counter = 21;
      if(state.checkboxs.SCOR.date) {
        counter -= 6;
      } 

      if(state.checkboxs.SCOR.id) {
        counter -= 6;
      }
      return counter;
    }
  }
};

export default QR;
