<template>
    <div>
        <h1>Welcome {{ username.name }} </h1>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    computed: mapState({
        
        username: state => {
           if(state.User.username !== null && state.User.username !== undefined){
               return state.User.username;
           }else{
               return {name:''}
           }
           
        }
    })
}
</script>

<style>

</style>