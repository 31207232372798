
const additionalInformation = {
  namespaced: true,
  state: {
    enableBilling: '',
    unstructuredMessage: '',
    structuredMessage: {
      bill_number: undefined,
      voucherDate: undefined,
      customerReference: undefined,
      UID_Number: undefined,
      VAT_Date: { start_date: null, end_date: null },
      VAT_Details: [],
      VAT_Import: [],
      VAT_Conditions: [],
    },
    datePickers: {
      billDate: {
        active: false
      },
    },
  },
  mutations: {
    unstructuredMessage: (state, payload) => {
      state.unstructuredMessage = payload.unstructuredMessage
    },
    STRUCTURED_MESSAGE: (state, payload) => {
      Object.assign(state.structuredMessage, payload.structuredMessage);
    },
    setVAT_Details: (state, payload) => {
      state.structuredMessage.VAT_Details = payload.VAT_Details;
    },
    setVAT_Import: (state, payload) => {
      state.structuredMessage.VAT_Import = payload.VAT_Import;
    },
    setVAT_Conditions: (state, payload) => {
      state.structuredMessage.VAT_Conditions = payload.VAT_Conditions;  
    },
    setBillingInformation: (state, payload) => {
      Object.assign(state.structuredMessage, {
        bill_number: payload.bill_number,
        customerReference: payload.customerReference,
        voucherDate: payload.voucherDate,
        UID_Number: payload.UID_Number,
        VAT_Details: payload.VAT_Details,
        VAT_Import: payload.VAT_Import,
        VAT_Conditions: payload.VAT_Conditions,
      })
    }
  },
  getters: {
    unstructuredMessage: state => {
      // this variable will be use to store the html tag and its value
      let newUnstructuredMessage = '';
      if(state.unstructuredMessage && state.unstructuredMessage.length > 0) {
        // escaping the content/value «/» and «\»; these must be replaced by «\/» and «\\»
        let chunks = state.unstructuredMessage.replace(/\\/g, '\\\\').replace(/\//g, '\\/').match(/.{1,35}/g);
        chunks.forEach((chunk ) => {
          newUnstructuredMessage += `<tspan x="0mm" dy="9pt" class="is-value-p" >${chunk}</tspan>`;
        })
        return {
          // HTML: will be used in the qr.card component
          html: newUnstructuredMessage,
          // plain: the content without html tag that will be sent to the server
          plain: state.unstructuredMessage,
        };
      } else {
        return '';
      }
    },
    structuredMap: state=>{
      if(state.structuredMessage.UID_Number !== undefined && state.structuredMessage.UID_Number !== ''){
        state.structuredMessage.UID_Number = `CHE-${state.structuredMessage.UID_Number}`;
      }
      return state.structuredMessage;
    },
    structuredMessage: state => {
      const message = {
        separator: '//',
        prefix: 'S1',
        bill_number: '/10/',
        voucherDate: '/11/',
        customerReference: '/20/',
        UID_Number: '/30/',
        VATDate: '/31/',
        VATDetails: '/32/',
        VATImportTax: '/33/',
        conditions: '/40/'
      }

      let structuredMessage = '';
      if (state.structuredMessage.bill_number) {
        // escaping the content/value «/» and «\»; these must be replaced by «\/» and «\\»
        structuredMessage += `${ message.bill_number }${ state.structuredMessage.bill_number.replace(/\\/g, '\\\\').replace(/\//g, '\\/') }`;
      }

      if (state.structuredMessage.voucherDate) {
        // Removing '-'
        let newVoucherDate = state.structuredMessage.voucherDate.replace(/-/g, '').slice(2);
        structuredMessage += `${message.voucherDate}${newVoucherDate}`;
      }

      if (state.structuredMessage.customerReference) {
        // escaping the content/value «/» and «\»; these must be replaced by «\/» and «\\»
        structuredMessage += `${message.customerReference}${state.structuredMessage.customerReference.replace(/\\/g, '\\\\').replace(/\//g, '\\/')}`;
      }

      if (state.structuredMessage.UID_Number) {
        // Removing CHE, MWST, TVA, IVA, NAT, '-', '.'
        let newUID_Number = state.structuredMessage.UID_Number.replace(/CHE|MWST|TVA|IVA|VAT|-|\./g, '');
        structuredMessage += `${message.UID_Number}${newUID_Number}`;
      }
      if (state.structuredMessage.VAT_Date.start_date) {
        // Removing '-'
        let newVATDate = state.structuredMessage.VAT_Date.start_date.replace(/-/g, '').slice(2);
        if (state.structuredMessage.VAT_Date.end_date) {
          newVATDate += state.structuredMessage.VAT_Date.end_date.replace(/-/g, '').slice(2);
        }
        structuredMessage += `${message.VATDate}${newVATDate}`;
      }

      if( state.structuredMessage.VAT_Details.length > 0 ) {
        let newVAT_Detail = '';
        state.structuredMessage.VAT_Details.forEach((detail, index) => {
          let semicolon = index > 0 ? ';' : '';
          let colon = detail.amount ? ':' : '';
          let rate = '';
          if(detail.rate !== null && detail.rate !== ''){
            rate = detail.rate.replace(/%/g, '');
          }
          let amount = 0;
          if(detail.amount !== null && detail.amount !== undefined){
            amount = formatAmount(detail.amount);
            
          }
          newVAT_Detail += `${semicolon}${rate}${colon}${amount}`;
        })
        structuredMessage += `${message.VATDetails}${newVAT_Detail}`;
      }

      if ( state.structuredMessage.VAT_Import.length > 0 ) {
        let newVAT_IMPORT = '';
        state.structuredMessage.VAT_Import.forEach((tax, index) => {
          let rate = '';
          if(tax.rate !== null && tax.rate !== undefined){
            rate = tax.rate.replace(/%/g, '');
          }
          let discount = 0;
          if(tax.discount !== null && tax.discount !== undefined){
            discount = formatAmount(tax.discount);
          }
          let semicolon = index > 0 ? ';' : '';
          let colon = tax.discount ? ':' : '';
          newVAT_IMPORT += `${semicolon}${rate}${colon}${discount}`;
        })
        structuredMessage += `${message.VATImportTax}${newVAT_IMPORT}`;
      }

      if ( state.structuredMessage.VAT_Conditions.length > 0 ) {
        let newVAT_Conditions = '';
        state.structuredMessage.VAT_Conditions.forEach((condition, index) => {
          let discount = '';
          if(condition.discount !== null && condition.discount !== undefined){
            discount = condition.discount.replace(/%/g, '');
          }
          let days = '';
          if(condition.days !== null && condition.days !== undefined){
            days = condition.days;
          }
          let semicolon = index > 0 ? ';' : '';
          let colon = days ? ':' : '';
          newVAT_Conditions += `${semicolon}${discount}${colon}${days}`;
        })
        structuredMessage += `${message.conditions}${newVAT_Conditions}`;
      }
      
      let newStructuredMessage = '';
      if(structuredMessage != null && structuredMessage.length > 0) {
        structuredMessage = `${message.separator}${message.prefix}${structuredMessage}`;
        let chunks = structuredMessage.match(/.{1,35}/g);
        chunks.forEach((chunk ) => {
          newStructuredMessage += `<tspan x="0mm" dy="9pt" class="is-value-p" >${chunk}</tspan>`;
        });
        return {
          html: typeof state.enableBilling === 'boolean' && state.enableBilling === true 
            ? newStructuredMessage 
            : typeof state.enableBilling === 'string' 
              ? newStructuredMessage
              : '',
          plain: typeof state.enableBilling === 'boolean' && state.enableBilling === true 
            ? structuredMessage 
            : typeof state.enableBilling === 'string'
              ? structuredMessage
              : '',
        }
      } else {
        return '';
      }
    }
  }
};

export default additionalInformation;

function formatAmount(amount){
  if(amount.match(/\./) !== null){
    let decimalString = amount.substr(amount.indexOf('.') + 1,amount.length);
    let decimalLenght = decimalString.length; 
    return Number(amount).toFixed(decimalLenght);
  } else {  
    return Number(amount);
  }
}