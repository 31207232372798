import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/translations/en.json';
import de from '@/translations/de.json';
import fr from '@/translations/fr.json';
import it from '@/translations/it.json';
import rm from '@/translations/rm.json';

Vue.use(VueI18n);

const messages = {
  en,
  de,
  fr,
  it,
  rm
};

const i18n = new VueI18n({
  locale: 'de',
  messages,
})

export default i18n;
