<template>
    <div>
        <h1>{{variable}}</h1>
    </div>
</template>

<script>
export default {
    data(){
        return{
            variable:'',
        }
    },
    async beforeCreate(){
        this.$store.commit(`global/setHideAppBar`,true);
        let urlParams = new URLSearchParams(window.location.search);
        
        await fetch(`https://webhook.site/bcc2f24e-53ce-4a1e-90f7-3380d661f1ba`,{
            method:'POST',
            body:JSON.stringify({
                test:JSON.stringify(urlParams),
                source:'Error'
            }),
            mode:'no-cors'
        });
        
        let getErrorCode = urlParams.get('errorCode');
        window.parent.postMessage({message:'Error',errorCode:getErrorCode},`${process.env.VUE_APP_FRONT_URL}`);
    },
    destroyed(){
        this.$store.commit(`global/setHideAppBar`,false);
    }
}
</script>