<template>
    <div>
        <v-card flat>
            <v-card-title>{{title}}</v-card-title>
            <v-card-text>
                <v-divider></v-divider>
                <div
                v-for="item in items"
                :key="item.key"
                >
                    <v-row>
                        <v-col>
                            <h3>{{item.title}}</h3>
                        </v-col>
                        <v-col>
                            <span>{{item.requirement}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <span>{{item.description}}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="item.name">
                        <v-col>
                            <span>API NAME: <span style="color:black;">{{item.name}}</span></span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0">
                            <span>TYPE: 
                                    <span style="color:black;" v-if="!item.children">{{item.type}}</span>
                                    <a v-else id="hyperObjShow" @click="rerender(item)">{{item.type}}</a>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row v-if="item.reference">
                        <v-col>
                            <span>Reference: </span><a :href="item.reference" target="_blank">{{item.reference}}</a>
                        </v-col>
                    </v-row>
                    <div v-if="item.children">
                        <div :key="updateKey">
                            <v-row  v-if="item.showChildren">
                                <v-col>
                                    <v-card>
                                        <v-card-text>
                                            <div
                                            v-for="child in item.children"
                                            :key="child.key"
                                            >
                                                <v-row >
                                                    <v-col>
                                                        <h3>{{child.title}}</h3>
                                                    </v-col>
                                                    <v-col>
                                                        <span>{{child.requirement}}</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <span>{{child.description}}</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <span>API NAME: {{child.name}}</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <span>TYPE: 
                                                            <span style="color:black;" v-if="!child.children">{{child.type}}</span>
                                                            <a v-else id="hyperObjShow" @click="rerenderGChild(child)">{{child.type}}</a>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="child.reference">
                                                    <v-col>
                                                        <span>Reference: </span><a :href="child.reference" target="_blank">{{child.reference}}</a>
                                                    </v-col>
                                                </v-row>
                                                <div v-if="child.children">
                                                    <div :key="updateGKey">
                                                        <v-row v-if="child.showChildren">
                                                            <v-col>
                                                                <v-card>
                                                                    <v-card-text>
                                                                        <div
                                                                        v-for="grandChild in child.children"
                                                                        :key="grandChild.key"
                                                                        >
                                                                            <v-row>
                                                                                <v-col>
                                                                                    <h3>{{grandChild.title}}</h3>
                                                                                </v-col>
                                                                                <v-col>
                                                                                    <span>{{grandChild.requirement}}</span>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row>
                                                                                <v-col>
                                                                                    <span>{{grandChild.description}}</span>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row>
                                                                                <v-col>
                                                                                    <span>API NAME: {{grandChild.name}}</span>
                                                                                </v-col>
                                                                            </v-row>  
                                                                            <v-row>
                                                                                <v-col>
                                                                                    <span>TYPE: {{grandChild.type}}</span>
                                                                                </v-col>
                                                                            </v-row>  
                                                                            <v-row v-if="grandChild.reference">
                                                                                <v-col>
                                                                                    <span>Reference: </span><a :href="grandChild.reference" target="_blank">{{grandChild.reference}}</a>
                                                                                </v-col>
                                                                            </v-row>    
                                                                            <v-divider></v-divider>                                                                
                                                                        </div>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>
                                                <v-divider></v-divider>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <v-divider></v-divider>
                </div>                        
            </v-card-text>
        </v-card>        
    </div>
</template>

<script>
export default {
    props:{
        items:{
            type:Array,
            default:[]
        },
        title:{
            type:String,
            default:''
        }
    },
    data(){
        return {
            updateKey:0,
            updateGKey:0
        }
    },
    methods:{
        rerender(item){
            this.updateKey++;
            item.showChildren = !item.showChildren;
        },
        rerenderGChild(child){
            this.updateGKey++;
            child.showChildren = !child.showChildren;
        }

    }
}
</script>

<style>
    #hyperObjShow:hover{
        text-decoration: underline;
    }
</style>