<template>
  <v-row>
    <v-col cols="12">
      <v-btn
        :color="$vuetify.theme.themes.light.blue.primary"
        block
        small
        @click="generate"
        :loading="DOMElements.generate.loading"
        dark
      >{{$t('qrGeneratorFree.miscellaneous.action_buttons.generate_button')}}</v-btn>
    </v-col>
    <v-col v-if="downloadPDFButton" cols="6">
      <v-btn
        :color="$vuetify.theme.themes.light.blue.primary"
        block
        small
        @click="downloadPDF"
        :loading="DOMElements.downloadPDF.loading"
        dark
      >{{$t('qrGeneratorFree.miscellaneous.action_buttons.downloadPDF_button')}}</v-btn>
    </v-col>
    <v-col v-if="downloadPNGButton" cols="6">
      <v-btn
        :color="$vuetify.theme.themes.light.blue.primary"
        block
        small
        @click="downloadPNG"
        :loading="DOMElements.downloadPNG.loading"
        dark
      >{{$t('qrGeneratorFree.miscellaneous.action_buttons.downloadPNG_button')}}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import { mapGetters } from "vuex";
const pdf = require("pdfjs-dist");

export default {
  mixins: [userMixin],
  data: function () {
    return {
      drawer: false,
      fileName: undefined,
      downloadPDFButton: false,
      downloadPNGButton: false,
      taskSuccesful: false,
      DOMElements: {
        generate: {
          loading: false,
        },
        downloadPDF: {
          loading: false,
        },
        downloadPNG: {
          loading: false,
        }
      },
      taskSuccessful: false,
    };
  },
  errorCaptured(err, vm, info) {
    console.log(`${err.stack}\n\nfound in ${info} of component`);
  },
  computed: {
    ...mapGetters({
      structuredMap: "AI/structuredMap"
    }),
  },
  methods: {
    async generate() {
      // before the browser makes any fetch, validate if the form has the requierements.
      let validations1 = this.$parent.$refs['form.creditor'].validate();
      let validations2 = this.$parent.$refs.qrBilling.$refs.form.validate();
      let validations3 = this.$parent.$refs.qrFormCreditor.$refs.form.validate();
      let validations4 = this.$parent.$refs.qrFormDebtor.$refs.form.validate();
      if(!validations1 || !validations2 || !validations3 || !validations4) {
        return this.$store.commit("global/setSnackbar", {
          message: "Invalid inputs",
        });
      }
      // Shows instead of the QR an animation to give the illusion that the browser its processing its petition.
      this.$store.commit("QR/setSkeleton", true);
      this.$store.commit("QR/setProcessing", true);
      this.$store.commit("QR/qrSVG", { qrSVG: "" });

      // this property its set to false to indicate that a new QR its being process and when it finishes scroll to the bottom
      this.taskSuccessful = false;
      this.DOMElements.generate.loading = true;

      this.downloadPDFButton = false;
      this.downloadPNGButton = false;
      let AdditionalInformationString = this.$store.getters["AI/structuredMessage"].plain || "";
      let unstructuredMessage = this.$store.getters["AI/unstructuredMessage"].plain || "";
      let newAmount = this.getNewAmount(this.$store.state.QR.amount);
      let params = JSON.stringify({
        UID:this.$store.state.AI.structuredMessage.UID_Number || "",
        language:this.$store.state.User.language,
        AdditionalInformationString: AdditionalInformationString,
        unstructuredMessage: unstructuredMessage,
        currency: this.$store.state.QR.currency,
        amount: newAmount,
        reference: this.$store.state.QR.reference ? this.$store.state.QR.reference.replace(/\s+/g, "") : "",
        // idProfile: 9,
        creditor: {
          name: this.creditor.name,
          last_name: this.creditor.last_name,
          address: this.creditor.address.street + (this.creditor.address.street && this.creditor.address.number ? ' ' : '') + this.creditor.address.number,
          street: this.creditor.address.street,
          number: this.creditor.address.number,
          zip: this.creditor.address.zip,
          city: this.creditor.address.city,
          account: this.creditor.account.replace(/\s+/g, ""),
          country: this.creditor.address.country,
        },
        debitor: {
          name: this.debitor.name,
          last_name: this.debitor.last_name,
          address: this.debitor.address.street + (this.debitor.address.street && this.debitor.address.number ? ' ' : '') + this.debitor.address.number,
          street: this.debitor.address.street,
          number: this.debitor.address.number,
          zip: this.debitor.address.zip,
          account: this.debitor.account,
          city: this.debitor.address.city,
          country: this.debitor.address.country,
        },
        additionalInformation: this.structuredMap
      });

      await fetch(`${process.env.VUE_APP_SERVER_URL}/api/free/generateQR`, {
        method: "POST",
        body: params,
      })
      .then((result) => result.json())
      .then((response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 200) {
            // As soon as a response its received, the buttons are shown
            this.downloadPDFButton = true;
            this.downloadPNGButton = true;

            this.fileName = response.file;
            // the QR skelon its hidden
            this.$store.commit("QR/setSkeleton", false);

            // the QR its replaced for the new one
            this.$store.commit("QR/qrSVG", { qrSVG: response.qrSvg });

            // this property its set to true to indicate that a new QR has been updated and scroll to the bottom
            this.taskSuccesful = true;
          } else {
            this.$store.commit("global/setSnackbar", {
              message: response.message,
            });
          }
        } else {
          this.$store.commit("global/setSnackbar", {
            message: this.$t("Globals.unable_server"),
          });
        }
      })
      .catch((err) => {
        this.$store.commit("global/setSnackbar", { message: err });
      });
      // Scroll to the bottom if theres a new QR
      this.$store.commit("QR/setProcessing", false);
      if (this.taskSuccesful) window.scrollTo(0, document.body.scrollHeight);
      this.DOMElements.generate.loading = false;
    },

    async downloadPDF() {
      if(this.fileName){
        let url = `data:application/pdf;base64,${this.fileName}`
        fetch(url)
        .then(result => result.blob())
        .then((response)=>{
          let url = window.URL.createObjectURL(response);
          let a = document.createElement("a");
          a.href = url;
          a.download = `Invoice.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();        
        })
        .catch((err)=>{
          return this.$store.commit(`global/setSnackbar`,{message:err});
        })
      }
    },

    async downloadPNG() {
      if(this.fileName){
        let url = `data:application/pdf;base64,${this.fileName}`
        fetch(url)
        .then(result => result.blob())
        .then((response)=>{
          let url = window.URL.createObjectURL(response);
          this.getPNGFile(url);  
        })
        .catch((err)=>{
          return this.$store.commit(`global/setSnackbar`,{message:err});
        })
      }
    },
    getNewAmount(amount) {
      //Sets the amount to a correct format befor sending it to the server
      if (!this.$store.state.QR.stateAmount) {
        if (typeof amount == "string") {
          return Number(amount.replace(/\s+/g, ""));
        } else {
          return amount;
        }
      } else {
        return undefined;
      }
    },
    getPNGFile(data) {
      let scale = 2;
      let canvas = document.createElement("canvas");
      let twoD = canvas.getContext("2d");
      var png = `Invoice.png`;

      pdf.getDocument(data).then((doc) => {
        doc.getPage(doc.numPages).then((page) => {
          var viewport = page.getViewport(scale);
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          page
            .render({
              canvasContext: twoD,
              viewport: viewport,
            })
            .then(function () {
              twoD.globalCompositeOperation = "destination-over";
              twoD.fillStyle = "#ffffff";
              twoD.fillRect(0, 0, canvas.width, canvas.height);
              let img = canvas.toDataURL("image/png");
              fetch(img)
                .then((result) => {
                  return result.blob();
                })
                .then((newblob) => {
                  let url = window.URL.createObjectURL(newblob);
                  let a = document.createElement("a");
                  a.href = url;
                  a.download = png;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove();
                });
            });
        });
      });
    },
    validateInputs() {
      // TODO: Validate creditor mandatory data
      // TODO: validate IBAN
      if (
        this.$store.state.User.creditor.account == "" ||
        this.$store.state.User.creditor.account == undefined
      ) {
        this.$store.commit("global/setSnackbar", {
          // message: "IBAN not provided",
            message: this.$t("snack_confirm.invalid_inputs"),
        });
        return false;
      }

      // TODO: validate firstname/lastName
      if (
        this.$store.state.User.creditor.name == "" ||
        this.$store.state.User.creditor.name == undefined
      ) {
        this.$store.commit("global/setSnackbar", {
          message: this.$t("rules.nameRules"),
        });
        return false;
      }

      // TODO: validate country
      if (
        this.$store.state.User.creditor.address.country == "" ||
        this.$store.state.User.creditor.address.country == undefined
      ) {
        this.$store.commit("global/setSnackbar", {
          // message: "The Country of the creditor has not been provided",
            message: this.$t("snack_confirm.invalid_inputs"),
        });
        return false;
      }

      // TODO: validate reference
      if (this.$store.state.User.creditor.account) {
        if (this.$store.state.User.creditor.account.length == 26) {
          if (this.$store.state.User.creditor.typeReference != "NON") {
            if (
              this.$store.state.QR.reference == "" ||
              this.$store.state.QR.reference == undefined
            ) {
              this.$store.commit("global/setSnackbar", {
                // message: "The Reference has not been provided",
                  message: this.$t("snack_confirm.invalid_inputs"),
              });
              return false;
            }
          }
        }
      }
      return true;
    },
  },
};
</script>