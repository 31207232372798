const Profile = {
    namespaced: true,
    state: {
        profile: []
    },
    mutations: {
        // setProfile: (state, payload) => Object.assign(state.profile, payload), 
        setProfile: (state, payload) => state.profile = payload, 
    },
    getters: {
        getProfile: state => state.profile,
    }
}


export default Profile;