<template>
    <div>
        <v-card>
            <v-card-title>
                <v-col cols="8">
                    {{$t("Admin.scheduledCharges.title")}}
                </v-col>
                <v-col cols="4">
                    <v-select
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    :label="$t('Admin.scheduledCharges.search_type')"
                    :items="headers.filtersArray"
                    v-model="searchSelected"
                    @change="getScheduled"
                    ></v-select>
                </v-col>
                
            </v-card-title>
            <v-card-text>
                <v-data-table
                :no-data-text="$t('qrGenerator.productsTable.data_available')"
                :items="scheduledArray"
                :headers="headers.scheduledHeaders"
                item-text="text"
                item-value="value"
                dense
                :loading="scheduledLoading"
                multi-sort
                >
                <template v-slot:item.edit="{ item }">
                    <v-icon
                    class="mr-2"
                    @click="editSC(item)"
                    >mdi-pencil</v-icon>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog width="500px" v-model="showDialog" persistent>
            <v-card width="500px">
                <v-card-title>{{$t("Admin.scheduledCharges.edit")}}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                :label="$t('Admin.scheduledCharges.transaction')"
                                v-model="item.transaction_subject"
                                dense
                                :rules="[rules.isMissing,rules.lengthCheck]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                :label="$t('Admin.scheduledCharges.six_descrip')"
                                v-model="item.six_description"
                                :rules="[rules.isMissing,rules.lengthCheck]"
                                dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                :label="$t('Admin.scheduledCharges.inicial_balance')"
                                v-model="item.initial_balance_subject"
                                :rules="[rules.isMissing,rules.lengthCheck]"
                                dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                :label="$t('Admin.scheduledCharges.final_balance')"
                                v-model="item.final_balance_subject"
                                :rules="[rules.isMissing,rules.lengthCheck]"
                                dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" >
                                <v-dialog
                                    ref="timeDialog"
                                    v-model="timeDialog"
                                    :return-value.sync="item.time"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="item.time"
                                        :label="$t('Admin.scheduledCharges.time')"
                                        :rules="[rules.isMissing]"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    v-if="timeDialog"
                                    v-model="item.time"
                                    full-width
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="timeDialog = false"
                                    >
                                       {{$t("Globals.cancel")}}
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.timeDialog.save(item.time)"
                                    >
                                        {{$t("Globals.ok")}}
                                    </v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="6">
                                <v-dialog
                                    ref="dateDialog"
                                    v-model="dateDialog"
                                    :return-value.sync="item.date"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="item.date"
                                        :label="$t('Admin.scheduledCharges.scheduled_date')"
                                        prepend-icon="mdi-calendar"
                                        :rules="[rules.isMissing]"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="item.date"
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="dateDialog = false"
                                    >
                                        {{$t("Globals.cancel")}}
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dateDialog.save(item.date)"
                                    >
                                        {{$t("Globals.ok")}}
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>                         
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                :label="$t('Admin.scheduledCharges.amount')"
                                v-model="item.amount"
                                type="number"
                                :rules="[rules.isMissing,rules.isNegative]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                    :color="$vuetify.theme.themes.light.blue.primary"
                    dark
                    @click="closeDialog"
                    >{{$t('Globals.btn_close')}}</v-btn>
                    <v-btn
                    dark
                    :loading="saveLoading"
                    :color="$vuetify.theme.themes.light.blue.primary"
                    @click="saveSchedule()"
                    >{{$t('Globals.save')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name:'scheduled-charges',
    data(){
        return {
            time: null,
            timeDialog: false,
            dateDialog:false,
            menu:false,
            date:null,
            showDialog:false,
            scheduledLoading:false,
            saveLoading:false,
            scheduledArray:[],
            searchSelected:'all',
            item:{
                id:null,
                transaction_subject:'',
                initial_balance_subject:'',
                final_balance_subject:'',
                six_description:'',
                amount:null,
                date:'',
                time:'',
                execution_date:'',
                execution_status:'',
                id_user:null
            },
            defaultItem:{
                id:null,
                transaction_subject:'',
                initial_balance_subject:'',
                final_balance_subject:'',
                six_description:'',
                amount:null,
                date:'',
                time:'',
                execution_date:'',
                execution_status:'',
                id_user:null                
            },
            currentIndex:null,
            rules:{
                isMissing: (v) =>{
                    return !!v || this.$t("rules.required");
                },
                isNegative: (v) =>{
                    return v > 0 || this.$t("rules.isGreater");
                },
                lengthCheck: (v) =>{
                    return String(v).length <= 255 || this.$t("rules.field_length");
                },
            }
        }
    },
    mounted(){
        this.getScheduled(this.searchSelected);
    },
    computed:{
        headers(){
        return{
                scheduledHeaders:[
                    {text:'ID',value:'id'},
                    {text:this.$t('Admin.scheduledCharges.status'),value:'execution_status'},
                    {text:this.$t('Admin.scheduledCharges.amount'),value:'amount'},
                    {text:this.$t('Admin.scheduledCharges.execution_date'),value:'execution_date'},
                    {text:this.$t('Admin.scheduledCharges.transaction'),value:'transaction_subject'},
                    {text:this.$t('Admin.scheduledCharges.inicial_balance'),value:'initial_balance_subject'},
                    {text:this.$t('Admin.scheduledCharges.final_balance'),value:'final_balance_subject'},
                    {text:this.$t('Admin.scheduledCharges.six_descrip'),value:'six_description'},
                    {text:this.$t('Admin.scheduledCharges.id_user'),value:'id_user'},
                    {text:this.$t('Admin.scheduledCharges.edit'),value:'edit',sortable:false}
                ],
                filtersArray:[
                    {text:this.$t('Admin.scheduledCharges.all'),value:'all'},
                    {text:this.$t('Admin.scheduledCharges.incoming'),value:'incoming'},
                    {text:this.$t('Admin.scheduledCharges.issued'),value:'issued'},
                    {text:this.$t('Admin.scheduledCharges.errors'),value:'errors'}
                ],
            }
        }
    },
    methods:{
        async getScheduled(item){
            this.scheduledLoading = true;
            this.scheduledArray = new Array();
            await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/scheduled-charges?type=${item}`)
            .then((response)=>{
                if(response){
                    for(let i = 0;i<response.data.length;i++){
                        let currentRecord = response.data[i];
                        let currentDate = new Date(currentRecord.execution_date);
                        let currentHour = '00';
                        let currentMinute = '00';
                        let currentSeconds = '00';
                        let currentMonth = '00';
                        if(currentDate){
                            let tempHour = `${currentDate.getUTCHours()}`;
                            let tempMinute = `${currentDate.getUTCMinutes()}`;
                            let tempSeconds = `${currentDate.getUTCSeconds()}`;
                            let tempMonth = `${currentDate.getUTCMonth() + 1}`;
                            currentMonth = tempMonth.length == 1 ? `0${tempMonth}` : tempMonth;
                            currentHour = tempHour.length == 1 ? `0${tempHour}` : tempHour;
                            currentMinute = tempMinute.length == 1 ? `0${tempMinute}` : tempMinute;
                            currentSeconds = tempSeconds.length == 1 ? `0${tempSeconds}`: tempSeconds;
                        }
                        this.scheduledArray.push({
                            id:currentRecord.id,
                            transaction_subject:currentRecord.transaction_subject,
                            initial_balance_subject:currentRecord.initial_balance_subject,
                            final_balance_subject:currentRecord.final_balance_subject,
                            six_description:currentRecord.six_description,
                            amount:currentRecord.amount !== undefined &&  currentRecord.amount !== null ? currentRecord.amount / 100 :0,
                            execution_date:`${currentDate.getFullYear()}-${currentMonth}-${currentDate.getDate()} ${currentHour}:${currentMinute}:${currentSeconds}`,
                            date:`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
                            time:`${currentHour}:${currentMinute}`,
                            execution_status:currentRecord.execution_status,
                            id_user:currentRecord.id_user
                        })
                    }
                }else{
                    return this.$store.commit(`global/setSnackbar`,{message:this.$t("Globals.unable_server")});
                }
            })
            .catch(this.$handleCatch);
            this.scheduledLoading = false;
        },
        editSC(item){
            this.currentIndex = this.scheduledArray.indexOf(item);
            Object.assign(this.item,item);
            this.showDialog = true;
        },
        closeDialog(){
            this.currentIndex = null;
            Object.assign(this.item,this.defaultItem);
            this.showDialog = false;
        },
        async saveSchedule(){
            if(!this.$refs.form.validate()){
                return this.$store.commit(`global/setSnackbar`,{message:this.$t("snack_confirm.invalid_inputs")});
            }
            this.saveLoading = true;
            await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/scheduled-charges`,{
                method:'POST',
                body:JSON.stringify({
                    id:this.item.id,
                    transaction_subject:this.item.transaction_subject,
                    initial_balance_subject:this.item.initial_balance_subject,
                    final_balance_subject:this.item.final_balance_subject,
                    six_description:this.item.six_description,
                    amount:Number(this.item.amount),
                    execution_date:this.item.date,
                    execution_time:this.item.time
                }),
                headers:{
                    'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                }
            })
            .then((response)=>{
                if(response){
                    Object.assign(this.scheduledArray[this.currentIndex],{
                        id:this.item.id,
                        transaction_subject:this.item.transaction_subject,
                        initial_balance_subject:this.item.initial_balance_subject,
                        final_balance_subject:this.item.final_balance_subject,
                        six_description:this.item.six_description,
                        amount:Number(this.item.amount),
                        date:this.item.date,
                        time:this.item.time,
                        execution_date:`${this.item.date} ${this.item.time}:00`,
                        execution_status:this.item.execution_status,
                        id_user:this.item.id_user                        
                    });
                    this.$store.commit(`global/setSnackbar`,{message:response.message});
                    return this.closeDialog();
                }else{
                    return this.$store.commit(`global/setSnackbar`,{message:this.$t("Globals.unable_server")});
                }
            })
            .catch(this.$handleCatch);
            this.saveLoading = false;
        }
    },

}
</script>