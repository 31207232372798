<template>
  <div>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="6" class="mx-auto">
            <v-row class="justify-center">
              <v-col>
                <v-card>
                  <v-progress-linear indeterminate :active="processing" color="green"></v-progress-linear>
                  <v-card-title class="d-flex justify-center">{{ $t('login.main_title') }}</v-card-title>
                  <v-card-text>
                    <v-form>
                      <v-text-field :label="$t('login.username_field')" v-model="username"
                        :rules="[rules.notEmpty]"></v-text-field>
                      <v-text-field :label="$t('login.password_field')" :type="showChars ? 'text' : 'password'"
                        v-model="password" :rules="[rules.notEmpty]"></v-text-field>
                      <v-spacer></v-spacer>
                      <v-btn :color="$vuetify.theme.themes.light.blue.primary" block small @click="login"
                        :loading="DOMElements.login.loading" :disabled="DOMElements.login.disabled" dark>{{
                          $t('login.btn_login') }}</v-btn>
                    </v-form>
                    <v-spacer></v-spacer>
                    <a class="text-center d-flex align-center justify-space-around" href="/recover-password">
                      {{ $t('login.forget_password') }}
                    </a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <!-- *******************************************POP UP********************************************** -->
      <v-dialog width="500" v-model="popup" transition="dialog-top-transition" max-width="600">
        <v-card flat width="500">

          <v-toolbar :color="$vuetify.theme.themes.dark.green.secondary" dark>
            <h3>{{ $t("login.locked_profile") }}</h3>
          </v-toolbar>
          <!-- <v-card-title   class="headline grey lighten-2"><h2> Account {{statusAccount}}</h2></v-card-title> -->
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-card-text class="text-h5 pa-12"> {{ messageStatus }} </v-card-text>
          <v-col cols="6" offset=3> <v-card-text> <a class="justify-center text-h5 " v-if="mails"
                href="mailto:">info@qrmodul.ch </a></v-card-text> </v-col>
          <v-card-actions class="justify-end">
            <v-btn v-if="btnActivation" @click="sendReactivation()" :disabled="disabledbtn"
              :dark="activateNewProfileDark" :color="$vuetify.theme.themes.light.green.primary"
              :loading="DOMElements.activate.loading"> {{ $t("login.order_link") }} </v-btn>
            <v-btn :color="$vuetify.theme.themes.dark.brown.secondary" :dark="activateNewProfileDark"
              @click="popup = false"> Close</v-btn>
          </v-card-actions>
          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
      <!-- ************************************************************************************************ -->

      <!-- *******************************************POP UP REACTIVATION********************************************** -->
      <!---->
      <v-dialog width="500" v-model="popupEmailValidation" transition="dialog-top-transition" max-width="600">
        <v-card flat width="500">

          <v-toolbar :color="$vuetify.theme.themes.dark.green.secondary" dark>
            <h3>{{ $t("login.locked_profile") }}</h3>
          </v-toolbar>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-card-text class="text-h5 pa-12"> {{ messageStatus }} </v-card-text>
          <v-col cols="6" offset=3> <v-card-text> <a class="justify-center text-h5 " v-if="mails"
                href="mailto:">info@qrmodul.ch </a></v-card-text> </v-col>
          <v-card-actions class="justify-end">
            <v-btn @click="sendReactivation()" :dark="activateNewProfileDark"
              :color="$vuetify.theme.themes.light.green.primary" :loading="DOMElements.activate.loading">
              {{ $t("login.order_link") }} </v-btn>
            <v-btn :color="$vuetify.theme.themes.dark.brown.secondary" :dark="activateNewProfileDark"
              @click="popupEmailValidation = false"> Close</v-btn>
          </v-card-actions>
          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
      <!-- ************************************************************************************************ -->

    </v-main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "login",
      activateNewProfileDark: true,
      btnActivation: false,
      popupEmailValidation: false,
      mails: true,
      popup: false,
      messageStatus: "",
      showChars: false,
      username: "",
      password: "",
      processing: false,
      DOMElements: {
        login: {
          loading: false,
        },
        activate: {
          loading: false,
        },
        continue: {
          loading: false,
        }
      },
      rules: {
        notEmpty: value => {
          return !!value || this.$t("rules.required")
        }
      },
    };
  },
  async beforeRouteEnter(to, from, next) {
    if (!from.name) {
      try {
        let result = await fetch(`${process.env.VUE_APP_SERVER_URL}/api/checkMembership`, {
          method: 'GET',
          credentials: 'include'
        });
        let response = await result.json();
        if (response) {
          if (response.status == 200) {
            let getLanguage = window.localStorage.getItem('User/setLanguage');
            let getLanguageMap = getLanguage ? JSON.parse(getLanguage) : null;
            return next(`${getLanguageMap ? `/${getLanguageMap.language}` : ''}/cockpit/qr-generator`);
          } else {
            return next();
          }
        }
      } catch (err) {
        return next();
      }
    } else {
      return next();
    }
  },
  mounted() {
    //This commit  changes the title on the dashboard
    this.$store.commit("global/setPageTitle", "Login");
    window.addEventListener('keypress', this.onKeypress, false);
    // console.log(this.$root.processing)
    // console.log(this.$root)
  },
  watch: {
    locale(newValue, oldValue) {
      this.$store.commit("global/setPageTitle", "Login")
    }
  },
  computed: {
    locale() {
      return this.$store.state.User.language;
    },
  },
  destroyed() {
    window.removeEventListener('keypress', this.onKeypress, false);
  },
  methods: {
    async login() {
      if (this.validateFields()) {
        this.DOMElements.login.loading = true;
        await fetch(`${process.env.VUE_APP_SERVER_URL}/api/login`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            username: this.username,
            password: this.password,
            pageLanguage: this.$store.state.User.language
          })
        })
          .then(result => result.json())
          .then(async response => {
            if (response.status === 200) {
              this.$store.commit("User/setLogin", {
                code: response.status,
                user: response.user,
              });
              this.$store.commit("User/setLanguage", { language: response.language });
              // if the user has already tried to access to a path but the router didnt let him
              // the router will save the path and redirect him to the login
              // when the user logins will be redirected to the path that he wanted
              //  this.$router.push(sessionStorage.getItem('userPathDesired') || '/cockpit/qr-generator');
              try {
                let result = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/csrfToken`);
                document.cookie = `_csrf=${result._csrf};`;
              } catch (err) {
                return this.$store.commit(`global/setSnackbar`, err);
              }
              this.$router.push(sessionStorage.getItem('userPathDesired')
                || `${response.language ? `/${response.language}` : ''}/cockpit/qr-generator`);
            } else if (response.status === 400) {
              this.popupEmailValidation = true;
              this.messageStatus = this.$t('StepByStep.OrderConfirmation.send_reactivation_email');
            } else {
              if (response.accountStatus == "locked") {
                this.popup = true;
                this.messageStatus = response.message;
              }
              else {
                this.$store.commit("global/setSnackbar", { message: this.$t('login.no_confirmed') });
              }
            }
          })
          .catch(err => {
            this.$store.commit("global/setSnackbar", { message: this.$t('login.login_failed') });
          });
        this.DOMElements.login.loading = false;
      }
    },
    sendReactivation() {
      this.DOMElements.activate.loading = true;
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/email/sendReactivation?email=${this.username}`, {
        method: 'GET',
      })
        .then((result) => result.json())
        .then(async (response) => {
          this.DOMElements.activate.loading = false;
          this.disabledbtn = true;
          this.activateNewProfileDark = false;
          this.popupEmailValidation = false;
          return this.$store.commit(`global/setSnackbar`, { message: response.message });
        })
    },
    validateFields() {
      if (this.username.length <= 0) {
        this.$store.commit("global/setSnackbar", { message: this.$t("login.username_not") });
        return false;
      } else if (this.password.length <= 0) {
        this.$store.commit("global/setSnackbar", { message: this.$t("login.password_not") });
        return false;
      } else {
        return true;
      }
    },
    onKeypress(e) {
      if (e.key === "Enter") {
        return this.login();
      }
    }
  }
};
</script>

<style scoped></style>