<template>
  <div>    
    <v-card>
      <v-card-title>
        <p class="text-subtitle-1">
           {{ this.$t('qrGenerator.productsTable.waitJournals') }}
        </p>  
      </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-data-table
            dense
            :no-data-text="$t('qrGenerator.productsTable.data_available')"
            v-model="invoicePerMonth"
            :headers="headers.headers"
            :items="invoicePerMonth2"
            :loading="loading"
            :custom-sort="customSortDebtors"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon class="mb-2" @click="pdfItem(item)">mdi-file-pdf</v-icon>
            </template>
            <template v-slot:item.disabled="{ item }">
              <v-icon color="green" v-if="item.disabled">mdi-check</v-icon>
              <v-icon color="red"  v-else>mdi-close</v-icon>
                </template>
          </v-data-table>
        </v-card-text>
    </v-card>
  </div>    
</template>


<script>
export default {
    name:"journalTable",
    data: function(){
        return{
            loading: false,
            invoicePerMonth:[],
            invoicePerMonth2:[],
        }
    },
    props:{
        YearInvoice:{
            type: String,
            default: ""
        },
        MonthInvoice:{
            type: String,
            default: ""
        }
    },
    computed:{
        headers(){
            return{
                headers:[
                    {text:  this.$t('QrSeries.step_four.emailDispatch.profile'),align:"start", value:"profile_name"},
                    {text:  this.$t('ProductUsage.Files'), value:"file", align: 'd-none'},
                    {text:  this.$t('ProductUsage.custom_name'), value:"custom_name"},
                    {text:  this.$t('ProductUsage.creation_date'), value:"creation_date"},
                    {text:  this.$t('QrSeries.step_four.emailDispatch.emailSent'), value: "emails_sent"},
                    {text:  this.$t('QrSeries.step_four.emailDispatch.emailSuccessfully'), value: "emails_successfully"},
                    {text:  this.$t('QrSeries.step_four.emailDispatch.emailError'), value: "emails_error"},
                    {text:  this.$t('ProductUsage.download'), value: "actions", sortable: false}
                ]
            }
        }
    },
    methods:{
      customSortDebtors(items, index, isDesc) {
        for (let i = 0; i < index.length; i++) {
          let currentIndex = index[i];
          let currentIsDesc = isDesc[i];
          items.sort((a, b) => {
            if (currentIndex === "creation_date") {
              if (currentIsDesc)
                return (
                  new Date(`${b[currentIndex]} UTC`) -
                  new Date(`${a[currentIndex]} UTC`)
                );
              return (
                new Date(`${a[currentIndex]} UTC`) -
                new Date(`${b[currentIndex]} UTC`)
              );
            } else if (currentIndex == "file") {
            if (currentIsDesc) return b[currentIndex] - a[currentIndex];
            return a[currentIndex] - b[currentIndex];
          } else {
            let aElement = a[currentIndex] ? `${a[currentIndex]}` : '';
            let bElement = b[currentIndex] ? `${b[currentIndex]}` : '';
            if (currentIsDesc)
              return aElement
                .toLowerCase()
                .localeCompare(bElement.toLowerCase());
            return bElement
              .toLowerCase()
              .localeCompare(aElement.toLowerCase());
          }
        });
      }
      return items;
    },
    pdfItem(item) {
      // Request that brings the selected PDF from the server
      fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/journal/download?file=${item.file}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((result) => {
          // We return the request of the server in blob for its respective download
          if(result.status == 200) {
            return result.blob();
          }

          if(result.status == 401) {
            return this.$router.push({name:'Login'});
          }
        })
        // Now we get the answer in the response variable
        .then((response) => {
          // We verify that the information in the variable is not null or undefined
          if (response !== null && response !== undefined) {
            // We declare a url variable which contains a link
            var url = window.URL.createObjectURL(response);
            // now we create an html link as if it were an <a> </a>
            var a = document.createElement("a");
            // in this case we pass it to the href the address of the file obtained
            a.href = url;
            // Now we create a download object with just the name of the pdf file
            a.download = item.custom_name;
            // An element is created in the body of the page for its respective download
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            // The click element is created for when this method is used
            a.click();
            // Now we remove the created tag
            a.remove();
          } else {
            // Message that is sent if the response variable is undefined or null
            return this.$store.commit("global/setSnackbar", {
              message: this.$t("ProductUsage.server_no_respond"),
            });
          }
        })
        // Catch that is created when there is an error in the fetch
        .catch((err) => {
          // Message that is sent to indicate the error in the fetch
          return this.$store.commit("global/setSnackbar", {
            message: err,
          });
        });
    },
    
     fromDateToString(date) {
      if (!date) return "";
      let newDate = new Date(date);
      if (!newDate) return "";
      let newDay = `${newDate.getDate()}`.padStart(2, "0");
      let newMonth = `${newDate.getMonth() + 1}`.padStart(2, "0");
      let newYear = `${newDate.getFullYear()}`.padStart(2, "0");
      let newHour = `${newDate.getHours()}`.padStart(2, "0");
      let newMinutes = `${newDate.getMinutes()}`.padStart(2, "0");
      return `${newYear}/${newMonth}/${newDay} ${newHour}:${newMinutes}`;
    },

    },
    mounted(){
      this.invoicePerMonth2 = [];
      this.loading = true;
      this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/account_journals?year=${this.YearInvoice}&month=${this.MonthInvoice}`)
        .then( response => {
          if(response){
            // if (response.status != 200) {
            // return this.$store.commit(`global/setSnackbar`,{message:response.message});
            // }
            this.loading = false;
            let journals = response.data.journals;
            for(let i = 0; i < journals.length ; i++  ){
                
                let dateCreation = this.fromDateToString(journals[i].creation_date);
                let formatDate = dateCreation ? dateCreation.split(" ")[0].replaceAll("/",".") : null;
                let lastName = journals[i].profile.last_name ;
                let firstName = journals[i].profile.first_name;

                let objeto = new Object();
                objeto["file"]= journals[i].file || "",
                objeto["profile_name"]= (journals[i].profile.last_name || "") + (journals[i].profile.first_name || '') ,
                objeto["custom_name"] = `${formatDate}_${lastName}_${firstName}_journal.pdf`;
                objeto["firstName"]= firstName || '' ,
                objeto["lastName"]=  lastName || '' ,
                  
                objeto["creation_date"]= dateCreation || "",
                objeto["emails_sent"]= journals[i].emails_sent;
                objeto["emails_successfully"]= journals[i].emails_successfully;
                objeto["emails_error"]= journals[i].emails_error;
                this.invoicePerMonth2.push(objeto);   
             };

          }
        })
        .catch((err) => {
          this.loading = false;
            return this.$store.commit("global/setSnackbar", { message: err });
        });
    }
}   
</script>
