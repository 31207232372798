import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import qrSVG from './components/QR/qr';
import store from './store/index';
import snackbar from './components/globals/snackbar';
import confirm from '@/components/globals/dialog';
import window from '@/components/globals/WindowCredits';
import i18n from '@/i18n.js';
import fetch from '@/plugins/fetch';
import VueCurrencyInput from 'vue-currency-input';

Vue.config.productionTip = false;
Vue.use(fetch);
Vue.component('qr', qrSVG);
Vue.component('snackbar', snackbar);
Vue.component('confirm', confirm);
Vue.component('window',window);
Vue.use(VueCurrencyInput);

new Vue({
  render: h => h(App),
  vuetify,
  router,
  store,
  i18n,
  // this method will recover all the data that was saved in the localstorage
  beforeCreate() {
		this.$store.commit('initialiseStore');
	}
}).$mount('#app')
