<template>
    <div>
        <v-form  ref="form">
            <v-row>
                <v-col>
                    <h3>{{$t("QrSeries.step_four.PrintShop.order_section")}}</h3>
                </v-col>
                <v-col>
                    <h3>{{$t("QrSeries.step_four.PrintShop.dispatch")}}</h3>
                </v-col>
           
            </v-row>
            <v-row>
                <v-col class="pt-11 pb-0">
                    <v-select
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    dense
                    :label="$t('QrSeries.step_four.PrintShop.priority')"
                    :items="$t('QrSeries.step_four.PrintShop.priority_items')"
                    item-text="text"
                    :hint="HintPriority"
                    item-value="value"
                    :rules="[rules.isRequired]"
                    v-model="orderSettings.type"  
                    @change="addOrderType"              
                    ></v-select>    
                </v-col>
                <v-col  class="pt-6 pb-0">
                    <v-select
                    v-if="selectionPart== false"
                    :label="$t('QrSeries.step_four.PrintShop.selection')"
                    :items="$t('QrSeries.step_four.PrintShop.selection_items')"
                    v-model="shippingSelection"
                    :rules="[rules.isRequired]"
                    @change="shippingSelect"
                    ></v-select>
                    <v-select
                    :disabled="true"
                    v-if="selectionPart"
                    :label="$t('QrSeries.step_four.PrintShop.selection')"
                    :items="$t('QrSeries.step_four.PrintShop.shipping_parts')"
                    v-model="shippingSelection"
                    @change="shippingSelect"
                    ></v-select>
                </v-col>
                <v-col class="pt-0 pb-1 mt-0">
                    <v-textarea
                    dense
                    no-resize
                    v-if="shippingSelection =='collective'"
                    :label="$t('QrSeries.step_four.PrintShop.collective_address')"
                    v-model="$store.state.addressInfo.collectiveAdress"
                    outlined
                    height="130"
                    :rules="[rules.isRequired,rules.fourLines]"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-5">
                    <h4>{{$t("QrSeries.step_four.PrintShop.paper")}}</h4>
                        <h4 v-if="checkMyClimate == false">{{infoTextCheck}}</h4>
                        <v-checkbox
                        v-if="checkMyClimate"
                        :hint="infoTextCheck"
                        persistent-hint
                        v-model="myClimatePaper"
                        @change="addCO2"
                        :label="$t('QrSeries.step_four.PrintShop.checkbox_co2')"
                        ></v-checkbox>
                </v-col>
                <v-col class="pt-15">
                    <v-select
                    v-if="postTarifShow && shippingSelection != 'collective'"
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    dense
                    :label="$t('QrSeries.step_four.PrintShop.postal_rate')"
                    :items="$t('QrSeries.step_four.PrintShop.postal_items')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.isRequired]"
                    v-model="orderSettings.tariff"
                    @change="addShipping"
                    ></v-select>
                    <v-select
                    :disabled="disableFranking"
                    v-if="postTarifShow && shippingSelection== 'collective' "
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    dense
                    :label="$t('QrSeries.step_four.PrintShop.postal_rate')"
                    :items="$t('QrSeries.step_four.PrintShop.postal_itemsNone')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.isRequired]"
                    v-model="orderSettings.tariff"
                    @change="addShipping"
                    ></v-select>
                </v-col>
                <v-col class="pt-7">
                    <h3  v-if="enclosureCheckShow">{{$t("QrSeries.step_four.PrintShop.shipping_instruction")}}</h3>
                        <v-checkbox
                        v-if="enclosureCheckShow"
                        dense
                        :label="$t('QrSeries.step_four.PrintShop.include_shipping')"
                        v-model="orderSettings.uploadCustomInsert"
                        @change="addInserts"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="bywColor" class="pt-2 pb-0">
                    <v-select
                    :disabled="true"
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    dense
                    :label="$t('QrSeries.step_four.PrintShop.color_setting')"
                    :items="$t('QrSeries.step_four.PrintShop.b&w_items')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.isRequired]"
                    v-model="orderSettings.color"
                    @change="addColorCost"                
                    ></v-select>
                    </v-col>
                    <v-col   v-if="bywColor== false" class="pt-5 pb-0">
                    <v-select
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    dense
                    :label="$t('QrSeries.step_four.PrintShop.color_setting')"
                    :items="$t('QrSeries.step_four.PrintShop.color_items')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.isRequired]"
                    v-model="orderSettings.color"
                    @change="addColorCost"                
                    ></v-select>
                </v-col>
                <v-col class="pt-0 pb-0">
                    <v-select
                    v-if="shippingSelection== 'collective'"
                    label="Postal tariff Parcel to collective address *"
                    :items="$t('QrSeries.step_four.PrintShop.post_tariff_items')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.isRequired]"
                    v-model="orderSettings.parcel"
                    @change="addShipping"
                    ></v-select>
                </v-col>    
                <v-col class="pt-0 pb-0">
                    <v-select
                    v-if="orderSettings.uploadCustomInsert"
                    :items="IncludeShippingItems"
                    :rules="[rules.isRequired]"
                    v-model="IncludeShipping"
                    ></v-select>
                    <v-file-input
                    dense
                    accept="application/pdf"
                    label="Upload supplement"
                    v-model="insertFile"
                    :rules="[rules.isRequired,rules.isPDF,rules.less2MB]"
                    v-if="IncludeShipping == 'impersonalized' && orderSettings.uploadCustomInsert == true "
                    @change="addInserts()"
                    ></v-file-input>
                     <v-select
                     v-if="insertFile && orderSettings.uploadCustomInsert == true"
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    dense
                    label="Color"
                    :items="$t('QrSeries.step_four.PrintShop.color_items')"
                    item-text="text"
                    item-value="value"
                    v-model="orderSettings.inserts"
                    @change="addShippingInsertColor()"
                    :rules="[rules.isRequired]"           
                    ></v-select>
                </v-col>    
            </v-row>
            <v-row>
                <v-col cols="4" class="pt-5 pb-0">
                    <v-select
                    v-if="envelopesShow"
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    dense
                    :label="$t('QrSeries.step_four.PrintShop.envelopes_title')"
                    :items="$t('QrSeries.step_four.PrintShop.evelopes_items')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.isRequired]"
                    v-model="orderSettings.envelope"     
                    @change="addEnvelopeCost"           
                    ></v-select> 
                </v-col>     
                <v-col cols="4" class="pt-5 pb-0">
                  <!-- <v-select
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    dense
                    :label="$t('QrSeries.step_four.PrintShop.priority')"
                    :items="$t('QrSeries.step_four.PrintShop.priority_items')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.isRequired]"
                    v-model="orderSettings.type"  
                    @change="addOrderType"              
                    ></v-select>                 -->
                </v-col>
                <v-col cols="4" class="pt-1 pb-0">
                    <v-textarea
                    dense
                    no-resize   
                    :label="$t('QrSeries.step_four.PrintShop.remarks')"
                    v-model="specialInstructions"
                    outlined
                    height="100"
                    :rules="[rules.twoLines]"
                    ></v-textarea>
                </v-col> 
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-select
                        v-if="orderSettings.envelope == 'logo'"
                        :label="$t('QrSeries.step_four.PrintShop.color_settings')"
                        v-model="orderSettings.envelopeColors"
                        :items="$t('QrSeries.step_four.PrintShop.color_settings_items')"
                        @change="addEnvelopeCost"
                        :rules="[local_rules.isEmptyEnvelopeColor]"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="orderSettings.envelope === 'logo'">
                    <v-card >
                        <v-card-title class="pb-0">
                            <v-row>
                                <v-col>
                                    {{$t('QrSeries.step_four.PrintShop.preview_title')}}
                                </v-col>
                                <v-col>
                                    <v-btn @click="showAddress = true">{{$t('QrSeries.step_four.PrintShop.btn_sender')}}</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-subtitle>
                        </v-card-subtitle>
                        <v-card-text class="pa-1">
                            <v-row>
                                <v-col>
                                    <e-preview
                                    ref="envelopePreview"
                                    :cardFlat="true"
                                    :enable_myclimate="ClimateLabel && myClimatePaper"
                                    :address_message="addressDialog"
                                    :font_size="Number(fontSize)"
                                    :font="fontSelected"
                                    :p_logo="LogoArea"
                                    :hideQRLogo="hideQRLogo"
                                    :selectedStamp="this.orderSettings.tariff"
                                    ></e-preview>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-data-iterator
                    :sort-by="sortBy.toLowerCase()"
                    :items="costsTable"
                    hide-default-footer
                    >
                    <template v-slot:default="props">
                        <v-card>
                            <v-card-title class="pb-0">{{$t('QrSeries.step_four.PrintShop.costs')}}</v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pt-1">
                                <v-row>
                                    <v-col class="pt-0 pb-0">{{$t('QrSeries.step_four.PrintShop.number_letters')}}:</v-col>
                                    <v-col class="pt-0 pb-0">{{`${$store.state.templateEditor.templateType == 'parts' ? 0 : numberInvoices}`}} / {{invoiceNumberPages}}</v-col>
                                </v-row>
                                <v-row
                                    v-for="item in props.items"
                                    :key="item.name"
                                
                                >
                                    <v-col class="pt-1 pb-1">
                                        {{setName(item.name)}} 
                                    </v-col>
                                    <v-col class="pt-1 pb-1">
                                        {{`${item.name != 'number_pages' ? 'CHF' : ''}`}} {{item.cost}}
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                    <v-col class="pt-1 pb-0">{{$t('QrSeries.step_four.PrintShop.total')}}</v-col>
                                    <v-col class="pt-1 pb-0">CHF {{calculateTotal}}</v-col>
                                </v-row>
                                    <v-row class="pt-7">
                                    <v-col>
                                            <div>{{$t('QrSeries.step_four.PrintShop.text')}}</div>
                                            <div></div>
                                    </v-col>
                                    <v-col>
                                        <h4> CHF 15.00 + 0.10 / Couvert</h4>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                    </v-data-iterator>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-end">
                <v-col cols="5">
                    <!-- <v-row>
                        <v-col>
                            <v-btn
                            dark
                            :color="$vuetify.theme.themes.light.blue.primary"
                            @click="confirmPrintJob"
                            >CONFIRM</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                            dark
                            @click="abortPrintJob"
                            :color="$vuetify.theme.themes.light.blue.primary"
                            >ABORT</v-btn>
                        </v-col>
                    </v-row> -->

                </v-col>
            </v-row>
            <v-col cols="8">
                <h4 style="color:gray;">{{$t('QrSeries.step_four.PrintShop.validation15')}}</h4>
                <v-dialog persistent v-model="showAddress"  max-width="500">
                    <v-card >
                        <v-card-text> 
                            <v-form>
                                <address-dialog
                                ref="addressdialog"
                                :myClimate="myClimatePaper"
                                />
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                        <v-btn
                        dark
                        @click="addressDialogF()"
                        :color="$vuetify.theme.themes.light.blue.primary"
                        >{{$t("Globals.confirm")}}</v-btn>
                         <v-btn
                        dark
                        @click="cancelDialogF()"
                        :color="$vuetify.theme.themes.light.blue.primary"
                        >{{$t("Globals.cancel")}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-form>
    </div>
</template>

<script>
// import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { debounce } from '@/mixins/functions'
import { degrees, error, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import rules from "@/mixins/rules";
import { isBase64 } from '@/mixins/functions';
import ePreview from './PF.Envelope.Preview.vue';
const calculator = require("../../calculatePrintPrice.js");
import DialogAddress from '@/components/cockpit/QR-Massive-Steps/QR Series/AddressDialog.vue'
export default {
    mixins: [rules],
    components:{
      "address-dialog": DialogAddress,
      ePreview,
    },
    name:'printJobCheckout',
    data(){
        return {
            images: {
                logo: require('../../../../assets/images/default/qrLogoComplete.png'),
                myClimate: require('../../../../assets/images/default/myclimate_gross.jpg')
            },
            disableFranking:false,
            enclosureCheckShow: false,
            sortBy: 'priority',
            remarksToPrint:"",
            postTarifShow:"",
            envelopesShow:"",
            bywColor:"",
            selectionPart:"",
            checkMyClimate: "",
            settingsObj:{
                paper:"",
                co2_compensation:false,
                envelope:"",
                mail:"",
                parcel: "",
                priority:"",
                inserts:""
            },
            insertFile:null,
            insertNumberPages:0,
            invoiceNumberPages:0,
            envelopeNumberPages:0,
            parcelWeights:[],
            dataResponse:[],
            invoiceLimit:0,
            showAddress: false,
            shippingSelection:"",
            infoTextCheck: this.$t('QrSeries.step_four.PrintShop.paper_blue'),
            numberInvoices:this.$store.state.Debtor.debtors_selected.length,
            myClimatePaper:false,
            orderSettings:{
                paper:'blue-angel',
                tariff:'',
                parcel: '',
                color:'',
                type:'',
                envelope:'',
                envelopeColors:'color',
                uploadCustomInsert:false,
                inserts:''
            },
            IncludeShipping:"",
            // IncludeShippingItems:[
            //     {text:'Impersonalized enclosure',value:"impersonalized"},
            //     {text:'Personalized insert',value:"personalized"}
            // ],
            IncludeShippingItems:[
                {text: this.$t("QrSeries.step_four.PrintShop.Impersonal_supplement"),value:"impersonalized"}
            ],
            costsTable:[],
            local_rules:{
                isEmptyEnvelopeColor: v => {
                    if(this.orderSettings.envelope === 'logo'){
                        return !!v || this.$t("rules.notempty");
                    }
                    return true;
                }
            }
        }
    },
    async mounted(){
        this.orderId = this.$store.state.qrSeries.OrderID;
         await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/premium/getPrices?orderId=${this.orderId}`)
            .then(async response =>{
                if(response.status == 200){
                    this.dataResponse = response.data;
                    this.invoiceLimit = response.limit;
                    this.invoiceNumberPages = response.invoicePages;
                    this.envelopeNumberPages = this.$store.state.Debtor.debtors_selected.length;
                    this.parcelWeights = response.parcelWeights;
                }
                else{
                    console.log(response.status)
                }
            })
            .catch((err)=>{
            this.$handleCatch(err)
        })

        if(this.$store.state.templateEditor.templateType == 'parts'){
            // Color
            this.addColorCost();          
        }
    },
    computed:{
        hideQRLogo:{
            get(){
                return this.$store.state.addressInfo.hideQRLogo;
            },
            set(value){
                this.$store.state.addressInfo.hideQRLogo = value;
            }
        },
        HintPriority(){
            if(this.orderSettings.type == "normal")
            {
                return this.$t("QrSeries.step_four.PrintShop.hintPriority")
            }else{
               return   this.$t("QrSeries.step_four.PrintShop.hintPriorityExpress")
            }
        },
        fontSize:{
            get(){
                return this.$store.state.addressInfo.fontSize;
            },
            set(value){
                this.$store.state.addressInfo.fontSize = value;
            }
        },
        fontSelected:{
            get(){
                return this.$store.state.addressInfo.fontSelected;
            },
            set(value){
                this.$store.state.addressInfo.fontSelected =  value;
            }
        },
        collectiveAdress:{
            get(){
                return this.$store.state.addressInfo.collectiveAdress
            },
            set(value){
                this.$store.commit("addressInfo/setColectiveAddress", value);
            }
        },
        specialInstructions:{
            get(){
                return this.$store.state.addressInfo.specialInstructions
            },
            set(value){
                this.$store.commit("addressInfo/setSpecialInstructions", value);
            }
        },
        addressDialog:{
            get() {
                return this.$store.state.addressInfo.addressArea
            },
            set(value) {
                this.$store.commit("addressInfo/setAddressInfo", value);
            },
        },
        LogoArea:{
            get() {
                return this.$store.state.addressInfo.logoArea
            },
            set(value) {
                this.$store.commit("addressInfo/setLogoArea", value);
            },
        },
        ClimateLabel: {
            get() {
                return this.$store.state.addressInfo.climateSelected;
            },
            set(value){
                this.$store.state.addressInfo.climateSelected = value;
            }
        },
        calculateTotal(){
            let total = 0;
            this.costsTable.forEach((element)=>{
                total += element.name == 'number_pages' ? 0 : Number(element.cost);
            })
            return total.toFixed(2);
        },
        OptionPaper(){
            if(this.myClimatePaper == true)
            {
                this.orderSettings.paper= "climate-paper"
            }else
            {
                this.orderSettings.paper= "blue-angel"
            }
        }
    },
    created(){
        // this.init()
        if(this.$store.state.templateEditor.templateType == "parts"){
            this.checkMyClimate = false;
            this.bywColor = true;
            this.selectionPart= true;
            this.orderSettings.color = "black_and_white"
            this.shippingSelection= "collective"
            this.addColorCost();
            this.envelopesShow = false;
            this.postTarifShow= true;
            this.enclosureCheckShow = false;
            this.orderSettings.tariff="none"
            this.disableFranking= true;
        }else{
            this.checkMyClimate = true;
            this.bywColor = false;
            this.selectionPart= false;
            this.envelopesShow = true;
            this.postTarifShow= true;
            this.enclosureCheckShow = true;
            //   this.orderSettings.tariff=""
        }
    },
    watch:{
        invoiceNumberPages:function(){
            this.$root.$emit("send", this.invoiceNumberPages);
        }
    },
    methods:{
        setName(name){
            return  this.$t(`QrSeries.step_four.PrintShop.${name}`)
        },
        shippingSelect(){
            this.orderSettings.tariff = "";
            this.settingsObj.mail = 0;
            this.settingsObj.parcel = 0
            this.orderSettings.parcel = "";

            //we need to recalculate the cost because in this case the charge parcel should not apply.
            let currentTariffMap = { name:'dispatch_costs', cost:0 , priority: 6 };
            currentTariffMap.cost = calculator.calculateShipping(this.settingsObj.mail, this.settingsObj.parcel,this.envelopeNumberPages, this.invoiceNumberPages, this.insertNumberPages, this.dataResponse, this.invoiceLimit,this.parcelWeights);    
            

            let currentTariffCostIndex = this.costsTable.findIndex(element => element.priority == 6);
            if(currentTariffCostIndex > -1){
                Object.assign(this.costsTable[currentTariffCostIndex],currentTariffMap);
                if( currentTariffMap.cost === "0.00"){
                    this.costsTable.splice(currentTariffCostIndex , 1);
                }

            }

        },
        addressDialogF(){
            if(!this.$refs.addressdialog.addressDialog && !this.$refs.addressdialog.LogoArea){
                return this.$store.commit("global/setSnackbar", {
                    message: `The logo or the address are missing, at least one must be specified.`,
                });                
            }
            let addressInfo = this.$refs.addressdialog.$refs.form.validate();
            if(addressInfo){
                this.showAddress = false;
            }else{
                return this.$store.commit("global/setSnackbar", {
                    message: this.$t("snack_confirm.invalid_inputs"),
                });
            }  
        },
        cancelDialogF(){  
            this.$root
                .$confirm(
                    this.$t('QrSeries.step_four.PrintShop.cancel_registration'),
                    this.$t('QrSeries.step_four.PrintShop.cancel_process')
                )
                .then((response) => {
                    if (response) {
                        this.$store.state.addressInfo.addressArea ="";
                        this.$store.state.addressInfo.logoArea = null;
                        this.$store.state.addressInfo.climateSelected= true;
                        this.showAddress = false;
                    } 
                });

        },
        // addPaperCost(){
        //     if(this.orderSettings.paper){
        //         let currentPaperMap = { name:'Paper', cost:0 };
        //         currentPaperMap.cost = this.orderSettings.paper == 'normal' ? 25 : 30;
        //         let currentPaperCostIndex = this.costsTable.findIndex(element => element.name == 'Paper');
        //         if(currentPaperCostIndex > -1){
        //             Object.assign(this.costsTable[currentPaperCostIndex],currentPaperMap);
        //         }else{
        //             this.costsTable.push(currentPaperMap);
        //         }
        //     }
        // },
        addColorCost(){
            this.settingsObj.paper = this.orderSettings.color
            if(this.orderSettings.color){
                let currentColorMap = { name: 'paper_print', cost:0 , priority: 2 };
                currentColorMap.cost = calculator.calculatePaper(this.orderSettings.color,this.invoiceNumberPages, this.dataResponse, this.invoiceLimit);
                let currentColorCostIndex = this.costsTable.findIndex(element => element.priority == 2);
                if(currentColorCostIndex > -1){
                    Object.assign(this.costsTable[currentColorCostIndex],currentColorMap);
                }else{
                    this.costsTable.push(currentColorMap);
                }
            }
        },
        addEnvelopeCost(){
            this.settingsObj.envelope = this.orderSettings.envelope == 'neutral' ? this.orderSettings.envelope : this.orderSettings.envelopeColors;
            if(this.orderSettings.envelope){
                let currentEnvelopeMap = { name: 'envelopes', cost:0 , priority: 3 };
                currentEnvelopeMap.cost = calculator.calculateEnvelope(this.orderSettings.envelope == 'neutral' ? this.orderSettings.envelope : this.orderSettings.envelopeColors,this.envelopeNumberPages, this.$store.state.templateEditor.templateType == 'parts' ? true : false, this.dataResponse, this.invoiceLimit);
                let currentEnvelopeCostIndex = this.costsTable.findIndex(element => element.priority == 3);
                if(currentEnvelopeCostIndex > -1){
                    Object.assign(this.costsTable[currentEnvelopeCostIndex],currentEnvelopeMap);
                }else{
                    this.costsTable.push(currentEnvelopeMap);
                }          
                
                if(this.orderSettings.envelope === 'logo'){
                    this.$nextTick(()=>{
                        this.renderPreview();
                    })
                }
            }
        },
        async addInserts(){
            let currentInsertPages= {name: 'number_pages', cost: 0 , priority: 4}
            let currentInsertPagesCost = this.costsTable.findIndex(element => element.priority == 4);
            this.insertNumberPages = await this.getInsertNumberofPages();
            if(currentInsertPagesCost > -1){
                if(this.insertNumberPages){
                    currentInsertPages.cost = this.insertNumberPages;
                    Object.assign(this.costsTable[currentInsertPagesCost],currentInsertPages);
                }else{
                    this.costsTable.splice(currentInsertPagesCost,1);
                }
            }else{
                if(this.insertNumberPages){
                    currentInsertPages.cost = this.insertNumberPages;
                    this.costsTable.push(currentInsertPages);
                }
            }        
            this.addShippingInsertColor();       
        },
        addShippingInsertColor(){
            let currentInsertsMap = { name:'mailing_inserts', cost:0 , priority: 5 };
            let currentInsertsCostIndex = this.costsTable.findIndex(element => element.priority == 5);
            this.settingsObj.inserts = this.orderSettings.inserts;
            if(this.insertNumberPages && this.settingsObj.inserts){
                currentInsertsMap.cost = calculator.calculateInserts(this.settingsObj.inserts, this.insertNumberPages, this.$store.state.templateEditor.templateType == 'parts' ? true : false, this.dataResponse, this.invoiceLimit);
                if(currentInsertsCostIndex > -1){
                    Object.assign(this.costsTable[currentInsertsCostIndex],currentInsertsMap);
                }else{
                    this.costsTable.push(currentInsertsMap);
                }
            }else{
                if(currentInsertsCostIndex > -1){
                    this.costsTable.splice(currentInsertsCostIndex,1);
                }
            }
        },
        addShipping(){

            //we need to reset the value of this variable because this charge not apply in this case
            if(this.shippingSelection != 'collective'){
                this.orderSettings.parcel = 0;
            }

            this.settingsObj.mail = this.orderSettings.tariff != 'none' ? this.orderSettings.tariff : '';
            this.settingsObj.parcel = this.orderSettings.parcel != 'none' ? this.orderSettings.parcel : 0;
             if(this.settingsObj.mail || this.settingsObj.parcel){
        
                let currentTariffMap = { name:'dispatch_costs', cost:0 , priority: 6 };
                currentTariffMap.cost = calculator.calculateShipping(this.settingsObj.mail, this.settingsObj.parcel,this.envelopeNumberPages, this.invoiceNumberPages, this.insertNumberPages, this.dataResponse, this.invoiceLimit,this.parcelWeights);
                let currentTariffCostIndex = this.costsTable.findIndex(element => element.priority == 6);
                if(currentTariffCostIndex > -1){
                    Object.assign(this.costsTable[currentTariffCostIndex],currentTariffMap);
                }else{
                    this.costsTable.push(currentTariffMap);
                }                
            }
        },
        addOrderType(){
            this.settingsObj.priority = this.orderSettings.type
            if(this.orderSettings.type){
                let currentOrderTypeMap = { name:'order_execution', cost:0 , priority: 7 };
                let process_feeMap = { name: 'process_fee', cost: 0 , priority: 7.5 };
                let prices = calculator.calculateOrder(this.orderSettings.type,this.envelopeNumberPages, this.$store.state.templateEditor.templateType == 'parts' ? true : false, this.dataResponse, this.invoiceLimit);
                currentOrderTypeMap.cost = prices.order_total;
                process_feeMap.cost = prices.processing_fee;
                let currentOrderTypeCostIndex = this.costsTable.findIndex(element => element.priority == 7);
                let process_feeMapCostIndex = this.costsTable.findIndex(element => element.priority == 7.5);
                if(currentOrderTypeCostIndex > -1){
                    if (this.$store.state.templateEditor.templateType != 'parts') Object.assign(this.costsTable[currentOrderTypeCostIndex],currentOrderTypeMap);
                }else{
                    if (this.$store.state.templateEditor.templateType != 'parts') this.costsTable.push(currentOrderTypeMap);
                }       
                if (process_feeMapCostIndex > -1)
                {
                    Object.assign(this.costsTable[process_feeMapCostIndex],process_feeMap);
                }   
                else
                {
                    this.costsTable.push(process_feeMap);
                }      
            }            
        },
        addCO2(){
            this.settingsObj.co2_compensation = this.myClimatePaper
            let currentClimateMap = { name:'co2', cost: 0 , priority: 8 };
            let currentClimateCostIndex = this.costsTable.findIndex(element => element.priority == 8);
            if(this.myClimatePaper){
                currentClimateMap.cost = calculator.calculateCO2(this.invoiceNumberPages, this.envelopeNumberPages, this.$store.state.templateEditor.templateType == 'parts' ? true : false, this.dataResponse, this.invoiceLimit);              
                if(currentClimateCostIndex > -1){
                    Object.assign(this.costsTable[currentClimateCostIndex],currentClimateMap);
                }else{
                    this.costsTable.push(currentClimateMap);
                }
            }else{
                  this.costsTable.splice(currentClimateCostIndex, 1)
            }
        },
        abortPrintJob(){
            return this.$store.commit(`global/setSnackbar`,{message:'Feature unavailable'});
        },
        confirmPrintJob(){
            return this.$store.commit(`global/setSnackbar`,{message:'Feature unavailable'});
        },
        async getInsertNumberofPages(){
            if (!this.insertFile){
                return null;
            }
            let fileBytes = null;
            if(this.insertFile.type == `application/pdf`){
                if(this.insertFile.size <= 2000000){
                     // convert the file to the format in bytes that the library requires to render
                    if(isBase64(this.insertFile)) {
                        fileBytes = base64ToArrayBuffer(this.insertFile);
                    } else {
                        fileBytes = URL.createObjectURL(this.insertFile);
                        fileBytes = await fetch(fileBytes).then(res => res.arrayBuffer());
                    }
                    var fileBuffer = Buffer.from(fileBytes);
                    const pdfDoc = await PDFDocument.load(fileBuffer);
                    const pages = pdfDoc.getPages();
                    return pages.length * this.envelopeNumberPages;
                }else{
                    this.$store.commit(`global/setSnackbar`,{message: this.$t("QrSeries.step_four.PrintShop.recomended_size")})
                    return null;
                }
            }else{
                this.$store.commit(`global/setSnackbar`,{message: this.$t("qrGenerator.productsTable.message_title")});
                return null;
            }
        },
        renderPreview(){
            if(this.$refs.envelopePreview){
                this.$refs.envelopePreview.init();
            }
        }
    },
}
</script>