<template>
  <v-content>
    <!-- no se usa -->
    <v-container>
      <v-row>
        <v-col cols="10" class="mx-auto">
          <v-row>
            <v-col cols="12">
              <v-card>
                <!-- <v-progress-linear indeterminate :active="processing" color="green"></v-progress-linear> -->
                <v-card-title class="d-flex justify-center">Registry</v-card-title>
                <v-card-text>
                  <signup-form>
                 </signup-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
              <qr-form>
              </qr-form>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-content>

</template>

<script>
import signupForm from '../signup/signup.form';
import qrForm from '../QR/qr.form';

export default { 
components:{
    "signup-form": signupForm,
    "qr-form": qrForm
}
}
</script>

<style>

</style>