<template>
  <div>
    <v-form ref="form">
      <v-card flat>
        <v-row>
          <v-col>
            <v-card-title>
              {{ $t("QrSeries.step_four.PrintShop.register_address") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <v-checkbox
                    :label="$t('QrSeries.step_four.PrintShop.hide_qrlogo')"
                    v-model="hideQRLogo"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    :rules="[rules.fiveLines]"
                    dense
                    label=""
                    outlined
                    height="150"
                    v-model="addressDialog"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-col class="pt-0">
                  <v-select
                    :no-data-text="
                      $t('qrGenerator.productsTable.data_available')
                    "
                    :label="$t('TemplateList.create_template.font')"
                    :items="fontItems"
                    item-text="text"
                    item-value="font"
                    v-model="fontSelected"
                  ></v-select>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    label="Size"
                    type="number"
                    v-model="fontSize"
                    @click="SizeMethod"
                    :rules="[rules.sizeFont]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    v-model="LogoArea"
                    :label="$t('QrSeries.step_four.PrintShop.upload')"
                    accept="image/png,image/jpeg"
                    :rules="[rules.onlyIMG, rules.less1MB]"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>{{
                    $t("QrSeries.step_four.PrintShop.allowedfile")
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span
                    >Achten Sie darauf, dass lhr Logo eine Auflösung von
                    mindestens 200 dpi aufweist. Maximal erlaubte Dateigrösse: 1
                    MB
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    :label="$t('QrSeries.step_four.PrintShop.print_label')"
                    v-model="ClimateLabel"
                    :disabled="!myClimate"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </div>
</template>
<script>
import rulesMixin from "@/mixins/rules";
export default {
  mixins: [rulesMixin],
  data() {
    return {
      fontItems: [
        { text: "Arial", font: "ArialNarrow" },
        { text: "PlayFair", font: "PlayFair" },
        { text: "Roboto", font: "Roboto" },
        { text: "Liberation Sans", font: "LiberationSans" },
      ],
    };
  },
  props: {
    myClimate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    print() {
      //    console.log(this.ClimateLabel)
    },
    SizeMethod() {
      this.SizeNumber = Number(this.SizeNumber);
    },
  },
  computed: {
    hideQRLogo: {
      get() {
        return this.$store.state.addressInfo.hideQRLogo;
      },
      set(value) {
        this.$store.state.addressInfo.hideQRLogo = value;
      },
    },
    fontSize: {
      get() {
        return this.$store.state.addressInfo.fontSize;
      },
      set(value) {
        this.$store.state.addressInfo.fontSize = value;
      },
    },
    fontSelected: {
      get() {
        return this.$store.state.addressInfo.fontSelected;
      },
      set(value) {
        this.$store.state.addressInfo.fontSelected = value;
      },
    },
    addressDialog: {
      get() {
        return this.$store.state.addressInfo.addressArea;
      },
      set(value) {
        // this.$store.commit("addressInfo/setAddressInfo", value);
        this.$store.state.addressInfo.addressArea = value;
      },
    },
    LogoArea: {
      get() {
        return this.$store.state.addressInfo.logoArea;
      },
      set(value) {
        // this.$store.commit("addressInfo/setLogoArea", value);
        this.$store.state.addressInfo.logoArea = value;
      },
    },
    ClimateLabel: {
      get() {
        return this.$store.state.addressInfo.climateSelected;
      },
      set(value) {
        // this.$store.commit("addressInfo/setClimate",value);
        this.$store.state.addressInfo.climateSelected = value;
      },
    },
  },
};
</script>