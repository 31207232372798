/*
*   This file will save in localstorage all kind of modification 
*   in the mutations
*/
const saveInLocalStorage = store => {
    store.subscribe((mutation, state) => {

        // ====================================================== //
        // ======================== USER ======================== //
        // ====================================================== //
        if(mutation.type == 'User/setLogin') {
            localStorage.setItem('User/setLogin', JSON.stringify({
                login: state.User.login,
                username: state.User.username,
            }))
        }

        if(mutation.type == 'User/setItems') {
            localStorage.setItem('User/setItems', JSON.stringify({
                items: state.User.items
            }))
        }

        if(mutation.type == 'User/setSubscription') {
            localStorage.setItem('User/setSubscription', JSON.stringify({
                subscription: state.User.subscription
            }))
        }

        if(mutation.type == 'User/setSubscriptionName') {
            localStorage.setItem('User/setSubscriptionName', JSON.stringify({
                subscriptionName: state.User.subscriptionName
            }))
        }
        
        if(mutation.type == 'User/setActiveAccount') {
            localStorage.setItem('User/setActiveAccount', JSON.stringify({
                activeAccount: state.User.activeAccount
            }))
        }

        // ====================================================== //
        // ========================= QR ========================= //
        // ====================================================== //
        if(mutation.type == 'QR/global') {
            localStorage.setItem('QR/global', JSON.stringify({
                reference: state.QR.reference,
                currency: state.QR.currency,
                amount: state.QR.amount,
            }))
        }

        if(mutation.type === 'User/setLanguage') {
            localStorage.setItem('User/setLanguage', JSON.stringify(state.User.language));
        }

        if(mutation.type === 'User/setCheckout') {
            localStorage.setItem('User/setCheckout', JSON.stringify(state.User.user.checkout))
        }

        // ====================================================== //
        // ====================== PROFILE ======================= //
        // ====================================================== //
        if(mutation.type === 'Profile/setProfile') {
            localStorage.setItem('Profile/setProfile', JSON.stringify(state.Profile.profile));
        }

    })
}

export default saveInLocalStorage;