<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="itemsArray"
            :items-per-page="10"
            class="elevation-1"
        >
            <template v-slot:item.file="{item}">

                <div class="d-flex align-center justify-center">
                    
                     <v-icon dark 
                        class="btn-add-file"
                        @click="uploadFile(item)"
                     >
                        mdi-plus
                     </v-icon>

                    
                        <p v-if="item.file" class="ma-0 ml-2 text-width" > {{  item.file.name }}  </p>
                        
                        <v-icon
                            v-if="item.file"
                            class="btn-delete-file"
                            @click="deleteFile(item)"
                        >
                            mdi-close
                        </v-icon>
                  

                </div>     
            </template>
        </v-data-table>

        <!-- v-dialog upload file -->
        <drag-drop-file @changeFile="onChangeFile" @closeDialog=" showDialogUploadFile = false " :showDialog="showDialogUploadFile" :keepFile="false" />  
                

    </div>
</template>

 


<script>
import dragDropFile from '@/components/email-dispatch/dragDropFile.vue';

export default {
    name:"tableCustomAttachments",
    props:{
        debtors:{
            type:Array,
            required:true
        }
    },  

    components:{
        "drag-drop-file": dragDropFile
    },
    data(){
        return { 
            headers: [
                        {
                            text: this.$t('QrSeries.step_four.emailDispatch.client_no'),
                            align: 'start',
                            sortable: false,
                            value: 'client_number',
                        },
                        { text: this.$t('QrSeries.step_four.emailDispatch.first_name'), value: 'name' },
                        { text: this.$t('QrSeries.step_four.emailDispatch.last_name'), value: 'last_name' },
                        { text: this.$t('QrSeries.step_four.emailDispatch.street'), value: 'street' },
                        { text: this.$t('QrSeries.step_four.emailDispatch.number'), value: 'number' },
                        { text: this.$t('QrSeries.step_four.emailDispatch.postal_code'), value: 'zip' },
                        { text: this.$t('QrSeries.step_four.emailDispatch.city'), value: 'city' },
                        { text: this.$t('QrSeries.step_four.emailDispatch.insert_document'), value:'file'}
                    ],
            rules:{
                sizeFile: (file) =>  !file || file.size < 1500000 ||  this.$t('QrSeries.step_four.emailDispatch.pdfSize'),
                checkTypeFile: (file) => !file || file.type == "application/pdf" || this.$t('QrSeries.step_four.emailDispatch.pdfType'),
                required: (file) =>  file ? true : 'The pdf file is required'
            },
            showDialogUploadFile:false,
            currentDebtor:null
        }
    },
    methods:{
       onChangeFile(file){
            let indexDebtor =  this.itemsArray.findIndex( element => element.id_b == this.currentDebtor.id_b );
            if(indexDebtor != -1){
                //modify the array to add file in debtor
                this.itemsArray[indexDebtor].file = file;
                //copy array to auxilar variable to show the new changes
                let  auxArray   =  [...this.itemsArray];
                //asign the aux variable (array) to local variable that controll the debtors in table
                this.itemsArray =  auxArray;
            }
       },
       uploadFile(item){
           this.currentDebtor = item;
           this.showDialogUploadFile = true;
       },
       deleteFile(item){
           let indexDebtor =  this.itemsArray.findIndex( element => element.id_b == item.id_b );
            if(indexDebtor != -1){
                 //modify the array to add file in debtor
                this.itemsArray[indexDebtor].file = null;
                //copy array to auxilar variable to show the new changes
                let  auxArray   =  [...this.itemsArray];
                //asign the aux variable (array) to local variable that controll the debtors in table
                this.itemsArray =  auxArray;
            }
       }
    },
    computed:{
         itemsArray:{
                get(){
                    return this.$store.state.qrSeries.items
                },
                set(value) {
                    this.$store.state.qrSeries.items = value;
                }
        },
    }

}

</script>


<style scoped>
    .btn-add-file{ 
        border-radius:50%;
        border:1px solid black;
        color:black;
        font-size: 18px;
        height:20px;
        width:20px;
    }
    .btn-delete-file{
        color:black;
        font-size : 20px;
        margin-left: auto !important;
    }

    .text-width{
        max-width: 200px;
        text-overflow: ellipsis;
    }
</style>