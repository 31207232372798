
import store from '@/store/index';
import router from '@/router';

/**
 * this file is used as plugin to replace the native fetch
 * the difference its that this plugin should handle all the bad responses and if its needed redirect to another page.
 * and return the response as json or the format its needed
 * to use it, just type this.$fetch
 * 
 * this.$fetch(url)
 * .then(response) => {
 *      console.log(response)
 * })
 * .catch(error => {
 *  this.$handleCatch(error)
 * })
 * 
 */

// Custom API error to throw
function ApiError(message, data, status) {
    let response = null;
    let isObject = false;

    // We are trying to parse response
    try {
        response = JSON.parse(data);
        isObject = true;
    } catch (e) {
        response = data;
    }

    this.response = response;
    this.message = message;
    this.status = status;
    this.toString = function () {
        return `${this.message} \nServer Says: ${isObject ? this.response.message : this.response}`
    };
}

export default {
    install(Vue, VueOptions) {

        // API wrapper function
        Vue.prototype.$fetch = (url, userOptions = {}) => {
        // Define default options
        const defaultOptions = {
            method: 'GET',
            credentials: 'include',
        };
        // Define default headers
        const defaultHeaders = {
            'Content-Type': 'application/json'
        };

        const options = {
            // Merge options
            ...defaultOptions,
            ...userOptions,
            // Merge headers
            headers: {
            ...defaultHeaders,
            ...userOptions.headers,
            },
        };

        // Detect is we are uploading a file
        const isFile = options.body instanceof File;

        // Stringify JSON data
        // If body is not a file
        if (options.body && typeof options.body === 'object' && !isFile) {
            options.body = JSON.stringify(options.body);
        }

        // Variable which will be used for storing response
        let response = null;

        return fetch(url, options)
            .then(responseObject => {
                // Saving response for later use in lower scopes
                response = responseObject;

                // HTTP unauthorized
                if (response.status === 401) {
                    const { currentRoute } = router;
                    const PREMIUM_NAMES = [
                        'admin',
                        'admin-dashboard',
                        'dashboard',
                        'users',
                        'general-product-settings',
                        'general-usage-info',
                        'scheduled-charges'
                    ]
                    store.commit('global/setSnackbar', 'You are not authorized to make that request, you are being redirected')
                    if(PREMIUM_NAMES.includes(currentRoute.name)) {
                        router.push('/admin');
                    } else {
                        store.commit('global/setSnackbar', 'You are not authorized to make that request, you are being redirected')
                        router.push('/login');
                    }
                    // Handle unauthorized requests
                    // Maybe redirect to login page?
                }

                // Check for error HTTP error codes
                if (response.status < 200 || response.status >= 300) {
                    // Get response as text
                    return response.text();
                }

                // Get response as json
                return response.json();
            })
            // "parsedResponse" will be either text or javascript object depending if
            // "response.text()" or "response.json()" got called in the upper scope
            .then(parsedResponse => {
                // Check for HTTP error codes
                if (response.status < 200 || response.status >= 300) {
                    // Throw error
                    throw parsedResponse;
                }

                // Request succeeded
                return parsedResponse;
            })
            .catch(error => {
                // Throw custom API error
                // If response exists it means HTTP error occured
                if (response) {
                    // throw new ApiError(`Request failed with status ${ response.status }.`, error, response.status);
                    throw new ApiError(`Request failed with status ${ response.status }.`, error, response.status);
                } else {
                    throw new ApiError(error.toString(), null, 'REQUEST_FAILED');
                }
            });
        };

        Vue.prototype.$handleCatch = (error, notify = true) => {
            if(notify) store.commit('global/setSnackbar', error);
        }
    }
} 
