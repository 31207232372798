
/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input) {
    var CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26
    };
    var iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
      digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter) {
      return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}

function mod97(string) {
var checksum = string.slice(0, 2),
    fragment;
for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
}
return checksum;
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	};
}

async function generateBase64String(file,callback){
  let url = window.URL.createObjectURL(file);
  let newBlob = await fetch(url).then(result => result.blob());
  let reader = new FileReader();
  reader.readAsDataURL(newBlob);
  reader.onload = () =>{
      return callback(reader.result)
  }
}

async function getBase64String(file){
  if(!file) return;
  return new Promise( async (resolve, reject) => {

    try {
      let url = window.URL.createObjectURL(file);
      let newBlob = await fetch(url).then(result => result.blob());
      let reader = new FileReader();
      reader.readAsDataURL(newBlob);
      reader.onload = () =>{
          return resolve(reader.result)
      }
    } catch {
      reject('Error when parsing logo')
    }
  })
  
}

function isBase64(str) {
  try {
      if (str ==='' || str.trim() ===''){ return false; }
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
}

function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

const base64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

function getPicDimensions(DataURL) {
  if(!DataURL) return;
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        })
      }

      img.src = DataURL;
    } catch(error) {
      reject('there was an error when getting the dimensions of the logo')
    }
  })
}; 

function getRandomID() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

/**
 * this function wraps text into the limit length specified.
 * @param {string} s 
 * @param {string | number} w 
 * @returns {string}
 */
// const wrap = (string, maxLength) => string.replace(new RegExp(`(?![^\\n]{1,${maxLength}}$)([^\\n]{1,${maxLength}})\\s`, 'g'), '$1\n');


function testWhite(x) {
  var white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
};

function wrapText(str, maxWidth) {
  let newLineStr = "\n"; 
  let res = '';
  while (str.length > maxWidth) {                 
    let found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join('');
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('');
      str = str.slice(maxWidth);
    }

  }

  return res + str;
}

function getFont() {
  
}

function fillParagraph(text, font, fontSize, maxWidth) {
  // if(!font) font = 

  var paragraphs = text.split('\n');
  for (let index = 0; index < paragraphs.length; index++) {
      var paragraph = paragraphs[index];
      if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
          var words = paragraph.split(' ');
          var newParagraph = [];
          var i = 0;
          newParagraph[i] = [];
          for (let k = 0; k < words.length; k++) {
              var word = words[k];
              newParagraph[i].push(word);
              if (font.widthOfTextAtSize(newParagraph[i].join(' '), fontSize) > maxWidth) {
                  newParagraph[i].splice(-1); // retira a ultima palavra
                  i = i + 1;
                  newParagraph[i] = [];
                  newParagraph[i].push(word);
              }
          }
          // paragraphs[index] = newParagraph.map(p => p.join(' ')).join('\n');
          // ["hola", "este", "es"]
          // "Hola este es"
          // debugger
          paragraphs[index] = newParagraph.map(word => {
            return word.join(' ')
          }).join('\n')
      }
  }
  return paragraphs.join('\n');
}

function alignCenter(text, font, fontSize, maxWidth) {
  const length = text.length;
  const w2 = Math.floor(maxWidth / 2);
  const l2 = Math.floor(length / 2);
  const s = new Array(w2 - l2 + 1).join(' ');
  text = s + text + s;
  if (text.length < maxWidth)
  {
      text += new Array(maxWidth - text.length + 1).join(" ");
  }

  return text;
}

const getDate = date => {
  if(!date) return;
  const months = {
    '01': 'Januar',
    '02': 'Februar',
    '03': 'März',
    '04': 'April',
    '05': 'Kann',
    '06': 'Juni',
    '07': 'Juli',
    '08': 'August',
    '09': 'September',
    '10': 'Oktober',
    '11': 'November',
    '12': 'Dezember'
  };

  date = date.split('-');
  return [date[0], months[date[1]], date[2]];
}

function floor(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.floor(value * multiplier) / multiplier;
}



export {
    isValidIBANNumber,
    debounce,
    generateBase64String,
    isBase64,
    base64ToArrayBuffer,
    getBase64String,
    getPicDimensions,
    base64toBlob,
    getRandomID,
    fillParagraph,
    getDate,
    alignCenter,
    floor,
    wrapText
}
