<template>
    <div>
        <v-select
            :no-data-text="$t('qrGenerator.productsTable.data_available')"
            :label="$t('TemplateList.create_template.font')"
            :items="fontItems"
            item-text="text"
            item-value="font"
            v-model="myFont"
            v-on:input="updateValue"
            @change="handleChange"
        ></v-select>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() { 
        return {
            fontItems: [
                { text: 'Arial', font: 'ArialNarrow' },
                { text: 'PlayFair', font: 'PlayFair' },
                { text: 'Roboto', font: 'Roboto' },
                { text: 'Liberation Sans', font: 'LiberationSans' }    
            ],
            bold: null,
            italic: null,
            myFont: null,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.myFont = this.font
        })
    },
    props: {
        font: {
            required: true,
        },
    },
    beforeDestroy() {
        this.myFont = '';
    },
    watch: {
        font(newVal, oldVal) {
            this.myFont = newVal;
        }
    },
    computed: {
        ...mapGetters({
            fonts: 'templateEditor/fonts',
        }),
    },
    methods: {
        updateValue() {
            this.$emit('update-font', this.myFont)
        },
        handleChange() {
            this.bold = null;
            this.italic = null;
            this.updateValue();
        }
    }

}
</script>

<style>

</style>