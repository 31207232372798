<template>
    <v-dialog  persistent v-model="showProgressBar" width="600px">
        <v-card width="600px">
            <v-card-title>PROCESSING...</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-progress-linear
                        :buffer-value="progressBarValue"
                        :value="progressBarValue"
                        height="20"
                        stream
                        >
                        <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                        </v-progress-linear>                        
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name:"progressBar",
    data(){
        return {
            showProgressBar: false,
            progressBarValue:0
        }
    }
}
</script>