<template>
<div>
    <v-row >
        <v-col class="" cols="12">
            <v-card>
                <v-card-title>{{$t('MasterData.title')}}</v-card-title>
                <v-card-subtitle>
                    <v-row>
                        <v-col cols="6" class="pt-1 pb-0">
                          {{$t('MasterData.biller_subtitle')}}
                        </v-col>
                        <v-col cols="6" class="pt-1 pb-0">
                        {{$t('MasterData.billing_subtitle')}}
                        </v-col>
                    </v-row>
                </v-card-subtitle>
                <v-card-text>
                    <v-form
                    ref="form"
                    :disabled="formsEnableFlag"
                    >
                        <v-row>
                            <v-col cols="6" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.account_numberiban')"
                                v-mask="'AA## #### #XXX XXXX XXXX X'"
                                v-model="accountNumber"
                                outlined
                                dense
                                @input="updateChanges()"
                                :rules="[
                                    rules.notEmpty,
                                    rules.ibanCounter,
                                    rules.isIBAN,
                                    rules.isValidIBANNumber,
                                    ruleChar.noInvalidCharacters
                                ]"
                                counter="26"
                                validate-on-blur
                                :loading="enableLoader"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.uid_number')"
                                v-model="UIDNumber"
                                outlined
                                dense
                                @input="updateChanges()"
                                prefix="CHE-"
                                v-mask="'###.###.###'"
                                :loading="enableLoader"
                                :rules="[ruleChar.noInvalidCharacters]"
                                ></v-text-field>                            
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col  v-if="showFieldIbanWir"  cols="6" class="pt-0 pb-0">
                                    <v-text-field
                                    class="pa-0 ma-0"
                                    :label="$t('MasterData.account_wir_numberiban')"
                                    v-model="accountWirNumber"
                                    v-mask="'AA## #### #XXX XXXX XXXX X'"
                                    outlined
                                    dense
                                    @input="updateChanges()"
                                    :rules="[
                                        rules.notEmpty,
                                        rules.isIBAN,
                                        rules.isValidIBANNumber,
                                        ruleChar.noInvalidCharacters
                                    ]"
                                    counter="26"
                                    validate-on-blur
                                    :loading="enableLoader"
                                    ></v-text-field>
                            </v-col>
                        </v-row>


                        <v-row>
                            <v-col cols="6" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.qriban_number')"
                                v-model="accountQRNumber"
                                v-mask="'AA## #### #XXX XXXX XXXX X'"
                                outlined
                                dense
                                @input="updateChanges()"
                                :rules="[
                                    rules.ibanCounter,
                                    rules.isQRIBAN,
                                    rules.isValidQRIBAN,
                                    ruleChar.noInvalidCharacters
                                ]"
                                counter="26"
                                validate-on-blur
                                :loading="enableLoader"
                                ></v-text-field>
                            </v-col>

                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    class="pa-0 ma-0"
                                    :label="$t('MasterData.reference')"
                                    v-model="reference"
                                    outlined
                                    dense
                                    @input="updateChanges()"
                                    validate-on-blur
                                    :loading="enableLoader"
                                    :rules="[ruleChar.noInvalidCharacters]"
                                >
                                </v-text-field>
                            </v-col>

                        </v-row>

                        <v-row>
                             <v-col v-if="showFieldIbanWir" cols="6" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.qriban_wir_number')"
                                v-model="accountWirQRNumber"
                                v-mask="'AA## #### #XXX XXXX XXXX X'"
                                outlined
                                dense
                                @input="updateChanges()"
                                :rules="[
                                    rules.ibanCounter,
                                    rules.isQRIBAN,
                                    rules.isValidQRIBAN,
                                    ruleChar.noInvalidCharacters
                                ]"
                                counter="26"
                                validate-on-blur
                                :loading="enableLoader"
                                ></v-text-field>
                            </v-col>  
                        </v-row>
                
                        <v-row>
                            <v-col cols="6" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.name_or_company')"
                                v-model="firstName"
                                outlined
                                dense
                                @input="updateChanges()"
                                :rules="[rules.hasFirstName,rules.firstNameLength,ruleChar.noInvalidCharacters]"
                                counter="35"
                                validate-on-blur
                                :loading="enableLoader"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0">
                                <v-select
                                :no-data-text="$t('qrGenerator.productsTable.data_available')"
                                class="pa-0 ma-0"
                                :label="$t('MasterData.currency')"
                                :items="currencies"
                                v-model="selectedCurrecy"                            
                                outlined
                                dense
                                @change="updateChanges()"
                                :loading="enableLoader"
                                ></v-select>
                            </v-col>
                        </v-row>

                      

                        <v-row>
                            <v-col cols="6" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.last_name')"
                                v-model="lastName"                            
                                outlined
                                dense       
                                @input="updateChanges()"
                                :rules="[rules.LastNameLength, ruleChar.noInvalidCharacters]"
                                counter="35"
                                validate-on-blur 
                                :loading="enableLoader"                   
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.street')"
                                v-model="street"                            
                                outlined
                                dense  
                                @input="updateChanges()"     
                                :loading="enableLoader"
                                :rules="[ruleChar.noInvalidCharacters]"                     
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.number')"
                                v-model="houseNumber"                            
                                outlined
                                dense    
                                @input="updateChanges()"  
                                :loading="enableLoader"
                                :rules="[ruleChar.noInvalidCharacters]"                    
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                               :label="$t('MasterData.zip')"
                                v-model="zipCode"                            
                                outlined
                                dense   
                                @input="updateChanges()"   
                                :loading="enableLoader"
                                :rules="[rules.zip, ruleChar.noInvalidCharacters]"                       
                                ></v-text-field>                            
                            </v-col>
                            <v-col cols="4" class="pt-0 pb-0">
                                <v-text-field
                                class="pa-0 ma-0"
                                :label="$t('MasterData.city')"
                                v-model="city"                            
                                outlined
                                dense   
                                @input="updateChanges()"  
                                :loading="enableLoader"  
                                :rules="[rules.city,rules.isRequired,ruleChar.noInvalidCharacters]"                      
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pt-0 pb-0">
                                <v-select
                                :no-data-text="$t('qrGenerator.productsTable.data_available')"
                                :items="this.$t('countryList')"
                                item-text="name"
                                item-value="code"
                                class="pa-0 ma-0"
                                :label="$t('MasterData.country')"
                                v-model="selectedCountry"                            
                                outlined
                                dense       
                                @change="updateChanges()"    
                                :loading="enableLoader"
                                :rules="[rules.country, ruleChar.noInvalidCharacters]"
                                ></v-select>
                            </v-col>
                            <v-col cols="6" class="pt-0 pb-0">
                                <v-text-field
                                v-model="customID"
                                dense
                                label="ID"
                                outlined
                                @change="updateChanges()"
                                :rules="[rules.customID, ruleChar.noInvalidCharacters]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row>
                        <v-col cols="9" class="mt-0 ma-0 pr-0 pb-0">
                            <v-form
                            ref="form2"
                            :disabled="formsEnableFlag"
                            >
                                <v-checkbox
                                class="float-right"
                                :label="$t('MasterData.default_profile')"
                                @change="updateCheckbox()"
                                v-model="defaultProfile"
                                ></v-checkbox>
                            </v-form>
                        </v-col>
                        <v-col cols="3" class="ml-0 mr-0 pl-1 pr-0 mt-0 pb-0">
                            <v-row>
                                <v-col cols="4" class="pl-5 pr-0 mr-0">
                                    <v-btn
                                    fab
                                    small
                                    @click="goPrevious()"
                                    class="mr-0 pl-0"
                                    :color="$vuetify.theme.themes.light.brown.primary"
                                    :disabled="backButtonFlag"                         
                                    >
                                        <v-icon
                                        large
                                        >mdi-arrow-left-drop-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="4" class="pl-0 ml-0 pr-0 mr-0">
                                    <v-btn
                                    fab
                                    small
                                    class="mr-0 pl-0"
                                    @click="goNext()"
                                    :color="$vuetify.theme.themes.light.brown.primary"
                                    :disabled="forwardButtonFlag"                            
                                    >
                                        <v-icon
                                        large
                                        >mdi-arrow-right-drop-circle-outline</v-icon>
                                    </v-btn>                                    
                                </v-col>
                                <v-col cols="4" class="pr-0 mr-0 ml-0 pl-0 mt-2">
                                    <h3>{{currentUser}}/{{profilesArray.length}}</h3>      
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" class="mt-0 mb-0 pb-0"></v-col>
                        <v-col cols="2" class="mt-0 mb-0 pb-0">
                            <v-btn
                            :color="$vuetify.theme.themes.light.blue.primary"
                            :dark="activateMutateDark"
                            width="80px"
                            @click="enableForms()"
                            :disabled="disabledMutateButton"
                            >{{$t('MasterData.btn_mutate')}}</v-btn>
                        </v-col>
                        <v-col cols="2" class="mt-0 pl-0 ml-0 mb-0 pb-0">
                            <v-btn
                            large
                            :color="$vuetify.theme.themes.light.blue.primary"
                            :dark="activateNewProfileDark"
                            :disabled="disabledNewProfile"
                            @click="addProfile()"
                            >{{$t('MasterData.btn_addnew')}}<br>{{$t('MasterData.btn_profile')}}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" class="mt-0 pt-0 mb-1 pb-1"></v-col>
                        <v-col cols="2" class="mt-0 pt-0 mb-1 pb-1">
                            <v-btn
                            :dark="saveButtonDarkEnabled"
                            :color="$vuetify.theme.themes.dark.green.secondary"
                            :disabled="deactivateSaveButton"
                            @click="mutateProfiles()"
                            :loading="DOMElements.mutateProfiles.loading"
                            width="80px"
                            >{{$t('MasterData.btn_save')}}</v-btn>
                        </v-col>
                        <v-col cols="2" class="mt-0 pt-2 mb-1 pb-1">
                            <v-btn
                            :dark="deleteButtonDarkEnabled"
                            :color="$vuetify.theme.themes.dark.green.secondary"
                            :disabled="disableDelete"
                            @click="deleteProfile()"
                            >{{$t('MasterData.btn_delete')}}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" class="mt-0 pt-0"></v-col>
                        <v-col cols="2" class="mt-0 pt-0">
                            <v-btn
                            :dark="cancelButtonDarkEnabled"
                            :color="$vuetify.theme.themes.dark.brown.secondary"
                            width="80px"
                            @click="cancelButtonAction()"
                            :disabled="deactivateCancelButton"
                            >{{$t('MasterData.btn_abort')}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>  
        </v-col>
    </v-row>

          <!--******************************************** Notification User Discount Partner **************************************************** -->
     <v-dialog
      transition="dialog-top-transition"
      v-model="showMasterDialog"
      persistent
      width="500"
    >
        <v-card flat  width="500">
            <v-toolbar
              :color="$vuetify.theme.themes.dark.green.secondary"
              dark
            >
                <h3> Partner </h3>
            </v-toolbar>
             <v-spacer></v-spacer>
            <div class="pa-1">
                <v-card-text class="text-body-1 py-2">
                    <span> 
                        {{messageDialogWirPartner}}
                    </span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2" color="primary" @click="cancelRelation" > {{ $t("Globals.cancel") }} </v-btn>
                    <v-btn class="mb-2" color="primary" @click="saveProfiles">  {{ $t("Globals.save") }} </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
    <!-- *********************************************************************************************************************************** -->
    </div>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from '@/mixins/rules';
import {validateCharac} from "../../../../functions/invalidCharacters";

export default {
    name:'MasterData',
    mixins: [rules],
    directives: {
      mask
    },
    watch:{
    locale(newValue,oldValue){
      this.$store.commit("global/setPageTitle",this.$t('MasterData.main_title'))
    }
  },
    computed:{
        locale(){
          return this.$store.state.User.language;
        },
        disableDelete(){
            if(!this.deactivateDeleteButton){
                if(this.profilesArray.length > 1){
                    this.deleteButtonDarkEnabled = true;
                    return false;
                }else{
                    this.deleteButtonDarkEnabled = false;
                    return true;
                }
            }else{
                this.deleteButtonDarkEnabled = false;
                return true;
            }
        },
        showFieldIbanWir(){
            return this.userInputIbanWithWir;
        }
    },
    data(){
        return {
            partnerName:'',
            showMasterDialog:false,
            messageDialogWirPartner:"",
            customID:'',
            deleteButtonDarkEnabled:false,
            deactivateDeleteButton:true,
            disabledNewProfile:false,
            activateNewProfileDark:true,
            activateMutateDark:false,
            disabledMutateButton:true,
            enableLoader:false,
            saveButtonDarkEnabled:false,
            cancelButtonDarkEnabled:false,
            deactivateSaveButton:true,
            deactivateCancelButton:true,
            backButtonFlag:true,
            forwardButtonFlag:true,
            accountNumber:'',
            accountWirNumber:'',
            accountQRNumber:'',
            accountWirQRNumber:'',
            UIDNumber:'',
            reference: '',
            firstName:'',
            currencies:[
                'CHF',
                'EUR'
            ],
            selectedCurrecy:'',
            lastName:'',
            userInputIbanWithWir:false,
            wirPartners:[],
            street:'',
            houseNumber:'',
            zipCode:'',
            city:'',
            selectedCountry:'',
            profileId:0,
            currentUser:0,
            subscriptionLimit:0,
            formsEnableFlag:true,
            profilesArray:[],
            backupProfilesArray:[],
            defaultProfile:null,
            DOMElements: {
                mutateProfiles: {
                    loading: false,
                }
            },
            ruleChar:{
                noInvalidCharacters: value =>{
                    if(validateCharac(value)){
                        return this.$t("Globals.error_character");
                    }else{
                        return true;
                    }
                }
            }
        }
    },
    async mounted(){
        //This commit  changes the title on the dashboard
        this.$store.commit("global/setPageTitle",this.$t('MasterData.main_title'));
        this.enableLoader = true;
        await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/profiles`)
            .then(async response => {
                if(response){
                    this.subscriptionLimit = response.subscriptionLimit; 
                    if(response.profiles.length > 0){
                        let getProfilesLength = response.profiles.length;
                        for (let i = 0;i < getProfilesLength;i++){
                            
                            let currentMap = {
                                id                 : response.profiles[i].id,
                                accountNumber      : response.profiles[i].iban,
                                accountQRNumber    : response.profiles[i].qr_iban,
                                accountWirNumber   : response.profiles[i].iban_wir,
                                accountWirQRNumber : response.profiles[i].qr_iban_wir,
                                UIDNumber          : response.profiles[i].uid_number,
                                reference          : response.profiles[i].reference,
                                firstName          : response.profiles[i].first_name,
                                selectedCurrecy    : response.profiles[i].currency,
                                lastName           : response.profiles[i].last_name,
                                street             : response.profiles[i].street,
                                houseNumber        : response.profiles[i].house_number,
                                zipCode            : response.profiles[i].zip_code,
                                city               : response.profiles[i].city,
                                selectedCountry    : response.profiles[i].country,
                                defaultProfile     : response.profiles[i].default,
                                customID           : response.profiles[i].custom_id,
                            };
                            this.profilesArray.push(currentMap);
                        }

                        this.backupProfilesArray = JSON.parse(JSON.stringify(this.profilesArray));
                        this.currentUser = 1;
                        this.backButtonFlag = true;
                        if(response.profiles.length <= 1) {
                            this.forwardButtonFlag = true;
                        } else {
                            this.forwardButtonFlag = false;
                        }
                        //-----Update fields to contain the first record data-------//
                        this.updateProfilesFields(0); 
                        this.enableLoader = false;                     
                    } else {
                        this.backButtonFlag = true;
                        this.forwardButtonFlag = true;
                        this.enableLoader = false;
                    }

                    this.activateMutateDark = true;
                    this.disabledMutateButton = false;
                }
        })
        .catch((err)=>{
            this.enableLoader = false;
            this.$handleCatch(err)
        })

        //get the partners that has the type wir 
        try{
            const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/partners/wir`);
            const {data} = await response.json();
            this.wirPartners = data.partners;
        }catch(err){
            console.log(err);
        }

        //validate if the iban match with some partner that has wir
        let iid = this.accountNumber?.replace(/\s+/g, '').substr(4,5); 
        this.userInputIbanWithWir = this.wirPartners.find( element => element.iid == iid );
    
    },
    methods:{
        cancelRelation(){
            
            //hiden the dialog  
            this.cancelButtonAction();    
            this.showMasterDialog = false;
    
        },
        enableForms(){
            if(this.profilesArray.length > 0){
                this.formsEnableFlag = false;
                this.deactivateSaveButton = false;
                this.saveButtonDarkEnabled = true;
                this.deactivateCancelButton = false;
                this.cancelButtonDarkEnabled = true;
                this.deactivateDeleteButton = false;
                if(this.subscriptionLimit > 1){
                    this.disabledNewProfile = false;
                    this.activateNewProfileDark = true;
                }
            }else{
                this.$store.commit('global/setSnackbar',{message:this.$t("MasterData.account_profiles")});
            }
        },
        async addProfile(){
            if(this.subscriptionLimit > this.profilesArray.length){
                this.profilesArray.push({
                    accountNumber:'',
                    accountQRNumber:'',
                    UIDNumber:'',
                    reference: '',
                    firstName:'',
                    selectedCurrecy:'',
                    lastName:'',
                    street:'',
                    houseNumber:'',
                    zipCode:'',
                    city:'',
                    selectedCountry:'',
                    defaultProfile:false,
                })
                this.currentUser = this.profilesArray.length;
                //-----------Sets the current new user in the fields----------//
                this.updateProfilesFields(this.currentUser - 1);
                this.$refs.form.validate();
                if(this.profilesArray.length == 1){
                    this.backButtonFlag = true;
                    this.forwardButtonFlag = true;                        
                }else{
                    this.backButtonFlag = false;
                    this.forwardButtonFlag = true;
                }
            }else{
                return this.$store.commit('global/setSnackbar',{message:this.$t("MasterData.number_permisible")});
            }
        },
        goPrevious(){
            if(this.currentUser > 1){
                this.updateChanges();
                let newCurrentUser = this.currentUser - 1;
                if(newCurrentUser >= 0){
                    if(newCurrentUser == 1 || newCurrentUser == 0){
                        this.backButtonFlag = true;
                        if(this.profilesArray.length > 1){
                            this.forwardButtonFlag = false;
                        }
                    }else{
                        if(this.profilesArray.length > 1){
                            this.forwardButtonFlag = false;
                        }
                    }
                    this.updateProfilesFields(newCurrentUser - 1);
                    this.$refs.form.validate();
                    this.currentUser = newCurrentUser;

                }else{
                    this.backButtonFlag = true;
                }
            }else{
                this.backButtonFlag = true;
            }
        },
        goNext(){
            if(this.currentUser < this.subscriptionLimit){
                this.updateChanges();
                let newCurrentUser = this.currentUser + 1;
                if(newCurrentUser >= this.profilesArray.length){
                    this.forwardButtonFlag = true;
                    this.backButtonFlag = false;
                }
                if(newCurrentUser <= this.profilesArray.length){
                    if(newCurrentUser == this.profilesArray.length){
                        this.forwardButtonFlag = true;
                    }
                    this.updateProfilesFields(newCurrentUser - 1);
                    this.$refs.form.validate();
                    this.currentUser = newCurrentUser;                   
                    this.backButtonFlag = false;
                }
                if(newCurrentUser >= this.subscriptionLimit){
                    this.forwardButtonFlag = true;
                }
            }else{
                this.forwardButtonFlag = true;
            }
        },
        updateChanges(){


            //validate if the iban match with some partner that has wir
            let iid = this.accountNumber?.replace(/\s+/g, '').substr(4,5); 
            this.userInputIbanWithWir = this.wirPartners.find( element => element.iid == iid );

            //we need to remove the value of these variables because the user delete de iban with match wir partner
            if(!this.userInputIbanWithWir){
                this.accountWirNumber = "";
                this.accountWirQRNumber = "";
            }

            this.profilesArray[this.currentUser - 1].accountNumber = this.accountNumber;
            this.profilesArray[this.currentUser - 1].accountQRNumber = this.accountQRNumber;
            this.profilesArray[this.currentUser - 1].accountWirNumber =    this.accountWirNumber  ;
            this.profilesArray[this.currentUser - 1].accountWirQRNumber =  this.accountWirQRNumber ;
            this.profilesArray[this.currentUser - 1].UIDNumber = this.UIDNumber;
            this.profilesArray[this.currentUser - 1].reference = this.reference;
            this.profilesArray[this.currentUser - 1].firstName = this.firstName;
            this.profilesArray[this.currentUser - 1].selectedCurrecy = this.selectedCurrecy;
            this.profilesArray[this.currentUser - 1].lastName = this.lastName;
            this.profilesArray[this.currentUser - 1].street = this.street;
            this.profilesArray[this.currentUser - 1].houseNumber = this.houseNumber;
            this.profilesArray[this.currentUser - 1].zipCode = this.zipCode;
            this.profilesArray[this.currentUser - 1].city = this.city;
            this.profilesArray[this.currentUser - 1].selectedCountry = this.selectedCountry;
            this.profilesArray[this.currentUser - 1].defaultProfile = this.defaultProfile ? true : false;
            this.profilesArray[this.currentUser - 1].customID = this.customID;        

        },
        async updateCheckbox(){
            if(this.defaultProfile){
                let findCheckMatch = await this.profilesArray.find((element)=>element.defaultProfile);
                if(findCheckMatch){
                    let getIndex = this.profilesArray.indexOf(findCheckMatch);
                    this.profilesArray[getIndex].defaultProfile = false;
                } 
            }
            this.updateChanges();
        },

        async saveProfiles(){
            if(this.formsEnableFlag == false){
                if(this.profilesArray.length > 0){
                    if(!this.$refs.form.validate()){
                        return this.$store.commit('global/setSnackbar',{message: this.$t('snack_confirm.invalid_inputs')});
                    }
                    let findDP = await this.profilesArray.find(element => element.defaultProfile == true);
                    if(!findDP){
                        return this.$store.commit('global/setSnackbar',{message:this.$t("MasterData.default_profileRule")});
                    }
                    let errorString = ``;
                    for(let i = 0;i<this.profilesArray.length;i++){
                        let validate = await this.validateProfile(i);
                        if(!validate){
                            errorString += this.$t("MasterData.invalid_data_profile") + `${i + 1}`;
                            errorString += i == this.profilesArray.length ? `` :`\n`;
                        }
                    }
                    if(errorString !== ``){
                        return this.$store.commit(`global/setSnackbar`,{message:errorString});
                    }
                    this.DOMElements.mutateProfiles.loading = true;
                    await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/profiles?lang=${this.$store.getters['User/getLanguage']}`,{
                        method:'POST',
                        credentials:'include',
                        body:JSON.stringify({
                            profiles:this.profilesArray
                        }),
                        headers:{
                            'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                        }
                    })
                    .then(async response => {
                        if(response) {
                            this.deactivateSaveButton = true;
                            this.deactivateCancelButton = true;
                            this.saveButtonDarkEnabled = false;
                            this.cancelButtonDarkEnabled = false;
                            this.deactivateDeleteButton = true;
                            this.formsEnableFlag = true;
                            this.backupProfilesArray = new Array();
                            this.profilesArray = new Array();


                            //show alert user get discount or lose
                            let messageAlertPartner = "";

                            if(response.alert.showAlertGetWir){
                                
                                //close dialog, user get wir
                                this.showMasterDialog = false;
                                //User get Discount Partner
                                messageAlertPartner = this.$t("Settings.messageAlertGetDiscountPartner");
                            
                            }else if(response.alert.showAlertLoseWir){

                                //User lose a WIR functionalities
                                 this.showMasterDialog = false;
                                 //User lose discount Partner
                                 messageAlertPartner = this.$t("Settings.messageAlertLoseDiscountPartner");

                            }else if(response.alert.showAlertGetDiscount){
                                
                                //User get Discount Partner
                                messageAlertPartner = this.$t("Settings.messageAlertGetDiscountPartner");

                            }else if(response.alert.showAlertLoseDiscount) { 
                                
                                //User lose discount Partner
                                messageAlertPartner = this.$t("Settings.messageAlertLoseDiscountPartner");

                            }

                            for(let i = 0;i<response.profiles.length;i++){
                                let currentProfile = response.profiles[i];
                                let currentMap = {
                                    id                : currentProfile.id,
                                    accountNumber     : currentProfile.iban,
                                    accountQRNumber   : currentProfile.qr_iban,
                                    accountWirNumber  : currentProfile.iban_wir,
                                    accountWirQRNumber: currentProfile.qr_iban_wir,
                                    UIDNumber         : currentProfile.uid_number,
                                    reference         : currentProfile.reference,
                                    firstName         : currentProfile.first_name,
                                    selectedCurrecy   : currentProfile.currency,
                                    lastName          : currentProfile.last_name,
                                    street            : currentProfile.street,
                                    houseNumber       : currentProfile.house_number,
                                    zipCode           : currentProfile.zip_code,
                                    city              : currentProfile.city,
                                    selectedCountry   : currentProfile.country,
                                    defaultProfile    : currentProfile.default,
                                    customID          : currentProfile.custom_id,
                                };
                                this.profilesArray.push(currentMap);
                            }

                            this.backupProfilesArray = JSON.parse(JSON.stringify(this.profilesArray));
                            await this.updateProfilesFields(this.currentUser - 1);
                            this.currentUser = this.currentUser;
                            
                            return this.$store.commit( `global/setSnackbar` , {
                                message:response.message,
                                optionalMessage:messageAlertPartner,
                                type:"dialog"
                            });
                            
                        }
                    })
                    .catch(e =>{
                        return this.$store.commit(`global/setSnackbar`,{message: e.response.message});
                    })
                    this.DOMElements.mutateProfiles.loading = false;
                }
            }else{
                return this.$store.commit(`global/setSnackbar`,{message:this.$t("MasterData.correct_saving")});
            }
        },

        async mutateProfiles(){

            //get realtion of user
            const { partners } = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/partners`);
            
            /* ---------------------------------------------GET PARTNER WIR ------------------------------------------------*/
            //check if the new iid matched with partner wir
            const defaulProfile = this.profilesArray.find(element => element.defaultProfile);
            let iid = defaulProfile.accountNumber?.replace(/\s+/g, '').substr(4,5); 
            
            let partnerWir = this.wirPartners[0];
            
            let userGetWirFunctionalities = false; 
            let userLoseWirFunctionalities = false;

            if(partnerWir){
                
                //if the partner with wir is not find in relation of partner user , the user get relation partner wir 
                if(!partners?.find( element => element.type=="Financial Institution" && element.iid == partnerWir.iid )){
                    
                    userGetWirFunctionalities =  partnerWir.iid == iid;
                    this.messageDialogWirPartner = this.$t('Settings.messageAlertGetWir');
                
                }else{ //user already has the relation with wir partner

                   //verify if user lose relation with partner
                   userLoseWirFunctionalities =  partnerWir.iid != iid;
                   this.messageDialogWirPartner = this.$t("Settings.messageAlertLoseWir"); 

                }

            }

            if(userGetWirFunctionalities){
 
                this.showMasterDialog = true;

            }else if(userLoseWirFunctionalities){
                
                this.showMasterDialog = true;

            }else{

                this.saveProfiles();
            
            }
                            
        },
        async cancelButtonAction(){
            if(this.formsEnableFlag == false){
                this.$root.$confirm(this.$t('Globals.confirm'), this.$t("MasterData.title_dialog")).then((response)=>{
                    if(response){
                        this.profilesArray = JSON.parse(JSON.stringify(this.backupProfilesArray));
                        this.formsEnableFlag = true;
                        this.updateProfilesFields(0);
                        this.currentUser = 1;
                        this.backButtonFlag = true;
                        this.forwardButtonFlag = this.profilesArray.length <= 1;
                        this.deactivateSaveButton = true;
                        this.saveButtonDarkEnabled = false;
                        this.deactivateCancelButton = true;
                        this.cancelButtonDarkEnabled = false;
                        this.deactivateDeleteButton = true;
                    }
                })
            }else{
                return this.$store.commit(`global/setSnackbar`,{message:this.$t("MasterData.correct_leaving")});
            }
        },
        updateProfilesFields(index){
            this.accountNumber   = this.profilesArray[index].accountNumber;
            this.accountQRNumber = this.profilesArray[index].accountQRNumber;
            this.accountWirNumber   = this.profilesArray[index].accountWirNumber;
            this.accountWirQRNumber = this.profilesArray[index].accountWirQRNumber;
            this.UIDNumber       = this.profilesArray[index].UIDNumber;
            this.reference       = this.profilesArray[index].reference;
            this.firstName       = this.profilesArray[index].firstName;
            this.selectedCurrecy = this.profilesArray[index].selectedCurrecy;
            this.lastName        = this.profilesArray[index].lastName;
            this.street          = this.profilesArray[index].street;
            this.houseNumber     = this.profilesArray[index].houseNumber;
            this.zipCode         = this.profilesArray[index].zipCode;
            this.city            = this.profilesArray[index].city;
            this.selectedCountry = this.profilesArray[index].selectedCountry;
            this.defaultProfile  = this.profilesArray[index].defaultProfile;
            this.profileId       = this.profilesArray[index].id;
            this.customID        = this.profilesArray[index].customID;

            //we need hidden the fields if user has not a iban wir
            let iid = this.accountNumber?.replace(/\s+/g, '').substr(4,5); 
            this.userInputIbanWithWir = this.wirPartners.find( element => element.iid == iid );

        },
        validateProfile(index){
            let validation1 = this.rules.isIBAN(this.profilesArray[index].accountNumber);
            let validation2 = this.rules.IBANLength(this.profilesArray[index].accountNumber)
            let validation3 = this.rules.isQRIBAN(this.profilesArray[index].accountQRNumber);
            let validation4 = this.rules.IBANLength(this.profilesArray[index].accountQRNumber);
            let validation5 = this.rules.hasFirstName(this.profilesArray[index].firstName);
            let validation6 = this.rules.firstNameLength(this.profilesArray[index].firstName);
            let validation7 = this.rules.hasLastName(this.profilesArray[index].lastName);
            let validation8 = this.rules.LastNameLength(this.profilesArray[index].lastName);

            if(validateCharac(this.firstName) || validateCharac(this.lastName) ||  validateCharac(this.street) || validateCharac(this.city) || 
               validateCharac(this.customID) || validateCharac(this.reference) ){
                return true;
            }

            if(validation1 === true && validation2 === true && validation3 === true && validation4 === true && validation5 === true && validation6 === true){
                return true;
            }else{
                return false;
            }
        },
        deleteProfile(){
            if(this.profilesArray.length == 1){
                return this.$store.commit(`global/setSnackbar`,{message:this.$t("MasterData.profile_exist")});
            }
            this.$root.$confirm(this.$t("Settings.confirm_title"),this.$t("MasterData.ask_delete"),{color:'red'}).then((response)=>{
                if(response){
                    if(!this.profileId){
                        this.profilesArray.splice(this.currentUser -1, 1);
                        this.currentUser = 1;
                        this.backButtonFlag = true;
                        if(this.profilesArray.length > 1){
                            this.forwardButtonFlag = false;
                        }else{
                            this.forwardButtonFlag = false;
                        }
                        this.updateProfilesFields(0);
                        return 
                    }else{
                        this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/profiles`,{
                            method: 'DELETE',
                            credentials: 'include',
                            body:JSON.stringify({
                                profile:this.profileId
                            }),
                            headers:{
                                'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                            }
                        })
                        .then(async response => {
                            if(response) {
                                await this.backupProfilesArray.splice(this.currentUser -1, 1);
                                await this.profilesArray.splice(this.currentUser -1, 1);
                                await this.updateProfilesFields(0);
                                this.currentUser = 1;
                                this.forwardButtonFlag = false;
                                this.backButtonFlag = true;
                            }
                        })
                        .catch(this.$handleCatch)
                    }
                }
            })
        }

    },
}
</script>

<style>

</style>