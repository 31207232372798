<template>
  <div>
    <v-card class="mt-5">
      <v-card-title>
        <v-row>
          <v-col cols="8">
            <v-text-field
              append-icon="mdi-magnify"
              :label="$t('CreditorFiles.search')"
              v-model="seachItems"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="d-flex justify-end align-end">
            <v-overflow-btn
              segmented
              overwflow
              dense
              :label="$t('TemplateList.actions')"
              class="mb-2"
              :items="headers.actionButtomItems"
              item-text="text"
            ></v-overflow-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          v-model="selectedTemplates"
          :headers="headers.headers"
          show-select
          :items="items"
          :search="seachItems"
          :loading="isTableLoading"
        >
          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-icon @click="showTemplate(item)"> mdi-file-eye </v-icon>
              <v-icon @click="editTemplate(item)">
                mdi-file-edit-outline
              </v-icon>
            </v-row>
          </template>
          <template v-slot:item.default="{ item }">
            <v-icon color="green" v-if="item.default">mdi-check</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- NEW TEMPLATE DIALOG -->
    <v-dialog v-if="dialogs.new" v-model="dialogs.new" persistent scrollable>
      <v-card>
        <v-card-title>{{ $t("TemplateList.new_template") }}</v-card-title>
        <v-card-text>
          <editor-template showDummyItems ref="newTemplate" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            :color="$vuetify.theme.themes.light.blue.primary"
            @click="dialogs.new = false"
            >{{ $t("TemplateList.create_template.cancel") }}</v-btn
          >
          <v-btn
            dark
            :color="$vuetify.theme.themes.light.blue.primary"
            @click="saveTemplate"
            :loading="DOMElments.new.loading"
            >{{ $t("TemplateList.create_template.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PREVIEW DIALOG -->
    <v-dialog
      v-if="dialogs.preview"
      v-model="dialogs.preview"
      persistent
      scrollable
      width="600"
    >
      <v-card>
        <v-card-title
          >{{ $t("TemplateList.create_template.preview_template") }}
          {{ templateName }}</v-card-title
        >
        <v-card-text>
          <viewer-template
            v-if="idTemplate"
            showDummyItems
            :idTemplate="idTemplate"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            :color="$vuetify.theme.themes.light.blue.primary"
            @click="dialogs.preview = false"
            >{{ $t("Globals.btn_close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EDIT TEMPLATE DIALOG -->
    <v-dialog v-if="dialogs.edit" v-model="dialogs.edit" persistent scrollable>
      <v-card>
        <v-card-title
          >{{ $t("TemplateList.create_template.edit_template") }} {{ templateName }}</v-card-title
        >
        <v-card-text>
          <editor-template
            v-if="idTemplate"
            ref="editorTemplate"
            showDummyItems
            :idTemplate="idTemplate"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            :color="$vuetify.theme.themes.light.blue.primary"
            @click="dialogs.edit = false"
            >{{ $t("Globals.cancel") }}</v-btn
          >
          <v-btn
            dark
            :color="$vuetify.theme.themes.light.blue.primary"
            @click="updateTemplate()"
            :loading="DOMElments.edit.loading"
            >{{ $t("TemplateList.create_template.update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getBase64String } from "@/mixins/functions";
import editorTemplateVue from "../editor/editor.vue";
import viewerTemplate from "../editor/editor.template.vue";
export default {
  components: {
    "editor-template": editorTemplateVue,
    "viewer-template": viewerTemplate,
  },
  data() {
    return {
      selectedTemplates: [],
      items: [],
      dialogs: {
        edit: false,
        preview: false,
        delete: false,
        new: false,
      },
      idTemplate: null,
      templateName: "",
      seachItems: "",
      isTableLoading: false,
      DOMElments: {
        new: {
          loading: false,
        },
        edit: {
          loading: false,
        },
      },
    };
  },
  async mounted() {
    this.loadTemplates();
  },
  methods: {
    async loadTemplates() {
      this.isTableLoading = true;
      this.items = [];
      const { templates } = await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/getTemplates`
      );
      if (templates) {
        for (let i = 0; i < templates.length; i++) {
          let item = templates[i];
          this.items.push({
            id_b: i + 1,
            id: item.id,
            creation_date: this.fromDateToString(item.creation_date),
            last_modified_date: this.fromDateToString(item.last_modified_date),
            template_name: item.template_name,
            default: item.default,
          });
        }
      }
      this.isTableLoading = false;
    },
    editTemplate(item) {
      let currentId = item.id;
      let currentTN = item.template_name;
      let currentDefault = item.default;
      this.dialogs.edit = true;
      this.$nextTick(() => {
        this.idTemplate = currentId;
        this.templateName = currentTN;
        this.isDefault = currentDefault;
      });
    },
    newTemplate() {
      this.dialogs.new = true;
    },
    async saveTemplate() {
      const {
        font,
        biller,
        centerSection,
        commentsSection,
        paymentDetails,
        logo,
        template_name,
        hiddeProducts,
      } = this.$store.getters["templateEditor/fields"];

    console.log('this.$store.getters["templateEditor/fields"]', this.$store.getters["templateEditor/fields"])
      const logoInfo = this.validateTemplateLogo();
      if (typeof logoInfo === "string") {
        return this.$store.commit("global/setSnackbar", logoInfo);
      }

      if (!this.$refs.newTemplate.$refs.controlsForm.validate()) {
        return this.$store.commit(
          "global/setSnackbar",
          this.$t("snack_confirm.invalid_inputs")
        );
      }

      this.DOMElments.new.loading = true;

      const data = {
        font: font ? font : "ArialNarrow",
        logo: {
          height: this.logoData.imageHeight,
          width: this.logoData.imageWidth,
          ranger: logo.ranger,
          positionX: logo.positionX,
          positionY: logo.positionY,
        },
        customerNotes: centerSection.customerNotes.content,
        contactDetails: biller.contactDetails.content,
        subject: centerSection.subject.content,
        closing: commentsSection.textLine.content,
        greeting: centerSection.textLine.content,
        hiddeProducts: hiddeProducts.value,
      };
      try {
        const logoBase64String = await getBase64String(logo.url);
        let _logo = null;
        if (logoBase64String) {
          _logo = logoBase64String.split(";base64,")[1];
        }

        this.setLoadingStateSaveDebtorList = true;
        const result = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/saveTemplate`,
          {
            method: "POST",
            body: JSON.stringify({
              default: this.isDefault,
              data: data,
              logo: _logo,
              name: logo.url?.name,
              templateName: template_name,
            }),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        );
        this.loadTemplates();
        this.dialogs.new = false;
        this.DOMElments.new.loading = false;
        this.setLoadingStateSaveDebtorList = false;
        if (result) {
          return this.$store.commit(`global/setSnackbar`, {
            message: result.message,
          });
        } else {
          return this.$store.commit(`global/setSnackbar`, {
            message: this.$t("StepByStep.invalid_response"),
          });
        }
      } catch (err) {
        this.DOMElments.new.loading = false;
        this.setLoadingStateSaveDebtorList = false;
        if (err.response) {
          return this.$store.commit(`global/setSnackbar`, {
            message: err.response.message,
          });
        } else {
          this.$handleCatch(err);
        }
      }
    },
    showTemplate({ id, template_name }) {
      this.dialogs.preview = true;
      this.idTemplate = id;
      this.templateName = template_name;
    },

    async deleteTemplates() {
      if (!this.selectedTemplates.length) {
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("TemplateList.select_template"),
        });
      }
      this.$root
        .$confirm(
          this.$t("Settings.confirm_title"),
          this.$t("TemplateList.delete_templates")
        )
        .then(async (deleteTemplate) => {
          if (deleteTemplate) {
            let templateIds = [];
            for (let i = 0; i < this.selectedTemplates.length; i++) {
              templateIds.push(this.selectedTemplates[i].id);
            }

            await this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/destroyTemplates`,
              {
                method: "DELETE",
                credentials: "include",
                body: templateIds,
                headers: {
                  "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                },
              }
            )
              .then((response) => {
                for (let i = 0; i < this.selectedTemplates.length; i++) {
                  this.items.splice(
                    this.items.indexOf(this.selectedTemplates[i]),
                    1
                  );
                }
                return this.$store.commit("global/setSnackbar", {
                  message: response.message,
                });
              })
              .catch((error) => {
                this.$store.commit("global/setSnackbar", error.toString());
              });

            this.loadTemplates();
          }
        });
    },

    async updateTemplate() {
      const {
        font,
        biller,
        centerSection,
        commentsSection,
        paymentDetails,
        logo,
        template_name,
        hiddeProducts,
      } = this.$store.getters["templateEditor/fields"];

      const logoInfo = this.validateTemplateLogo();
      if (typeof logoInfo === "string") {
        return this.$store.commit("global/setSnackbar", logoInfo);
      }

      if (!this.$refs.editorTemplate.$refs.controlsForm.validate()) {
        return this.$store.commit(
          "global/setSnackbar",
          this.$t("snack_confirm.invalid_inputs")
        );
      }

      this.DOMElments.edit.loading = true;
      const data = {
        font: font ? font : "ArialNarrow",
        logo: {
          height: this.logoData.imageHeight,
          width: this.logoData.imageWidth,
          ranger: logo.ranger,
          positionX: logo.positionX,
          positionY: logo.positionY,
        },
        customerNotes: centerSection.customerNotes.content,
        contactDetails: biller.contactDetails.content,
        subject: centerSection.subject.content,
        closing: commentsSection.textLine.content,
        greeting: centerSection.textLine.content,
        hiddeProducts: hiddeProducts.value,
      };

      try {
        let logoBase64String = null;
        if (logo.url instanceof File) {
          logoBase64String = await getBase64String(logo.url);
        }
        let _logo = null;

        if (logoBase64String) {
          _logo = logoBase64String.split(";base64,")[1];
        }

        this.setLoadingStateSaveDebtorList = true;
        const result = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/mutateTemplate`,
          {
            method: "POST",
            body: JSON.stringify({
              default: this.isDefault,
              data: data,
              logo: _logo || logo.url,
              name: logo.url?.name,
              templateName: template_name,
              templateId: this.idTemplate,
            }),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        );

        this.loadTemplates();
        this.dialogs.edit = false;
        this.DOMElments.edit.loading = false;
        this.setLoadingStateSaveDebtorList = false;
        if (result) {
          return this.$store.commit(`global/setSnackbar`, {
            message: result.message,
          });
        } else {
          return this.$store.commit(`global/setSnackbar`, {
            message: this.$t("StepByStep.invalid_response"),
          });
        }
      } catch (err) {
        this.setLoadingStateSaveDebtorList = false;
        this.DOMElments.edit.loading = false;
        if (err.response) {
          return this.$store.commit(`global/setSnackbar`, {
            message: err.response.message,
          });
        } else {
          this.$handleCatch(err);
        }
      }
    },

    fromDateToString(date) {
      if (!date) return "";
      let newDate = new Date(date);
      if (!newDate) return "";
      let newDay = `${newDate.getDate()}`.padStart(2, "0");
      let newMonth = `${newDate.getMonth() + 1}`.padStart(2, "0");
      let newYear = `${newDate.getFullYear()}`.padStart(2, "0");
      let newHour = `${newDate.getHours()}`.padStart(2, "0");
      let newMinutes = `${newDate.getMinutes()}`.padStart(2, "0");
      return `${newYear}/${newMonth}/${newDay} ${newHour}:${newMinutes}`;
    },
    customSortDebtors(items, index, isDesc) {
      for (let i = 0; i < index.length; i++) {
        let currentIndex = index[i];
        let currentIsDesc = isDesc[i];
        items.sort((a, b) => {
          if (
            currentIndex === "last_modified_date" ||
            currentIsDesc === "creation_date"
          ) {
            if (currentIsDesc)
              return (
                new Date(`${b[currentIndex]} UTC`) -
                new Date(`${a[currentIndex]} UTC`)
              );
            return (
              new Date(`${a[currentIndex]} UTC`) -
              new Date(`${b[currentIndex]} UTC`)
            );
          } else if (currentIndex == "id") {
            if (currentIsDesc) return b[currentIndex] - a[currentIndex];
            return a[currentIndex] - b[currentIndex];
          } else {
            let aElement = a[currentIndex] ? a[currentIndex] : "";
            let bElement = b[currentIndex] ? b[currentIndex] : "";
            if (currentIsDesc)
              return aElement
                .toLowerCase()
                .localeCompare(bElement.toLowerCase());
            return bElement.toLowerCase().localeCompare(aElement.toLowerCase());
          }
        });
      }
      return items;
    },
    validateTemplateLogo() {
      const { imageWidth, maxWidth, imageHeight, maxHeight, xDiff, yDiff } =
        this.logoData;

      if (imageWidth > maxWidth) {
        return this.$t("TemplateList.image_width");
      }

      if (imageHeight > maxHeight) {
        return this.$t("TemplateList.image_heigth");
      }

      if (xDiff < 0) {
        return this.$t("TemplateList.position_x");
      }

      if (yDiff < 0) {
        return this.$t("TemplateList.position_y");
      }

      return true;
    },
  },
  computed: {
    headers() {
      return {
        headers: [
          {
            text: "Id",
            align: "start",
            sortable: false,
            value: "id_b",
            width: "1%",
          },
          {
            text: this.$t("TemplateList.template_name"),
            value: "template_name",
          },
          {
            text: this.$t("TemplateList.creation_date"),
            value: "creation_date",
          },
          {
            text: this.$t("TemplateList.last_modified"),
            value: "last_modified_date",
          },
          {
            text: "Default",
            value: "default",
            sortable: false,
          },
          {
            text: this.$t("TemplateList.actions"),
            value: "actions",
            sortable: false,
          },
        ],
        actionButtomItems: [
          {
            text: this.$t("TemplateList.new_template"),
            callback: () => this.newTemplate(),
          },
          {
            text: this.$t("TemplateList.delete_template"),
            callback: () => this.deleteTemplates(),
          },
        ],
      };
    },
    isDefault: {
      get() {
        return this.$store.state.templateEditor.isDefault;
      },
      set(value) {
        this.$store.state.templateEditor.isDefault = value;
      },
    },
    logoData() {
      return this.$store.state.templateEditor.logoData;
    },
  },
};
</script>

<style>
</style>