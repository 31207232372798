<template>
  <!-- Componente que muestra todos los debtors que el usuario tiene en su cuenta -->
  <!--Component that shows all the debtors that the user has in their account -->
  <div>
    <br />  
    <v-card flat>
      <v-card-text class="pa-0 ma-0">
        <debtor-table ref="debtorTable">
          <template class="mt-0" v-slot:actionSlot>
            <v-overflow-btn
              segmented
              overflow
              dense
              :label="$t('CreditorFiles.actions')"
              :items="actionButtonItems.actionButtonItems"
              item-text="text"
              :loading="enableActionBtnLoading"
              :disabled="disabledActionBtn"
            ></v-overflow-btn>
          </template>
        </debtor-table>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="showReferenceGeneratorDialog" width="500px">
      <v-card width="500px">
        <v-card-title>{{
          $t("Debtors.OverFlowButtons.reference_generator")
        }}</v-card-title>
        <v-card-text>
          <debtor-reference ref="debtorsReference" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            :color="$vuetify.theme.themes.light.blue.primary"
            @click="showReferenceGeneratorDialog = false"
            >{{ $t("Debtors.btn_cancel") }}</v-btn
          >
          <v-btn
            :loading="loadingSaveReference"
            :color="$vuetify.theme.themes.light.blue.primary"
            @click="saveReference"
            dark
            >{{ $t("Debtors.btn_save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <debtor-import ref="debtorImport" />
    <debtor-import-file ref="debtorImportFile" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import debtorsReference from "./debtors.reference.vue";
import debtorsTable from "./debtors.table.vue";
import debtorsImport from "./debtors.import.vue";
import debtorsImportFile from "./debtors.importFile.vue";
export default {
  components: {
    "debtor-reference": debtorsReference,
    "debtor-table": debtorsTable,
    "debtor-import": debtorsImport,
    "debtor-import-file": debtorsImportFile,
  },
  name: "debitors",
  data() {
    return {
      showLoadingStateDownload: false,
      showReferenceGeneratorDialog: false,
      importFile: null,
      showImportFileDialogFlag: false,
      disabledActionBtn: false,
      enableActionBtnLoading: false,
      rules: {
        isEmpty: (v) => {
          return !!v || this.$t("rules.required");
        },
        isType: (v) => {
          if (v) {
            let MIMETypesArrays = [
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "text/csv",
            ];
            let matchedMIME = MIMETypesArrays.find(
              (element) => element === v.type
            );
            if (matchedMIME) return true;
            return this.$t("Debtors.file_type");
          } else {
            return true;
          }
        },
      },
      //Array to store each of the debitors later
      debitors: [],
      loadingSaveReference: false,
    };
  },
  watch: {
    locale(newValue, oldValue) {
      this.$store.commit(
        "global/setPageTitle",
        this.$t("Debtors.main_title_green")
      );
    },
  },
  computed: {
    locale() {
      return this.$store.state.User.language;
    },
    actionButtonItems() {
      return {
        actionButtonItems: [
          {
            text: this.$t("Debtors.OverFlowButtons.adddebtor"),
            callback: () => this.$refs.debtorTable.showDialog(),
          },
          {
            text: this.$t("Debtors.OverFlowButtons.download"),
            callback: () =>
              (this.$refs.debtorImport.showGetImportFileDialog = true),
          },
          {
            text: this.$t("Debtors.OverFlowButtons.import_list"),
            callback: () =>
              (this.$refs.debtorImportFile.showImportFileDialogFlag = true),
          },
          {
            text: this.$t("Debtors.OverFlowButtons.export_list"),
            callback: () => this.exportDebtorList(),
          },
          {
            text: this.$t("Debtors.OverFlowButtons.duplicate_debtor"),
            callback: () => this.duplicateDebtors(),
          },
          {
            text: this.$t("Debtors.OverFlowButtons.delete_selected"),
            callback: () => this.deleteSelectedDebtors(),
          },
          {
            text: this.$t("Debtors.OverFlowButtons.delete_all"),
            callback: () => this.deleteAllDebtors(),
          },
          {
            text: this.$t("Debtors.OverFlowButtons.reference_generator"),
            callback: () => (this.showReferenceGeneratorDialog = true),
          },
        ],
      };
    },
    ...mapGetters({
      creditor: "User/creditor",
    }),
    reference: {
      get() {
        return this.$store.state.QR.reference;
      },
      set(value) {
        this.$store.sate.QR.reference = value;
      },
    },
    debtors: {
      get() {
        return [...this.$store.state.Debtor.debtors];
      },
      set(value) {
        return (this.$store.state.Debtor.debtors = [...value]);
      },
    },
    selected: {
      get() {
        return [...this.$store.state.Debtor.debtors_selected];
      },
      set(value) {
        return (this.$store.state.Debtor.debtors_selected = [...value]);
      },
    },
  },
  //Vue method, at that point Vue has already loaded all the component options and therefore the data section and methods already exist.
  // Here you can make calls to variables and you can execute methods.
  mounted() {
    //This commit  changes the title on the dashboard
    this.$store.commit(
      "global/setPageTitle",
      this.$t("Debtors.main_title_green")
    );
  },
  methods: {
    //Method to remove the debit that a trash icon is selected
    async exportDebtorList() {
      this.disabledActionBtn = true;
      this.enableActionBtnLoading = true;
      try {
        let result = await fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/user/debtors/export?lang=${this.$i18n.locale}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (result) {
          let response = await result.json();
          if (response.status != 200) {
            this.enableActionBtnLoading = true;
            this.disabledActionBtn = false;
            return this.$store.commit(`global/setSnackbar`, {
              message: response.message,
            });
          }
          let base64Complete = `data:text/csv;base64,${response.data}`;
          fetch(base64Complete)
            .then((resultFile) => resultFile.blob())
            .then((responseFile) => {
              let url = window.URL.createObjectURL(responseFile);
              let a = document.createElement("a");
              a.href = url;
              a.download = `Debtors.csv`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
              this.enableActionBtnLoading = false;
              this.disabledActionBtn = false;
              return this.$store.commit(
                `global/setSnackbar`,
                this.$t("snack_confirm.success")
              );
            })
            .catch((err) => {
              return this.$store.commit(`global/setSnackbar`, { message: err });
            });
        } else {
          return this.$store.commit(`global/setSnackbar`, {
            message: this.$t("StepByStep.invalid_response"),
          });
        }
      } catch (err) {
        this.enableActionBtnLoading = false;
        this.disabledActionBtn = false;
        return this.$store.commit(`global/setSnackbar`, { message: err });
      }
    },

    async duplicateDebtors() {
      // If we dont have a value selected, send a message that said: "Select at least one debtor from the list"
      if (this.$refs.debtorTable.selected.length <= 0) {
        return this.$store.commit(
          `global/setSnackbar`,
          this.$t("Debtors.select_one")
        );
      }
      //Create a list of the selected records with all the values of each one
      let debtorsDuplicateList = this.$refs.debtorTable.selected.map(
        (element) => element.id_b
      );

      // Show a confirm message if you want duplicate the selected records
      this.$root
        .$confirm(
          this.$t("StepByStep.confirm_title"),
          this.$t("Debtors.duplicate_selected"),
          { color: "blue" }
        )
        .then(async (confirmation) => {
          // If you confirm
          if (confirmation) {
            this.enableActionBtnLoading = true;
            this.disabledActionBtn = true;
            try {
              // Call BackEnd EndPoint
              let response = await this.$fetch(
                `${process.env.VUE_APP_SERVER_URL}/api/user/debtors/duplicate`,
                {
                  method: "POST",
                  // We tell to the backend to bring the object debtors from debtorsDuplicateList
                  body: JSON.stringify({ debtors: debtorsDuplicateList }),
                  headers: {
                    "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                  },
                }
              );
              // If the petition was realized with success
              if (response) {
                if (response.status == 200) {
                  await this.$refs.debtorTable.loadDebtorsList();
                }
                this.enableActionBtnLoading = false;
                this.disabledActionBtn = false;
                this.$store.commit("global/setSnackbar", {
                  message: response.message,
                });
              } else {
                this.enableActionBtnLoading = false;
                this.disabledActionBtn = false;
                this.$store.commit(
                  `global/setSnackbar`,
                  this.$t("CreditorFiles.unable_server")
                );
              }
            } catch (err) {
              this.enableActionBtnLoading = false;
              this.disabledActionBtn = false;
              this.$store.commit(`global/setSnackbar`, { message: err });
            }
            return (this.$refs.debtorTable.selected = new Array());
          }
        });
    },

    async deleteSelectedDebtors() {
      if (this.$refs.debtorTable.selected.length <= 0) {
        return this.$store.commit(
          `global/setSnackbar`,
          this.$t("Debtors.select_one")
        );
      }
      let debtorsDeleteList = await Promise.all(
        this.$refs.debtorTable.selected.map((element) => element.id_b)
      );
      this.$root
        .$confirm(
          this.$t("StepByStep.confirm_title"),
          this.$t("Debtors.delete_selected"),
          { color: "red" }
        )
        .then(async (confirmation) => {
          if (confirmation) {
            this.enableActionBtnLoading = true;
            this.disabledActionBtn = true;
            try {
              let response = await this.$fetch(
                `${process.env.VUE_APP_SERVER_URL}/api/user/debtors`,
                {
                  method: "DELETE",
                  body: JSON.stringify(debtorsDeleteList),
                  headers: {
                    "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                  },
                }
              );
              if (response) {
                if (response.status == 200) {
                  await this.$refs.debtorTable.loadDebtorsList();
                }
                this.enableActionBtnLoading = false;
                this.disabledActionBtn = false;
                this.$store.commit("global/setSnackbar", {
                  message: response.message,
                });
              } else {
                this.enableActionBtnLoading = false;
                this.disabledActionBtn = false;
                this.$store.commit(
                  `global/setSnackbar`,
                  this.$t("CreditorFiles.unable_server")
                );
              }
            } catch (err) {
              this.enableActionBtnLoading = false;
              this.disabledActionBtn = false;
              this.$store.commit(`global/setSnackbar`, { message: err });
            }
            return (this.$refs.debtorTable.selected = new Array());
          }
        });
    },

    async deleteAllDebtors() {
      // If we dont have any data on our table, we send a message that he/she cant to this action
      if (this.$refs.debtorTable.debtors.length <= 0) {
        return this.$store.commit(
          `global/setSnackbar`,
          this.$t("Debtors.noDebtor")
        );
      }
      // Get all de debtors
      let debtorsDeleteList = await Promise.all(
        this.$refs.debtorTable.debtors.map((element) => element.id_b)
      );
      // Send a confirm message if he/she want to continue
      this.$root
        .$confirm(
          this.$t("StepByStep.confirm_title"),
          this.$t("Debtors.delete_all_warning"),
          { color: "red" }
        )
        // If he/she confirm
        .then(async (confirmation) => {
          if (confirmation) {
            this.enableActionBtnLoading = true;
            this.disabledActionBtn = true;
            try {
              // Call the backend method
              let response = await this.$fetch(
                `${process.env.VUE_APP_SERVER_URL}/api/user/debtors`,
                {
                  method: "DELETE",
                  body: JSON.stringify(debtorsDeleteList),
                  headers: {
                    "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                  },
                }
              );
              // If it works, refresh the table
              if (response) {
                if (response.status == 200) {
                  await this.$refs.debtorTable.loadDebtorsList();
                }
                this.enableActionBtnLoading = false;
                this.disabledActionBtn = false;
                this.$store.commit("global/setSnackbar", {
                  message: response.message,
                });
              } else {
                this.enableActionBtnLoading = false;
                this.disabledActionBtn = false;
                this.$store.commit(
                  `global/setSnackbar`,
                  this.$t("CreditorFiles.unable_server")
                );
              }
            } catch (err) {
              this.enableActionBtnLoading = false;
              this.disabledActionBtn = false;
              this.$store.commit(`global/setSnackbar`, { message: err });
            }
            return (this.$refs.debtorTable.selected = new Array());
          }
        });
    },

    async saveReference() {
      const componentRef = this.$refs.debtorsReference;
      const { getReference } = componentRef;
      const { date, id } = getReference();
      if (this.$refs.debtorTable.selected.length > 0) {
        this.loadingSaveReference = true;
        this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/user/debtors/update-reference`,
          {
            method: "POST",
            body: JSON.stringify({
              debtors: this.$refs.debtorTable.selected,
              date,
              id,
              typeReference: this.creditor.typeReference,
            }),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        )
          .then(async ({ message }) => {
            this.$store.commit("global/setSnackbar", message);
            this.loadingSaveReference = false;
            await this.$refs.debtorTable.loadDebtorsList();
            this.showReferenceGeneratorDialog = false;
          })
          .catch((err) => {
            this.loadingSaveReference = false;
            return this.$handleCatch(err);
          });
      } else {
        this.loadingSaveReference = false;
        this.$store.commit("global/setSnackbar", this.$t("Debtors.select_one"));
      }
    },
    async updateDebtorList() {
      await this.$refs.debtorTable.loadDebtorsList();
    },
  },
};
</script>
