
<!-- Componente que muestra todos los archivos que tiene la cuenta del usuario con sesion activa -->
<!-- Component that shows all the files that the user account with active session has -->
<template>
  <div>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('CreditorFiles.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <v-overflow-btn
          segmented
          dense
          :label="$t('CreditorFiles.actions')"
          :items="headers.filesActions"
          item-text="text"
          flat
          :loading="filesActionsLoader"
          :disabled="filesActionsDisabled"
          ></v-overflow-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-0 ma-0">
      <v-data-table
        :no-data-text="$t('qrGenerator.productsTable.data_available')"
        v-model="selectedFiles"
        show-select
        :headers="headers.headers"
        :items="files2"
        :search="search"
        :loading="loadingState"
        :loading-text="$t('CreditorFiles.loading')"
        dense
        calculate-widths
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
        }">
        <template v-slot:item.actions="{ item }">
          <v-icon class="mb-2" @click="pdfItem(item)" :disabled="pdfIconDisabled">mdi-file-pdf</v-icon>
          <v-icon class="mb-2" @click="pngItem(item)" :disabled="pngIconDisabled">mdi-file-image</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  </div>
</template>
<script>
// Library used to convert PDF to PNG
const pdf = require('pdfjs-dist');
export default {
  name: "filesqrmassive",
  data: function()  {
    return{
      selectedFiles:[],
      filesActionsLoader:false,
      filesActionsDisabled:false,
      pdfIconDisabled:false,
      pngIconDisabled:false,
      // Variable that is used to filter by any field in the table
      search: "",
      //Variable not used
      dialog: false,
      // Variable that is used for loading mode
      loadingState: true,
      //Array for the files
      files: [],
      //Array for the files
      files2: [],
      editedIndex: -1,
      editedIndex2: -1,
      //Array to edit some item
      editedItem: {
        name: "",
        date: 0,
        id_b: 0
      },
      //Array to define a default
      defaultItem: {
        name: "",
        date: 0
      }
    };
  },
  watch:{
    locale(newValue,oldValue){
      this.$store.commit("global/setPageTitle",this.$t('CreditorFiles.creditor_title'))
    }
  },
  computed:{
    locale(){
      return this.$store.state.User.language;
    },
    headers(){
      return{
        filesActions:[
          {text:this.$t('CreditorFiles.download_multiple'),callback:()=>this.downloadMultipleFiles()}
        ],
          // Title text for each column in the table
        headers: [
          { text: "Id", align: "start", value: "id", width: "1%" },
          { text: this.$t("CreditorFiles.creation_date"), value: "date",width:140 },
          { text: this.$t("CreditorFiles.download"), value: "actions", sortable: false },
          { text: this.$t("CreditorFiles.account"), value: "c_account",width:200 },
          { text: this.$t("CreditorFiles.file_name"), value: "name" },
          { text: this.$t("CreditorFiles.debtor_name"), value: "d_name" },
          { text: this.$t("CreditorFiles.debtor_lname"), value: "d_last_name" },
          { text: this.$t("CreditorFiles.debtor_street"), value: "d_street" },
          { text: this.$t("CreditorFiles.debtor_number"), value: "d_number" },
          { text: this.$t("CreditorFiles.debtor_zip"), value: "d_zip" },
          { text: this.$t("CreditorFiles.debtor_city"), value: "d_city" },
          { text: this.$t("CreditorFiles.debtor_country"), value: "d_country" },
          { text: this.$t("CreditorFiles.bill_number"), value: "bn" },
          { text: this.$t("CreditorFiles.bill_date"), value: "bd" },
          { text: this.$t("CreditorFiles.message"), value: "um" },
          { text: this.$t("CreditorFiles.creditor_name"), value: "c_name" },
          { text: this.$t("CreditorFiles.creditor_lname"), value: "c_last_name" },
          { text: this.$t("CreditorFiles.creditor_street"), value: "c_street" },
          { text: this.$t("CreditorFiles.creditor_number"), value: "c_number" },
          { text: this.$t("CreditorFiles.creditor_zip"), value: "c_zip" },
          { text: this.$t("CreditorFiles.creditor_city"), value: "c_city" },
          { text: this.$t("CreditorFiles.creditor_country"), value: "c_country" },
        ],
        }
    }
  },

  //Vue method, at that point Vue has already loaded all the component options and therefore the data section and methods already exist.
  // Here you can make calls to variables and you can execute methods.
  created() {
    this.$store.commit("global/setPageTitle",this.$t('CreditorFiles.creditor_title'))
    //We call the following method
    this.initialize();
  },
  methods: {
    //Method that captures all the files that the user has in credits (account)
    async initialize() {
      //The following fetch is done which sent the information to the server
      await fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/files?lang=${this.$store.getters['User/getLanguage']}`, {
        method: "GET",
        credentials: "include"
      })
        .then(result => {
          // We verify that the status returned by the server is 200
          // since this implies that the answer received is correct
          if(result.status == 401) {
              return this.$router.push({name:'Login'});
          }
          return result.json();
        })
        .then(response => {
          // we check the data that is sent in the server response in the variable
          // response
          // Now we verify that the response variable is not null or undefined
          if (response) {
            if(response.status != 200){
              return this.$store.commit(`global/setSnackbar`,{message:response.message});
            }
            // we verify that the files object in the response variable is not undefined or null
            if (response.files !== undefined && response.files !== null) {
              // If it fulfills the two if above, then a forerach is done, which goes through
              // all the fields of e
              response.files.forEach(element => {            
                // We create a variable to store an object
                let objeto = new Object();
                // variable for the new date
                //// Variable in which to save the file name
                let fileName = "";
                //// We verify that the path of the pdf is not null, undefined or empty
                if (
                  element.path_file_pdf !== null &&
                  element.path_file_pdf !== undefined &&
                  element.path_file_pdf !== "") {
                  // If it meets the if, then we get the file name by reducing the
                  // path that is received with the substring method
                  fileName = element.path_file_pdf;
                }
                // Now we are putting in the object each parameter obtained by the cycle in its respective
                // property on the object
                objeto["id"] = element.id;
                objeto["date"] = this.fromDateToString(element.creation_date) || "";
                objeto["name"] = fileName || '';
                objeto["path_pdf"] = element.path_file_pdf || "";
                objeto["path_png"] = element.path_file_png || "";
                if (
                  element.id_reference !== null &&
                  element.id_reference !== undefined
                ) {
                  let elementRef = element.id_reference;
                  let fileName = element.custom_name;
                  objeto["name"] = !!fileName ? fileName : `${new Date(element.creation_date).getFullYear()}_${("0" + (new Date(element.creation_date).getMonth() + 1)).slice(-2)}_${("0" + new Date(element.creation_date).getDate()).slice(-2)}${!!elementRef.last_name_debitor ? `_${elementRef.last_name_debitor}` : ''}${!!elementRef.name_debitor ? `_${elementRef.name_debitor}` : ''}` || "";
                  objeto["c_account"] = elementRef.account_creditor || "";
                  objeto["c_name"] = elementRef.name_creditor || "";
                  objeto["c_last_name"] = elementRef.last_name_creditor || "";
                  objeto["c_street"] = elementRef.street_creditor || "";
                  objeto["c_number"] = elementRef.number_creditor || "";
                  objeto["c_zip"] = elementRef.zip_creditor;
                  objeto["c_city"] = elementRef.city_creditor || "";
                  objeto["c_country"] = elementRef.country_creditor || "";
                  // We eliminate the spaces in the reference to avoid conflicts when
                  // send it to the server
                  objeto["ref"] = elementRef.reference?.replace(/\s+/g, "") || "";
                  //Se continuan almacenando los elementos en el arreglo llamdo objeto
                  objeto["d_name"] = elementRef.name_debitor || "";
                  objeto["d_last_name"] = elementRef.last_name_debitor || "";
                  objeto["d_street"] = elementRef.street_debitor || "";
                  objeto["d_number"] = elementRef.number_debitor || "";
                  objeto["d_zip"] = elementRef.zip_debitor;
                  objeto["d_city"] = elementRef.city_debitor || "";
                  objeto["d_country"] = elementRef.country_debitor || "";
                  // Now follows the bill information
                  objeto["iexp"] = elementRef.invoiceExpeditionDate || "";
                  objeto["iexp2"] = elementRef.invoiceExpirationDate || "";

                  objeto["bn"] = elementRef.billNumber;
                  objeto["bd"] = elementRef.billDate || "";
                  objeto["cr"] = elementRef.custumerReference || "";
                  objeto["un"] = elementRef.uidNumber?.replace(/\s+/g, "") || "";
                  objeto["vdsd"] = elementRef.vDMswtStartDate || "";
                  objeto["vded"] = elementRef.vDMswtEndDate || "";
                  objeto["vdr"] = elementRef.vDRate;
                  objeto["vdta"] = elementRef.vDTotalAmount;

                  objeto["vitr"] = elementRef.vITaxRate || "";
                  objeto["vitd"] = elementRef.vITaxDiscount;
                  objeto["cd"] = elementRef.conditionsDiscount;
                  objeto["ced"] = elementRef.conditionsEndDate;

                  objeto["um"] = elementRef.unstructuredMessage || "";
                }
                // Now we put the captured object in the previously declared array
                this.files2.push(objeto);
              });
            } else {
              // If an error occurs then we return a message
              return this.$store.commit("global/setSnackbar", {
                message: this.$t("CreditorFiles.unable_fileslist")
              });
            }
          } else {
            // If an error occurs then we return a message
            return this.$store.commit("global/setSnackbar", {
              message: this.$t("CreditorFiles.unable_server")
            });
          }
        })
        .then(() => {
          // return the lodingState to false (loading ...)
          return (this.loadingState = false);
        })
        .catch(err => {
          // Error in fetch request
          return this.$store.commit("global/setSnackbar", {
            message: this.$t("CreditorFiles.files_not_loaded")
          });
        });
    },

    // Method that obtains the pdf that is selected in the table and downloads it
    pdfItem(item) {
      // Request that brings the selected PDF from the server
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/file/download?lang=${this.$store.getters['User/getLanguage']}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ id: item.path_pdf }),
        headers:{
          'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
        }
      }).then(result => {
          // We return the request of the server in blob for its respective download
          if(result.status == 200){
            return result.blob();
          }
          
          if(result.status == 401) {
              return this.$router.push({name:'Login'});
          }
        })
        // Now we get the answer in the response variable
        .then(response => {
          // We verify that the information in the variable is not null or undefined
          if(response !== null && response !== undefined){
            if(response.status){
              return this.$store.commit(`global/setSnackbar`,{message:response.message});
            }
            // We declare a url variable which contains a link
            var url = window.URL.createObjectURL(response);
            // now we create an html link as if it were an <a> </a>
            var a = document.createElement("a");
            // in this case we pass it to the href the address of the file obtained
            a.href = url;
            // Now we create a download object with just the name of the pdf file
            a.download = `${item.name}.pdf`;
            // An element is created in the body of the page for its respective download
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            // The click element is created for when this method is used
            a.click();
            // Now we remove the created tag
            a.remove();
          }else{
            // Message that is sent if the response variable is undefined or null
            return this.$store.commit('global/setSnackbar',{message: this.$t("CreditorFiles.response_server")})
          }
        })
        // Catch that is created when there is an error in the fetch
        .catch(err => {
          // Message that is sent to indicate the error in the fetch
          return this.$store.commit("global/setSnackbar", {
            message: err
          });
        });
    },
    // Method that obtains the png that is selected in the table and downloads it
    pngItem(item) {
      // Fetch requests that are created to get the selected pdf
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/file/download?lang=${this.$store.getters['User/getLanguage']}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ id: item.path_pdf }),
        headers:{
          'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
        }
      })
      // We return the request of the server in blob for its respective download
        .then(result => {
          if(result.status == 200) {
            return result.blob();
          }

          if(result.status == 401) {
            return this.$router.push({name:'Login'});
          }
        })
       // We obtain the information from the pdf in the response variable
        .then(response => {
          // we verify that the response does not come empty
          if(response !== null && response !== undefined){
            if(response.status){
              return this.$store.commit(`global/setSnackbar`,{message:response.message});
            }
          // We obtain the PDF link for its respective download  
          let url = window.URL.createObjectURL(response);
          // Now we use the following method to convert the pdf to png
          this.getPNGFile(url,item)
          }else{
            // If the response variable is undefined we send the following message
            return this.$store.commit('global/setSnackbar',{message: this.$t("CreditorFiles.response_server")})
          }
        })
        // If there is an error in the request, the message that appears in the catch is sent


        .catch(err => {
          return this.$store.commit("global/setSnackbar", {
            message: err
          });
        });
    },
    // Method that converts the pdf file to png, two parameters are defined
    // the data that is the link resivido (pdf) and the item that is selected in the table
    getPNGFile(data,item){
    // We define a variable that stores the file name
    let fileName = this.ResponseFileName;
    // The scale that the image will have
    let scale = 2;
    // We create a canvas in the dom for its respective use
    let canvas = document.createElement('canvas');
    // We define the resolution of the canvas
    let twoD = canvas.getContext('2d');
    // Now we use the library that allows us to convert the pdf to png
    pdf.getDocument(data).then((doc)=>{
      // We get the number of pages of the PDF
      doc.getPage(doc.numPages).then((page)=>{
        // Create a view with the scale defined above
        var viewport = page.getViewport(scale)
        // The height of the canvas is defined
        canvas.height = viewport.height
        // The widht of the canvas is defined
        canvas.width = viewport.width
        // The image format is rendered
        page.render({
          canvasContext: twoD,
          viewport: viewport
        }).then(function () {
          // Defined globally
          twoD.globalCompositeOperation = 'destination-over'
          // Color is defined
          twoD.fillStyle = '#ffffff'
          //The type of shape is defined
          twoD.fillRect(0, 0, canvas.width, canvas.height)
          // Now we create a variable to know the format of the
          // image to convert
          let img = canvas.toDataURL('image/png')
          // We make a request that returns the converted image at the moment
          // to use this method
            fetch(img).then(result =>{
              // The blog is defined to know the characteristics of the 
              // image
              return result.blob();
            }).then((newblob)=>{
              // we get a link to send it later to downloads
              let url = window.URL.createObjectURL(newblob);
              // Create a link <a> </a> in the dom
              let a = document.createElement('a');
              // Now we use the href to know what to download
              a.href = url;
              // The following is done to download the image in the browser and
              // we pass the name and its extension
              a.download = `${item.name}.png`;
              // Now we add in the body of the element the label to
              document.body.appendChild(a);
              // Event containing the above for when the method is called
              a.click();    
              // We remove the tag and what it contains <a> </a>
              a.remove();   
            });

        })
      })
    })
   },
  downloadMultipleFiles(){
    if(this.selectedFiles.length <= 0){
      return this.$store.commit(`global/setSnackbar`,{message: this.$t("CreditorFiles.select_field")});
    }
    if(this.selectedFiles.length > 500){
      return this.$store.commit(`global/setSnackbar`,{message: this.$t("CreditorFiles.select_less500")});
    }
    let plural = this.selectedFiles.length > 1 ? 'files' : 'file';
    this.$root.$confirm(this.$t('StepByStep.confirm_title'), this.$t("CreditorFiles.select_field")+`${this.selectedFiles.length} ${plural}?`).then(async confirmation =>{
      if(confirmation){
        this.filesActionsLoader = true;
        this.filesActionsDisabled = true;
        this.pdfIconDisabled = true;
        this.pngIconDisabled = true;
        let newObjectIds = await this.selectedFiles.map(element => element.id);
        try{
          let response = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/files/download`,{
            method:'POST',
            body:JSON.stringify(newObjectIds),
            headers:{
              'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
            }
          });
          if(response){
            if(response.status == 200){
              if(response.file){
                let fileBase64 = `data:application/zip;base64,${response.file}`;
                fetch(fileBase64)
                .then(result => result.blob())
                .then((response)=>{
                  let url = window.URL.createObjectURL(response);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `files.zip`;
                  document.body.appendChild(a);
                  a.click();    
                  a.remove();   
                })
                .catch(err =>{
                  this.filesActionsLoader = false;
                  this.filesActionsDisabled = false;
                  this.pdfIconDisabled = false;
                  this.pngIconDisabled = false;
                  return this.$store.commit(`global/setSnackbar`,{message:err});
                })
              }
            }
            this.filesActionsLoader = false;
            this.filesActionsDisabled = false;
            this.pdfIconDisabled = false;
            this.pngIconDisabled = false;
            return this.$store.commit(`global/setSnackbar`,{message:response.message});
          }else{
            this.filesActionsLoader = false;
            this.filesActionsDisabled = false;
            this.pdfIconDisabled = false;
            this.pngIconDisabled = false;
            return this.$store.commit(`global/setSnackbar`,{message:this.$t('CreditorFiles.unable_server')});
          }
        }catch(err){
          this.filesActionsLoader = false;
          this.filesActionsDisabled = false;
          this.pdfIconDisabled = false;
          this.pngIconDisabled = false;
          return this.$store.commit(`global/setSnackbar`,{message:''});
        }
      }
    })
   },
    fromDateToString(date) {
      if (!date) return "";
      let newDate = new Date(date);
      if (!newDate) return "";
      let newDay = `${newDate.getDate()}`.padStart(2, "0");
      let newMonth = `${newDate.getMonth() + 1}`.padStart(2, "0");
      let newYear = `${newDate.getFullYear()}`.padStart(2, "0");
      let newHour = `${newDate.getHours()}`.padStart(2, "0");
      let newMinutes = `${newDate.getMinutes()}`.padStart(2, "0");
      return `${newYear}-${newMonth}-${newDay} ${newHour}:${newMinutes}`;
    },
    customSortDebtors(items, index, isDesc) {
      for (let i = 0; i < index.length; i++) {
        let currentIndex = index[i];
        let currentIsDesc = isDesc[i];
        items.sort((a, b) => {
          if (currentIndex === "date") {
            if (currentIsDesc)
              return (
                new Date(`${b[currentIndex]} UTC`) -
                new Date(`${a[currentIndex]} UTC`)
              );
            return (
              new Date(`${a[currentIndex]} UTC`) -
              new Date(`${b[currentIndex]} UTC`)
            );
          } else if (currentIndex == "id") {
            if (currentIsDesc) return b[currentIndex] - a[currentIndex];
            return a[currentIndex] - b[currentIndex];
          } else {
            let aElement = a[currentIndex] ? a[currentIndex] : '';
            let bElement = b[currentIndex] ? b[currentIndex] : '';
            if (currentIsDesc)
              return aElement
                .toLowerCase()
                .localeCompare(bElement.toLowerCase());
            return bElement
              .toLowerCase()
              .localeCompare(aElement.toLowerCase());
          }
        });
      }
      return items;
    },    

  }
};
</script>

<style scoped>
</style>