<template>
       <v-dialog  persistent v-model="popup"  attach ligth absolute width="700"  hide-overlay   > 
          <v-card >
            <v-card-title>{{propsMap.titlepopup}}</v-card-title>
             <!-- <v-card-title>Insuficient Credits</v-card-title> -->
            <v-spacer> </v-spacer>
          <v-card-subtitle>
            <div
            v-for="item in propsMap.subtitlepopup.split('\n')"
            :key="item"
            >
              <span>{{item}}</span>
            </div>
          </v-card-subtitle>
           <!-- <v-card-subtitle>Your account is Single Tariff or You spend all of your credits</v-card-subtitle> -->

            <v-card-text>
          <!-- <p> {{$t('qrGenerator.windowsCredit.subtitle_global')}}</p> -->
              <div id="div">    
                        <!-- <v-list-item >
                     <v-list-item-content>
                        <v-list-item-title class="headline">
                    {{$t('qrGenerator.windowsCredit.operation_requires')}}
                          <font size="5">  1 Invoice</font>
                        </v-list-item-title>
                      </v-list-item-content>
                        </v-list-item >   
                     <v-list-item v-if="propsMap.creditsinsuf">
                       <v-list-item-content>
                          <v-list-item-title class="headline">
                    {{$t('qrGenerator.windowsCredit.extra_invoice')}}
                          <font size="5">  {{ propsMap.remainingcredits}}</font>
                        </v-list-item-title>
                         </v-list-item-content>
                    </v-list-item>   
                       <v-list-item>
                       <v-list-item-content>
                          <v-list-item-title class="headline">
                      {{$t('qrGenerator.windowsCredit.price_charge')}}
                          <font size="5">  {{ propsMap.price }} CHF</font>
                        </v-list-item-title>
                         </v-list-item-content>
                    </v-list-item>  
                         <v-list-item>
                       <v-list-item-content>
                          <v-list-item-title class="headline">
                      {{$t('qrGenerator.windowsCredit.price_charge_month')}}
                          <font size="5">  {{ propsMap.total }} CHF</font>
                        </v-list-item-title>
                         </v-list-item-content>
                    </v-list-item>   -->
                    
              </div>
            </v-card-text>
             <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="popup= false"
          >
              {{$t('qrGenerator.windowsCredit.btn_disagree')}}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="ParentCheckCredits(propsMap.origin)"
          >
            {{$t('qrGenerator.windowsCredit.btn_agree')}}
          </v-btn>
        </v-card-actions>
          </v-card>
        </v-dialog>
</template>

<script>
export default {
  name: "WindowCredits",
  props:["propsMap"],
      mounted(){
        this.popup = this.propsMap.popup;
    },
  data: () => ({
    creditsinsuf: "",
    titlepopup: "",
    subtitlepopup: "",
    popup: false,
    price:"",
    dialogcredits: false, 
    membcredits: "" ,
    creditsused:"" ,
    remainingcredits:"",
    membership: "",
    dialog : false,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 200
    }

}),
 methods: {
  ParentCheckCredits(origin) {
    
    this.popup = false;
    if (origin == 'single')
    {
      this.$parent.tabReckoner(true);
    }
    else
    {
      this.$parent.$refs.finalStep.$refs.progressBar.showProgressBar = true
      this.$parent.CreateInvoice(true);
    }
  }

  
}

}

</script>