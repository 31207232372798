<template>
  <v-form ref="form">
    <v-col class="py-0">
      <v-row class="py-0" no-gutters>
        <h2>{{ $t('QrSeries.step_three.arrow_navigation') }} {{ currentIndex + 1 }}/{{ currentLength }}</h2>
        <v-icon v-if="debtorLength" @click="previousError">mdi-arrow-left-box</v-icon>
        <v-icon v-if="debtorLength" @click="nextError">mdi-arrow-right-box</v-icon>
      </v-row>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-row>
        <v-col cols="5">
          <v-row no-gutters>
            <v-col>
              {{ $t('QrSeries.step_three.correct_QR') }}
              <v-card>
                <v-card-title>{{ $t('QrSeries.step_three.account_payableto') }}</v-card-title>
                <v-card-text>
                  <qr-creditor ref="qrCreditor"></qr-creditor>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{ $t('QrSeries.step_three.subtitle_qrbills') }}
              <v-card>
                <v-card-title>{{ $t('QrSeries.step_three.recipient_address') }}</v-card-title>
                <v-card-text class="pa-0">
                  <qr-debtor isCityRequired isZipRequired isStreetRequiered isNameRequired ref="qrDebtor"></qr-debtor>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7" class="py-6">
          <v-row>
            <v-col>
              <v-card>
                <v-row class="pt-0 ma-0">
                  <v-col cols="6" class="ma-0">
                    <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" v-model="language"
                      :items="$t('Debtors.Language')" item-text="text" item-value="value"
                      :label="$t('Debtors.language')" dense :rules="[rules.isRequired]"></v-select>
                  </v-col>
                </v-row>
                <v-card-title class="pt-0"> {{ $t('QrSeries.step_three.invoice_details') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                        :return-value.sync="invoice_date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :disabled="templateType === 'parts'" v-model="invoice_date"
                            :label="$t('QrSeries.step_three.invoice_date')" prepend-icon="mdi-calendar" readonly
                            v-bind="attrs" v-on="on" :rules="[rules.isEmpty]"></v-text-field>
                        </template>
                        <v-date-picker v-model="invoice_date" :locale="$store.state.User.language" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            {{ $t('QrSeries.step_three.btn_cancel') }}
                          </v-btn>
                          <v-btn text color="primary" @click="() => {
                            this.$refs.menu.save(this.invoice_date)
                            this.handleInvoiceDate(this.invoice_date)
                          }">
                            {{ $t("Globals.ok") }}
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-btn dark :color="$vuetify.theme.themes.light.blue.primary" @click="onClickShowProductDialog">{{
                        $t('QrSeries.step_three.product_information') }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pt-0  justify-start">
                      <!-- <v-icon
                              size="30"
                              color="red"
                              v-if="productsnull">
                              mdi-close-circle
                            </v-icon>-->
                      <v-alert dense v-if="productsnull" type="error" icon="mdi-close-circle">Invalid amount of
                        products</v-alert>
                      <v-alert dense v-else-if="productsInvalidName" type="error" icon="mdi-close-circle">Invalid
                        products name</v-alert>
                      <v-icon size="30" color="green darken-2" v-else>
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <!-- <v-alert
                              dense
                              v-else
                              type="success"
                              icon="mdi-checkbox-marked-circle"
                            > </v-alert> -->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card flat v-if="!onlyQR">
                <v-col>
                  <v-slider label="Zoom" max="1" min="0" step=".1" v-model="zoom"></v-slider>
                </v-col>
                <v-divider></v-divider>
                <v-col class="pb-0" cols="12">
                  <template-viewer v-if="!onlyQR" ref="templateViewer" :key="renderViewer" :renderQR="false"
                    :items="getItems" :subject="templateString.subject" :presentation="templateString.message"
                    :contactDetails="templateString.contact" :bottomComment="templateString.comment"
                    :calculations="calculations" :debtor="diplayDebtorOnTemplate" :idTemplate="idTemplate"
                    :payable_until="payable_until" :invoice_date="invoice_date" :renderLogoRectangle="false"
                    :onlyQR="onlyQR" :zoom="zoom" cardFlat :noScroll="false" :maxSizeDocument="false"
                    :currency="currency" />
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <p class="ml-3">
              {{ $t("QrSeries.step_three.infotext_2") }}
            </p>
            <v-col class="pt-0">
              <!-- if user has wir we will show thow qr payments parts with the diferents currencies  -->
              <qr-card :withWir="false"></qr-card>
              <qr-card v-if="userHasWir"></qr-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog persistent v-model="showProductsDialog">
      <v-card>
        <v-card-text>
          <debtor-products-table ref="debtorProductTable" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn dark :color="$vuetify.theme.themes.light.blue.primary" @click="showProductsDialog = false">{{
            $t('snack_confirm.btn_close') }}</v-btn>
          <v-btn dark :color="$vuetify.theme.themes.light.blue.primary" @click="saveProducts">{{
            $t('MasterData.btn_save')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import QRCreditor from "@/components/QR/qr.form.creditor.vue";
import QRDebtor from "@/components/QR/qr.form.debtor.vue";
import QRCard from "@/components/QR/qr.card.vue";
import DebtorProductsTable from "@/components/cockpit/archieve/debtors/debtors.table.productsTable.vue";
import { mapGetters, mapMutations } from "vuex";
import editorTemplateVue from "../template/editor/editor.template.vue";
// Reference Type Detector
const detector = require("../../../detectTypeReference.js");
import {
  address,
  debitorFullName,
  zip,
  nameMissingZip,
  debitorCountryRules,
  city,
  fieldRequired,
  productsRequired,
  languages,
  invoiceDateRequired,
} from "@/components/cockpit/QR-Massive-Steps/rules";
export default {
  name: "qr-massive-validations",
  components: {
    "qr-creditor": QRCreditor,
    "qr-debtor": QRDebtor,
    "qr-card": QRCard,
    "debtor-products-table": DebtorProductsTable,
    "template-viewer": editorTemplateVue,
  },
  data() {
    return {
      productSError: false,
      show: true,
      templateString: {
        subject: '',
        message: '',
        contact: '',
        comment: '',
      },
      invoiceDate: "",
      menu: false,
      currentError: "",
      showProductsDialog: false,
      items: [],
      ErrorItems: [],
      renderViewer: 0,
      currentDebtorFailIndex: "",
      currentLength: "",
      currentIndex: 0,
      calculations: {
        total: 0,
        vatTotal: 0,
        subTotal: 0,
      },
      correctDebtors: [],
      rules: {
        isRequired: (v) => {
          if (!this.language) {
            return !!v || this.$t('rules.notempty');
          }
          return true;
        },
        isEmpty: (v) => {
          if (this.templateType === 'complete') {
            return !!v || this.$t('rules.notempty');
          }
          return true;
        }
      },
      zoom: 0,
    };
  },
  computed: {
    checkboxBilling: {
      get() {
        return this.$store.state.qrSeries.checkboxBilling;
      },
      set(value) {
        this.$store.state.qrSeries.checkboxBilling = value;
      }
    },

    userHasWir() {
      return this.$store.state.User.wir_active;
    },

    currency() {
      return this.$store.state.QR.currency;
    },
    selected_template() {
      return this.$store.state.qrSeries.selected_template_settings;
    },
    language: {
      get() {
        return this.$store.state.QR.language;
      },
      set(value) {
        this.$store.state.QR.language = value;
      }
    },
    invoice_date: {
      get() {
        return this.$store.state.QR.invoice_date;
      },
      set(value) {
        this.$store.state.QR.invoice_date = value;
      }
    },
    payable_until: {
      get() {
        return this.$store.state.QR.payable_until;
      },
      set(value) {
        this.$store.state.QR.payable_until = value;
      },
    },
    ...mapGetters({
      user: "User/user",
      creditor: 'User/creditor',
    }),
    ...mapMutations({
      setDebitor: 'QR/setDebitor',
    }),
    currentDebtor() {
      return this.getDebtorData(this.currentIndex)
    },
    getItems() {
      const debtor = this.debtorsFail[this.currentIndex];
      if (!debtor) return [];
      if (!debtor.products_json) return [];
      const items = debtor ? JSON.parse(debtor.products_json) : [];
      if (!items) return [];
      this.calculations.total = 0;
      this.calculations.vatTotal = 0;
      this.calculations.subTotal = 0;
      const _items = [];
      items.forEach(item => {
        const quantity = Number(item.quantity) || 0;
        const unitPrice = Number(item.unitPrice) || 0;
        const vatProduct = Number(item.vatProduct) || 0;
        const vat = Number(item.vat) || 0;
        const total = quantity * unitPrice

        const obj = {
          item: item.name,
          quantity,
          prize: unitPrice,
          vatPercent: vat,
          vat: vatProduct,
          total,
        }

        _items.push(obj)
      })

      const total = _items.reduce((acc, curr) => acc + curr.total, 0);
      const getVat = _items.reduce((acc, curr) => acc + curr.vat, 0);
      const getSub = total - getVat;

      if (!debtor.amount) {
        this.$store.state.QR.amount = total;
        debtor.amount = total;
      } else {
        this.$store.state.QR.amount = debtor.amount;
      }
      this.calculations.total = total;
      this.calculations.subTotal = getSub;
      this.calculations.vatTotal = getVat;

      return _items
    },
    debtorsLength() {
      return this.debtorsFail.length
    },
    productsnull() {
      if (!this.currentDebtor) return false;
      if (this.currentDebtor.products_json != null) {
        if (this.currentDebtor.products_json.length < 3) {
          return true;
        } else {
          let products = JSON.parse(this.currentDebtor.products_json);
          if (products.length == 0) return true;
          if (products.length > 15) return true;
          return false;
        }
      } else {
        return true;
      }
    },
    productsInvalidName() {
      if (!this.currentDebtor) return false;
      if (this.currentDebtor.products_json != null) {

        let products = JSON.parse(this.currentDebtor.products_json);
        let productFilter = products.some((item) => {
          return item.name.length > 40
        });
        return false;

      } else {
        return true;
      }
    },
    debtorLength() {
      if (this.debtorsFail) {
        if (this.debtorsFail.length > 1) return true;
      }
      return false;
    },
    PDFDate: {
      get() {
        return this.$store.state.templateEditor.PDFDate;
      },
      set(value) {
        this.$store.state.templateEditor.PDFDate = value;
      }
    },
    renderQR() {
      return this.$store.getters['templateEditor/renderQR'];
    },
    reference: {
      get() {
        return this.$store.state.QR.reference;
      },
      set(reference) {
        this.$store.commit("QR/setReference", reference);
      }
    },
    debtorsFail: {
      get() {
        return this.$store.state.QR.debtorsFail;
      },
      set(value) {
        this.$store.state.QR.debtorsFail = value;
      },
    },
    templateType: {
      get() {
        return this.$store.state.templateEditor.templateType;
      },
      set(value) {
        this.$store.state.templateEditor.templateType = value;
      }
    },
    diplayDebtorOnTemplate() {
      const debtor = this.$store.getters['QR/debitor'];
      return {
        client_number: debtor.client_number,
        invoice_number: debtor.invoice_number ? debtor.invoice_number : '',
        company: debtor.company,
        first_name: debtor.name,
        last_name: debtor.last_name,
        address_suffix: debtor.address_suffix,
        street: debtor.address.street,
        street_number: debtor.address.number,
        zip: debtor.address.zip,
        city: debtor.address.city,
        salutation: debtor.salutation
      };
    },
    typeOfReference: {
      get() {
        return this.$store.state.User.creditor.typeReference;
      },
      set(value) {
        return this.$store.state.User.creditor.typeReference = value;
      }
    },
    onlyQR() {
      if (this.$store.state.templateEditor.templateType === 'parts') {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    typeOfReference(newToR, oldToR) {
      let debtor = this.getDebtorData(this.currentIndex);
      if (debtor) {
        this.reference = '';
        this.$nextTick(() => {
          try {
            let calculateReference = detector.detectTypeReference(debtor.reference);
            if (calculateReference === newToR) {
              this.reference = this.formatReference(calculateReference, debtor.reference);
            }
            this.runValidations();
          } catch (e) {
            console.error(e)
          }

        })
      }
    },
    selected_template(newValue, oldValue) {
      if (newValue) {
        this.templateString = {
          subject: newValue.subject || '',
          message: newValue.greeting || '',
          contact: newValue.contactDetails || '',
          comment: newValue.closing || '',
        }
      }
      this.reRenderViewer();
    }
  },
  methods: {
    async init() {
      this.currentLength = this.debtorsFail.length;
      const debtor = this.getDebtorData(this.currentIndex);
      let calculateReference = await detector.detectTypeReference(debtor.reference);
      if (calculateReference === 'QRR') {
        this.creditor.referenceMask = `## ##### ##### ##### ##### #####`;
      } else if (calculateReference === 'SCOR') {
        this.creditor.referenceMask = `AA## XXXX XXXX XXXX XXXX XXXX X`;
      } else {
        this.creditor.referenceMask = ``;
      }
      this.$store.commit('QR/setCurrency', debtor.currency);
      this.$store.commit('AI/unstructuredMessage', { unstructuredMessage: debtor.message });
      if (!debtor.amount) {
        if (!debtor.products_json) {
          this.$store.commit('QR/setAmount', 0);
        } else {
          let productList = JSON.parse(debtor.products_json);
          if (!Array.isArray(productList)) {
            this.$store.commit('QR/setAmount', 0);
          } else {
            let totalAmount = 0;
            for (let i = 0; i < productList.length; i++) {
              let currentProduct = productList[i];
              totalAmount += currentProduct.quantity * currentProduct.unitPrice;
            }
            this.$store.commit('QR/setAmount', Number(totalAmount) || 0);
          }
        }
      } else {
        this.$store.commit('QR/setAmount', Number(debtor.amount) || 0);
      }
      // console.log('debtor.billing_information.invoice_date', debtor)
      this.invoice_date = debtor.billing_information.invoice_date;
      this.language = debtor.language;
      this.payable_until = debtor.payable_until;
      // this.$refs.templateViewer.payable_until = debtor.payable_until;
      this.$store.commit('QR/setDebitor', {
        company: debtor.company,
        name: debtor.name,
        last_name: debtor.last_name,
        address_suffix: debtor.address_suffix,
        address: {
          street: debtor.street,
          number: debtor.number,
          zip: debtor.zip,
          city: debtor.city,
          country: debtor.country,
        },
        client_number: debtor.client_number,
        invoice_number: debtor.billing_information ? debtor.billing_information.invoice_no : '',
        salutation: debtor.salutation
      });
      if (this.checkboxBilling) {
        this.$store.commit('AI/setBillingInformation', {
          bill_number: debtor.billing_information ? debtor.billing_information.invoice_no || '' : '',
          customerReference: this.$store.getters['qrSeries/getProfileData'] ? this.$store.getters['qrSeries/getProfileData'].reference || '' : '',
          voucherDate: debtor.billing_information ? debtor.billing_information.vat_date || '' : '',
          UID_Number: this.$store.getters['qrSeries/getProfileData'] ? this.$store.getters['qrSeries/getProfileData'].UIDNumber || '' : '',
          VAT_Details: [],
          VAT_Import: [],
          VAT_Conditions: []
        });
      }
      this.creditor.typeReference = calculateReference;
      this.reRenderViewer();
    },
    getDebtorData(index) {
      return this.debtorsFail[index];
    },
    formatReference(typeRefernce, reference) {
      if (reference && typeRefernce) {
        let newReference = reference.replace(/\s/g, '');
        if (typeRefernce === 'QRR') {
          if (newReference.length === 27) {
            let responseReference = `${newReference.substring(0, 2)}`;
            responseReference += ` ${newReference.substring(2, 7)}`;
            responseReference += ` ${newReference.substring(7, 12)}`;
            responseReference += ` ${newReference.substring(12, 17)}`;
            responseReference += ` ${newReference.substring(17, 22)}`;
            responseReference += ` ${newReference.substring(22, 27)}`;
            return responseReference;
          }
        } else if (typeRefernce === 'SCOR') {
          if (newReference.length === 25) {
            let responseReference = `${newReference.substring(0, 4)}`;
            responseReference += ` ${newReference.substring(4, 8)}`;
            responseReference += ` ${newReference.substring(8, 12)}`;
            responseReference += ` ${newReference.substring(12, 16)}`;
            responseReference += ` ${newReference.substring(16, 20)}`;
            responseReference += ` ${newReference.substring(20, 24)}`;
            responseReference += ` ${newReference.substring(24, 25)}`;
            return responseReference;
          }
        }
      }
      return '';
    },
    async previousError() {
      let currentDebtor = this.debtorsFail[this.currentIndex];
      currentDebtor.billing_information.invoice_date = this.invoice_date;
      await this.saveCurrentCreditor();
      let validation = await this.saveCurrentDebtor(this.currentIndex);
      if (validation) {
        await this.updateServerData(currentDebtor, (approved, message) => {
          if (approved) {
            if (this.currentIndex == 0) {
              this.currentIndex = this.debtorsFail.length
            }
            this.currentIndex -= 1;
            this.init();
          } else {
            this.debtorsFail.push(currentDebtor);
            return this.$store.commit(`global/setSnackbar`, message)
          }
        })
      }
    },
    async nextError() {
      if (this.debtorsFail.length == 0) {
        this.currentLength = 0;
      } else {
        let currentDebtor = this.debtorsFail[this.currentIndex];
        currentDebtor.billing_information.invoice_date = this.invoice_date;
        await this.saveCurrentCreditor();
        let validation = await this.saveCurrentDebtor(this.currentIndex);
        if (validation) {
          await this.updateServerData(currentDebtor, (approved, message) => {
            if (approved) {
              this.currentIndex += 1;
              if (this.currentIndex === this.debtorsFail.length) {
                this.currentIndex = 0;
              }
              this.init();
            } else {
              this.debtorsFail.push(currentDebtor);
              return this.$store.commit(`global/setSnackbar`, message);
            }
          });
        }
      }
    },
    async updateServerData(debtor, callback) {
      let currentProfile = this.$store.getters['qrSeries/getProfileData'];
      let params = {
        profile: {
          id: currentProfile.id,
          type: this.creditor.typeReference,
          IBAN: this.creditor.account,
          IBAN_WIR: this.creditor.accountWir,
          name: this.creditor.name,
          last_name: this.creditor.last_name,
          street: this.creditor.address.street,
          number: this.creditor.address.number,
          zip: this.creditor.address.zip,
          city: this.creditor.address.city,
          country: this.creditor.address.country,
        },
        debtor: {
          id: debtor.id_b,
          name: debtor.name,
          last_name: debtor.last_name,
          street: debtor.street,
          number: debtor.number,
          zip: debtor.zip,
          city: debtor.city,
          country: debtor.country,
          currency: debtor.currency,
          message: this.$store.getters["AI/unstructuredMessage"].plain || '',
          products_json: debtor.products_json,
          reference: this.reference,
          invoice_date: debtor.billing_information.invoice_date
        }
      };
      try {
        let response = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/debtor/series`, {
          method: 'POST',
          body: JSON.stringify(params),
          headers: {
            'X-CSRF-Token': `${this.$store.getters['global/_csrf']}`
          }
        });
        if (response) {

          if (response.status == 200) {


            this.saveCurrentCreditor()


          }

          return await callback(response.status === 200, response.message);
        } else {
          return await callback(undefined, this.$t('Globals.unable_server'));
        }
      } catch (err) {
        return await callback(undefined, err);
      }
    },
    saveDebtor() {
      //this function is called from the parent, to save the code of 
      //debtorFail, when you press continue
      return this.saveCurrentDebtor(this.currentIndex);
      // if(this.debtors.length != 0){
      // }
    },

    checkArrayNotNulls(array) {
      return !array || array.length === 0 || array.some(item => item == null || item === '');
    },

    async saveCurrentDebtor(index) {
      let debtor = this.debtorsFail[index];

      // console.log('debtor erronea=> ', debtor)
      this.updateCurrentDebtorsFail(index);
      let getTypeOfReference = await detector.detectTypeReference(debtor.reference);
      // console.log('getTypeOfReference=> ', getTypeOfReference)
      let ibansToValidate = [];
      if (getTypeOfReference === 'QRR') {
        if (this.userHasWir) {
          ibansToValidate.push(this.creditor.qrIBAN_wir);
          ibansToValidate.push(this.creditor.qrIBAN);
        } else {
          ibansToValidate.push(this.creditor.qrIBAN);
        }

      } else {
        if (this.userHasWir) { 
          ibansToValidate.push(this.creditor.qr_account_wir);
          ibansToValidate.push(this.creditor.qr_account);
        } else { 
          ibansToValidate.push(this.creditor.qr_account_wir);
        }

      }

      try {
        debitorFullName(debtor);
        address(debtor);
        zip(debtor);
        nameMissingZip(debtor);
        debitorCountryRules(debtor);
        city(debtor);
        fieldRequired(debtor);
        productsRequired(debtor);
        languages(debtor)
        if (this.templateType === 'complete') {
          invoiceDateRequired(debtor);
        }
         
        // console.log('getTypeOfReference condicion => ', !getTypeOfReference)
        // console.log("iban object => ", ibansToValidate)
        // console.log("iban response => ", this.checkArrayNotNulls(ibansToValidate))
        if (!getTypeOfReference && this.checkArrayNotNulls(ibansToValidate)) {
          throw new Error(this.$t('rules.isIBAN'));
        }

        this.debtorsFail.splice(index, 1);
        if (this.debtorsFail.length > 1) this.currentIndex -= 1;
        this.currentIndex = 0;
        // this.init();
        return true;
      } catch (error) {
        this.runValidations();
        this.$store.commit(`global/setSnackbar`, error);
        return false;
      };

    },
    saveCurrentCreditor() {
      //important we set account because in this moment this variable represent the two types of iban
      if (this.creditor.typeReference === 'QRR') {
        if (this.userHasWir) {
          this.creditor.qrIBAN_wir = this.creditor.accountWir;
          this.creditor.qrIBAN = this.creditor.account;
        } else
          this.creditor.qrIBAN = this.creditor.account;

      } else if (this.creditor.typeReference === 'SCOR' || this.creditor.typeReference === 'NON') {
        if (this.userHasWir) {
          this.creditor.qr_account_wir = this.creditor.accountWir;
          this.creditor.qr_account = this.creditor.account;
        } else {
          this.creditor.qr_account = this.creditor.account;
        }
      }
    },
    updateCurrentDebtorsFail(index) {
      const debitor = this.$store.getters['QR/debitor'];
      const _global = this.$store.getters['QR/global'];
      const message = this.$store.getters["AI/unstructuredMessage"];
      Object.assign(this.debtorsFail[index], {
        name: debitor.name,
        last_name: debitor.last_name,
        street: debitor.address.street,
        number: debitor.address.number,
        zip: debitor.address.zip,
        city: debitor.address.city,
        country: debitor.address.country,
        currency: _global.currency,
        message: message.plain,
        reference: this.reference,
        client_number: debitor.client_number
      });
      this.debtorsFail[index].language = this.language;
      this.debtorsFail[index].billing_information.invoice_date = this.invoice_date;
      this.debtorsFail[index].billing_information.invoice_no = debitor.invoice_number;
    },
    showSinglePDialogFn() {
      this.showProductsDialog = true;
      this.$nextThick(() => {
        //Loads the products
      });
    },
    saveProducts() {
      const products = this.$refs.debtorProductTable.productItems
      if (products.length === 0) {
        return this.$store.commit('global/setSnackbar', 'You have to at least add one item');
      }
      if (products.length > 15) {
        return this.$store.commit('global/setSnackbar', 'You can not add more than 15 products');
      }
      this.reRenderViewer();
      this.showProductsDialog = false;
      this.$nextTick(() => {
        try {
          this.debtorsFail[this.currentIndex].products_json = JSON.stringify(this.$refs.debtorProductTable.productItems);
          this.debtorsFail[this.currentIndex].amount = this.$refs.debtorProductTable.calculateTotalPrice;
          this.$store.state.QR.amount = this.$refs.debtorProductTable.calculateTotalPrice;
        } catch (error) {
          console.error(error)
        }

      })
    },
    onClickShowProductDialog() {
      this.showProductsDialog = true;
      const products_json = this.currentDebtor.products_json;
      this.$nextTick(() => {
        try {
          this.$refs.debtorProductTable.productItems = JSON.parse(products_json ? products_json : "[]");

        } catch (error) {
          console.error(error)
        }
      })
    },
    reRenderViewer() {
      this.renderViewer++;
    },
    runValidations() {
      let validation1 = this.$refs.form.validate();
      let validation2 = this.$refs.qrCreditor.$refs.form.validate();
      let validation3 = this.$refs.qrDebtor.$refs.form.validate();
      return validation1 && validation2 && validation3;
    },
    handleInvoiceDate(date) {
      if (date) {
        if (this.$refs.templateViewer) {
          this.$refs.templateViewer.invoice_date = date;
          this.$refs.templateViewer.init();
        }
      }
    },
  },
  props: {
    debtors: {
      type: Array,
      default: () => {
        return []
      }
    },
    idTemplate: {
      type: Number,
      default: null,
    }
  }
};

</script>
<style scoped>
.main {
  display: block;
}
</style>