<template>
  <div>
    <v-form
      ref="form"
    >
    <v-row>
      <v-col cols="6 pb-0 pt-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.email_field')"
          v-model="user.email"
          :rules="[rules.isRequired, rules.email]"
        ></v-text-field>
      </v-col>
      <v-col cols="6 pb-0 pt-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.confirmmail_field')"
          v-model="user.email2"
          :rules="[v => (this.user.email === this.user.email2) || this.$t('rules.emailmatch')]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6 pt-1 pb-0" v-if="!hidden_fields.includes('password')">
        <v-text-field
          :label="$t('StepByStep.UserInformation.password_field')"
          :type="showChars ? 'text' : 'password'"
          v-model="user.password"
          :disabled="edit"
          :rules="[rules.isRequired, rules.passwordLength]"
        ></v-text-field>
      </v-col>
      <v-col cols="6 pt-1 pb-0" v-if="!hidden_fields.includes('password')">
        <v-text-field
          :label="$t('StepByStep.UserInformation.confirmpassword_field')"
          :type="showChars ? 'text' : 'password'"
          v-model="user.password2"
          :disabled="edit"
          :rules="[v => (this.user.password === this.user.password2) || this.$t('rules.passwordConfirmationRule')]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1 pb-0">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="$t('StepByStep.UserInformation.legalform_entry')"
          :label="$t('StepByStep.UserInformation.legalform_title')"
          v-model="user.legalFormSelected"
          @change="legalFormFunction"
          :rules="[(v) => !!v || this.$t('rules.legalform_rule')]"
        ></v-select>
      </v-col>
      <v-col v-if="user.legalFormOthersStatus">
        <v-text-field label="Others" v-model="legalFormOthersText">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="user.companyStatus">
      <v-col class="pt-1 pb-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.entities_name_field')"
          v-model="user.companyText"
          :disabled="edit"
          :rules="[rules.companyRules]"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col v-if="user.salutationStatus" class="pt-1 pb-0">
        <v-select
         :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :label="$t('StepByStep.UserInformation.salutation_title')"
         :items="this.$t('salutationList')"
          :disabled="edit"
          v-model="user.salutationText"
          :rules="[rules.salutationRules]"
        >
        </v-select>
      </v-col>
      <v-col v-if="user.nameStatus" class="pt-1 pb-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.privates_first_name_field')"
          v-model="user.name"
          :disabled="edit"
          :rules="[rules.nameRules]"
        ></v-text-field>
      </v-col>
      <v-col  v-if="user.nameStatus" class="pt-1 pb-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.privates_last_name_field')"
          v-model="user.lastname"
          :disabled="edit"
          :rules="[rules.lastNameRules]"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- new fields -->
    <v-row v-if="user.contactStatus">
      <v-col cols="6 pt-1 pb-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.entities_contact_first_name_field')"
          v-model="user.name"
          :disabled="edit"
          :rules="[(v) => !!v || this.$t('rules.nameRules')]"
        ></v-text-field>
      </v-col>
      <v-col cols="6 pt-1 pb-0">  
           <v-text-field
          :label="$t('StepByStep.UserInformation.entities_contact_last_name_field')"
          v-model="user.lastname"
          :disabled="edit"
          :rules="[(v) => !!v || this.$t('rules.lastNameRules')]"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- *************** -->
    <v-row>
      <v-col cols="3 pt-1 pb-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.address')"
          v-model="user.addressSuffixText"
          :disabled="edit"
        ></v-text-field>
      </v-col>
      <v-col cols="3 pt-1 pb-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.street_field')"
          v-model="user.street"
          :rules="[(v) => !!v || this.$t('rules.street_rule')]"
          :disabled="edit"
        ></v-text-field>
      </v-col>
      <v-col cols="3 pt-1 pb-0">
        <v-text-field 
        :label="$t('StepByStep.UserInformation.number_field')" 
        v-model="user.number" 
        :rules="[rules.numberLength]"
        :disabled="edit"
        ></v-text-field>
      </v-col>
      <v-col cols="3 pt-1 pb-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.zip_field')"
          v-model.number="user.zip"
          :disabled="edit"
          :rules="[rules.zipLength]"
          validate-on-blur
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6 pt-1 pb-0">
        <v-text-field
          :label="$t('StepByStep.UserInformation.city_field')"
          v-model="user.city"
          :disabled="edit"
          :rules="[(v) => !!v || this.$t('rules.city_rule')]"
        ></v-text-field>
      </v-col>
      <v-col cols="6 pt-1 pb-0">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="$t('countryList')"
          item-text="name"
          item-value="code"
          :label="$t('StepByStep.UserInformation.country_field')"
          v-model="user.country"
          :disabled="edit"
          :rules="[(v) => !!v || this.$t('rules.entry_rule')]"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="user.industryStatus1">
      <v-col class="pt-1 pb-0">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="$t('StepByStep.UserInformation.industryItems1')"
          :label="$t('StepByStep.UserInformation.industry_field')"
          v-model="user.industrySelected"
          :disabled="edit"
          @change="industryFunction"
        ></v-select>
      </v-col>
      <v-col v-if="user.industryOthersStatus">
        <v-text-field label="Others" v-model="user.industryOthersText" :disabled="edit"></v-text-field>
      </v-col>
    </v-row>
        <v-row v-if="user.industryStatus2">
      <v-col class="pt-1 pb-0">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="$t('StepByStep.UserInformation.industryItems2')"
          :label="$t('StepByStep.UserInformation.industry_field')"
          v-model="user.industrySelected"
          :disabled="edit"
          @change="industryFunction"
        ></v-select>
      </v-col>
      <v-col v-if="user.industryOthersStatus">
        <v-text-field label="Others" v-model="user.industryOthersText" :disabled="edit"></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="user.numberMembersStatus">
      <v-col class="pt-1 pb-0">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="numberMembersItems"
         :label="$t('StepByStep.UserInformation.number_members')"
          :disabled="edit"
          v-model="user.numberMembersSelected"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="user.numberEmployeesStatus">
      <v-col class="pt-1 pb-0">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="numberMembersItems"
          :label="$t('StepByStep.UserInformation.number_employees')"
          :disabled="edit"
          v-model="user.numberEmployeesSelected"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="user.UIDStatus">
      <v-col cols="12">
        <v-text-field :disabled="edit" 
        :label="$t('StepByStep.UserInformation.UID_field')" v-model="user.UIDText">
          <template v-slot:prepend-inner>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon link @click="UIDclick" v-on="on"> info </v-icon>
              </template>
              <span>Get your UID Number</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!hidden_fields.includes('reference')" class="pt-1 pb-0">
        <v-text-field label="Reference"  v-model="user.referenceText" :disabled="edit"></v-text-field>
      </v-col>
    </v-row>
     <v-row>
      <v-col class="pt-1 pb-0">
            <v-text-field
              :label="$t('StepByStep.UserInformation.reference')"
              v-model="user.referenceText"
            >
            </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!hidden_fields.includes('paymentSelected')">
      <v-col class="pt-1 pb-0">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="paymentItems"
          :disabled="edit"
          label="Means of payment"
          v-model="user.paymentSelected"
          :rules="[(v) => !!v || this.$t('rules.payment_rule')]"
        ></v-select>
      </v-col>
    </v-row>
    </v-form>
  </div>
</template>

<script>
import rules from '@/mixins/rules';
import { mapState } from 'vuex';
import countryList from "../../countrylist.json";
export default {
  mixins: [rules],
  data() {
      return {
        showChars: false,
        timeoutValue:-1,
        name: "",
        nameStatus:false,
        lastname: "",
        username: "",
        email: "",
        email2: '',
        street: "",
        number: "",
        zip: "",
        city: "",
        nameContact:"",
        lastnameContact:"",
        country: "US",
        manualError:{
          password: false,
          password2: false,
          email: false,
          email2: false
        },
        DOMElements: {
          email2: {
            error: false,
            errorMessage: '',
          }
        },
        legalFormOthersText:'',
        legalFormSelected:'',
        legalFormOthersStatus:false,
        industryItems:[],
        industryStatus1:false,
        industryStatus2:false,
        industrySelected:'',
        industryOthersText:'',
        industryOthersStatus:false,
        numberMembersItems:[
          '0-10',
          '11-50',
          '51-250',
          "251-1'000",
          "1'001-5'000",
          ">5'000"
        ],
        numberMembersSelected:'',
        numberMembersStatus:false,
        numberEmployeesSelected:'',
        numberEmployeesStatus:false,
        UIDText:'',
        UIDStatus:false,
        referenceText:'',
        salutationText:'',
        salutationStatus:false,
        companyStatus:false,
        contactStatus: false,
        companyText:'',
        paymentItems:[
          'QR Bill',
          'Card Method',
        ],
        paymentSelected:'',
        addressSuffixText:'',
        hoverStatus:false,
        password: "",
        password2: "",
        countryList: countryList,
      

      };
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.password2) || this.$t('rules.passwordConfirmationRule')
    },
    ...mapState({
      user: state => state.User.user,
    }),
  },
  reference: {
    get() {
      return this.user.referenceText;
    },
    set(reference) {
      this.user.referenceText = reference;
    }
  },
  mounted() {
    this.user.country = 'CH'

  },
  methods: {
    UIDclick(){
      //When the UID help icon is pressed this function is triggered
      //causing a new window to open with the following url
      window.open('https://www.zefix.ch/de/search/entity/welcome','_blank')
    },
    industryFunction(){
      //this function changes the status for the industry others field
      //when the users selects the others option in the industry field
      //then the field is shown     
      if(this.user.industrySelected === 'Others'){
        return this.user.industryOthersStatus = true;
      }else{
        this.user.industryOthersText = '';
        return this.user.industryOthersStatus = false;
      }
    },
    legalFormFunction(){
      //This function show/hides certian fields depending the user's selection in the
      //legal form field. It also deletes the fields data if not needed.
      switch(this.user.legalFormSelected){
        case 'Private person':
          this.user.industrySelected        = '';
          this.user.industryOthersText      = '';
          this.user.numberMembersSelected   = '';
          this.user.numberEmployeesSelected = '';
          this.user.UIDText                 = '';
          this.user.companyText             = '';
          this.user.nameStatus            = true;
          this.user.salutationStatus      = true;
          this.user.companyStatus         = false;
          this.user.contactStatus         = false;
          this.user.UIDStatus             = false;
          this.user.legalFormOthersStatus = false;
          this.user.numberMembersStatus   = false;
          this.user.numberEmployeesStatus = false;
          this.user.industryStatus1        = false;
          this.user.industryStatus2        = false;
          // this.industryItems = this.$t("StepByStep.UserInformation.industryItems2");
        break;
        case 'Self-employed person(s)':
          this.user.numberMembersSelected   = '';
          this.user.numberEmployeesSelected = '';
          this.user.salutationText = '';
          this.user.companyText = '';
          this.user.UIDText = '';
          this.user.companyStatus         = false;
          this.user.contactStatus         = false;
          this.user.UIDStatus             = false;
          this.user.industryStatus1       = false;
          this.user.industryStatus2       = true;
          this.user.numberEmployeesStatus = false;
          this.user.nameStatus            = true;
          this.user.salutationStatus      = true;
          this.user.legalFormOthersStatus = false;
          this.user.numberMembersStatus   = false;
          // this.industryItems =  this.$t("StepByStep.UserInformation.industryItems2");
        break;
        case 'Sole proprietorship':
          this.user.numberMembersSelected = '';
          this.user.salutationText        = '';
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.numberEmployeesStatus = false;
          this.user.industryStatus1       = false;
          this.user.industryStatus2       = true;
          this.user.UIDStatus             = true;
          this.user.nameStatus            = false;
          this.user.salutationStatus      = true;
          this.user.legalFormOthersStatus = false;
          this.user.numberMembersStatus   = false;
          // this.industryItems =  this.$t("StepByStep.UserInformation.industryItems2");
        break;
        case 'Association':
          this.user.numberEmployeesSelected = '';
          this.user.UIDText                 = '';
          this.user.salutationText          = '';
          this.user.numberMembersStatus   = true;
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.nameStatus            = false;
          this.user.salutationStatus      = true;
          this.user.UIDStatus             = false;
          this.user.legalFormOthersStatus = false;
          this.user.numberEmployeesStatus = false;
          this.user.industryStatus1        = true;
          this.user.industryStatus2        = false;
          // this.industryItems =  this.$t("StepByStep.UserInformation.industryItems1");
        break;
        case 'Donation Organization':
          this.user.numberEmployeesSelected = '';
          this.user.UIDText                 = '';
          this.user.salutationText          = '';
          this.user.industryStatus1        = true;
          this.user.industryStatus2        = false;
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.numberMembersStatus   = true;
          this.user.nameStatus            = false;
          this.user.salutationStatus      = true;
          this.user.UIDStatus             = false;
          this.user.legalFormOthersStatus = false;
          this.user.numberEmployeesStatus = false;
          // this.industryItems =  this.$t("StepByStep.UserInformation.industryItems1");
        break;
        case 'Fundation':
          this.user.UIDText               = '';
          this.user.numberMembersSelected = '';
          this.user.salutationText        = '';
          this.user.industryStatus1        = true;
          this.user.industryStatus2        = false;
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.nameStatus            = false;
          this.user.salutationStatus      = true;
          this.user.UIDStatus             = false;
          this.user.legalFormOthersStatus = false;
          this.user.numberMembersStatus   = false;
          this.user.numberEmployeesStatus = true;
          // this.industryItems =  this.$t("StepByStep.UserInformation.industryItems1");
        break;
        case 'Cooperative':
          this.user.numberMembersSelected = '';
          this.user.salutationText        = '';
          this.user.nameStatus            = false;
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.salutationStatus      = true;
          this.user.UIDStatus             = true;
          this.user.legalFormOthersStatus = false;
          this.user.numberMembersStatus   = false;
          this.user.numberEmployeesStatus = true;
          this.user.numberEmployeesStatus = true;
          this.user.industryStatus1        = false;
          this.user.industryStatus2        = true;
          // this.industryItems = this.$t("StepByStep.UserInformation.industryItems2");
        break;
        case 'Company':
          this.user.numberMembersSelected = '';
          this.user.salutationText        = '';
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.UIDStatus             = true;
          this.user.numberEmployeesStatus = true;
          this.user.nameStatus            = false;
          this.user.salutationStatus      = true;
          this.user.legalFormOthersStatus = false;
          this.user.numberMembersStatus   = false;
          this.user.industryStatus1        = false;
          this.user.industryStatus2        = true;
          // this.industryItems =  this.$t("StepByStep.UserInformation.industryItems2");
        break;
        case 'Public administration':
          this.user.numberMembersSelected = '';
          this.user.industrySelected      = '';
          this.user.industryOthersText    = '';
          this.user.salutationText        = '';
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.UIDStatus             = true;
          this.user.numberEmployeesStatus = true;
          this.user.nameStatus            = false;
          this.user.salutationStatus      = true;
          this.user.numberMembersStatus   = false;
          this.user.legalFormOthersStatus = false;
          this.user.industryStatus1        = false;
          this.user.industryStatus2        = false;
        break;
        case 'Others':
          this.user.numberMembersSelected = '';
          this.user.industrySelected      = '';
          this.user.industryOthersText    = '';
          this.user.salutationText        = '';
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.UIDStatus             = true;
          this.user.numberEmployeesStatus = true;
          this.user.legalFormOthersStatus = true;
          this.user.nameStatus            = false;
          this.user.salutationStatus      = true;
          this.user.numberMembersStatus   = false;
          this.user.industryStatus1       = false;
          this.user.industryStatus2       = false;
        break;

        default:
          this.user.industrySelected      = '';
          this.user.industryOthersText    = '';
          this.user.numberMembersSelected = '';
          this.user.salutationText        = '';
          this.user.companyText           = '';
          this.user.companyStatus         = true;
          this.user.contactStatus         = true;
          this.user.nameStatus            = false;
          this.user.salutationStatus      = true;
          this.industryItems              = [];
          this.user.UIDStatus             = false;
          this.user.numberEmployeesStatus = false;
          this.user.numberMembersStatus   = false;
          this.user.industryStatus1       = false;
          this.user.industryStatus2       = false;
          this.user.legalFormOthersStatus = false;
        break;
      }

      this.industryItems.sort();
      this.industryItems.sort((a, b) => {
          return (a === "Others") - (b === "Others") || +(a>b)||-(a<b);
      });
      // this.legalFormItems.sort();
      // this.legalFormItems.sort((a, b) => {
      //     return (a === "Others") - (b === "Others") || +(a>b)||-(a<b);
      // });
    },
    passwordError() {
      return this.user.password === this.user.password2 ? '' : this.$t("rules.passwordConfirmationRule")
    },
    EmailError(){
      if(this.user.email === this.user.email2) {
        return true;
      } else {
        // this.DOMElements.email.error = true;
        this.DOMElements.email2.error = true;
        this.DOMElements.email2.errorMessage = this.$t("rules.emailmatch")
        // return 
      }
      // return this.user.email === this.user.email2 ? '' : 'Emails must match'
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    hidden_fields: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
};
</script>

<style>
</style>