import i18n from '@/i18n.js'
const global = {
  namespaced: true,
  state: {
    PageTitle: "",
    snackbar: {
      timout: 5000,
      type: '',
      left: false,
      right: false,
      bottom: false,
      centered: false,
      top: false,
      multiline: false,
      persistent: false,
      message: '',
      optionalMessage: '',
      style: {
        message: {}
      },
    },
    selected_languages: [
      {
        text: i18n.t("app.languages.de"),
        value: "de",
      },
      {
        text: i18n.t("app.languages.fr"),
        value: "fr",
      },
      {
        text: i18n.t("app.languages.it"),
        value: "it",
      },
      {
        text: i18n.t("app.languages.en"),
        value: "en",
      }
    ],
    languages: [
      {
        text: i18n.t("app.languages.de"),
        value: "de",
      },
      {
        text: i18n.t("app.languages.fr"),
        value: "fr",
      },
      {
        text: i18n.t("app.languages.it"),
        value: "it",
      },
      {
        text: i18n.t("app.languages.en"),
        value: "en",
      }
    ],
    languages_plus: [
      {
        text: i18n.t("app.languages.de"),
        value: "de",
      },
      {
        text: i18n.t("app.languages.fr"),
        value: "fr",
      },
      {
        text: i18n.t("app.languages.it"),
        value: "it",
      },
      {
        text: i18n.t("app.languages.en"),
        value: "en",
      },
      {
        text: i18n.t("app.languages.rm"),
        value: 'rm'
      }
    ],
    snackbarStatus: false,
    dialogStatus: false,
    url: '',
    enviroment: window.location.href.includes('localhost'),
    hideAppBar: false,
  },
  mutations: {
    setSnackbar(state, payload) {
      const { message } = payload;
      let optionalMessage = typeof payload == "object" && 'optionalMessage' in payload ? payload.optionalMessage : ''
      state.snackbar.message = message || payload;
      state.snackbar.optionalMessage = optionalMessage;

      Object.assign(state.snackbar, payload)
    },
    setURL(state, payload) {
      state.url = payload;
    },
    setDialogResponse(state, payload) {
      state.dialog.response = payload;
    },
    setPageTitle(state, payload) {
      state.PageTitle = payload;
    },
    setHideAppBar(state, payload) {
      state.hideAppBar = payload ? payload : false;
    },
    setLanguages(state) {
      state.selected_languages = [...state.languages];
    },
    setLanguagesPlus(state) {
      state.selected_languages = [...state.languages_plus];
    }
  },
  getters: {
    snackbar: state => state.snackbar,
    snackbarOptions: state => state.snackbar,
    snackbarStatus: state => state.snackbarStatus,
    dialogStatus: state => state.dialogStatus,
    getLanguages: state => [...state.languages],
    getLanguagesPlus: state => [...state.languages_plus],
    _csrf: state => {
      let cookies = document.cookie.split(';');
      let cookieValue = '';
      if (cookies.length > 0) {
        for (let i = 0; i < cookies.length; i++) {
          let currentCookie = cookies[i];
          let getEqualsIndex = currentCookie.indexOf('=');
          if (getEqualsIndex > -1) {
            let cookieName = currentCookie.substring(0, getEqualsIndex);
            if (cookieName === '_csrf') {
              if (currentCookie.length >= getEqualsIndex + 1) {
                cookieValue = currentCookie.substring(getEqualsIndex + 1, currentCookie.length);
              }
              continue;
            }
          }
        }
      }
      return cookieValue;
    }
  }
}

export default global;