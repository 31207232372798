module.exports = {
    calculatePaper: function (color, number_pages, products, limit){
        // Get the prices for the specified settings
        let paperPrintPrice = 0;
        for (let i = 0; i < products.length; i++)
        {
            if (color == products[i].product_name.replace("paper_",""))
            {
                paperPrintPrice = number_pages > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
            }
        }

        // With each price found, we calculate the toal for each category
        let paperPrintTotal = paperPrintPrice * number_pages;
        paperPrintTotal /= 100;

        // Sum up the prices and return the costs as an object, specifying the breakdown of the costs
        return paperPrintTotal.toFixed(2);

    },

    calculateEnvelope: function (envelope, number_envelopes, is_it_slips, products, limit){
        // Get the prices for the specified settings
        let envelopePrice = 0;
        for (let i = 0; i < products.length; i++)
        {
            if (envelope == products[i].product_name.replace("envelope_",""))
            {
                envelopePrice = number_envelopes > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
            }
        }
    
        // With each price found, we calculate the toal for each category
        let envelopeTotal = is_it_slips ? 0 : envelopePrice * number_envelopes;
        envelopeTotal /= 100;

        // Sum up the prices and return the costs as an object, specifying the breakdown of the costs
        return envelopeTotal.toFixed(2);
    
    },

    calculateCO2: function (number_pages, number_envelopes, is_it_slips, products, limit){
        // Get the prices for the specified settings
        let co2PricePaper = 0;
        let co2PriceEnvelope = 0;
        for (let i = 0; i < products.length; i++)
        {
            if (products[i].product_name == "co2_compensation_paper")
            {
                co2PricePaper = number_pages > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
            }
            if (products[i].product_name == "co2_compensation_envelope")
            {
                co2PriceEnvelope = number_envelopes > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
            }
        }
    
        // With each price found, we calculate the toal for each category
        let co2TotalPaper = co2PricePaper * Math.ceil((number_pages / 100));
        let co2TotalEnvelope = co2PriceEnvelope * Math.ceil((number_envelopes / 100));
        let co2Total = is_it_slips ? 0 : co2TotalPaper + co2TotalEnvelope;
        co2Total /= 100;

        // Sum up the prices and return the costs as an object, specifying the breakdown of the costs
        return co2Total.toFixed(2);
    
    },

    calculateOrder: function (priority, number_envelopes, is_it_slips, products, limit){
        // Get the prices for the specified settings
        let priorityPrice = 0;
        let orderPrice = 0;
        for (let i = 0; i < products.length; i++)
        {
            if (priority == products[i].product_name.replace("priority_",""))
            {
                priorityPrice = number_envelopes > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
            }
            if (products[i].product_name == "order")
            {
                orderPrice = number_envelopes > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
            }
        }
    
        // With each price found, we calculate the total for each category
        let orderTotal = is_it_slips ? 0 : (orderPrice * number_envelopes);
        orderTotal /= 100;
        let processingFee = (priorityPrice / 100).toFixed(2);
        let response = {
            order_total: orderTotal.toFixed(2),
            processing_fee: processingFee
        }

        // Sum up the prices and return the costs as an object, specifying the breakdown of the costs
        return response;
    
    },

    calculateShipping: function (mail, parcel, number_envelopes, number_invoices, number_inserts, products, limit, weightInfo){
        // Get the prices for the specified settings
        let mailPrice = 0;
        let shippingTotal = 0;

        // The price calcultation depends on whether this is payment parts (parcel) or a full template (A or B mail) 
        if (parcel)
        {
            let pageWeight = 0;
            let envelopeWeight = 0;
            let boxWeight = 0;
            for (let i = 0; i < weightInfo.length; i++)
            {
                if (weightInfo[i].item_name == 'paper') pageWeight = weightInfo[i].weight;
                if (weightInfo[i].item_name == 'envelope') envelopeWeight = weightInfo[i].weight;
                if (weightInfo[i].item_name == 'box') boxWeight = weightInfo[i].weight;
            } 
            let parcelWeigth = ((number_invoices + number_inserts) * pageWeight) + (number_envelopes * envelopeWeight) + boxWeight;
            let parcelCategory = '';
            if (parcelWeigth < 2000)
            {
                parcelCategory = '_2kg';
            }
            else if (parcelWeigth < 10000)
            {
                parcelCategory = '_10kg';
            }
            else
            {
                parcelCategory = '_30kg';
            }
            for (let i = 0; i < products.length; i++)
            {
                if (parcel + parcelCategory == products[i].product_name.replace("parcel_",""))
                {
                    mailPrice = number_envelopes > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
                }
            }
            shippingTotal = mailPrice;
        }
        if (mail)
        {
            for (let i = 0; i < products.length; i++)
            {
                if (mail == products[i].product_name.replace("mail_",""))
                {
                    mailPrice = number_envelopes > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
                }
            }
            shippingTotal += mailPrice * number_envelopes;
        }
    
        // With each price found, we calculate the toal for each category
        shippingTotal /= 100;

        // Sum up the prices and return the costs as an object, specifying the breakdown of the costs
        return shippingTotal.toFixed(2);
    
    },

    calculateInserts: function (color, number_pages, is_it_slips, products, limit){
        // Get the prices for the specified settings
        let insertsPrice = 0;
        for (let i = 0; i < products.length; i++)
        {
            if (color == products[i].product_name.replace("inserts_",""))
            {
                insertsPrice = number_pages > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
            }
        }
    
        // With each price found, we calculate the toal for each category
        let insertsTotal = is_it_slips ? 0 : insertsPrice * number_pages;
        insertsTotal /= 100;

        // Sum up the prices and return the costs as an object, specifying the breakdown of the costs
        return insertsTotal.toFixed(2);
    
    },


    calculateTotal: function (settings, number_invoices, is_it_slips, products, limit){
        // Get the prices for the specified settings
        let paperPrintPrice = 0;
        let envelopePrice = 0;
        let co2Price = 0;
        let priorityPrice = 0;
        let insertsPrice = 0;
        let orderPrice = 0;
        let mailPrice = 0;
        for (let i = 0; i < products.length; i++)
        {
        if (settings.color == products[i].product_name.replace("paper_",""))
        {
            paperPrintPrice = number_invoices > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
        }
        if (settings.co2_compensation && products[i].product_name == "co2_compensation")
        {
            co2Price = number_invoices > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
        }
        if (settings.envelope == products[i].product_name.replace("envelope_",""))
        {
            envelopePrice = number_invoices > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
        }
        if (settings.inserts == products[i].product_name.replace("inserts_",""))
        {
            insertsPrice = number_invoices > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
        }
        if (settings.priority == products[i].product_name.replace("priority_",""))
        {
            priorityPrice = number_invoices > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
        }
        if (products[i].product_name == "order")
        {
            orderPrice = number_invoices > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
        }
        if (settings.mail == products[i].product_name.replace("mail_",""))
        {
            mailPrice = number_invoices > limit ? products[i].qr_saturated_price : products[i].qr_threshold_price;
        }
        }

        // With each price found, we calculate the toal for each category
        let paperPrintTotal = paperPrintPrice * number_invoices;
        let envelopeTotal = is_it_slips ? 0 : envelopePrice * number_invoices;
        let co2Total = is_it_slips ? 0 : co2Price * number_invoices;
        let shippingTotal = is_it_slips ? 6 : mailPrice * number_invoices;
        let insertsTotal = is_it_slips ? 0 : insertsPrice * number_invoices;
        let orderTotal = (orderPrice * number_invoices) + priorityPrice;

        paperPrintTotal /= 100;
        envelopeTotal /= 100;
        co2Total /= 100;
        shippingTotal /= 100;
        insertsTotal /= 100;
        orderTotal /= 100;
        // Sum up the prices and return the costs as an object, specifying the breakdown of the costs
        return { 
            errorFlag: false, 
            message: 'Success',
            data:
            {
                paperPrint: paperPrintTotal,
                envelope: envelopeTotal,
                co2: co2Total,
                shipping: shippingTotal,
                inserts: insertsTotal,
                order: orderTotal,
                total: paperPrintTotal + envelopeTotal + co2Total + shippingTotal + insertsTotal + orderTotal
            }
        };

    }
}