<template>
  <v-card class="pa-0" flat height="100%"> 
    <!-- PAYABLE TO SECTION !-->
    <v-card-title class="pa-0">{{ $t("qrGeneratorFree.payableTo.main_title") }}</v-card-title>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col class="pa-0">
            <v-text-field
              :label="$t('StepByStep.UserInformation.iban_field')"
              v-model="creditor.account"
              :rules="[
                rules.notEmpty,
                rules.ibanCounter,
                rules.isIBAN,
                rules.isValidIBANNumber
              ]"
              v-mask="'AA## #### #XXX XXXX XXXX X'"
              counter="26"
              @input="checkMathPartner"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="showFieldIbanWir">
           <v-col class="pa-0">
               <v-text-field
                :label="$t('StepByStep.UserInformation.iban_field_wir')"
                v-model="creditor.accountWir"
                :rules="[
                  rules.notEmpty,
                  rules.ibanCounter,
                  rules.isIBAN,
                  rules.isValidIBANNumber
                ]"
              v-mask="'AA## #### #XXX XXXX XXXX X'"
              counter="26"
            ></v-text-field>
           </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <v-text-field
               :label="$t('StepByStep.UserInformation.qriban_field')"
              v-model="creditor.qr_account"
              :rules="[
                rules.ibanCounter,
                rules.isQRIBAN,
                rules.isValidQRIBAN
              ]"
              v-mask="'AA## #### #XXX XXXX XXXX X'"
              counter="26"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="showFieldIbanWir">
          <v-col class="pa-0">
            <v-text-field
               :label="$t('StepByStep.UserInformation.qriban_field_wir')"
              v-model="creditor.qr_account_wir"
              :rules="[
                rules.ibanCounter,
                rules.isQRIBAN,
                rules.isValidQRIBAN
              ]"
              v-mask="'AA## #### #XXX XXXX XXXX X'"
              counter="26"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="pa-0" >
            <v-text-field
              label="My reference"
              v-model="user.referenceText"
            >
            </v-text-field>
          </v-col>
        </v-row> -->
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import rules from '@/mixins/rules';
export default {
  mixins: [rules],
  directives: {
    mask,
  },
  data() {
    return {
      showFieldIbanWir:false
    };
  },
  methods: {
    checkMathPartner(){
       let iid = this.creditor.account?.replace(/\s+/g, '').substr(4,5);
       this.showFieldIbanWir = !!this.wirPartners.find(element => element.iid == iid);
       this.$emit("userInputIbanWir",this.showFieldIbanWir);
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/user',
      debitor: "QR/debitor",
      creditor: "User/creditor",
    }),
    reference: {
      get() {
        return this.user.referenceText;
      },
      set(reference) {
        this.user.referenceText = reference;
      }
    },
  },
  props: {
    edit: Boolean,
    wirPartners:Array
  },
};
</script>

<style>
</style>