<template>
    <div>
        <!-- <v-tabs>
            <v-tab>Headers</v-tab>
            <v-tab>Items</v-tab>
            <v-tab>Payment Details</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabItems" > 

        </v-tabs-items> -->
        <p class="text-body-1" >
            Headers
        </p>
        <v-row no-gutters >
            <v-col cols="12" >
                <font-selector
                    :font="headers.fontType" 
                    @update-font="headers.fontType = $event"
                 ></font-selector>
            </v-col>
        </v-row>

        <p class="text-body-1 mt-5 mb-0">
            Items
        </p>
        <v-row no-gutters >
            <v-col cols="12" >
                <font-selector 
                    :font="items.fontType" 
                    @update-font="items.fontType = $event"
                />
            </v-col>
        </v-row>
        <p class="text-body-1 mt-5 mb-0">
            Payment Details
        </p>
        <v-tabs v-model="tabPaymentDetails">
            <v-tab>Headers</v-tab>
            <v-tab>Values</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabPaymentDetails" >
            <v-tab-item>
                <v-expansion-panels accordion >
                    <v-expansion-panel>
                        <v-expansion-panel-header>Invoice Amount</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row no-gutters >
                                <v-col cols="12">
                                    <v-text-field
                                        label="Content"
                                    >

                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" >
                                    <font-selector 
                                        :font="paymentDetails.headers.invoiceAmount.fontType"
                                        @update-font="paymentDetails.headers.invoiceAmount.fontType = $event"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Sub Total</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row no-gutters >
                                <v-col cols="12" >
                                    <v-text-field 
                                        label="Content"                                    
                                    >

                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" >
                                    <font-selector 
                                        :font="paymentDetails.headers.subTotal.fontType"
                                        @update-font="paymentDetails.headers.subTotal.fontType = $event"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>VAT</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row no-gutters >
                                <v-col cols="12" >
                                    <v-text-field 
                                        label="Content"                                    
                                    >

                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" >
                                    <font-selector 
                                        :font="paymentDetails.headers.vat.fontType"
                                        @update-font="paymentDetails.headers.vat.fontType = $event"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Total</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row no-gutters >
                                <v-col cols="12" >
                                    <v-text-field 
                                        label="Content"                                    
                                    >

                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" >
                                    <font-selector 
                                        :font="paymentDetails.headers.total.fontType"
                                        @update-font="paymentDetails.headers.total.fontType = $event"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>
            </v-tab-item>

            <v-tab-item>
                <v-expansion-panels accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header>SubTotal</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row no-gutters>
                                <v-col cols="12" >
                                    <font-selector 
                                        :font="paymentDetails.values.subTotal.fontType"
                                        @update-font="paymentDetails.values.subTotal.fontType = $event"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>VAT</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row no-gutters>
                                <v-col cols="12" >
                                    <font-selector 
                                        :font="paymentDetails.values.vat.fontType"
                                        @update-font="paymentDetails.values.vat.fontType = $event"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Total</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row no-gutters>
                                <v-col cols="12" >
                                    <font-selector 
                                        :font="paymentDetails.values.total.fontType"
                                        @update-font="paymentDetails.values.total.fontType = $event"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Currency</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row no-gutters>
                                <v-col cols="12" >
                                    <font-selector 
                                        :font="paymentDetails.values.currency.fontType"
                                        @update-font="paymentDetails.values.currency.fontType = $event"
                                    />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    
                </v-expansion-panels>
            </v-tab-item>
        </v-tabs-items>

    </div>
</template>

<script>
import fontSelectorControlsVue from '../editor.font.vue';
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "templateEditor/getField",
  mutationType: "templateEditor/updateField",
});
export default {
    components: {
        'font-selector': fontSelectorControlsVue
    },
    data() {
        return {
            tabItems: null,
            tabPaymentDetails: null,
        }
    },
    computed: {
        ...mapFields({
          headers       : 'fields.itemsSection.headers',
          items         : 'fields.itemsSection.items',
          paymentDetails: 'fields.itemsSection.paymentDetails',
        }),
    }
}
</script>

<style>

</style>