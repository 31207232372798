<template>
  <v-form ref="form" >
    <v-row no-gutters>
      <v-col cols="12">
        <v-select
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :items="this.$t('Debtors.typeReference')"
          item-text="type"
          item-value="value"
          :label="$t('qrGenerator.payableTo.typeOfReference_field')"
          v-model="creditor.typeReference"
          @change="handleTypeReferenceChange"
        ></v-select>
      </v-col>
    </v-row>

    <div>
      <v-row v-if="creditor.typeReference == 'QRR'">
        <v-col>
          <v-row no-gutters>
            <v-col cols="3">
              <v-checkbox :label="$t('Debtors.date')" v-model="QRRCheckboxs.date">
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="8" v-if="QRRCheckboxs.date">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Picker in dialog"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    {{$t("Globals.cancel")}}
                  </v-btn>
                  <v-btn text color="primary" @click="saveQRRDate(date)">
                   {{$t("Globals.ok")}}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <v-checkbox
                class="py-0"
                :label="$t('Debtors.clientno')"
                v-model="QRRCheckboxs.id"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="false">
            <v-col v-if="QRRCheckboxs.date" cols="2">
              <v-text-field
                :label="$t('Debtors.date')"
                v-model="QRRFields.date"
                :rules="[rules.QRRDateLength, rules.noSpecialCharacters]"
                counter="6"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col v-if="QRRCheckboxs.id">
              <v-text-field
                :label="$t('Debtors.clientno')"
                v-model="QRRFields.id"
                :rules="[rules.QRRIdLength, rules.QRRIDMiniumLength, rules.noSpecialCharacters]"
                counter="10"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="QRRFields.reference"
                :label="$t('Debtors.reference')"
                :counter="QRRReferenceCounter"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="QRRCheckDigit"
                label="Check Digit"
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="creditor.typeReference == 'SCOR'">
        <v-col>
          <v-row no-gutters>
            <v-col cols="3">
              <v-checkbox :label="$t('Debtors.date')" v-model="SCORCheckboxs.date">
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="8" v-if="SCORCheckboxs.date">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Picker in dialog"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    {{$t("Globals.cancel")}}
                  </v-btn>
                  <v-btn text color="primary" @click="saveSCORDate(date)">
                    {{$t("Globals.ok")}}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <v-checkbox
                class="py-0"
                :label="$t('Debtors.clientno')"
                v-model="SCORCheckboxs.id"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="false">
            <v-col cols="2">
              <v-text-field value="RF" disabled> </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="SCORCheckDigit"
                label="Check Digit"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" v-if="SCORCheckboxs.date">
              <v-text-field
                :label="$t('Debtors.date')"
                v-model="SCORFields.date"
                :rules="[rules.SCORDatelength, rules.noSpecialCharacters]"
                counter="6"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col v-if="SCORCheckboxs.id">
              <v-text-field
                :label="$t('Debtors.clientno')"
                v-model="SCORFields.id"
                :rules="[rules.SCORIdLength, rules.SCORIDMiniumLength, rules.noSpecialCharacters]"
                counter="6"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="SCORFields.reference"
                :rules="[rules.noSpecialCharacters]"
                :label="$t('Debtors.reference')"
                :counter="SCORReferenceCounter"
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  directives: {
    mask,
  },
  data() {
    return {
      rules: {
        SCORDatelength: (value) => {
          if (this.creditor.typeReference === 'SCOR') {
            if (value) {
              if (value.length > 6 && this.SCORCheckboxs.date) {
                return false;
              }
            }
            if (!value && this.SCORCheckboxs.date) {
              return false;
            }
          }
          return true;
        },
        SCORDateMiniumLength: value => {
          if(this.creditor.typeReference === 'SCOR') {
            if(value) {
              if(value.length < 6) {
                return false;
              }
            }
          }
          return true;
        },
        noSpecialCharacters: (value) => {
            if (value) {
              return /^[a-zA-Z0-9]+$/i.test(value);
            }
          return true;
        },
        SCORIDMiniumLength: value => {
          if(this.creditor.typeReference === 'SCOR') {
            if(value) {
              if(value.length < 6) {
                return false;
              }
            }
          }

          return true;
        },
        SCORIdLength: (value) => {
          // if (this.SCORTabSelected == "AUTOMATIC") {
          if (this.creditor.typeReference === 'SCOR') {
            if (value) {
              if (value.length > 6 && this.SCORCheckboxs.id) {
                return false;
              }
            }
            if (!value && this.SCORCheckboxs.id) {
              return false;
            }
          }
          return true;
        },
        QRRDateLength: (value) => {
          // if (this.QRRTabSelected == "AUTOMATIC") {
          if (this.creditor.typeReference === 'QRR') {
            if (value) {
              if (value.length > 6 && this.QRRCheckboxs.date) {
                return false;
              }
            }
            if (!value && this.QRRCheckboxs.date) {
              return false;
            }
          }
          return true;
        },
        QRRIdLength: (value) => {
          if (this.creditor.typeReference === 'QRR') {
            if (value) {
              if (value.length > 10 && this.QRRCheckboxs.id) {
                return false;
              }
            }
            if (!value && this.QRRCheckboxs.id) {
              return false;
            }
          }
          return true;
        },
        QRRIDMiniumLength: value => {
          if(this.creditor.typeReference === 'QRR') {
            if(value) {
              if(value.length < 10) {
                return false
              }
            }
          }

          return true;
        },
        QRRDateMiniumLength: value => {
          if(this.creditor.typeReference === 'QRR') {
            if(value) {
              if(value.length < 6) {
                return false;
              }
            }
          }
          return true;
        }
      },
      modal: null,
      date: null,
      QRRFields: {
        date: "",
        id: "",
        reference: "",
        checkDigit: "",
      },
      SCORFields: {
        date: "",
        id: "",
        reference: "",
        checkDigit: "",
      },
      QRRCheckboxs: {
        date: false,
        id: false,
      },
      SCORCheckboxs: {
        date: false,
        id: false,
      },
      reference: "",
    };
  },
  computed: {
    ...mapGetters({
      creditor: "User/creditor",
      SCORCheckDigit: "QR/SCORCheckDigit",
      QRRCheckDigit: "QR/QRRCheckDigit",
      QRRReferenceCounter: "QR/QRRReferenceCounter",
      SCORReferenceCounter: "QR/SCORReferenceCounter",
    }),
    referenceMask: {
      get() {
        return this.$store.state.User.creditor.referenceMask;
      },
      set(value) {
        this.$store.commit("User/setReferenceMask", value);
      },
    },
    referenceCounter: {
      get() {
        return this.$store.state.User.creditor.referenceCounter;
      },
      set(value) {
        this.$store.commit("User/setReferenceCounter", value);
      },
    },
    QRRReference() {
        const { date = '', id = '' } = this.QRRFields;
        const { date:dateCheckbox, id:idCheckbox } = this.QRRCheckboxs;

        const str = `${dateCheckbox ? date : ''}${idCheckbox ? id : ''}`;
        this.reference = str;
        return str;
    },
    SCORReference() {
        const { date = '', id = '' } = this.SCORFields;
        const { date:dateCheckbox, id:idCheckbox } = this.SCORCheckboxs;

        let str = '';
        if((dateCheckbox || idCheckbox) && (date || id)) str += 'RF';
        if(dateCheckbox && date) str += date;
        if(idCheckbox && id) str += id;
        
        this.reference = str;
        return str;
    }
  },
  methods: {
    saveQRRDate(date) {
      this.$refs.dialog.save(date);
      this.QRRFields.date = date.split("-").join("").substring(2, 8);
    },
    saveSCORDate(date) {
      this.$refs.dialog.save(date);
      this.SCORFields.date = date.split("-").join("").substring(2, 8);
    },
    handleTypeReferenceChange() {
      this.reference = '';
      this.SCORFields.date = '';
      this.SCORFields.id = '';
      this.SCORFields.reference = '';
      this.SCORCheckboxs.date = false;
      this.SCORCheckboxs.id = false;
      this.QRRFields.date = '';
      this.QRRFields.id = '';
      this.QRRFields.reference = '';
      this.QRRCheckboxs.date = false;
      this.QRRCheckboxs.id = false;
      this.date = null;
    },
    getReference() {
      const obj = {};
      if(this.creditor.typeReference === 'SCOR') {
        if(this.SCORCheckboxs.date) {
          obj.date = this.SCORFields.date
        }
        obj.id = this.SCORCheckboxs.id;
      }

      if(this.creditor.typeReference === 'QRR') {
        if(this.QRRCheckboxs.date) {
          obj.date = this.QRRFields.date;
        }
        obj.id = this.QRRCheckboxs.id;
      }

      return obj;
    },
  },
};
</script>

<style>
</style>