<template>
    <div>
        <v-dialog persistent v-model="showImportFileDialogFlag" width="500px">
            <v-card width="500px">
                <v-card-title>Import File</v-card-title>
                <v-card-text>
                    <v-form ref="fileForm">
                        <v-file-input
                        :label="this.$t('qrGenerator.productsTable.file_input')"
                        v-model="importFile"
                        :rules="[rules.isEmpty,rules.isType]"
                        ></v-file-input>
                    </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                    :color="$vuetify.theme.themes.light.blue.primary" 
                    @click="hideImportDialog"
                    dark
                    >{{$t("Globals.cancel")}}</v-btn>
                    <v-btn
                    :color="$vuetify.theme.themes.light.blue.primary" 
                    @click="saveImportDebtorList"
                    :loading="setLoadingStateSaveDebtorList"
                    dark          
                    >{{$t("Globals.save")}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name:'debtor-import-file',
    data(){
        return {
            showImportFileDialogFlag:false,
            setLoadingStateSaveDebtorList:false,
            importFile:null,
            rules:{
                isEmpty: (v)=>{
                    return !!v || this.$t("rules.required");
                },
                isType: (v)=>{
                    if(v){
                        let MIMETypesArrays = [
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'text/csv'
                        ];
                        let matchedMIME = MIMETypesArrays.find(element=>element === v.type);
                        if(matchedMIME) return true;
                        return this.$t('Debtors.file_type');
                    }else{
                        return true
                    }
                }
            },
        }
    },
    methods:{
        hideImportDialog(){
            this.showImportFileDialogFlag = false;
            this.importFile = null;
        },
        async saveImportDebtorList(){
            if(!this.$refs.fileForm.validate()){
                return this.$store.commit(`global/setSnackbar`,this.$t('snack_confirm.invalid_inputs'));
            }
            this.generateBase64String(this.importFile,async(base64String)=>{
                if(base64String){
                    try{
                        let data = base64String.split(';base64,')[1];
                        this.setLoadingStateSaveDebtorList = true;
                        let result = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/debtors/import`,{
                            method:'POST',
                            body:JSON.stringify({
                                data:data,
                                name:this.importFile.name
                            }),
                            headers:{
                                'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                            }
                        })
                        if(result){
                            if(result.status == 200){
                                this.showImportFileDialogFlag = false;
                                this.setLoadingStateSaveDebtorList = false
                                this.$parent.updateDebtorList();
                                return this.$store.commit(`global/setSnackbar`,{message:result.message});
                            }
                            if(result.status == 400){
                                this.showImportFileDialogFlag = false;
                                this.setLoadingStateSaveDebtorList = false
                                return this.$store.commit(`global/setSnackbar`,this.$t("Debtors.errorReference") );
                            }
                            if(result.status == 406){
                                this.showImportFileDialogFlag = false;
                                this.setLoadingStateSaveDebtorList = false
                                return this.$store.commit('global/setSnackbar',{message:this.$t("Globals.error_character")});
                            }

                        }else{
                            this.setLoadingStateSaveDebtorList = false;
                            return this.$store.commit(`global/setSnackbar`,{message: this.$t("StepByStep.invalid_response")});  
                        }
                    }catch(err){
                        this.setLoadingStateSaveDebtorList = false;
                        if(err.response?.message == "Error Template"){
                            err = this.$t("Debtors.errorTemplate");
                        }
                        return this.$store.commit(`global/setSnackbar`,{message:err});
                    }
                }else{
                    this.setLoadingStateSaveDebtorList = false;
                    return this.$store.commit(`global/setSnackbar`,'Unable to process the file');
                }
            })
        }, 
        async generateBase64String(file,callback){
            let url = window.URL.createObjectURL(file);
            let newBlob = await fetch(url).then(result => result.blob());
            let reader = new FileReader();
            reader.readAsDataURL(newBlob);
            reader.onload = () =>{
                return callback(reader.result)
            }
        },       
    }
}
</script>