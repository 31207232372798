<template>
    <div>
        <v-dialog persistent v-model="showGetImportFileDialog" width="500">
            <v-card width="500">
                <v-card-title>{{$t('Debtors.OverFlowButtons.import_list')}}</v-card-title>
                <v-card-text>
                <v-row>
                    <v-col> <!-- :items="['XLSX','CSV']" -->
                        <v-select
                        :no-data-text="$t('qrGenerator.productsTable.data_available')"
                        v-model="selectedFileType"
                        :items="['XLSX']" 
                        disabled
                        label="Type"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                        :no-data-text="$t('qrGenerator.productsTable.data_available')"
                        v-model="selectedLanguage"
                        :items="$t(`Settings.languageSelector.languages`)"
                        item-value="value"
                        item-text="text"
                        ></v-select>
                    </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                    dark
                    :color="$vuetify.theme.themes.light.blue.primary"
                    @click="showGetImportFileDialog = false"
                    >{{$t("Globals.cancel")}}</v-btn>
                    <v-btn
                    dark
                    :color="$vuetify.theme.themes.light.blue.primary"
                    @click="downloadImportFile"
                    :loading="showLoadingStateDownload"
                    >{{$t("CreditorFiles.download")}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name:'debtors-import-file',
    data(){
        return {
            showGetImportFileDialog:false,
            selectedFileType:'XLSX',
            selectedLanguage:`${this.$i18n.locale}`,
            showLoadingStateDownload:false,
        }
    },
    methods:{
        async downloadImportFile(){
            if(this.showGetImportFileDialog){
                this.showLoadingStateDownload = true;
                try{
                let responseFile = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/debtors/files/import?lang=${this.selectedLanguage}&type=${this.selectedFileType}`);
                if(responseFile){
                    if(responseFile.status == 200){
                    let MIMEType = this.selectedFileType === 'CSV' ? `text/csv`:`application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
                    let dataType = `data:${MIMEType};base64,${responseFile.data}`;
                        await fetch(dataType)
                        .then(result => result.blob())
                        .then(response=>{
                            let url = window.URL.createObjectURL(response);
                            let a = document.createElement("a");
                            a.href = url;
                            a.download = `${responseFile.name}`;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            a.remove();    
                            this.showLoadingStateDownload = false;            
                        })
                        .catch(err=>{
                            this.showLoadingStateDownload = false;
                            return this.$store.commit(`global/setSnackbar`,{message:err});
                        })
                    }
                    this.showLoadingStateDownload = false;
                    return this.$store.commit(`global/setSnackbar`,{message:responseFile.message});
                }else{
                    this.showLoadingStateDownload = false;
                    return this.$store.commit(`global/setSnackbar`,{message: this.$t("StepByStep.invalid_response")});
                }
                }catch(err){
                    this.showLoadingStateDownload = false;
                    return this.$store.commit(`global/setSnackbar`,{message:err});
                }
            }
        }        
    }
}
</script>