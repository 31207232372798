import Vue from 'vue';
import Vuex from 'vuex';

import User from './modules/user';
import QR from './modules/qr';
import global from './modules/global';
import AI from './modules/additionalInformation';
import steps from './modules/steps';
import saveInLocalStorage from './saveInLocalStorage';
import Profile from './modules/profile';
import templateEditor from './modules/templateEditor';
import Debtor from './modules/debtor';
import qrSeries from './modules/qr-series';
import addressInfo from './modules/address-information.js'

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        User,
        QR,
        global,
        AI,
        steps,
        Profile,
        templateEditor,
        Debtor,
        qrSeries,
        addressInfo
    },
    mutations: {
        // This method will recover all the data that was saved in the localstorage
        // this data will be saved when every mutation runs in the file src\store\saveInLocalStorage.js
        initialiseStore(state) {
            if(localStorage.getItem('User/setLogin')) {
                let { username, login} = JSON.parse(localStorage.getItem('User/setLogin'));
                state.User.username = username;
                state.User.login = login;
            }

            // recovers the product that are used in QRW
            if(localStorage.getItem('User/setItems')) {
                let { items } = JSON.parse(localStorage.getItem('User/setItems'));
                state.User.items = items;
            }

            if(localStorage.getItem('User/setSubscription')) {
                let { subscription } = JSON.parse(localStorage.getItem('User/setSubscription'));
                state.User.subscription = subscription;
            }

            if(localStorage.getItem('User/setSubscriptionName')) {
                let { subscriptionName } = JSON.parse(localStorage.getItem('User/setSubscriptionName'));
                state.User.subscriptionName = subscriptionName;
            }

            if(localStorage.getItem('User/setActiveAccount')) {
                let { activeAccount } = JSON.parse(localStorage.getItem('User/setActiveAccount'));
                state.User.activeAccount = activeAccount;
            }

            if(localStorage.getItem('QR/global')) {
                let { reference, currency, amount } = JSON.parse(localStorage.getItem('QR/global'));
                state.QR.reference = reference;
                state.QR.currency = currency;
                state.QR.amount = amount;
            }

            if(localStorage.getItem('User/setLanguage')) {
                const language = JSON.parse(localStorage.getItem('User/setLanguage'));
                state.User.language = language;
            }

            if(localStorage.getItem('User/setCheckout')) {
                const checkout = JSON.parse(localStorage.getItem('User/setCheckout'));
                state.User.user.checkout = checkout;
            }

            if(localStorage.getItem('Profile/setProfile')) {
                const profile = JSON.parse(localStorage.getItem('Profile/setProfile'));
                state.Profile.profile = profile;
            }
        },
    },
    plugins: [ saveInLocalStorage ]

})

export default store;
