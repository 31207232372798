<template>
  <v-card>
      <v-card-title>
        {{$t("Admin.password.change_password")}}
      </v-card-title>
      <v-card-text>
          <v-text-field
            :label="$t('Admin.password.current_password')"
            :rules="[rules.notEmpty]"
            type="password"
            v-model="oldPassword"
          >
          </v-text-field>
          <v-text-field
            :label="$t('Admin.password.password')"
            type="password"
            :rules="[rules.notEmpty, rules.passwordLength]"
            v-model="password"
          >
          </v-text-field>
          <v-text-field
            :label="$t('Admin.password.confirm_password')"
            type="password"
            :rules="[v => (this.password === this.password2) || this.$t('rules.passwordConfirmationRule') ]"
            v-model="password2"
          >
          </v-text-field>
          <v-btn
            :color="$vuetify.theme.themes.light.blue.primary"
            block
            dark
            small
            @click="change_password"
          >{{$t("Admin.password.change_password")}}</v-btn>
      </v-card-text>
  </v-card>
</template>

<script>
import rules from '@/mixins/rules'
export default {
    data() {
        return {
            oldPassword: '',
            password: '',
            password2: '',

        }
    },
    created(){
       this.$store.commit("global/setPageTitle", this.$t("Admin.password.change_password"))
    },
    computed:{
    locale(){
      return this.$store.state.User.language;
      }
    },
    watch:{
      locale(newValue,oldValue){
        this.$store.commit("global/setPageTitle", this.$t("Admin.password.change_password"));
      }
    },
    methods: {
        change_password() {
            this.$fetch('', {
                method: 'POST',
                body: JSON.stringify({
                    oldPassword: this.oldPassword,
                    password: this.password,
                    password2: this.password2
                })
            })
            .then(response => {

            })
            .then(this.$handleCatch)

        }
    },
    mixins: [rules]
}
</script>

<style>

</style>