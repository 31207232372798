<template>
  <div>
    <v-card class="d-flex">
      <v-navigation-drawer clipped app v-model="drawer" :mini-variant.sync="mini" permanent right>
        <v-list>
          <v-list-item :exact="false" icon @click.stop="mini = !mini">
            <v-icon>mdi-menu</v-icon>
          </v-list-item>
          <template v-for="item in items"  >
            <template v-if="!item.items">
              <v-list-item :exact="false" :key="item.title" :to="item.to" @click="Logout(item.title)">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-else-if="item.items">
              <v-list-group :key="item.title" :prepend-icon="item.icon">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                :exact="false"
                  v-for="subItem in item.items"
                  :key="subItem.title"
                  @click="subItem.active = !subItem"
                  :to="subItem.to"
                >
                  <v-list-item-icon>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="subItem.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>
          </template>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <v-container class="pt-0" fluid>
      <router-view ref="view" ></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "cockpit",
  data() {
    return {
      mini: false,
      drawer: false,
      excluded: [],
      excludedSons: [],
      prevRoute: '',
    };
  },
  computed: {
    items() {
      return [
        {
          id: 1,
          title: this.$t("Cockpit.QrModule"),
          icon: "mdi-qrcode",
          to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/services`,
          premium: true,
          items: [
            {
              id: 12,
              title: this.$t("Cockpit.QrGenerator"),
              icon: "mdi-qrcode",
              to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/qr-generator`,
              premium: true
            },
                {
              id: 7,
              title:this.$t("Cockpit.QrMassive"),
              icon: "mdi-qrcode",
              to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/qr-series`,
              premium: true
            }
            // {
            //   id: 13,
            //   title: this.$t("Cockpit.QrMassive"),
            //   icon: "mdi-qrcode",
            //   to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/service/qr-massive`,
            //   premium: true
            // }
          ]
        },
        {
          id: 2,
          title:this.$t("Cockpit.Debtors") ,
          icon: "mdi-account-cash",
          premium: false,
          items: [
              {
                id: 21,
                title: this.$t("Cockpit.ListDebtors"),
                icon: "mdi-account-multiple",
                to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/archieve/debtors`,
                premium: true
              },
              // {
              //   id: 22,
              //   title: this.$t("Cockpit.UploadDebtors"),
              //   icon: "mdi-microsoft-excel",
              //   to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/service/upload-debitors`,
              //   premium: true
              // },
          ]
        },
        {
          id: 3,
          title: this.$t("Cockpit.MyProfile"),
          icon: "mdi-account-circle",
          to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/account`,
          premium: false,
          items: [
            //  {
            //   id: 11,
            //   title: this.$t("Cockpit.InvoiceTemplates"),
            //   icon: "mdi-folder",
            //   to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/template`,
            //   premium: true
            // },
            {
              id: 31,
              title: this.$t("Cockpit.MasterData"),
              icon: "mdi-account-tie",
              to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/master-data`,
              premium: false              
            },
            {
              id: 33,
              title: this.$t("Cockpit.Settings"),
              icon: "mdi-cog",
              to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/settings`,
              premium: false
            },
            {
              id: 34,
              title: this.$t("Cockpit.ProductUsage"),
              icon: "mdi-card-account-details-star",
              to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/product-usage`,
              premium: false
            },
            {
              title:  this.$t("Cockpit.InvoiceTemplates"),
              icon: 'mdi-file-document-multiple-outline',
              to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/template`,
              premium: true,
            },
          ]
        },
        {
          id: 4,
          title: this.$t("Cockpit.Archieve"),
          icon: "mdi-folder",
          to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/archieve`,
          premium: false,
          items: [
            {
              id: 44,
              title: this.$t("Cockpit.Files"),
              icon: "mdi-file-document",
              to: `${this.$i18n.locale ? `/${this.$i18n.locale}` : ''}/cockpit/archieve/filesqrmassive`,
              premium: true
            }
          ]
        },
        // {
        //   id: 5,
        //   title: this.$t("Cockpit.Validations"),
        //   icon: "mdi-check-outline",
        //   to: "/cockpit/validations",
        //   premium: false
        // },
        { 
          id: 6,
          title: this.$t("Cockpit.Logout"), 
          icon: "mdi-power", 
          premium: false 
        }
      ]
    }
  },
  watch: {
    $route() {
      const subscriptionName = this.$store.getters["User/subscriptionName"];
      return this.viewsCalculation(subscriptionName);
    }
  },
  filters: {
    ifInItems: function(value) {
      return value.items !== undefined;
    }
  },
  created() {
    const subscriptionName = this.$store.getters["User/subscriptionName"];
    return this.viewsCalculation(subscriptionName);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    gBack() {
      this.$router.go(-1);
    },
    Logout: function(item) {
       if (item === "Logout") {
         this.$store.commit('QR/clearDebitor')
         fetch(`${process.env.VUE_APP_SERVER_URL}/api/logout`, {
           method: "POST",
           credentials: "include",
         })
        .then(result => result.json())
        .then(() => {
          document.cookie = '_csrf=;';
          this.$store.commit("User/setLogin", {
            code:'',
            user:'',
          });
          this.$store.commit("User/setSubscriptionName", "");
          this.$router.push({ name: "Login" });
        })
        .catch(err => {
          this.$store.commit("global/setSnackbar", { message: err });
        });
       }
     },
    viewsCalculation(subName) {
      const activeAccount = this.$store.getters["User/activeAccount"];
      if (subName === "Free Trial") {
        this.items = this.items.filter(element => {
          if (element.premium === false) {
            if (element.items !== null && element.items !== undefined) {
              element.items = element.items.filter(subElement => {
                if (subElement.premium === false) {
                  return true;
                } else {
                  this.excludedSons.push({
                    data: subElement,
                    index: this.items.indexOf(element),
                    sonIndex: element.items.indexOf(subElement)
                  });
                  return false;
                }
              });
            }
            return true;
          } else {
            if (element.items !== null && element.items !== undefined) {
              element.items = element.items.filter(subElement => {
                if (subElement.premium === false) {
                  return true;
                } else {
                  this.excludedSons.push({
                    data: subElement,
                    index: this.items.indexOf(element),
                    sonIndex: element.items.indexOf(subElement)
                  });
                  return false;
                }
              });
            }
            this.excluded.push({
              data: element,
              index: this.items.indexOf(element)
            });
            return false;
          }
        });
      } else if (
        (subName === "Angebot QR-Start"  ||
          subName === "Angebot QR-Plus"  ||
          subName === "Angebot QR-Profi" ||
          subName === "Single Tariff") &&
        this.excluded.length !== -1
      ) {
        this.excluded.forEach(element => {
          this.items.splice(element.index, 0, element.data);
          if (element.items !== null && element.items !== undefined) {
            this.excludedSons.forEach(subElement => {
              if (element.index === subElement.index) {
                element.items.splice(subElement.sonIndex, 0, subElement.data);
              }
            });
          }
        });
        return (this.excluded = []);
      }
    }
  }
};
</script>

<style scoped>

</style>