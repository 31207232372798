import { wrapText, isValidIBANNumber } from "./functions"
import { StandardFonts, PDFDocument } from 'pdf-lib';

const getFont = async () => {
  const pdfDoc = await PDFDocument.create();
  const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  return font;
}


export default {
    data() {
      return {
        commentSeccionMessage: ''
      }
    },
    computed: {
        rules() {
            return {
                sizeFont: value => {      
                  if(value > 0){  
                  return true ;
                }
                 else {
                   return "Invalid size, must be greater than 0"}
                },
                isRequired: value => !!value || this.$t("rules.required"),
                notEmpty: value => !!value || this.$t("rules.notempty"),
                isIBAN: value => {
                    const code = value ? value.replace(/\s+/g,"") : '';
                    if(code.length > 0) {
                        if(code[4] != 3) return true;
                        else return this.$t("rules.isIBAN")
                    } else {
                        return true;
                    }
                },
                isQRIBAN: value => {
                    const code = value ? value.replace(/\s+/g,"") : '';
                    if(code.length > 0) {
                        if(code[4] == 3) return true;
                        else return  this.$t("rules.isQRIBAN")
                    } else {
                        return true;
                    }
                },
                isValidQRIBAN: value => {
                    if(value) {
                        return isValidIBANNumber(value) === 1 ? true : this.$t("rules.isValidQRIBAN");
                    }

                    if(!value) {
                        return true;
                    }
                },
                IBANLength: (v) =>{
                      return v.length <= 26 || this.$t('rules.invalid_length');
                },
                isValidIBANNumber: value => isValidIBANNumber(value) === 1 ? true :this.$t("rules.isValidQRIBAN"),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) ||this.$t("rules.email");
                },
                passwordLength: value =>{
                    return String(value).length >= 8 || this.$t('rules.passwordLength');
                },
                onlyNumber: value => {
                  return typeof value === "number" || this.$t('rules.onlyNumber')
                },
                zipLength: value => {
                  if(value){
                    return String(value).length <= 16 || this.$t('rules.zipLength')
                  }else{
                    return true;
                  }
                },
                nameRules: value=>{
                  if(this.user.legalFormSelected === 'Private person'){
                    return !!value || this.$t('rules.nameRules')
                  }else{
                    return true;
                  }
                },
                UIDRules: value =>{
                  if(this.user.legalFormSelected === 'Cooperative' || this.user.legalFormSelected === 'Company'){
                    return !!value || this.$t('rules.entry_rule')
                  }else{
                    return true;
                  }
                },
                salutationRules: value =>{
                  if(this.user.legalFormSelected === 'Private person'){
                    return !!value || this.$t('rules.salutationRules');
                  }else{
                    return true;
                  }
                },
                companyRules: value=>{
                  if(this.user.legalFormSelected !== 'Private person'){
                    return !!value || this.$t('rules.companyRules');
                  }else{
                    return true;
                  }
                },
                lastNameRules: value =>{
                  if(this.user.legalFormSelected === 'Private person'){
                    return !!value || this.$t('rules.lastNameRules');
                  }else{
                    return true;
                  }
                },
                passwordLength: value =>{
                  return String(value).length >= 8 || this.$t('rules.passwordLength');
                },
                isGreater: value =>{
                  return value >= 0 || this.$t('rules.isGreater')
                },
                ibanRules: value => {
                    if(this.errorState){
                        return false;
                    }else{
                        return true;
                    }
                },
                ibanCounter: value => !value || value.length <= 26 || this.$t('qrGeneratorFree.rules.ibanCounter'),
                isName: value => !!value || this.$t('qrGeneratorFree.rules.isName'),
                hasFirstName: (v) =>{
                  return !!v ||  this.$t('rules.nameRules');
                },
                firstNameLength: (v) =>{
                  if(v){
                      return v.length <= 35 ||this.$t('rules.name_length');
                  }else{
                      return true;
                  }
                },
                hasLastName: (v) =>{
                  return !!v ||  this.$t('rules.lastNameRules')
                },
                LastNameLength: (v) =>{
                  if(v){
                      return v.length <= 35 || this.$t('rules.name_length');
                  }else{
                      return true;
                  }
                },
                customID: (v) =>{
                  return !!v || this.$t('rules.entry_rule');
                },   
                creditorFullName: value => this.creditor.name.length + this.creditor.last_name.length <= 70 || this.$t('qrGeneratorFree.rules.isName'),
                address: value => value.length <= 70 || this.$t('qrGeneratorFree.rules.address'),
                zip: value => value.length <= 16 || this.$t('qrGeneratorFree.rules.zip'),
                city: value => value.length <= 35 || this.$t('qrGeneratorFree.rules.city'),
                country: value => !!value || this.$t('qrGeneratorFree.rules.country'),
                nameMissingZip: value => {
                if(this.debitor.name.length > 0){
                        return !!this.debitor.address.zip || this.$t('qrGeneratorFree.rules.zipRequired');
                    }else{
                        return true;
                    }
                },
                nameMissingCreditorZip: value =>{
                    if(this.creditor.name.length > 0){
                        return !!this.creditor.address.zip || this.$t('qrGeneratorFree.rules.zipRequired');
                    }else{
                        return true;
                    }
                },
                nameMissingCity: value =>{
                    if(this.debitor.name.length > 0){
                        return !!this.debitor.address.city || this.$t('qrGeneratorFree.rules.cityRequired');
                    }else{
                        return true;
                    }
                },
                nameCreditorMissingCity: value =>{
                    if(this.creditor.name.length > 0){
                        return !!this.creditor.address.city || this.$t('qrGeneratorFree.rules.cityRequired');
                    }else{
                        return true;
                    }
                },
                numberLength: value =>{
                  if(value) return value.length <= 255 || this.$t('rules.numberLength');
                  return true;
                },
                onlyIMG: value => {
                  if(value instanceof File) {
                    if(value.type !== 'image/jpeg' && value.type !== 'image/png') {
                        return 'Type file forbidden'
                    }
                  }
                  return true
                },
                less1MB: value => {
                  if(value instanceof File) {
                    if(value.size > 1000000) {
                      return 'File has to be less than 1 MB';
                    }
                  }

                  return true;
                },
                less2MB: value =>{
                  if(value instanceof File) {
                    if(value.size > 2000000){
                      return 'File has to be less than 2 MB';
                    }
                  }
                  return true;
                },
                fiveLines: value =>  {
                  if(value){
                    let auxArray = value.split(/\n/g);
                    if(auxArray.length > 5) return `No more than 5 lines`
                    let invalidLengthFlag = true;
                    for(let i = 0;i<auxArray.length;i++){
                      let currentIndex = auxArray[i];
                      if(currentIndex){
                        if(typeof currentIndex === 'string'){
                          if(currentIndex.length > 35){
                            invalidLengthFlag = false;
                            continue;
                          }
                        }
                      }
                    }
                    return invalidLengthFlag ? true : `Invalid Length, each line should have a max of 35 chars.`;
                  }
                  return true;
                },
                fourLines: value =>  {
                  if(value){
                    let auxArray = value.split(/\n/g);
                    if(auxArray.length > 4) return `No more than 4 lines`
                    let invalidLengthFlag = true;
                    for(let i = 0;i<auxArray.length;i++){
                      let currentIndex = auxArray[i];
                      if(currentIndex){
                        if(typeof currentIndex === 'string'){
                          if(currentIndex.length > 35){
                            invalidLengthFlag = false;
                            continue;
                          }
                        }
                      }
                    }
                    return invalidLengthFlag ? true : `Invalid Length, each line should have a max of 35 chars.`;
                  }
                  return true;
                },
                twoLines: value =>  {
                  if(value){
                    let auxArray = value.split(/\n/g);
                    if(auxArray.length > 2) return `No more than 2 lines`
                    let invalidLengthFlag = true;
                    for(let i = 0;i<auxArray.length;i++){
                      let currentIndex = auxArray[i];
                      if(currentIndex){
                        if(typeof currentIndex === 'string'){
                          if(currentIndex.length > 45){
                            invalidLengthFlag = false;
                            continue;
                          }
                        }
                      }
                    }
                    return invalidLengthFlag ? true : `Invalid Length, each line should have a max of 45 chars.`;
                  }
                  return true;
                },
                // isPDFEmpty: v => {
                //     if(this.orderSettings.uploadCustomInsert) return this.rules.isEmpty(v);
                //     return true;
                // },
                isPDF: v =>{
                    if(v) return v.type == 'application/pdf' || 'File must be PDF'
                    return true;
                },
                sizeFile: (file) =>  !file || file.size < 1500000 || this.$t('QrSeries.step_four.emailDispatch.pdfSize'),
                checkTypeFile: (file) => !file || file.type == "application/pdf" || this.$t('QrSeries.step_four.emailDispatch.pdfType'),
                required: (file) => {
                   return file ? true : 'The pdf file is required'
                } 
            }
        },
    },
}