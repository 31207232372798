<template>
    <v-dialog
      v-model="showDialog"
      persistent
      width="500"
    >
      <v-card>
      
          <v-toolbar dense  dark color="#95c11f">
              <v-toolbar-title>
                 Upload File  
              </v-toolbar-title>
              <v-spacer></v-spacer> 
          </v-toolbar>

          <v-card-text class="container-primary">
            <div class="container-info"
              @dragover="dragover" @drop="drop"  
            >   
                <template v-if="!file">
                    <v-icon class="container-icon"
                      x-large
                    > 
                      mdi-cloud-upload
                    </v-icon>
                    <p class="ma-0">Drag and Drop Here</p>
                    <p class="ma-0" >or</p>

                    <p class="container-input-file">
                      <input type="file" id="inputFile"
                       @change="onChange" ref="file" accept=".pdf" />
                       Browse Files</p>
                </template>

                <template v-else>
                    <img class="file-img" src="@/assets/pdf.png" alt="icon pdf">
                    <p class="file-name">{{ file.name }}</p> 

                    <!-- delete button file -->
                    <v-btn
                        class="mx-2 btn-delete-file"
                        fab
                        dark
                        x-small
                        color="dark"
                        @click="deleteFile"
                    >
                        <v-icon dark>
                            mdi-close-thick
                        </v-icon>
                    </v-btn>

                </template>
 
            </div>
          </v-card-text>
              
          <v-card-actions class="justify-end">

              <p class="messageValidated" v-if="messageValidation" >{{ messageValidation  }} </p>
            
              <v-btn
                dark          
                :color="$vuetify.theme.themes.light.blue.primary"
                @click="cancel"
                >Cancel</v-btn>
              <v-btn
                dark
                @click="saveFile"
                :color="$vuetify.theme.themes.light.blue.primary"
                >Save</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'DragDropFile',
  props:{
     showDialog:{
       type: Boolean,
       default: false
     },
     keepFile:{
       type: Boolean,
       default: true
     }
  },
  data(){
    return { 
        file:null,
        messageValidation:"",
        flagValidations:false,
    }
  },
  methods:{
    validateFile(files){
        if(files){
         if(files.length > 1 ){
             this.messageValidation = this.$t('QrSeries.step_four.emailDispatch.pdfNumberFiles');
         }else{
            let auxFile = files[0];
            if( auxFile.type != "application/pdf"){
                this.messageValidation = this.$t('QrSeries.step_four.emailDispatch.pdfType');
                return false;
            }else if ( auxFile.size > 1572864 ){
                this.messageValidation = this.$t('QrSeries.step_four.emailDispatch.pdfSize'); 
                return false;
            }
            
            return true;
             
         }
        }
    }, 
    onChange(event){
       this.flagValidations =  this.validateFile(event.target.files)
       if(this.flagValidations){
           this.file = event.target.files[0];
           this.messageValidation = "";
       }
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.flagValidations =   this.validateFile(event.dataTransfer.files);
      if(this.flagValidations){
           this.file = event.dataTransfer.files[0];
           this.messageValidation = "";
      }
    },
    cancel(){
        this.messageValidation = "";
        //this.$emit('changeFile',this.file);
        this.$emit('closeDialog');
    },
    saveFile(){
        this.$emit('closeDialog');
        if(this.flagValidations){
            this.$emit('changeFile',this.file);
            if(!this.keepFile){
               this.file = null;
            }
        }
    },
    deleteFile(){
        this.file = null;
        this.$emit('changeFile',null);
    }
  }
}
</script>

<style scoped>

  .container-primary{
     padding:5px 10px !important;
  }

  .container-icon{
     font-size: 50px !important;
  }

  .container-info{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     border:2px dashed #616161;
     margin-top: 15px;
     margin-bottom: 10px;
     height: 150px;
     position: relative;
  }

  .file-img{
    width: 50px;
  }

  .file-name{
    font-size: 20px;
  } 

  .container-input-file{
     position:relative;
    color: #2196F3 !important;
     margin: 0;
     overflow: hidden;
  }

  .container-input-file:hover{
     cursor: pointer !important;
  }

   #inputFile:hover{
     cursor: pointer !important;
   }

  #inputFile{
    opacity:0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .messageValidated{
      color:red;
      opacity: .8;
      font-size: 16px;
      margin-right: auto !important;
      margin-left: 5px;
  }

  .btn-delete-file{
      position: absolute;
      top:10px;
      right:0;
  }

</style>
