<template>
    <v-row>
        <v-select
            :no-data-text="$t('qrGenerator.productsTable.data_available')"
            class="pl-3 pr-3"
            autowidth
            :items="profilesArray"
            item-text="customID"
            :label="$t('qrGenerator.payableTo.select_user')"
            item-value="id"
            v-model="select_master"
            @change="infofields"
            return-object
            outlined
            dense
        ></v-select>
    </v-row>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import { mapGetters, mapState } from 'vuex';
export default {
    mixins: [userMixin],
    data() {
        return {
            profilesArray: [],
            select_master: "",
            debitorData: [],
            debtors: [],
            debitors: [],
        }
    },
    computed: {
        ...mapGetters({
            user: 'User/user',          
            creditor: 'User/creditor'
        }),
        reference:{
            get(){
                return this.$store.state.QR.reference;
            },
            set(value){
                return this.$store.state.QR.reference = value;
            }
        },
        userHasWir(){
          return this.$store.state.User.wir_active;
        },
        SCORTabSelected:{
            get(){
                return this.$store.state.QR.SCORTabSelected;
            },
            set(value){
                return this.$store.state.QR.SCORTabSelected = value;
            }            
        },
        QRRTabSelected:{
             get(){
                return this.$store.state.QR.QRRTabSelected;
            },
            set(value){
                return this.$store.state.QR.QRRTabSelected = value;
            }                
        }
    },
    watch:{
        select_master(){
            if(this.creditor.typeReference !== 'NON'){
                let getDebtorReference = this.$store.getters['QR/asignCorrectDebtorReference'];
                if(getDebtorReference){
                    this.reference = getDebtorReference;
                }else{
                    if(this.creditor.typeReference === 'SCOR'){
                        if(this.SCORTabSelected === 'AUTOMATIC')
                        this.reference = this.$store.getters['QR/actualReference'];
    
                    }else if(this.creditor.typeReference === 'QRR'){
                        if(this.QRRTabSelected === 'AUTOMATIC')
                        this.reference = this.$store.getters['QR/actualReference'];
                    }
                }
            }
        }
    },
    methods: {
        infofields() {
            
            //loads into the debtor's fields the data corresponding to the selected debitor
            let fields = this.profilesArray.filter(element => {
                return this.select_master.id == element.id;
            });

            let selcreditor = fields[0];
       
           let currentAccount    = selcreditor.accountNumber;
           let currentAccountWir = selcreditor.accountWirNumber;
            if(this.creditor.typeReference === 'QRR'){
                if(this.userHasWir){
                    currentAccountWir = selcreditor.accountWirQRNumber;    
                }else{
                    currentAccount = selcreditor.accountQRNumber;
                }
            }  
    
            this.creditor.account         = currentAccount;
            this.creditor.accountWir      = currentAccountWir;
        
            this.creditor.name            = selcreditor.firstName;
            this.creditor.last_name       = selcreditor.lastName;
            this.creditor.address.street  = selcreditor.street;
            this.creditor.address.number  = selcreditor.houseNumber;
            this.creditor.address.zip     = selcreditor.zipCode;
            this.creditor.address.city    = selcreditor.city;
            this.creditor.address.country = selcreditor.selectedCountry;
            this.$store.commit("QR/setCurrency", selcreditor.selectedCurrecy || 'CHF');
            // Saves the info in the variables, then the variables are called by the checkbox in the other component
            this.user.numberuid      = selcreditor.UIDNumber;
            this.user.referen        = selcreditor.reference;
            this.user.reference      = selcreditor.reference;
            this.user.account        = selcreditor.accountNumber;
            this.user.qr_account     = selcreditor.accountQRNumber;
            this.user.account_wir    = selcreditor.accountWirNumber;
            this.user.qr_account_wir = selcreditor.accountWirQRNumber; 

            this.$parent.$parent.$parent.$refs.qrBilling.Bringfrommaster();
        },

        async getProfiles() {
            await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/profiles`)
            .then(async response => {
            if (response) {
                if(response.status == 200)
                {     
                    if (response.profiles.length > 0) {
                        let getProfilesLength = response.profiles.length;
                        for (let i = 0; i < getProfilesLength; i++) {
                            await this.profilesArray.push({
                                id: response.profiles[i].id,
                                customID:response.profiles[i].custom_id !== undefined && response.profiles[i].custom_id !== null ? response.profiles[i].custom_id : `- Profile ${i}`,
                                accountNumber: response.profiles[i].iban,
                                accountQRNumber: response.profiles[i].qr_iban,
                                accountWirNumber: response.profiles[i].iban_wir,
                                accountWirQRNumber: response.profiles[i].qr_iban_wir,
                                UIDNumber: response.profiles[i].uid_number,
                                reference: response.profiles[i].reference,
                                firstName: response.profiles[i].first_name,
                                selectedCurrecy: response.profiles[i].currency,
                                lastName: response.profiles[i].last_name,
                                street: response.profiles[i].street,
                                houseNumber: response.profiles[i].house_number,
                                zipCode: response.profiles[i].zip_code,
                                city: response.profiles[i].city,
                                selectedCountry: response.profiles[i].country,
                                defaultProfile: response.profiles[i].default
                            });
                            if(this.profilesArray[i].defaultProfile == true){
                                this.select_master = response.profiles[i]
                                this.creditor.account         = response.profiles[i].iban || response.profiles[i].qr_iban;
                                this.creditor.accountWir      = response.profiles[i].iban_wir || response.profiles[i].qr_iban_wir;
                                this.creditor.name            = response.profiles[i].first_name;
                                this.creditor.last_name       = response.profiles[i].last_name;
                                this.creditor.address.street  = response.profiles[i].street;
                                this.creditor.address.number  = response.profiles[i].house_number;
                                this.creditor.address.zip     = response.profiles[i].zip_code;
                                this.creditor.address.city    = response.profiles[i].city;
                                this.creditor.address.country = response.profiles[i].country;
                                this.$store.commit("QR/setCurrency", response.profiles[i].currency || 'CHF');
                                this.user.numberuid  = response.profiles[i].uid_number;
                                this.user.reference    = response.profiles[i].reference;
                                // this.user.reference  = response.profiles[i].reference;
                                
                                this.user.account    = response.profiles[i].iban;
                                this.user.qr_account = response.profiles[i].qr_iban;
                                this.user.account_wir = response.profiles[i].iban_wir;
                                this.user.qr_account_wir = response.profiles[i].qr_iban_wir;

                            }
                        }
                    }
                }
                else {
                     this.$store.commit('global/setSnackbar', {message: response.message});
                }
            }
            })
            .catch(err => {
                this.enableLoader = false;
                this.$handleCatch(err);
            });
        },
    },
    async beforeMount() {
        await this.getProfiles();
    }
}
</script>

<style>

</style>