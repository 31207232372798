<template>
   <v-select
        :no-data-text="$t('qrGenerator.productsTable.data_available')"
        autowidth
        :items="debtorList"
        item-text="fullName"
        :label="$t('qrGenerator.payableBy.debtorSelector_field')"
        item-value="id"
        v-model="debtorSelected"
        return-object 
        outlined
        dense
    >

    </v-select>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            debtorList: [],
        }
    },
    async created() {
        await this.getDebtors();
    },
    mounted() {
        this.$store.watch(state => state.templateEditor.debtorSelected, (newValue, oldValue) => {
            
            const { name, last_name, address_suffix, street, zip, city, number } = this.debitor;
            const debtor = newValue;
            
            let str = '';

            if(name || last_name) {
                if(name) {
                    str += name + ' ';
                }

                if(last_name) {
                    str += last_name;
                }
                str += '\n';
            }

            if(address_suffix) {
                str += address_suffix;
                str += '\n';
            }

            if(street || number) {
                if(street) {
                    str += street + ' ';
                }

                if(number) {
                    str += number
                }
                str += '\n';
            }

            if(zip || city) {
                if(zip) {
                    str += zip + ' '; 
                }

                if(city) {
                    str += city;
                }
            }

            this.$store.state.templateEditor.fields.customerSection.content = str;

        })
    },
    computed: {
        ...mapGetters({
            debitor: 'templateEditor/debitor',
        }),
        debtorSelected: {
            get() {
                return this.$store.state.templateEditor.debtorSelected;
            },
            set({ID}) {
                // TODO: set info object of the debtor
                this.$store.state.templateEditor.debtorSelected = ID;
            }
        },
        debitorsData: {
            get() {
                return this.$store.state.templateEditor.debitorsData;
            },
            set(value) {
                this.$store.state.templateEditor.debitorsData = value;
            }
        }
    },
    methods: {
        async getDebtors() {
            await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/debitorsData`)
            .then(response => {
              if (response !== null && response !== undefined) {
                  var array = response.debitors;
                  var hash = {};
                  array = array.filter(function(current) {
                  let go =
                      current.name !== undefined
                      ? String(current.name) + String(current.last_name)
                      : String(current.last_name)
                      ? String(current.street)
                      : String(current.street)
                      ? Number(current.number)
                      : Number(current.number)
                      ? Number(current.zip)
                      : Number(current.zip)
                      ? String(current.country)
                      : String(current.country);
                  let exists = !hash[go] || false;
                  hash[go] = true;
                  return exists;
                  });
                  const _debitorsData = [];
                  array.forEach(element => {
                      this.debtorList.push({
                          fullName: element.name + " " + element.last_name,
                          ID: element.id
                      });
                      _debitorsData.push(element);
                  });
                  this.debitorsData.push(..._debitorsData)
              }
            })
            .catch(this.$handleCatch);
        }
    }
}
</script>

<style>

</style>