/**
 * 
 * @param reference without white spaces
 */
 function QRR(reference) {
    var table = [0, 9, 4, 6, 8, 2, 7, 1, 3, 5];
    var uebertrag = 0;
  
    for (var i = 0; i < reference.length; i++) {
  
      c = parseInt(reference.substring(i, i + 1), 10);
      index = (uebertrag + c) % 10;
  
      uebertrag = table[index];
    }
    return (10 - uebertrag) % 10;
}


module.exports = {
    QRR
}