<template>
    <v-row>
        <v-col cols="12">
            <v-btn class="white-text" :disabled="filePermission" :color="$vuetify.theme.themes.light.blue.primary" block
                small @click="() => { this.$parent.tabReckoner(false) }" :loading="DOMElements.generate.loading">{{
                    $t('qrGeneratorFree.miscellaneous.action_buttons.generate_button') }}</v-btn>
        </v-col>
        <v-col v-if="downloadPDFButton" cols="6">
            <v-btn :color="$vuetify.theme.themes.light.blue.primary" block small @click="downloadPDF"
                :loading="DOMElements.downloadPDF.loading" dark>{{
                    $t('qrGeneratorFree.miscellaneous.action_buttons.downloadPDF_button') }}</v-btn>
        </v-col>
        <v-col v-if="downloadPNGButton" cols="6">
            <v-btn :color="$vuetify.theme.themes.light.blue.primary" block small @click="downloadPNG"
                :loading="DOMElements.downloadPNG.loading" dark>{{
                    $t('qrGeneratorFree.miscellaneous.action_buttons.downloadPNG_button') }}</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import { mapGetters } from 'vuex';
import { validateCharac } from "../../../functions/invalidCharacters";

const pdf = require("pdfjs-dist");

export default {
    props: {
        filePermission: {
            type: Boolean,
            default: true
        }
    },
    mixins: [userMixin],
    data() {
        return {
            drawer: false,
            fileName: undefined,
            downloadPDFButton: false,
            downloadPNGButton: false,
            taskSuccesful: false,
            DOMElements: {
                generate: {
                    loading: false,
                },
                downloadPDF: {
                    loading: false,
                },
                downloadPNG: {
                    loading: false,
                }
            },
            ResponseFileName: "",
            taskSuccessful: false,
            newReferenceId: '',
            membership: '',
            creditsused: "",
            membcredits: "",
            remainingcredits: "",
        }
    },
    computed: {
        ...mapGetters({
            productsItems: 'User/items',
            structuredMap: "AI/structuredMap",
            unstructured: "AI/unstructuredMessage",
            SCORReference: 'QR/SCORAReference',
        }),
        currency() {
            if (this.$store.state.QR.currency == "" && this.wirActive) {
                return "CHF"
            }
            return this.$store.state.QR.currency;
        },

        wirActive() {
            return this.$store.state.User.wir_active;
        },


        reference: {
            get() {
                return this.$store.state.QR.reference;
            },
            set(reference) {
                this.$store.commit("QR/setReference", reference);
            }
        },
        amountStatus: {
            get() {
                return this.$store.state.QR.stateAmount;
            }
        },
        debitor: {
            get() {
                return this.$store.state.QR.debitor
            },
            set(value) {
                Object.assign(this.$store.state.QR.debitor, value)
                // this.$store.state.QR.debitor = value;
            }
        },
        amount: {
            get() {
                return this.$store.state.QR.amount
            },
            set(newValue) {
                this.$store.commit('QR/setAmount', newValue)
            }
        },
        percentage: {
            get() {
                return this.$store.state.QR.wirPercentage;
            },
            set(newValue) {
                this.$store.commit('Qr/setPercentage', newValue)
            }
        }
    },
    methods: {
        async generate(addExtra) {

            let validation1 = this.$parent.$refs.qrFormCreditor.$refs.form.validate();
            let validation2 = this.$parent.$refs.qrFormDebtor.$refs.form.validate();
            let validation3 = this.$parent.$refs.qrProductTable.$refs.form.validate();
            let validation4 = this.$parent.$refs.qrBilling.$refs.form.validate();

            let currentReference = this.$store.getters['QR/reference'];
            let account = this.wirActive ? this.creditor.accountWir : this.creditor.account;

            if (account) {
                // QR IBAN & QRR
                if (account.charAt(5) == 3) {
                    if (currentReference) {
                        if ((currentReference.replaceAll(' ', '')).length !== 27) {
                            return this.$store.commit('global/setSnackbar', 'invalid QR IBAN / QRR combination')
                        }
                    }
                }

                // QR & SCOR
                if (account.charAt(5) != 3) {
                    if (currentReference) {
                        if (currentReference.replaceAll(' ', '').length >= 5 && currentReference.replaceAll(' ', '').length > 25) {
                            return this.$store.commit('global/setSnackbar', 'invalid IBAN / SCOR combination');
                        }
                    }
                }
            } else {
                return this.$store.commit('global/setSnackbar', 'You did not provide a QR / QR IBAN');
            }


            if (!validation1 || !validation2 || !validation3 || !validation4) {
                return this.$store.commit("global/setSnackbar", {
                    message: "Invalid inputs"
                });
            }

            // Add a extra verification in case a bug happend and let the user to continue even if the fields have a invalid character
            if (this.getValuesToValidate()) {
                return this.$store.commit('global/setSnackbar', { message: this.$t("Globals.error_character") });
            }

            this.downloadPDFButton = false;
            this.downloadPNGButton = false;
            let unstructuredMessage = this.$store.getters["AI/unstructuredMessage"].plain || "";
            let AdditionalInformationString = this.$store.getters["AI/structuredMessage"].plain || "";
            let dateObject = this.getInvoiceDates();

            this.$store.commit("QR/setSkeleton", true);
            this.$store.commit("QR/setProcessing", true);
            this.$store.commit("QR/qrSVG", { qrSVG: "" });
            this.$store.commit("QR/qrSVG2", { qrSVG2: "" });
            this.taskSuccessful = false;

            let params = {
                currencies: [],
                reference: currentReference,
                profileID: this.debitor.id,
                language: this.$store.state.User.language,
                unstructuredMessage: unstructuredMessage,
                AdditionalInformationString: AdditionalInformationString,
                creditor: {
                    name: this.creditor.name,
                    last_name: this.creditor.last_name,
                    street: this.creditor.address.street,
                    address: this.creditor.address.street + (this.creditor.address.street && this.creditor.address.number ? ' ' : '') + this.creditor.address.number,
                    number: this.creditor.address.number,
                    zip: this.creditor.address.zip,
                    city: this.creditor.address.city,
                    account: this.creditor.account.replace(/\s+/g, ""),
                    country: this.creditor.address.country
                },
                debitor: {
                    id: this.debitor.id,
                    name: this.debitor.name,
                    last_name: this.debitor.last_name,
                    address: this.debitor.address.street + (this.debitor.address.street && this.debitor.address.number ? ' ' : '') + this.debitor.address.number,
                    street: this.debitor.address.street,
                    number: this.debitor.address.number,
                    zip: this.debitor.address.zip,
                    account: this.debitor.account,
                    city: this.debitor.address.city,
                    country: this.debitor.address.country
                },
                additionalInformation: this.structuredMap
            };

            //add the currencies to json params 
            //the options are CHF / CHW 

            //if currency has not value take chf by default
            if (this.currency == "EUR") {

                params.currencies.push({
                    currency: "EUR",
                    amount: this.getNewAmount(this.amount)
                });

            }

            if (this.currency == "CHF") {

                params.currencies.push({
                    currency: "CHF",
                    amount: this.getNewAmount(this.amount)
                });

            }

            if (this.currency == "CHW") {

                params.currencies.push({
                    currency: "CHW",
                    amount: this.getNewAmount(this.amount)
                });

                //send iban wir 
                params.creditor.account = this.creditor.accountWir.replace(/\s+/g, "");

            }

            if (this.currency == "CHF/CHW") {

                let amountCHW = 0;
                let amountCHF = 0;
                let result = 0;
                //calculate the percentaje of amount abount wir 
                if (this.percentage != 0) {

                    amountCHW = (this.percentage / 100) * this.amount;


                    let decimalCHW = Math.floor((amountCHW - Math.floor(amountCHW)) * 100);
                    let centesimaCHW = decimalCHW % 10;

                    //rounder the amount of CHW
                    if (centesimaCHW >= 1 && centesimaCHW < 5) {

                        let decimals = Math.floor(decimalCHW / 10) / 10 + 0.05;
                        result = Math.floor(amountCHW) + Math.floor(decimals * 100) / 100;
                        amountCHW = this.getNewAmount(String(result));

                    } else if (centesimaCHW > 5 && centesimaCHW <= 9) {

                        result = Math.floor(amountCHW) + ((decimalCHW - centesimaCHW) + 10) / 100;
                        amountCHW = this.getNewAmount(String(result));

                    } else {

                        result = (Math.floor(amountCHW) + Math.floor((amountCHW - Math.floor(amountCHW)) * 100) / 100)
                        amountCHW = this.getNewAmount(String(result));
                    }

                    amountCHF = this.amount - amountCHW;

                    params.currencies.push({
                        currency: "CHF",
                        amount: this.getNewAmount(amountCHF)
                    });

                    params.currencies.push({
                        currency: "CHW",
                        amount: this.getNewAmount(amountCHW)
                    });

                }

                //send iban wir 
                params.creditor.account_wir = this.creditor?.accountWir.replace(/\s+/g, "") || " ";
            }


            let data = {
                QRData: params,
                // TableData: this.productsItems,
                InvoiceDates: dateObject,
                addExtra: addExtra,
                saveDebitor: this.$parent.$refs['debtor.selection'].tabdebitor == 'tab-1' ? true : false,
            };

            console.log("data", data)
            //A request is made to the "/api/exampleQr" endpoint, to generate the invoice
            await fetch(`${process.env.VUE_APP_SERVER_URL}/api/premium/generateQR`, {
                method: "POST",
                body: JSON.stringify(data),
                credentials: "include",
                headers: {
                    "X-CSRF-Token": `${this.$store.getters['global/_csrf']}`
                }
            })
                .then(result => {
                    if (result.status == 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    return result.json()
                })
                .then(async response => {
                    if (response !== undefined && response !== null) {
                        if (response.status === 200) {

                            console.log("response ", response)
                            //If the response status is 200, the qr svg is placed in the number part preview
                            //And the download buttons are set to be visible
                            this.qrSVG = response.qrsvg; // parent
                            this.qrSVG2 = response.qrsvg2; // parent
                            this.downloadPDFButton = true;
                            this.downloadPNGButton = true;
                            this.ResponseFileName = response.file;
                            this.$store.commit("QR/setSkeleton", false);
                            this.$store.commit("QR/qrSVG", { qrSVG: response.qrsvg });
                            this.$store.commit("QR/qrSVG2", { qrSVG2: response.qrsvg2 });
                            this.taskSuccessful = true;
                            this.newReferenceId = response.reference;

                            this.debitor = {
                                reference: this.$store.state.QR.reference,
                            }
                            return this.$store.commit("global/setSnackbar", {
                                message: response.message
                            });

                        } else {
                            if (response.status == 403 && response.message == "Your account has insufficient credits") {
                                this.dialog = true; // parent
                                this.$parent.updateBuyCredits += 1;
                                this.$parent.price = response.data.price;
                                this.$parent.total = response.data.total;
                                this.membership = response.data.membership;
                                this.creditsused = response.data.creditsUsed;
                                this.membcredits = response.data.membershipCredits;
                                this.$parent.remainingcredits = response.data.membershipCredits - response.data.creditsUsed;
                                //if membership is single tariff is going to show a dialog
                                if (this.$parent.remainingcredits <= 0 || this.membership == 5) {
                                    if (this.membership === 5) {
                                        this.$parent.popup = true;
                                        this.$parent.titlepopup = this.$t("qrGenerator.windowsCredit.title_singletariff");
                                        this.$parent.subtitlepopup = this.$t("qrGenerator.windowsCredit.subtitle_insuficient");
                                        this.$parent.updateBuyCredits += 1;
                                    } else {
                                        //Activates de popup that show the data
                                        this.$parent.creditsinsuf = true;
                                        this.$parent.popup = true;
                                        this.$parent.titlepopup = this.$t("qrGenerator.windowsCredit.title_insuficientcr");
                                        this.$parent.subtitlepopup = this.$t("qrGenerator.windowsCredit.subtitle_insuficient");
                                        this.$parent.updateBuyCredits += 1;
                                        // this.DOMElements.checkcredits.loading = false;
                                    }
                                }
                            } else {
                                this.$store.commit("global/setSnackbar", {
                                    message: response.message
                                });
                            }
                        }
                    } else {
                        this.$store.commit("global/setSnackbar", {
                            message: this.$t("StepByStep.invalid_response")
                        });
                    }
                })
                .catch(err => {
                    return this.$store.commit("global/setSnackbar", { message: err });
                });
            this.$store.commit("QR/setProcessing", false);
            if (this.taskSuccessful) window.scrollTo(0, document.body.scrollHeight);
        },

        async downloadPDF() {
            //Makes a requesto to "/api/download" endpoint
            //Downloads the created invoice as pdf
            this.DOMElements.downloadPDF.loading = true;
            await fetch(`${process.env.VUE_APP_SERVER_URL}/api/download?file=${this.ResponseFileName}`, {
                method: "GET",
                credentials: "include"
            })
                .then(result => {
                    if (result.status == 200) {
                        return result.blob();
                    } else {
                        return result.json();
                    }
                })
                .then(response => {
                    if (response !== null && response !== undefined) {
                        if (response.status) {
                            return this.$store.commit(`global/setSnackbar`, {
                                message: response.message
                            });
                        }
                        let url = window.URL.createObjectURL(response);
                        let a = document.createElement("a");
                        a.href = url;
                        let today = new Date();
                        a.download = `${today.getFullYear()}.${("0" + (today.getMonth() + 1)).slice(-2)}.${("0" + today.getDate()).slice(-2)}${!!this.debitor.last_name ? `_${this.debitor.last_name}` : ''}${!!this.debitor.name ? `_${this.debitor.name}` : ''}.pdf`;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        return this.$store.commit("global/setSnackbar", {
                            message: this.$t("snack_confirm.success")
                        });
                    } else {
                        return this.$store.commit("global/setSnackbar", {
                            message: this.$t("StepByStep.invalid_response")
                        });
                    }
                })
                .catch(err => {
                    return this.$store.commit("global/setSnackbar", {
                        message: "Unable to download PDF file"
                    });
                });
            this.DOMElements.downloadPDF.loading = false;
        },

        async downloadPNG() {
            //Makes a requesto to "/api/download" endpoint
            //Downloads the created payment part as png
            this.DOMElements.downloadPNG.loading = true;
            await fetch(`${process.env.VUE_APP_SERVER_URL}/api/download?file=${this.ResponseFileName}`, {
                method: "GET",
                credentials: "include"
            })
                .then(result => {
                    if (result.status == 200) {
                        return result.blob();
                    } else {
                        return result.json();
                    }
                })
                .then(response => {
                    if (response !== null && response !== undefined) {
                        if (response.status) {
                            return this.$store.commit(`global/setSnackbar`, {
                                message: response.message
                            });
                        }
                        let url = window.URL.createObjectURL(response);
                        this.getPNGFile(url);
                        return this.$store.commit("global/setSnackbar", {
                            message: this.$t("snack_confirm.success")
                        });
                    } else {
                        return this.$store.commit("global/setSnackbar", {
                            message: this.$t("StepByStep.invalid_response")
                        });
                    }
                })
                .catch(err => {
                    return this.$store.commit("global/setSnackbar", {
                        message: "Unable to download PNG file"
                    });
                });
            this.DOMElements.downloadPNG.loading = false;

        },
        getNewAmount(amount) {
            //Sets the amount to a correct format befor sending it to the server
            if (!this.amountStatus) {
                if (typeof amount == "string") {
                    return Number(amount.replace(/\s+/g, ""));
                } else {
                    return amount;
                }
            } else {
                return undefined;
            }
        },

        getPNGFile(data) {
            //This function converts the numbert part section
            //of the received pdf into a png file, and then downloads it
            let fileName = this.ResponseFileName;
            let scale = 2;
            let canvas = document.createElement("canvas");
            let twoD = canvas.getContext("2d");
            pdf.getDocument(data).then(doc => {
                doc.getPage(doc.numPages).then(page => {
                    var viewport = page.getViewport(scale);
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    page
                        .render({
                            canvasContext: twoD,
                            viewport: viewport
                        })
                        .then(function () {
                            twoD.globalCompositeOperation = "destination-over";
                            twoD.fillStyle = "#ffffff";
                            twoD.fillRect(0, 0, canvas.width, canvas.height);
                            let img = canvas.toDataURL("image/png");
                            fetch(img)
                                .then(result => {
                                    return result.blob();
                                })
                                .then(newblob => {
                                    let url = window.URL.createObjectURL(newblob);
                                    let a = document.createElement("a");
                                    a.href = url;
                                    a.download = `${fileName}.png`;
                                    document.body.appendChild(a);
                                    a.click();
                                    a.remove();
                                });
                        });
                });
            });
        },
        getInvoiceDates() {
            //Returns an object containing the values of the invoice dates
            //if the values are null the invoices won't be included
            const expeditionDate = this.$store.state.AI.structuredMessage.VAT_Date.start_date;
            const expirationDate = this.$store.state.AI.structuredMessage.VAT_Date.end_date;
            const dateObject = new Object();
            if (expeditionDate !== null && expeditionDate !== undefined) {
                dateObject["expeditionDate"] = expeditionDate;
            }
            if (expirationDate !== null && expirationDate !== undefined) {
                dateObject["expirationDate"] = expirationDate;
            }
            return dateObject;
        },
        async loadFile(addExtra) {

            // Verify if the fields don't have a invalid character
            if (this.getValuesToValidate()) {
                return this.$store.commit('global/setSnackbar', { message: this.$t("Globals.error_character") });
            }

            let currentReference = this.$store.getters['QR/reference'];

            let account = this.wirActive ? this.creditor.accountWir : this.creditor.account;

            if (account) {
                // QR IBAN & QRR
                if (account.charAt(5) == 3) {
                    if (currentReference) {
                        if ((currentReference.replaceAll(' ', '')).length !== 27) {
                            return this.$store.commit('global/setSnackbar', 'invalid QR IBAN / QRR combination')
                        }
                    }
                }

                // QR & SCOR
                if (account.charAt(5) != 3) {
                    if (currentReference) {
                        if (currentReference.replaceAll(' ', '').length >= 5 && currentReference.replaceAll(' ', '').length > 25) {
                            return this.$store.commit('global/setSnackbar', 'invalid IBAN / SCOR combination');
                        }
                    }
                }
            } else {
                return this.$store.commit('global/setSnackbar', 'You didnt provide a QR / QR IBAN');
            }
            this.DOMElements.generate.loading = true;
            //This function generates an invoice embeding 2 pdf files
            if (!this.$parent.validateForms()) {
                this.DOMElements.generate.loading = false;
                return this.$store.commit("global/setSnackbar", {
                    message: "Invalid inputs"
                });
            }

            this.downloadPDFButton = false;
            this.downloadPNGButton = false;

            this.$store.commit("QR/setSkeleton", true);
            this.$store.commit("QR/setProcessing", true);
            this.$store.commit("QR/qrSVG", { qrSVG: "" });
            this.$store.commit("QR/qrSVG2", { qrSVG2: "" });
            this.taskSuccessful = false;

            let newAmount = this.getNewAmount(this.amount);
            let dateObject = this.getInvoiceDates();
            let params = {
                saveDebitor: this.$parent.$refs['debtor.selection'].tabdebitor == 'tab-1' ? true : false,
                addExtra: addExtra,
                InvoiceDates: dateObject,
                additionalInformation: this.$store.getters["AI/structuredMessage"].plain || "",
                additionalInformationMap: this.structuredMap,
                unstructuredMessage: this.$store.getters["AI/unstructuredMessage"].plain || "",
                language: this.$store.state.User.language,
                placement: this.$parent.$refs.qrProductTable.placementSelected ? this.$parent.$refs.qrProductTable.placementSelected : 'NP',
                currencies: [],
                amount: newAmount,
                reference: currentReference,
                profileID: this.debitor.id,
                creditor: {
                    name: this.creditor.name,
                    last_name: this.creditor.last_name,
                    address: this.creditor.address.street + (this.creditor.address.street && this.creditor.address.number ? ' ' : '') + this.creditor.address.number,
                    street: this.creditor.address.street,
                    number: this.creditor.address.number,
                    zip: this.creditor.address.zip,
                    city: this.creditor.address.city,
                    account: this.creditor.account.replace(/\s+/g, ""),
                    country: this.creditor.address.country
                },
                debitor: {
                    id: this.debitor.id,
                    name: this.debitor.name,
                    last_name: this.debitor.last_name,
                    address: this.debitor.address.street + (this.debitor.address.street && this.debitor.address.number ? ' ' : '') + this.debitor.address.number,
                    street: this.debitor.address.street,
                    number: this.debitor.address.number,
                    zip: this.debitor.address.zip,
                    account: this.debitor.account,
                    city: this.debitor.address.city,
                    country: this.debitor.address.country
                }
            };

            //if currency has not value take chf by default
            if (this.currency == "EUR") {

                params.currencies.push({
                    currency: "EUR",
                    amount: this.getNewAmount(this.amount)
                });

            }

            if (this.currency == "CHF") {

                params.currencies.push({
                    currency: "CHF",
                    amount: this.getNewAmount(this.amount)
                });

            }

            if (this.currency == "CHW") {

                params.currencies.push({
                    currency: "CHW",
                    amount: this.getNewAmount(this.amount)
                });

                //send iban wir 
                params.creditor.account = this.creditor.accountWir.replace(/\s+/g, "");

            }

            if (this.currency == "CHF/CHW") {

                let amountCHW = 0;
                let amountCHF = 0;
                let result = 0;
                //calculate the percentaje of amount abount wir 
                if (this.percentage != 0) {

                    amountCHW = (this.percentage / 100) * this.amount;


                    let decimalCHW = Math.floor((amountCHW - Math.floor(amountCHW)) * 100);
                    let centesimaCHW = decimalCHW % 10;

                    //rounder the amount of CHW
                    if (centesimaCHW >= 1 && centesimaCHW < 5) {

                        let decimals = Math.floor(decimalCHW / 10) / 10 + 0.05;
                        result = Math.floor(amountCHW) + Math.floor(decimals * 100) / 100;
                        amountCHW = this.getNewAmount(String(result));

                    } else if (centesimaCHW > 5 && centesimaCHW <= 9) {

                        result = Math.floor(amountCHW) + ((decimalCHW - centesimaCHW) + 10) / 100;
                        amountCHW = this.getNewAmount(String(result));

                    } else {

                        result = (Math.floor(amountCHW) + Math.floor((amountCHW - Math.floor(amountCHW)) * 100) / 100)
                        amountCHW = this.getNewAmount(String(result));
                    }

                    amountCHF = this.amount - amountCHW;

                    params.currencies.push({
                        currency: "CHF",
                        amount: this.getNewAmount(amountCHF)
                    });

                    params.currencies.push({
                        currency: "CHW",
                        amount: this.getNewAmount(amountCHW)
                    });

                }

                //send iban wir 
                params.creditor.account_wir = this.creditor?.accountWir.replace(/\s+/g, "") || " ";
            }


            params = JSON.stringify(params);


            let objeto = new FormData();
            objeto.append("file", this.$parent.$refs.qrProductTable.pdfFile);
            let newMap = encodeURI(params);


            console.log("newMap => ", newMap)
            //Makes a request to the "/api/QrconcatPdf"
            //sends the data as parameters & the pdf file that the user wants to embed
            await fetch(`${process.env.VUE_APP_SERVER_URL}/api/premium/qr/generate/pdf?data=${newMap}`,
                {
                    method: "POST",
                    credentials: "include",
                    body: objeto,
                    headers: {
                        'X-CSRF-Token': `${this.$store.getters['global/_csrf']}`
                    }
                })
                .then(result => result.json())
                .then(async response => {
                    if (response !== undefined && response !== null) {
                        if (response.status === 406) {
                            return this.$store.commit('global/setSnackbar', { message: this.$t("Globals.error_character") });
                        }
                        if (response.status === 200) {

                            console.log(response)
                            //If the response status is 200, the qr svg is placed in the number part preview
                            //And the download buttons are set to be visible
                            this.$store.commit("QR/qrSVG", { qrSVG: response.content });
                            this.qrSVG = response.content;
                            this.downloadPDFButton = true;
                            this.downloadPNGButton = true;
                            this.ResponseFileName = response.file;
                            this.$store.commit("QR/setSkeleton", false);
                            this.taskSuccessful = true;
                            this.newReferenceId = response.reference;
                            // this.DOMElements.checkcredits.loading = false;

                            let objeto = new FormData();
                            objeto.append("file", this.$parent.$refs.qrProductTable.pdfFile);

                            this.debitor = {
                                reference: this.$store.state.QR.reference || ''
                            }
                            return this.$store.commit("global/setSnackbar", {
                                message: response.message
                            });
                        } else {
                            if (response.status == 403) {
                                this.$parent.price = response.data.price;
                                this.$parent.total = response.data.total;
                                this.membership = response.data.membership;
                                this.creditsused = response.data.creditsUsed;
                                this.membcredits = response.data.membershipCredits;
                                this.$parent.remainingcredits = response.data.membershipCredits - response.data.creditsUsed;
                                //if membership is single tariff is going to show a dialog
                                if (this.remainingcredits <= 0 || this.membership == 5) {
                                    if (this.membership === 5) {
                                        this.$parent.popup = true;
                                        this.$parent.titlepopup = this.$t("qrGenerator.windowsCredit.title_singletariff");
                                        this.$parent.subtitlepopup = this.$t("qrGenerator.windowsCredit.subtitle_insuficient");
                                        this.$parent.updateBuyCredits += 1;
                                    } else {
                                        //Activates de popup that show the data
                                        this.$parent.creditsinsuf = true;
                                        this.$parent.popup = true;
                                        this.$parent.titlepopup = this.$t("qrGenerator.windowsCredit.title_insuficientcr");
                                        this.$parent.subtitlepopup = this.$t("qrGenerator.windowsCredit.subtitle_insuficient");
                                        this.$parent.updateBuyCredits += 1;
                                    }
                                }
                            }
                            if (response.message != 'Your account has insufficient credits') {
                                this.$store.commit("global/setSnackbar", {
                                    message: response.message
                                });
                            }
                        }
                    } else {
                        this.$store.commit("global/setSnackbar", {
                            message: this.$t("StepByStep.invalid_response")
                        });
                    }
                })
                .catch(err => {
                    this.$store.commit("global/setSnackbar", { message: err });
                    // this.DOMElements.checkcredits.loading = false;
                });
            this.$store.commit("QR/setProcessing", false);
            this.DOMElements.generate.loading = false;
            if (this.taskSuccessful) window.scrollTo(0, document.body.scrollHeight);
        },
        getValuesToValidate() {

            // ------ Account Information ------
            let accountName = this.$parent.$refs.qrFormCreditor.creditor.name;
            let accountLastName = this.$parent.$refs.qrFormCreditor.creditor.last_name;
            let accountStreet = this.$parent.$refs.qrFormCreditor.creditor.address.street;
            let accountNumber = this.$parent.$refs.qrFormCreditor.creditor.address.number;
            let accountCity = this.$parent.$refs.qrFormCreditor.creditor.address.city;

            // ------ Debitor Information ------
            let debitorName = this.$parent.$refs.qrFormDebtor.debitor.name;
            let debitorLastName = this.$parent.$refs.qrFormDebtor.debitor.last_name;
            let debitorStreet = this.$parent.$refs.qrFormDebtor.debitor.address.street;
            let debitorNumber = this.$parent.$refs.qrFormDebtor.debitor.address.number;
            let debitorCity = this.$parent.$refs.qrFormDebtor.debitor.address.city;
            let debitorMessage = this.$parent.$refs.qrFormDebtor.unstructuredMessage;

            // ------ Billing Information ------
            let referenceBilling = this.$parent.$refs.qrProductTable.CustomerReference;

            let verificationArray = [
                accountName,
                accountLastName,
                accountStreet,
                accountNumber,
                accountCity,
                debitorName,
                debitorLastName,
                debitorStreet,
                debitorNumber,
                debitorCity,
                debitorMessage,
                referenceBilling
            ];

            let result = validateCharac(verificationArray).status;
            return result;
        }
    },
}

</script>


<style scoped>
.white-text {
    color: white !important;
}
</style>