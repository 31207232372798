<template>
  <v-row>
    <v-col cols="12">
      <v-col cols="12">
        <v-text-field :label="$t('TemplateList.create_template.template_name')" v-model="template_name"
          :rules="[rules.isRequired]"></v-text-field>
        <font-selector :font="fields.font" @update-font="updateFont" />
        <v-checkbox v-model="isDefault" label="Default"></v-checkbox>

        <v-checkbox v-model="hiddeProducts.value" :label="$t('TemplateList.create_template.hiddeTable')"></v-checkbox>
      </v-col>
    </v-col>
    <v-col cols="12">
      <v-col cols="12" class="py-0">
        <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')"
          :label="$t('TemplateList.create_template.section')" v-model="section" :items="sectionItems" item-text="text"
          item-value="section" :hint="$t('TemplateList.create_template.section_hint')" persistent-hint></v-select>
      </v-col>
      <v-col cols="12" no-gutters v-show="section === 'logo'">
        <v-row>
          <v-col cols="12">
            <!-- v-model="logo.url" -->
            <v-file-input v-model="fileLogo" accept="image/png,image/jpeg"
              :label="$t('TemplateList.create_template.logo_file')"
              :hint="$t('TemplateList.create_template.recomended_size')" persistent-hint
              :rules="[rules.onlyIMG, rules.less1MB]"></v-file-input>
            <v-btn block :color="$vuetify.theme.themes.light.blue.primary" dark v-if="myDeleteLogoButton"
              @click="deleteLogo">
              {{ $t("TemplateList.create_template.delete_logo") }}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-slider :label="$t('TemplateList.create_template.size')" v-model="logo.ranger" class="align-self-stretch"
              min="1" max="100" step="1"></v-slider>
          </v-col>
          <v-col cols="6">
            <v-text-field :label="$t('TemplateList.create_template.positionX')" type="number"
              v-model="logo.positionX"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field :label="$t('TemplateList.create_template.positionY')" type="number"
              v-model="logo.positionY"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-show="section === 'centerSection'">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("TemplateList.create_template.contact_details")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-textarea :label="$t('TemplateList.create_template.content')"
                    v-model="biller.contactDetails.content" :rules="[rules.fiveLines]"></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("TemplateList.create_template.subject")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field :label="$t('TemplateList.create_template.content')"
                    v-model="centerSection.subject.content" :rules="[rules.isRequired]"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>



          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("TemplateList.create_template.message")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-textarea :label="$t('TemplateList.create_template.content')"
                    v-model="centerSection.textLine.content"
                    :hint="$t('TemplateList.create_template.standardised_message')" :counter="175" persistent-hint
                    :rules="[local_rules.contentLengthMessage]"></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="hiddeProducts.value">
            <v-expansion-panel-header>{{
              $t("TemplateList.create_template.table")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-textarea :label="$t('TemplateList.create_template.content')" :counter="900" persistent-hint
                    v-model="centerSection.customerNotes.content"
                    :rules="[local_rules.contentLengthNotes]"></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" v-show="section === 'commentsSection'">
        <p>{{ $t("TemplateList.create_template.closing_sentence") }}</p>
        <v-textarea :label="$t('TemplateList.create_template.content')" v-model="commentsSection.textLine.content"
          :counter="175" :rules="[local_rules.contentLength]"></v-textarea>
      </v-col>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import fontSelectorControlsVue from "./editor.font.vue";
import itemsSectionVue from "./sections/itemsSection.vue";
import templateEditorDebtorSelectorVue from "./editor.debtorSelector.vue";
import rulesMixin from "@/mixins/rules";
import { mapGetters } from "vuex";

const { mapFields } = createHelpers({
  getterType: "templateEditor/getField",
  mutationType: "templateEditor/updateField",
});
export default {
  mixins: [rulesMixin],
  components: {
    "font-selector": fontSelectorControlsVue,
    "items-section": itemsSectionVue,
    "debtor-selector": templateEditorDebtorSelectorVue,
  },
  data() {
    return {
      templateName: "",
      section: "",
      sectionItems: [
        { section: "logo", text: this.$t("TemplateList.create_template.logo") },
        {
          section: "centerSection",
          text: this.$t("TemplateList.create_template.middle_section"),
        },
        {
          section: "commentsSection",
          text: this.$t("TemplateList.create_template.comment_area"),
        },
      ],
      myDeleteLogoButton: false,
      fileLogo: [],
      local_rules: {
        contentLength: (v) => {
          if (v) {
            return v.length <= 175
              ? true
              : `Invalid Comment Area Length, 175 characters per line must be met.`;
          }
          return true;
        },
        contentLengthMessage: (v) => {
          if (v) {
            let splittedMessage = v.split("\n");
            if (splittedMessage.length > 2 || this.flagValidationBreakLine) {
              return "Message must not have more than 2 lines.";
            }
            if (v.length > 175) {
              return "Invalid Comment Area Length, a max of 175 characters.";
            }
          }

          if (this.flagValidation) {
            return "A lot of text for just two lines";
          }

          return true;
        },

        contentLengthNotes: (v) => {
          if (v) {
            if (v.length > 900) {
              return "Invalid Comment Area Length, a max of 900 characters.";
            }
          }
          return true;
        },

      },
    };
  },
  watch: {
    fileLogo(newValue, oldValue) {
      this.$store.state.templateEditor.fields.logo.url = newValue;
    },
  },
  computed: {
    ...mapGetters({
      flagValidation: "templateEditor/flagValidation",
      flagValidationBreakLine: "templateEditor/flagValidationBreakLine",
    }),
    ...mapFields({
      fields: "fields",
      hiddeProducts: "fields.hiddeProducts",
      logo: "fields.logo",
      biller: "fields.biller",
      customerSection: "fields.customerSection",
      centerSection: "fields.centerSection",
      invoiceDetails: "fields.invoiceDetails",
      commentsSection: "fields.commentsSection",
      paymentDetails: "fields.paymentDetails",
      template_name: "template_name",
    }),
    isDefault: {
      get() {
        return this.$store.state.templateEditor.isDefault;
      },
      set(value) {
        this.$store.state.templateEditor.isDefault = value;
      },
    },
    InfoText() {
      if (this.placementSelected == "LP") {
        return this.$t("qrGenerator.productsTable.info_text");
      } else {
        return "";
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.myDeleteLogoButton = this.deleteLogoButton;
    });
  },
  methods: {
    deleteLogo() {
      this.$store.commit("templateEditor/reinitializeLogo");
      this.myDeleteLogoButton = false;
    },
    updateFont(value) {
      this.fields.font = value;
      this.$emit("update-font", value);
    },
  },
  props: {
    deleteLogoButton: {
      default: false,
    },
  },
};
</script>

<style scoped>
#controls-container {
  /* display: grid; */
  /* grid-template-rows: minmax(80vh, 0vh) 20vh; */
}
</style>