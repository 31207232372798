<template>
        <div>
            <v-row>
                <v-col>
                    <span>This method can be used to get the file you specify</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <api-page-description 
                    :items="items"
                    :title="'Request Arguments:'"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <api-page-description 
                    :items="items_response"
                    :title="'Response:'"
                    />                    
                </v-col>
            </v-row>
        </div>
</template>

<script>
import APIPageDesc from './default/API.Page.description.vue'
import APIPageExm from './default/API.Page.Example.vue' 
export default {
    name:'APIQRG',
    components:{
        'api-page-description':APIPageDesc,
        'api-page-example':APIPageExm
    },
    data(){
        return {
            items:[
                {
                    id:1,
                    title:'File',
                    description:'The name of the file to download.',
                    type:'String',
                    requirement:'Mandatory',
                    name:'file',
                }
            ],
            items_response:[
                {
                    id:1,
                    title:'File',
                    description:'The requested file.',
                    type:'File',
                },
            ],
        }
    }
}
</script>
