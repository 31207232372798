import Vue from 'vue'
import Router from 'vue-router' 

// ====================================================== //
// ==================== SIGNUP/LOGIN ==================== //
// ====================================================== //
import login from '@/components/login/login';

// ====================================================== //
// ======================= PASSWORD====================== //
// ====================================================== //
import RecoverPassword from '@/components/login/RecoverPassword';
import ResetPassword from '@/components/globals/ResetPassword';

// ====================================================== //
// ======================= COCKPIT ====================== //
// ====================================================== //
import cockpit from '@/components/cockpit/cockpit';

// ---- ENTRA POR URL ----
import cockpitHome from '@/components/cockpit/cockpit.home';
import stepByStep from '@/components/step-by-step/stepByStep';
import stepByStepPartner from '@/components/step-by-step-partner/stepByStep';

import qrPremium from '@/components/QR/premium/qr.premium.vue'
import APIDocs from '@/components/cockpit/API/API.Documentation.vue'

// ~~~~~~~~~~~~~~~ ARCHIEVE ~~~~~~~~~~~~~~ //
import debitors from '@/components/cockpit/archieve/debtors/debtors.vue';
import filesqrmassive from '@/components/cockpit/archieve/filesqrmassive';


// ~~~~~~~~~~~~~~~ SERVICES ~~~~~~~~~~~~~~ //
import listTemplate from '@/components/cockpit/template/list/list.vue'

// ~~~~~~~~~~~~~~~~~~ QR ~~~~~~~~~~~~~~~~~ //
import qr from '@/components/QR/qr';
// import QRSeries from '@/components/QR/premium/qr.series.invoices';
import QRSeries from '@/components/cockpit/QR-Massive-Steps/qr.series.invoices';


// ====================================================== //
// ==================== GENERAL VIEWS =================== //
// ====================================================== //
import store from './store/index';
import error404 from '@/components/404.vue';
import NewSettings from '@/components/cockpit/My Profile/Settings/NewSettings.vue';
import success from '@/components/globals/success.vue';
import error from '@/components/globals/error.vue';
import reactivation from '@/components/globals/reactivation.vue';

// ====================================================== //
// ======================= ADMIN ======================== //
// ====================================================== //
import dashboardHome from '@/components/cockpit/AdminComponents/dashboard.home';
import dashboard from '@/components/cockpit/AdminComponents/AdminHome'
import adminlogin from '@/components/login/adminLogin';
import dashboardUsers from '@/components/cockpit/AdminComponents/dashboardUsers';
import dashboardPartners from '@/components/cockpit/AdminComponents/dashboardPartners';
import generalProductSetting from '@/components/cockpit/AdminComponents/GeneralProductSetting.vue';
import generalProductInfo from '@/components/cockpit/AdminComponents/GeneralUsageInformation.vue';
import changePassword from '@/components/cockpit/AdminComponents/changePassword.vue';
import scheduledCharges from '@/components/cockpit/AdminComponents/dashboardScheduledCharges.vue';


// ~~~~~~~~~~~~~~~~~~ PROFILE ~~~~~~~~~~~~~~~~~ //

import profile from '@/components/step-by-step/profile';
import masterData from '@/components/cockpit/My Profile/MasterData/MasterData.vue';
import productUsage from '@/components/cockpit/My Profile/ProductUsage/productUsage';

//import testComponent  from '@/components/cockpit/ProductTable/Table';


Vue.use(Router)
const prod_routes = [
  { path: '/', component: qr },

  //{ path:'/test', component: testComponent },

  //-------------------------------------
  { path: '/:language?/login', component: login, name: 'Login' },
  { path: '/reset-password', component: ResetPassword, name: 'reset-password' },
  { path: '/recover-password', component: RecoverPassword, name: 'recover-password' },
  { path: '/success', component: success },
  { path: '/error', component: error },
  { path: '/reactivation', component: reactivation },
  { path: '/:language?/step-by-step/:step?', component: stepByStep, name: 'stepByStep' },
  { path: '/:language?/step-by-step-partner/:deep_link', component: stepByStepPartner },
  {
    path: '/:language?/cockpit/', component: cockpit, children: [
      { path: '', component: cockpitHome, name: 'Cockpit' },
      { path: 'qr-generator', component: qrPremium, name: 'qr-table' },
      { path: 'qr-series', component: QRSeries, name: 'qr-series' },
      { path: 'archieve/debtors', component: debitors, name: 'debtors' },
      { path: 'master-data', component: masterData, name: 'master-data' },
      { path: 'product-usage', component: productUsage, name: 'product-usage' },
      { path: 'settings', component: NewSettings, name: 'new-settings' },
      // { path: 'validations', component: validations, name: 'validations' },
      { path: 'template', component: listTemplate, name: 'template' },
      { path: 'archieve/filesqrmassive', component: filesqrmassive, name: 'filesqrmassive' },
      // { path: 'service/upload-debitors', component:  uploaddebitors, name: 'uploaddebitors'},
      // { path: 'service/qr-massive', component: qr_massive, name: 'qr-massive'},
    ]
  },
  { path: '/:language?/api-documentation', component: APIDocs, name: 'api-documentation' },
  { path: '/admin', component: adminlogin, name: 'admin' },
  {
    path: '/:language?/admin/dashboard', component: dashboard,
    children: [
      { path: '', component: dashboardHome, name: 'admin-dashboard' },
      { path: 'users', component: dashboardUsers, name: 'users' },
      { path: 'partners', component: dashboardPartners, name: 'partners' },
      { path: 'general-product-settings', component: generalProductSetting, name: 'general-product-settings' },
      { path: 'general-usage-info', component: generalProductInfo, name: 'general-usage-info' },
      { path: 'change-password', component: changePassword, name: 'change-password' },
      { path: 'scheduled-charges', component: scheduledCharges, name: 'scheduled-charges' }
    ]
  },
  { path: '/:language?/create-qr', component: qr },
  { path: '/:language?', component: qr },
  { path: '*', component: error404 },
];

const router = new Router({
  routes: [...prod_routes],
  linkActiveClass: "active",
  mode: "history"
})

//List of premium paths
let premiumArray = [
  'qr-massive',
  'uploaddebitors',
  'filesqrmassive',
  'debtors',
  'qr-table',
  'template',
];

//List of freemium paths
let freemiumArray = [
  'Cockpit',
  'bills',
  'validations',
  'archieve',
  'manage-fix-dates',
  'services',
  'subscriptions',
  'paymentSucces',
  'payment',
  'master-data',
  'product-usage',
  'new-settings',
  'qr-series'
];

//List of admin paths
let adminArray = [
  'dashboard',
  'users',
  'general-product-settings',
  'general-usage-info',
  'scheduled-charges'
];
//This function is in charge of executing code before any redirection
//This function runs each time you redirect to a component
router.beforeEach(async (to, from, next) => {
  //sessionStorage.setItem('userPathDesired', undefined);
  //Depending on the route to which it is directed, the indexOf method is used to see what type 
  //of route it is, if it is a route for registered users only, if it is a route for subscribed 
  //users or if it is a route for admins.

  /** Depending on the route you want to go to, the indexOf method checks the type of route.
   * There are 4 type of routes:
   * the freemium routes
   * the admin routes
   * routes that aren't classified
   */
  let isPremium = premiumArray.indexOf(to.name)
  let isFreemium = freemiumArray.indexOf(to.name)
  let isAdminium = adminArray.indexOf(to.name)
  if (isPremium >= 0 || isFreemium >= 0) {

    //If the route is either premium or freemium runs this condition
    //A request is made to the "/api/checkMembership" endpoint, this endpoint checks whether the user is logged in or not
    fetch(`${process.env.VUE_APP_SERVER_URL}/api/checkMembership`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(result => result.json())
      .then(async (response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 200) {
            const { resp } = response;
            const { name } = resp;
            //If the session is successful then save the name of your subscription in the storage.
            //If the user is logged in then its subscripcion's name is stored
            store.commit('User/setSubscriptionName', response.resp.name);
            const subscriptionName = store.getters['User/subscriptionName'];
            // We also save his active_account status
            store.commit('User/setActiveAccount', response.active_account);
            const activeAccount = store.getters['User/activeAccount'];

            store.commit('User/setAccountName', name);

            //check if user has a wir payment 
            store.commit('User/setWir', response.wirActive);

            if (isPremium >= 0) {
              //If the subscription is premium then it enters this case in which it asks 
              //if the user's subscription is 'Free trial' or empty.

              //If the route is a premium, this logic is triggered
              if (subscriptionName === 'Free Trial' || subscriptionName === '') {
                //If the user is not premium then he/she cannot access the content and is redirected to the cockpit.

                //If the user doesn't have a premium subscription, he/she won't be able to access the component
                //so the user is redirected to the dashboard
                next({ name: 'Cockpit' });
                store.commit('global/setURL', to.path);
              } else {
                //If the user is premium then it lets you access the component

                //If the user has a premium subscription, he/she is able to access the component
                store.commit('global/setURL', to.path);
                next();
              }
            } else if (isFreemium >= 0) {
              //If the user wants to go to a component that is in the freemium component 
              //array, he/she enters in this case.
              //Here runs the logic for the freemium paths

              //If the user want to access freemium route & the he/she is logged in then the access is granted
              if (to.name == 'payment') {
                //If the user wants to go to the payment component then it falls into this case

                //If the user wants to access the payment component then runs this logic
                if (from.name == 'subscriptions' || from.name === 'stepByStep' || from.name === 'qr-table') {
                  //If the user wants to access the payment from the subscriptions 
                  //component, he or she is allowed to do so.

                  //If the user wants to access the payment component from the subscriptions component 
                  //the access is granted
                  store.commit('global/setURL', to.path);
                  next();
                }
                else {
                  //If the user wants to access the payment from the component from any other than subscriptions 
                  //then

                  //If the user whishes to access the payment component from another component that isn't the
                  //subscriptions component he/she won't be able to access
                  store.commit('global/setURL', to.path);
                  next({ name: 'Cockpit' });
                }
              } else if (to.name === 'paymentSucces') {
                //If the user wants to go to the paymentSuccess component

                //If the user wants to access the paymentSuccess component then runs this logic
                if (from.name === 'subscriptions') {
                  store.commit('global/setURL', to.path);
                  return next()
                } else {
                  store.commit('global/setURL', to.path);
                  return next()
                }
              } else {
                //If the user wants to go to any other component then it falls into this case

                //If the user wants to go to a component that isn't payment or paymentSuccesss
                //the access is granted as long as the user is logged in
                store.commit('global/setURL', to.path);
                return next();
              }
            } else {
              //This case should never be fulfilled but it is added just in case it enters this case 
              //if it is not directed to a premium or freemium component but that cannot happen because 
              //it is in the case of premium and freemium.

              //This logic runs when the path is neither premium or freemium, but due to the fact
              //that we are in a case where the path must be freemium or premium to begin with,
              //this case never occurs
              store.commit('global/setURL', to.path);
              return next();
            }
          } else {
            //If the server response is not correct then the user is not logged into the system so it 
            //returns the user to login.

            //If the server response isn't 200 that means the user is not logged in
            //so gets redirected to the login component
            store.commit('global/setSnackbar', { message: response.message })
            store.commit('global/setURL', '/login');
            sessionStorage.setItem('userPathDesired', to.path);
            return next({ name: 'Login' });
          }
        } else {
          //If the server response is not correct then the user is not logged into the system so 
          //it returns the user to login.

          //If the server response isn't 200 that means the user is not logged in
          //so gets redirected to the login component          
          store.commit('global/setSnackbar', { message: 'Please Login' })
          sessionStorage.setItem('userPathDesired', to.path);
          store.commit('global/setURL', '/login');
          return next({ name: 'Login' });
        }
      })
      .catch((err) => {
        //If the server response is not correct then the user is not logged into the system so it 
        //returns the user to login.

        //If the server response isn't 200 that means the user is not logged in
        //so gets redirected to the login component        
        store.commit('global/setSnackbar', { message: err })
        sessionStorage.setItem('userPathDesired', to.path);
        store.commit('global/setURL', '/login');
        return next({ name: 'Login' });
      });
  } else if (isAdminium >= 0) {
    //If the path name is in the list of paths for admins enter in this case

    //If the user wants to access an admin component then runs this logic
    //A request to the "/api/isAdmin" endpoint is made to check whether the user is logged in or not
    fetch(`${process.env.VUE_APP_SERVER_URL}/api/isAdmin`, {
      method: "GET",
      credentials: "include"
    })
      .then(result => {
        return result.json();
      })
      .then(response => {
        if (response !== undefined && response !== null) {
          if (response.status === 200) {
            //If the server response is successful, then it redirects you to where you want to go

            //if the server response is 200, then the access is granted
            store.commit('global/setURL', to.path);
            // store.commit("global/setSnackbar", { message: response.message });
            return next();
          } else {
            //If the server response is not correct then the user is not logged into the system 
            //so it returns the user to the login for admins.

            //If the server response isn't 200 that means the user is not logged in
            //so gets redirected to the login component                 
            store.commit('global/setURL', '/admin');
            store.commit("global/setSnackbar", { message: response.message });
            return next({ name: "admin" });
          }
        } else {
          //If the server response is not correct then the user is not logged into 
          //the system so it returns the user to the login for admins. 

          //If the server response isn't 200 that means the user is not logged in
          //so gets redirected to the login component               
          store.commit('global/setURL', '/admin');
          store.commit("global/setSnackbar", {
            message: "Login Failed, please try again"
          });
          return next({ name: "admin" });
        }
      })
      .catch(err => {
        //If the server response is not correct then the user is not logged into the system so it returns 
        //the user to the login for admins.

        //If the server response isn't 200 that means the user is not logged in
        //so gets redirected to the login component     
        store.commit('global/setURL', '/admin');
        store.commit("global/setSnackbar", { message: err });
        return next({ name: "admin" });
      });
  } else {
    //If the target component does not fall in any case then let you access it
    //Here runs the logic for the paths that aren't premium or freemium or Adminium

    store.commit('global/setURL', to.path);
    next();
  }

})
export default router;