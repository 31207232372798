const global = {
    namespaced: true,
    state: {
        showAlert: false,
        alertMessage: "",
        debtors: [],
        debtors_selected: [],
        debtorsFail: [],
        debtorsCorrect: [],
    },
    mutations: {
        setShowAlert(state, value) {
            state.showAlert = value;
        },
        setAlertMessage(state, message) {
            state.alertMessage = message;
        },
        setDebtorsFail: (state, payload) => {
            state.debtorsFail = payload;
        },
        debtorsCorrect: (state, payload) => {
            state.debtorsCorrect = payload;
        }
    },
    getters: {
        debtors: state => [...state.debtors],
        debtors_selected: state => [...state.debtors_selected],
        showAlert: (state) => state.showAlert,
        alertMessage: state => state.Debtor.alertMessage,
    },
    actions: {
        triggerAlert({ commit }, message) {
            commit('setAlertMessage', message);
            commit('setShowAlert', true);
            setTimeout(() => {
                commit('setShowAlert', false);
            }, message.type == 'error' ? 10000 : 6000);
        },
    },
};

export default global;