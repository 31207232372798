<template>
    <div>
        <v-row>
            <v-col>
                <span>This method can be used to generate invoices in your account.</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <api-page-description 
                :items="items"
                :title="'Request Arguments:'"
                />
            </v-col>
            <v-col>
                <api-page-example
                :items="items" 
                :title="'Request Example:'"
                />                
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <api-page-description 
                :items="items_example"
                :title="'Response Arguments:'"
                />
            </v-col>
            <v-col>
                <api-page-example
                :items="items_example" 
                :title="'Response Example:'"
                />                    
            </v-col>
        </v-row>
    </div>
</template>
<script>
import APIPageDesc from './default/API.Page.description.vue'
import APIPageExm from './default/API.Page.Example.vue'
export default {
    name:'APIQR',
    components:{
        'api-page-description':APIPageDesc,
        'api-page-example':APIPageExm
    },
    data(){
        return{
            updateKey:0,
            items:[
                {   
                    key:1,
                    name:"reference",
                    value:"[Debtor's Reference]",
                    title:'Reference',
                    requirement:'Optional',
                    type:'String',
                    description:'The reference is going to be printed on the payment part.'
                },
                {
                    key:2,
                    name:"language",
                    value:"[Invoice Language]",
                    title:'Language',
                    requirement:'Optional',
                    type:'String',
                    description:`The language the invoice is going to be generated.\nThe possible values
                    are: ["de","en","fr","it","rm"].`
                },
                {
                    key:3,
                    name:"amount",
                    value:"[Invoice Amont]",
                    title:'Amount',
                    requirement:'Optional',
                    type:'String',
                    description:'The amount related to the invoice. If it is not specified the corner marks are shown. If a template is specified the sum of all the products prices will be used instead of this field.'
                },
                {
                    key:4,
                    name:"percentage_wir", 
                    value:"[Percentage WIR]",
                    title:'Percentage WIR',
                    requirement:'Optional',
                    type:'Number',
                    description:'The percentage that will be applied to the amount to divide in CHF and CHW, only apply if the user has a WIR'
                },
                {
                    key:5,
                    name:"currency",
                    value:"[Invoice Currency]",
                    title:'Currency',
                    requirement:'Optional',
                    type:'String',
                    description:`The currency used on the invoices.\nThe possible values are: ["CHF","EUR"].\n
                    If it is not specified CHF is used.`
                },
                {
                    key:6,
                    name:"message",
                    value:"[Invoice Unstrutued Message]",
                    title:'Message',
                    requirement:'Optional',
                    type:'String',
                    description:'A message added on the additional information section in the payment part.'
                },
                {
                    key:7,
                    name:"creditor",
                    title:'Creditor',
                    requirement:'Mandatory',
                    type:'Object',
                    showChildren:false,
                    description:'An object containing the creditor fields',
                    children:[
                        { 
                            key:61,
                            name:"profile_id",
                            value:"[ID assigned to the profile]",
                            title:'PROFILE ID',
                            requirement:'Optional',
                            type:'String',
                            description:`The profile id of the profile you want to use. If specified this will overwrite the other fields.`
                        },
                        {
                            key:62,
                            name:"default_profile",
                            value:"[Default profile Flag]",
                            title:'Default Profile',
                            requirement:'Optional',
                            type:'Boolean',
                            description:`A boolean to let the system know if the request must use the default profile. This argument does not have priority over the profile id,but does have priority over the other fields.`                            
                        },
                        { 
                            key:63,
                            name:"account",
                            value:"[Creditor's IBAN/QR-IBAN]",
                            title:'ACCOUNT',
                            requirement:'Mandatory',
                            type:'String',
                            description:`The Creditor's IBAN or QR IBAN number.`
                        },
                        { 
                            key:64,
                            name:"account_wir",
                            value:"[Creditor's IBAN/QR-IBAN WIR]",
                            title:'ACCOUNT WIR',
                            requirement:'Mandatory',
                            type:'String',
                            description:`The Creditor's IBAN or QR IBAN WIR number. only apply if the user has a WIR`
                        },
                        {
                            key:65,
                            name:"first_name",
                            value:"[Creditor's First Name]",
                            title:'First Name/Company',
                            requirement:'Mandatory',
                            type:'String',
                            description:`The Creditor's First Name`
                        },
                        {
                            key:66,
                            name:"last_name",
                            value:"[Creditor's Last Name]",
                            title:'Last Name',
                            requirement:'Optional',
                            type:'String',
                            description:`The Creditor's Last Name`
                        },
                        {
                            key: 67,
                            name:"street",
                            value:"[Creditor's Street]",
                            title:'Street',
                            requirement:'Optional',
                            type:'String',
                            description:`The Creditor's Street`
                        },
                        {
                            key:68,
                            name:"house_number",
                            value:"[Creditor's House Number]",
                            title:'House Number',
                            requirement:'Optional',
                            type:'String',
                            description:`The Creditor's House Number`
                        },
                        {
                            key:69,
                            name:"zip",
                            value:"[Creditor's Zip Code]",
                            title:'Zip Code',
                            requirement:'Mandatory',
                            type:'String',
                            description:`The Creditor's Zip code`
                        },
                        {
                            key:70,
                            name:"city",
                            value:"[Creditor's City]",
                            title:'City',
                            requirement:'Mandatory',
                            type:'String',
                            description:`The Creditor's City`
                        },
                        {
                            key:71,
                            name:"country",
                            value:"[Creditor's Country]",
                            title:'Country',
                            requirement:'Mandatory',
                            type:'String',
                            description:`The Creditor's Country Name, the countries should follow the ISO 3166-1 (Alpha-2) format, meaning that the specified country must be 2 characters long.`,
                            reference:`https://www.iso.org/obp/ui/#search/code/`     
                        }
                    ]
                },
                {
                    key:8,
                    name:"debtor",
                    title:'Debtor',
                    requirement:'Optional',
                    type:'Object',
                    showChildren:false,
                    description:'An object containing the debtor fields',
                    children:[
                        {
                            key:71,
                            name:"first_name",
                            value:"[Debtor's First Name]",
                            title:'First Name',
                            requirement:'Optional',
                            type:'String',
                            description:`The Debtor's First Name`
                        },
                        {
                            key:72,
                            name:'salutation',
                            value:"[Debtor's Salutation]",
                            title:'Salutation',
                            requirement:'Optional',
                            type:'String',
                            description:'The salutation template to be used.'
                        },
                        {
                            key:73,
                            name:"last_name",
                            value:"[Debtor's Last Name]",
                            title:'Last Name',
                            requirement:'Optional',
                            type:'String',
                            description:`The Debtor's Last Name`
                        },
                        {
                            key:74,
                            name:"street",
                            value:"[Debtor's Street]",
                            title:'Street',
                            requirement:'Optional',
                            type:'String',
                            description:`The Debtor's Street`
                        },
                        {
                            key:75,
                            name:"house_number",
                            value:"[Debtor's House Number]",
                            title:'House Number',
                            requirement:'Optional',
                            type:'String',
                            description:`The Debtor's House Number`
                        },
                        {
                            key:76,
                            name:"zip",
                            value:"[Debtor's Zip Code]",
                            title:'Zip Code',
                            requirement:'Optional',
                            type:'String',
                            description:`The Debtor's Zip Code`
                        },
                        {
                            key:77,
                            name:"city",
                            value:"[Debtor's City]",
                            title:'City',
                            requirement:'Optional',
                            type:'String',
                            description:`The Debtor's City`
                        },
                        {
                            key:78,
                            name:"country",
                            value:"[Debtor's country]",
                            title:'Country',
                            requirement:'Optional',
                            type:'String',
                            description:`The Debtor's Country, the countries should follow the ISO 3166-1 (Alpha-2) format, meaning that the specified country must be 2 characters long.`,
                            reference:`https://www.iso.org/obp/ui/#search/code/`  
                        }
                    ]
                },
                {
                    key:9,
                    name:"billing_information",
                    title:'Billing Information',
                    requirement:'Optional',
                    type:'Object',
                    showChildren:false,
                    description:'An object containing the billing information',
                    children:[
                        {
                            key:81,
                            name:"use_profile_data",
                            value:"[Default profile Flag]",
                            title:'Use Profile Data',
                            requirement:'Optional',
                            type:'Boolean',
                            description:`A flag indicating if the profile data should be use.`                            
                        },
                        {
                            key:82,
                            name:"bill_number",
                            value:"[Invoice's Bill Number]",
                            title:'Bill Number',
                            requirement:'Optional',
                            type:'String',
                            description:`The Invoice's Bill Number. If a template is specified the this value is used on the template.`                             
                        },
                        {
                            key:83,
                            name:"bill_date",
                            value:"[Invoice's Bill Date]",
                            title:'Bill Date',
                            requirement:'Optional',
                            type:'String',
                            description:`The Invoice's Bill Date. If a template is specified the this value is used on the template.`   
                        },
                        {
                            key:84,
                            name:"order_reference",
                            value:"[Invoice's Order Reference]",
                            title:'Order Reference',
                            requirement:'Optional',
                            type:'String',
                            description:`The Invoice's Order Reference`
                        },
                        {
                            key:85,
                            name:"uid_number",
                            value:"[Invoice's UID Number]",
                            title:'UID Number',
                            requirement:'Optional',
                            type:'String',
                            description:`The Invoice's UID Number. If a template is specified the this value is used on the template.`
                        },
                        {
                            key:86,
                            name:"vat_dates",
                            title:'VAT Dates',
                            requirement:'Optional',
                            type:'Object',
                            description:`An object with the start date & end date`,
                            showChildren:false,
                            children:[
                                {
                                    key:861,
                                    name:"start_date",
                                    value:"[Invoice's VAT Start date]",
                                    title:'Start Date',
                                    requirement:'Optional',
                                    type:'String',
                                    description:`The Invoice's VAT Start Date. The date should have the following format:\'YYYY-MM-DD\'`                                    
                                },
                                {
                                    key:862,
                                    name:"end_date",
                                    value:"[Invoice's VAT End date]",
                                    title:'End Date',
                                    requirement:'Optional',
                                    type:'String',
                                    description:`The Invoice's VAT End Date. The date should have the following format:\'YYYY-MM-DD\'`       
                                }
                            ]
                        },
                        {
                            key:9,
                            name:"vat_details",
                            title:'VAT Details',
                            requirement:'Optional',
                            type:'Array',
                            description:`The List Invoice's VAT Details. The array must contain objects with a valid rate and amount to be able to be considered`,
                            showChildren:false,
                            children:[
                                {
                                    key:91,
                                    name:"rate",
                                    value:"[Current VAT Detail's Rate]",
                                    title:'Rate',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:`The rate of the current vat details in the list`                             
                                },
                                {
                                    key:92,
                                    name:"amount",
                                    value:"[Current VAT Detail's Amount]",
                                    title:'Amount',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:`The amount of the current vat details in the list`   
                                }
                            ]
                        },
                        {
                            key:10,
                            name:"vat_import_tax",
                            title:'VAT Import Tax',
                            requirement:'Optional',
                            type:'Array',
                            description:`The List Invoice's VAT Import Tax. The array must contain objects with a valid rate and discount to be able to be considered`,
                            showChildren:false,
                            children:[
                                {
                                    key:101,
                                    name:"rate",
                                    value:"[Current VAT Import Tax's Rate]",
                                    title:'Rate',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:`The rate of the current vat import tax in the list`                             
                                },
                                {
                                    key:102,
                                    name:"discount",
                                    value:"[Current VAT Import Tax's Amount]",
                                    title:'Discount',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:`The discount of the current vat import tax in the list`   
                                }                        
                            ],
                        },
                        {
                            key:11,
                            name:'conditions',
                            title:'Conditions',
                            requirement:'Optional',
                            description:`The List Invoice's Conditions. The array must contain objects with a valid discount and days to be able to be considered`,
                            type:'Array',
                            showChildren:false,
                            children:[
                                {
                                    key:111,
                                    name:"discount",
                                    value:"[Current Condition's Rate]",
                                    title:'Discount',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:`The discount of the current condition in the list.`                             
                                },
                                {
                                    key:112,
                                    name:"days",
                                    value:"[Current Condition's Amount]",
                                    title:'Days',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:`The days of the current condition in the list. `
                                }  
                            ]
                        }
                    ]
                },
                {
                    key:10,
                    name:'template',
                    title:'Template',
                    requirement:'Optional',
                    type:'Object',
                    description:'An object contaning the template properties.',
                    showChildren:false,
                    children:[
                        {
                            key:91,
                            name:'name',
                            value:'[Template\'s Name]',
                            title:'Name',
                            requirement:'Optional',
                            type:'String',
                            description:'The name account\'s template to be used.'
                        },
                        {
                            key:93,
                            name:'default',
                            value:'[Default Template Flag]',
                            title:'Default',
                            requirement:'Optional',
                            type:'Boolean or String',
                            description:'A flag to indicate the system to use the account\'s default template.'
                        },
                        {
                            key:94,
                            name:'payable_by',
                            value:'[Template\'s Payable by date]',
                            title:'Payable By',
                            requirement:'Optional',
                            type:'String',
                            description:'The payable by date on the invoice. The date should have the following format:\'YYYY-MM-DD\'.'
                        },
                        {
                            key:95,
                            name:'products',
                            title:'Products',
                            requirement:'Optional',
                            type:'Array',
                            showChildren:false,
                            description:'The List of products to print in the template. If a template is specified at least 1 product must be listed. The max amount of products to be used is 15.',
                            children:[
                                {
                                    key:941,
                                    name:'name',
                                    value:'[Product\'s Name]',
                                    title:'Name',
                                    requirement:'Optional',
                                    type:'String',
                                    description:'The name related to the product'
                                },
                                {
                                    key:942,
                                    name:'percentage',
                                    value:'[Percentage WIR]',
                                    title:'Percentage',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:'The percentage that will be apply to Unit Price, only apply if the user has a WIR'
                                },
                                {
                                    key:943,
                                    name:'unit_price',
                                    value:'[Product\'s Unit Price]',
                                    title:'Unit Price',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:'The unit price related to the product'
                                },
                                {
                                    key:944,
                                    name:'quantity',
                                    value:'[Product\'s Quantity]',
                                    title:'Quantity',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:'The quantity related to the product'
                                },
                                {
                                    key:945,
                                    name:'vat',
                                    value:'[Product\'s VAT]',
                                    title:'VAT',
                                    requirement:'Optional',
                                    type:'Number',
                                    description:'The VAT related to the product'
                                }
                            ]
                        },
                        {
                            key:96,
                            name:'vat_over',
                            value:'[Vat Over Flag]',
                            title:'VAT Over',
                            requirement:'Optional',
                            type:'Boolean or String',
                            description:'A flag to indicate the system if it should calculate the VAT based on the amount or over the amount.',                            
                        }
                    ],
                },
                {
                    key:11,
                    name:'letter_dispatch',
                    title:'Letter Dispatch',
                    requirement:'Optional',
                    type:'Object',
                    description:'The object containing the letter dispatch\'s settings',
                    showChildren:false,
                    children:[
                        {
                            key:101,
                            name:'priority',
                            value:'[The letter\'s priority]',
                            title:'Letter Dispatch Priority',
                            requirement:'Mandatory',
                            type:'String',
                            description:'The letter\'s priority. The possible values are: \'standard\' or \'express\'.'
                        },
                        {
                            key:102,
                            name:'my_climate',
                            value:'[My Climate\'s Flag]',
                            title:'My Climate',
                            requirement:'Optional',
                            type:'Boolean or String',
                            description:'A Flag indicating if the post office should use environmentally friendly papel'
                        },
                        {
                            key:103,
                            name:'color',
                            value:'[The letter\'s color]',
                            title:'Color',
                            requirement:'Mandatory',
                            type:'String',
                            description:'The letter\'s color. The possible values are: \'color\' or \'black_and_white\'.'
                        },
                        {
                            key:104,
                            name:'franking_envelopes',
                            value:'[The letter\'s franking envelope]',
                            title:'Franking Envelopes',
                            requirement:'Mandatory',
                            type:'String',
                            description:'The letter\'s franking evelopes. The possible values are: \'A\',\'B\' or \'none\'. If the generated file is payment part only then \'none\' is the only valid option. If the generated file is a template invoice then \'A\', \'B\' or \'none\' are valid options when a collective address is also specified. If a template invoice is specified but the collective address is not, then \'A\' & \'B\' are the only valid options.'
                        },
                        {
                            key:105,
                            name:'collective_address',
                            value:'[The letter\'s collective address]',
                            title:'Collective Address',
                            requirement:'Optional',
                            type:'Object',
                            description:'The collective address data object. If no collective address is specified then the creditor data takes its place.',
                            showChildren:false,
                            children:[
                                {
                                    key:1051,
                                    name:'address',
                                    value:'[The collective address\' address]',
                                    title:'Address',
                                    requirement:'Mandatory',
                                    type:'String',
                                    description:'The address to send the letter. The address cannot conatin more than 4 lines with a max of 35 characters for each line.',
                                },
                                {
                                    key:1052,
                                    name:'postal_tariff',
                                    value:'[The collective address\' postal tariff]',
                                    title:'postal_tariff',
                                    requirement:'Mandatory',
                                    type:'String',
                                    description:'The collective address\' postal tariff. The possible values are: \'postpac_priority\' or \'swiss_express\'.'
                                }
                            ]
                        },
                        {
                            key:106,
                            name:'remarks_to_printshop',
                            value:'[The letter dispatch\'s remarks]',
                            title:'Remarks to Printshop',
                            requirement:'Optional',
                            type:'String',
                            description:'The Letter dispatch\'s remarks to the printshop. The remarks cannot contain more than 2 lines with a max of 45 characters for each line.'
                        }
                    ]
                }
            ],
            items_example:[
                {
                    key:1,
                    name:'status',
                    value:'200',
                    title:'Status',
                    type:'Number',
                    description:'The http response status.'
                },
                {
                    key:2,
                    name:'message',
                    value:'Success',
                    title:'Message',
                    type:'String',
                    description:'The respose associated message.'
                },
                {
                    key:3,
                    name:'data',
                    title:'Data',
                    type:'Object',
                    description:'The response object containing parameters useful to the user.',
                    children:[
                        { 
                            key:31,
                            name:'file',
                            title:'File',
                            description:'The name of the generated file',
                            value:'[File\'s name reference]',
                            type:'String'
                        },
                    ]
                }
            ],
        }
    },
}
</script>
