<template>
  <v-row>
    <v-col cols="12" class="py-2">
      <v-stepper v-model="this.$store.state.qrSeries.stepperEmail" alt-labels>
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="this.$store.state.qrSeries.stepperEmail > 1" step="1">
            <!-- {{ $t("QrSeries.step_one.subtitle_stepone") }} -->
            <label style="text-align: center"> {{ $t("QrSeries.step_four.emailDispatch.labelStep1") }}  </label>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="this.$store.state.qrSeries.stepperEmail > 2" step="2">
            <label style="text-align: center"> {{ $t("QrSeries.step_four.emailDispatch.labelStep2") }} </label>
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <div>
              <div class="ma-5">
                <table-emails :key="tableKey"  ref="table" @closefun="()=>$emit('closedialog')" :debtors="debtors" />
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2" style="padding-top: 0px !important">
            <div>
              <v-row>
                <v-col style="padding-top: 0px !important;">
                  <send-email @closefun="()=>$emit('closedialog')" :debtors="debtors" />
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
  </v-row>
</template>


<script>
import tableEmails from "@/components/email-dispatch/tableEmails.vue";
import sendEmailDispatch from "@/components/email-dispatch/sendEmailDispatch.vue";

export default {
  emits:['closedialog'],
  components: {
    "table-emails": tableEmails,
    "send-email": sendEmailDispatch,
  },
  computed:{
    tableKey:{
      get() {
        return this.$store.state.qrSeries.tableKey
      },
      set(value) {
        this.$store.state.qrSeries.tableKey = value;
      }
    },
  },
  data() {
    return {
      e1: this.$store.state.qrSeries.stepperEmail,
      updateBuyCredits: 0,
      creditsinsuf: false,
      titlepopup: "",
      subtitlepopup: "",
      popup: false,
      price: "",
      total: "",
      membership: "",
      creditsused: "",
      membcredits: "",
      remainingcredits: "",
      SelectedCustom: "",
      donationSelected: "",
      payableSelected: "",
      checkboxAddress: true,
      checkboxReference: true,
      checkboxAmount: true,
      checkboxMessage: true,
      checkboxBilling: false,
      templateType: "",
      ProfileSelected: "standard",
      SelectionIntegration: "",
      createComplete: "",
      idTemplate: null,
      snackbarsuccess: false,
    };
  },
  props: {
    debtors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  }
};
</script>