<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-row v-if="hiddenSection">
          <v-col>
            <v-btn dark width="100%" :color="$vuetify.theme.themes.light.blue.primary" @click="mutationFinal()">{{
              $t("QrSeries.step_four.btn_mutate_bill") }}</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="hiddenSection">
          <v-col>
            <v-btn :dark="dark" width="100%" :disabled="show" @click="SaveMutation()"
              :color="$vuetify.theme.themes.light.blue.secondary">{{ $t("QrSeries.step_four.btn_save_mutation")
              }}</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="hiddenSection">
          <v-col>
            <v-btn :dark="dark" width="100%" :disabled="show" @click="cancelMutation()"
              :color="$vuetify.theme.themes.dark.brown.secondary">{{ $t("QrSeries.step_four.btn_cancel_mutation")
              }}</v-btn>
          </v-col>
        </v-row>
        <v-form ref="dateForm" :disabled="show">
          <v-row>
            <v-col>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <h3>{{ $t("QrSeries.step_four.invoice_details") }}</h3>
                </v-col>
              </v-row>
              <v-form ref="form2">
                <v-row>
                  <v-col class="pt-0 pb-0">
                    <v-menu ref="menu" :disabled="show" v-model="menu" :close-on-content-click="false"
                      :return-value.sync="invoice_date" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="show" v-model="invoice_date"
                          :label="$t('QrSeries.step_four.invoice_date')" prepend-icon="mdi-calendar" readonly
                          v-bind="attrs" v-on="on" dense outlined :rules="[rules.isEmpty]"></v-text-field>
                      </template>
                      <v-date-picker v-model="invoice_date" :disabled="show" :locale="$store.state.User.language"
                        no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          {{ $t("QrSeries.step_three.btn_cancel") }}
                        </v-btn>
                        <v-btn text color="primary" @click="() => {
                          this.$refs.menu.save(this.invoice_date);
                          this.handleInvoiceDate(this.invoice_date);
                        }
                          ">{{ $t("Globals.ok") }}</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0 pb-0">
                    <v-menu ref="menu1" :disabled="show" v-model="menu1" :close-on-content-click="false"
                      :return-value.sync="payable_until" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="payable_until" :label="$t('QrSeries.date')" :disabled="show"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                      </template>
                      <v-date-picker v-model="payable_until" :disabled="show" :locale="$store.state.User.language"
                        no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          {{ $t("QrSeries.step_three.btn_cancel") }}
                        </v-btn>
                        <v-btn text color="primary" @click="() => {
                          this.$refs.menu1.save(this.payable_until);
                          this.handlePayableUntil(this.payable_until);
                        }
                          ">{{ $t("Globals.ok") }}</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
              <v-row v-if="hiddenSection">
                <v-col class="pt-0 pb-3">
                  <v-btn @click="onClickShowProductDialog" dark width="100%"
                    :color="$vuetify.theme.themes.light.blue.primary">{{
                      $t("QrSeries.step_three.product_information") }}</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <h3>{{ $t("QrSeries.step_three.account_payableto") }}</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <v-form>
                    <qr-creditor ref="qrCreditor"> </qr-creditor>
                  </v-form>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>{{ $t("QrSeries.step_three.recipient_address") }}</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-form>
                    <qr-debtor ref="qrDebtor" :key="keyDebtor"> </qr-debtor>
                  </v-form>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="8">
        <v-row>
          <v-col class="pt-0">
            <v-row>
              <v-col>
                <v-btn width="100%" dark class="pa-7" :color="$vuetify.theme.themes.dark.green.secondary"
                  @click="CreateInvoiceFinal()">{{ $t("QrSeries.step_four.btn_create_bill") }} <br />
                  {{ $t("QrSeries.step_four.btn_create_bill2") }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn :disabled="!BtnDownload" width="100%" :dark="!BtnDownloadDark" class="pa-7"
                  :color="$vuetify.theme.themes.dark.brown.secondary" @click="CancelProcess()"><span>{{
                    $t("QrSeries.step_four.btn_discard_invoice") }}<br />
                    {{ $t("QrSeries.step_four.btn_discard_invoice2") }}</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn :disabled="BtnDownload" width="100%" :light="BtnDownloadDark" class="pa-5"
                  style="color:white !important;" :color="$vuetify.theme.themes.light.blue.secondary"
                  @click="download()" :loading="DOMElements.download.loading">{{ $t("QrSeries.step_four.btn_download")
                  }}</v-btn>
              </v-col>
              <v-col>
                <v-btn :disabled="BtnDownload" width="100%" :dark="BtnDownloadDark" @click="print()" class="pa-5"
                  :color="$vuetify.theme.themes.light.blue.secondary">{{ $t("QrSeries.step_four.btn_letter") }} <br />
                  {{ $t("QrSeries.step_four.btn_letter2") }}</v-btn>
              </v-col>
              <v-col v-if="subscriptionName == 'Angebot QR-Profi' || subscriptionName == 'Angebot QR-Plus'">
                <v-btn :disabled="BtnDownload" :dark="BtnDownloadDark"
                  :color="$vuetify.theme.themes.light.blue.secondary" width="100%" class="pa-5"
                  @click="showEmailDispatch = true">{{ $t("QrSeries.step_four.btn_email") }}</v-btn>
              </v-col>
              <v-col>
                <!--:disabled="BtnDownload"-->
                <v-btn width="100%" :disabled="BtnDownload" :light="BtnDownloadDark" class="pa-5"
                  style="color:white !important;" :color="$vuetify.theme.themes.light.blue.secondary"
                  @click="updateBexioInvoiceStatus()" :loading="DOMElements.update_status.loading">{{
                    $t("QrSeries.step_four.btn_update_status_bexio_invoice")
                  }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <v-card-title class="justify-end">
                <v-row>
                  <v-col cols="6">
                    <v-slider label="Zoom" max="1" min="0" step=".1" v-model="zoom"></v-slider>
                  </v-col>
                  <v-col cols="6">
                    {{ currentIndex + 1 }}/{{ currentLength }}
                    <v-icon @click="previousError()" :disabled="arrowsDisabled">mdi-arrow-left-box</v-icon>
                    <v-icon @click="nextError()" :disabled="arrowsDisabled">mdi-arrow-right-box</v-icon>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-divider></v-divider>
                <v-row>
                  <v-col>
                    <template-viewer ref="templateViewer" :key="renderViewer" :qrCode="qrCode" :items="getItems"
                      :calculations="calculations" :subject="templateString.subject"
                      :presentation="templateString.message" :contactDetails="templateString.contact"
                      :bottomComment="templateString.comment" :cardFlat="true" :debtor="diplayDebtorOnTemplate"
                      :renderQR="renderQR" :idTemplate="idTemplate" :invoice_date="invoice_date"
                      :payable_until="payable_until" :renderLogoRectangle="false" :onlyQR="onlyQR" :zoom="zoom"
                      :noScroll="false" :maxSizeDocument="false" :currency="currency" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="showProductDialog">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-form :disabled="show">
            <debtor-product-table ref="debtorProductTable" />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn dark @click="showProductDialog = false" :color="$vuetify.theme.themes.light.blue.primary">{{
            $t("Globals.btn_close") }}</v-btn>
          <v-btn dark @click="saveProducts" :color="$vuetify.theme.themes.light.blue.primary">{{ $t("Globals.save")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showPrint">
      <v-card class="pt-8">
        <v-card-title>{{
          $t("QrSeries.step_four.PrintShop.title")
        }}</v-card-title>
        <v-card-text>
          <v-form>
            <print-job ref="PrintInvoice" />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn dark @click="cancelPrint()" :color="$vuetify.theme.themes.light.blue.primary">{{
            $t("QrSeries.step_four.PrintShop.cancel") }}</v-btn>
          <v-btn @click="confirmDialog()" :color="$vuetify.theme.themes.light.blue.primary" dark>{{
            $t("QrSeries.step_four.PrintShop.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- v-dialog email Dispatch -->

    <v-dialog persistent v-model="showEmailDispatch">
      <v-card>
        <v-toolbar dense dark color="#95c11f">
          <v-toolbar-title>{{
            $t("QrSeries.step_four.emailDispatch.titleDialog")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn @click="closeDialog" dark text>
              <v-icon>mdi-close</v-icon>
            </v-btn> -->
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <email-dispatch @closedialog="closeDialog" :debtors="debtors" :numberQrGenerated="debtors.length" />
        </v-card-text>
        <!-- <v-card-actions class="justify-end">
          <v-btn
            dark
            @click="cancelPrint()"
            :color="$vuetify.theme.themes.light.blue.primary"
            >{{ $t("QrSeries.step_four.PrintShop.cancel") }}</v-btn
          >
          <v-btn
            @click="confirmDialog()"
            :color="$vuetify.theme.themes.light.blue.primary"
            dark
            >{{ $t("QrSeries.step_four.PrintShop.confirm") }}
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <!-- *************************** -->

    <progress-bar ref="progressBar"></progress-bar>

    <v-dialog persistent v-model="showPrintProgress" width="200px">
      <v-card width="200px">
        <v-card-title class="justify-center">PROCESSING...</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-layout row justify-center align-center>
                <v-progress-circular :size="70" :width="7" color="green" indeterminate></v-progress-circular>
              </v-layout>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QRCreditor from "@/components/QR/qr.form.creditor.vue";
import QRDebtor from "@/components/QR/qr.form.debtor.vue";
import DebtorProductsTable from "@/components/cockpit/archieve/debtors/debtors.table.productsTable.vue";
import { mapGetters, mapMutations } from "vuex";
import { getBase64String } from "@/mixins/functions";
import ProgressBar from "@/components/globals/progressBar.vue";
import step1 from "@/components/cockpit/QR-Massive-Steps/qr.series.invoices.vue";
import editorTemplateVue from "../template/editor/editor.template.vue";
import QrPrintJob from "@/components/post-office/PrintJobCheckout.vue";
import emailDispatch from "@/components/email-dispatch/qr.series.emailDispatch.vue";
import DialogAddress from "@/components/cockpit/QR-Massive-Steps/QR Series/AddressDialog.vue";
import { validateCharac } from "../../../functions/invalidCharacters";

// Reference Type Detector
const detector = require("../../../detectTypeReference.js");
import {
  address,
  debitorFullName,
  zip,
  nameMissingZip,
  debitorCountryRules,
  city,
  fieldRequired,
  productsRequired,
  invoiceDateRequired,
} from "@/components/cockpit/QR-Massive-Steps/rules";
export default {
  name: "qr-massive-final",
  components: {
    "qr-creditor": QRCreditor,
    "qr-debtor": QRDebtor,
    "debtor-product-table": DebtorProductsTable,
    "progress-bar": ProgressBar,
    "qr-series-1": step1,
    "template-viewer": editorTemplateVue,
    "print-job": QrPrintJob,
    "email-dispatch": emailDispatch,
    "address-dialog": DialogAddress,
  },
  data() {
    return {
      templateString: {
        subject: "",
        message: "",
        contact: "",
        comment: "",
      },
      hiddenSection: false,
      subscriptionName: "",
      items: [],
      renderViewer: 0,
      showProgressBar: false,
      BtnDownload: true,
      BtnDownloadDark: false,
      dark: false,
      show: true,
      showProductDialog: false,
      showPrint: false,
      showEmailDispatch: false,
      showAddress: true,
      menu: false,
      menu1: false,
      showPrintProgress: false,
      invoiceDate: "",
      invoiceDate1: "",
      currentDebtorIndex: 0,
      currentLength: 0,
      currentIndex: 0,
      qrCode: null,
      calculations: {
        total: 0,
        vatTotal: 0,
        subTotal: 0,
      },
      creditorAux: {},
      debitorAux: {},
      invoice_dateAux: "",
      payable_untilAux: "",
      DOMElements: {
        download: {
          loading: false,
        },
        update_status: {
          loading: false,
        }
      },
      currentItems: [],
      keyDebtor: 0,
      PDFDate: "",
      arrowsDisabled: false,
      rules: {
        isEmpty: (v) => !!v || this.$t("rules.entry_rule"),
      },
      zoom: 0,
      invoiceNumberPages: 0,
      noAllow: false
    };
  },
  props: {
    debtors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkboxAddress: {
      type: Boolean,
      default: true,
    },
    checkboxReference: {
      type: Boolean,
      default: true,
    },
    checkboxAmount: {
      type: Boolean,
      default: true,
    },
    checkboxMessage: {
      type: Boolean,
      default: true,
    },
    checkboxBilling: {
      type: Boolean,
      default: true,
    },
    checkboxAddData: {
      type: Boolean,
      default: false,
    },
    idTemplate: {
      type: Number,
      default: null,
    },
  },
  beforeDestroy() {
    if (this.$refs.PrintInvoice) {
      Object.assign(this.$refs.PrintInvoice, {
        orderSettings: {
          color: "black_and_white",
          envelope: "",
          tariff: "",
          type: "",
          uploadCustomInsert: "",
          parcel: "",
          envelopeColors: "black_and_white",
        },
        shippingSelection: "collective",
        myClimatePaper: false,
        insertFile: "",
        IncludeShipping: "",
        specialInstructions: "",
      });
    }
    this.$store.state.addressInfo.collectiveAdress = "";
    this.$store.state.addressInfo.addressArea = "";
    this.$store.state.addressInfo.logoArea = null;
    this.$store.state.addressInfo.climateSelected = true;
  },
  beforeMount() {
    this.subscriptionName = this.$store.getters["User/subscriptionName"];
    this.$root.$on("send", data => {
      this.invoiceNumberPages = data;
    })
  },
  mounted() {
    this.$refs.qrDebtor.show = true;
    this.$refs.qrCreditor.show = true;
  },
  methods: {
    closeDialog() {
      this.$store.state.qrSeries.stepperEmail = 1
      this.showEmailDispatch = false
    },
    cancelPrint() {
      this.$root
        .$confirm(
          this.$t("snack_confirm.cancel_title"),
          this.$t("snack_confirm.cancel_process")
        )
        .then((response) => {
          if (response) {
            this.showPrint = false;
            // this.$router.push({name: "qr-table" });
          }
        });
    },
    async confirmDialog() {

      // Take the values from the text field Collective Address and Remark to print shop
      let collectiveAddress = this.$store.state.addressInfo.collectiveAdress;
      let remarkToPrintShop = this.$store.state.addressInfo.specialInstructions;

      let validationArray = [collectiveAddress, remarkToPrintShop]

      // Confirm if we have a invalid input character
      if (validateCharac(validationArray).status) {
        return this.$store.commit('global/setSnackbar', { message: this.$t("Globals.error_character") });
      }

      const PrintQR = this.$refs.PrintInvoice.$refs.form.validate();
      if (PrintQR) {
        let validResponse = await this.generatePrintOrder();
        if (validResponse) {
          // Object.assign(this.$refs.PrintInvoice, {
          //   orderSettings:{
          //   color:"black_and_white",
          //   envelope:"",
          //   tariff:"",
          //   type:"",
          //   },
          //   shippingSelection:"collective",
          // });
          // this.$store.state.addressInfo.collectiveAdress ="";
          this.showPrint = false;
        }
      } else {
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("snack_confirm.invalid_inputs"),
        });
      }
    },
    print() {
      this.showPrint = true;
    },
    async updateBexioInvoiceStatus() {
      this.DOMElements.update_status.loading = true;
      const debtors = this.debtors.map((debtor) => { return { debitor_id: debtor.id_b, id: debtor.id, full_name: `${debtor.name} ${debtor.last_name}` } })

      const lang = this.$store.getters["User/getLanguage"]
      await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL
        }/api/bexio/invoice_status`,
        {
          method: "POST",
          body: JSON.stringify({ debtors, lang }),
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          }
        }
      ).then(async (response) => {
       
        if (response) {
          const alert_body = [];


          response.body.forEach(element => {
            alert_body.push({
              icon_type: element.debitor_status === "invoice bexio updated success" ? 'mdi-checkbox-marked-circle' : element.debitor_status === "Debtor unrelated to Bexio" ? 'mdi-alert' : 'mdi-close-circle'
              ,
              msj: !element.response.toLowerCase().includes('error')
                ? `${this.$t("Debtors.status_updated.success.name")} "${element.debitor.id_qr_debtor} - ${element.debitor.full_name}" ${this.$t("Debtors.status_updated.success.msj")}. `
                : `${this.$t("Debtors.status_updated.error.msj")} "${element.debitor.id_qr_debtor} - ${element.debitor.full_name}". `,
              status: element.debitor_status === "invoice bexio updated success" ? this.$t("Debtors.status_updated.success.bexio_success") : element.debitor_status === "Debtor unrelated to Bexio" ? this.$t("Debtors.status_updated.success.unrelated") : this.$t("Debtors.status_updated.error.bexio_not_found")
            });
          });
          this.DOMElements.update_status.loading = false;
          return this.$store.commit("global/setSnackbar", {
            message: "debtors alert updated status invoice in bexio and database qr",
            optionalMessage: { alert_body }
          });
        } else {
          return this.$store.commit("global/setSnackbar", {
            message: "Unable to download the order requested.",
          });
        }
      })
        .catch((err) => {
          console.log(err)
          return this.$store.commit("global/setSnackbar", {
            message: err,
          });
        });
    },
    async download() {
      this.DOMElements.download.loading = true;
      let orderId = this.$store.state.qrSeries.OrderID;
      await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL
        }/api/premium/massiveDownload?orderId=${orderId}&start=${0} `,
        {
          method: "GET",
        }
      )
        .then(async (response) => {
          if (!!response.zipString) {
            var element = document.createElement("a");
            element.setAttribute(
              "href",
              "data:text/plain;base64," + response.zipString
            );
            element.setAttribute("download", "templates.zip");
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.DOMElements.download.loading = false;
            return this.$store.commit("global/setSnackbar", {
              message: this.$t("snack_confirm.success"),
            });
          } else {
            this.DOMElements.download.loading = false;
            return this.$store.commit("global/setSnackbar", {
              message: "Unable to download the order requested.",
            });
          }
        })
        .catch((err) => {
          this.DOMElements.download.loading = false;
          return this.$store.commit("global/setSnackbar", {
            message: err,
          });
        });
      // this.DOMElements.download.loading = false;
    },
    async initFinal() {
      this.currentLength = this.debtors.length;
      const debtor = this.getDebtorData(this.currentIndex);
     
      const billing = debtor.billing_information;
      const currentProfile = this.profile;
      this.invoice_date = billing.invoice_date;
      this.payable_until = debtor.payable_until;
      // this.$refs.templateViewer.payable_until = debtor.payable_until;
      // this.$refs.templateViewer.invoice_date = billing.invoice_date;

      this.$store.commit("QR/setAmount", Number(debtor.amount) || 0);
      this.$store.commit("QR/setCurrency", debtor.currency);
      this.$store.commit("AI/unstructuredMessage", {
        unstructuredMessage: debtor.message,
      });

      let getTypeReference = detector.detectTypeReference(debtor.reference);
      this.typeOfReference = getTypeReference;
      this.$nextTick(async () => {
        this.$store.state.QR.reference = this.formatReference(
          getTypeReference,
          debtor.reference
        );
        this.$store.commit("AI/setBillingInformation", {
          bill_number: billing.invoice_no,
          customerReference: currentProfile.reference,
          voucherDate: billing.invoice_date,
          UID_Number: currentProfile.UIDNumber,
          VAT_Details: {
            rate: billing.VAT,
            amount: billing.discount,
          },
          VAT_Import: {
            rate: billing.VAT,
            amount: billing.discount,
          },
          VAT_Conditions: {
            discount: billing.discount,
            days: billing.payment_deadline,
          },
        });

        this.$store.commit("QR/setDebitor", {
          name: debtor.name,
          last_name: debtor.last_name,
          address: {
            street: debtor.street,
            number: debtor.number,
            zip: debtor.zip,
            city: debtor.city,
            country: debtor.country,
          },
          client_number: debtor.client_number ? debtor.client_number : "",
          invoice_number: debtor.billing_information
            ? debtor.billing_information.invoice_no
            : "",
        });

        this.qrCode = await this.getQRCode();
        this.reRenderDebtor();
        this.reRenderViewer();
      });
    },
    CancelProcess() {
      this.$root
        .$confirm(
          this.$t("QrSeries.step_three.btn_cancel"),
          this.$t("QrSeries.step_four.cancel_message")
        )
        .then((response) => {
          if (response) {
            this.BtnDownload = true;
            this.BtnDownloadDark = false;
            this.$router.push({ name: "qr-table" });
          }
        });
    },
    mutationFinal() {
      this.show = false;
      this.dark = true;
      this.$refs.qrCreditor.show = false;
      this.$refs.qrDebtor.show = false;

      //save dates
      let StoreInvoiceDate = this.$store.state.QR.invoice_date;
      this.invoice_dateAux = StoreInvoiceDate;

      let StorePayableUntil = this.$store.state.QR.payable_until;
      this.payable_untilAux = StorePayableUntil;
      //save credior
      let StoreAux = this.$store.getters["User/creditor"];
      this.creditorAux.account = StoreAux.account;
      this.creditorAux.name = StoreAux.name;
      this.creditorAux.last_name = StoreAux.last_name;
      this.creditorAux.address = {
        street: StoreAux.address.street,
        number: StoreAux.address.number,
        zip: StoreAux.address.zip,
        city: StoreAux.address.city,
        country: StoreAux.address.country,
      };
      this.creditorAux.reference = StoreAux.reference;
      this.creditorAux.id = StoreAux.id;

      //save debitor
      let StoreDebAux = this.$store.getters["QR/debitor"];
      this.debitorAux.name = StoreDebAux.name;
      this.debitorAux.last_name = StoreDebAux.last_name;
      this.debitorAux.address = {
        street: StoreDebAux.address.street,
        number: StoreDebAux.address.number,
        zip: StoreDebAux.address.zip,
        city: StoreDebAux.address.city,
        country: StoreDebAux.address.country,
      };

      this.arrowsDisabled = true;
    },
    async updateServerData(debtor, callback) {
      let currentProfile = this.$store.getters["qrSeries/getProfileData"];
      let creditorInfo = this.$store.getters["User/creditor"];
      let params = {
        profile: {
          id: currentProfile.id,
          type: creditorInfo.typeReference,
          IBAN: creditorInfo.account,
          IBAN_WIR: creditorInfo.accountWir,
          name: creditorInfo.name,
          last_name: creditorInfo.last_name,
          street: creditorInfo.address.street,
          number: creditorInfo.address.number,
          zip: creditorInfo.address.zip,
          city: creditorInfo.address.city,
          country: creditorInfo.address.country,
        },
        debtor: {
          id: debtor.id_b,
          name: debtor.name,
          last_name: debtor.last_name,
          street: debtor.street,
          number: debtor.number,
          zip: debtor.zip,
          city: debtor.city,
          country: debtor.country,
          currency: debtor.currency,
          message: this.$store.getters["AI/unstructuredMessage"].plain || "",
          products_json: debtor.products_json,
          reference: this.reference,
        },
      };
      try {
        let response = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL} /api/user / debtor / series`,
          {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]} `,
            },
          }
        );
        if (response) {
          return await callback(response.status === 200, response.message);
        } else {
          return await callback(undefined, this.$t("ProductUsage.unable_find"));
        }
      } catch (err) {
        return await callback(undefined, err);
      }
    },
    async SaveMutation() {
      let validationsResponse = this.runValidations();
      if (!validationsResponse) {
        return this.$store.commit(`global / setSnackbar`, {
          message: this.$t("snack_confirm.invalid_inputs"),
        });
      }
      this.saveCurrentDebtor();
      let currentDebtor = this.debtors[this.currentIndex];
      let validation = this.validateDebtor(this.currentIndex);
      if (validation) {
        await this.updateServerData(currentDebtor, (approved, message) => {
          if (approved) {
            this.$refs.qrCreditor.show = true;
            this.$refs.qrDebtor.show = true;
            this.show = true;
            this.dark = false;
            this.arrowsDisabled = false;
            this.initFinal();
          } else {
            return this.$store.commit(`global / setSnackbar`, message);
          }
        });
      }
    },
    validateDebtor(index) {
      let debtor = this.debtors[index];
      try {
        debitorFullName(debtor);
        address(debtor);
        zip(debtor);
        nameMissingZip(debtor);
        debitorCountryRules(debtor);
        city(debtor);
        fieldRequired(debtor);
        productsRequired(debtor);
        if (this.$store.state.templateEditor.templateType === "complete") {
          invoiceDateRequired(debtor);
        }
        // this.initFinal();
        return true;
      } catch (error) {
        this.runValidations();
        this.$store.commit(`global / setSnackbar`, error);
        return false;
      }
    },
    runValidations() {
      let validation2 = this.$refs.qrCreditor.$refs.form.validate();
      let validation3 = this.$refs.qrDebtor.$refs.form.validate();
      return validation2 && validation3;
    },
    saveCurrentDebtor() {
      const debtor = this.$store.getters["QR/debitor"];
      const _global = this.$store.getters["QR/global"];
      const message = this.$store.getters["AI/unstructuredMessage"];
      const amount = this.$store.state.QR.amount;

      Object.assign(this.debtors[this.currentIndex], {
        name: debtor.name,
        last_name: debtor.lastName,
        street: debtor.address.street,
        number: debtor.address.number,
        zip: debtor.address.zip,
        city: debtor.address.city,
        country: debtor.address.country,
        currency: _global.currency,
        message: message.plain,
        amount,
      });

      this.debtors[this.currentIndex].payable_until = this.payable_until;
      this.debtors[this.currentIndex].billing_information.invoice_date =
        this.invoice_date;
    },
    cancelMutation() {
      this.$root
        .$confirm(
          this.$t("QrSeries.step_four.btn_cancel_mutation"),
          this.$t("QrSeries.step_four.cancel_message")
        )
        .then((response) => {
          if (response) {
            this.show = true;
            this.dark = false;
            //Returns the invoice_date value
            this.$store.state.QR.invoice_date = this.invoice_dateAux;
            //The value of payable until
            this.$store.state.QR.payable_until = this.payable_untilAux;

            //Returns the value of creditor to what it was before modifying it.
            this.$store.state.User.creditor.account = this.creditorAux.account;
            this.$store.state.User.creditor.name = this.creditorAux.name;
            this.$store.state.User.creditor.last_name =
              this.creditorAux.last_name;
            this.$store.state.User.creditor.address.street =
              this.creditorAux.address.street;
            this.$store.state.User.creditor.address.number =
              this.creditorAux.address.number;
            this.$store.state.User.creditor.address.zip =
              this.creditorAux.address.zip;
            this.$store.state.User.creditor.address.city =
              this.creditorAux.address.city;
            this.$store.state.User.creditor.address.country =
              this.creditorAux.address.country;
            // this.$store.state.User.creditor.numberuid = this.profile.UIDNumber;
            this.$store.state.User.creditor.reference =
              this.creditorAux.reference;
            this.$store.state.User.creditor.id = this.creditorAux.id;
            this.$refs.qrCreditor.show = true;
            this.$refs.qrDebtor.show = true;

            //Returns the debitor value to what it was before modifying it.
            this.$store.state.QR.debitor.name = this.debitorAux.name;
            this.$store.state.QR.debitor.last_name = this.debitorAux.last_name;
            this.$store.state.QR.debitor.address.street =
              this.debitorAux.address.street;
            this.$store.state.QR.debitor.address.number =
              this.debitorAux.address.number;
            this.$store.state.QR.debitor.address.city =
              this.debitorAux.address.city;
            this.$store.state.QR.debitor.address.zip =
              this.debitorAux.address.zip;
            this.$store.state.QR.debitor.address.country =
              this.debitorAux.address.country;

            this.arrowsDisabled = false;
          }
        });
    },
    getDebtorData(index) {
      return this.debtors[index];
    },
    CreateInvoiceFinal() {

      // If the user insist on create a qr bill when we already warning him,
      // send the warning again.

      if (this.noAllow) {
        return this.$store.commit('global/setSnackbar', { message: this.$t("Globals.error_character") });
      }
      this.$refs.progressBar.progressBarValue = 0;
      this.$refs.progressBar.showProgressBar = true;
      const debtor = this.getDebtorData(this.currentIndex);
      this.$parent.$parent.$parent.CreateInvoice(debtor);
      this.BtnDownload = false;
      this.BtnDownloadDark = true;
    },
    CancelProcess() {
      this.$root
        .$confirm(
          this.$t("QrSeries.step_three.btn_cancel"),
          this.$t("QrSeries.step_four.cancel_message")
        )
        .then((response) => {
          if (response) {
            this.BtnDownload = true;
            this.BtnDownloadDark = false;
            this.$router.push({ name: "qr-table" });
          }
        });
    },
    previousError() {
      if (this.currentIndex == 0) {
        this.currentIndex = this.debtors.length;
      }
      this.currentIndex -= 1;
      this.initFinal();
    },
    nextError() {
      this.currentIndex += 1;
      if (this.currentIndex === this.debtors.length) {
        this.currentIndex = 0;
      }
      this.initFinal();
    },
    async getQRCode() {
      // TODO get inputs checkboxs data
      const debitor = this.$store.getters["QR/debitor"];
      const creditor = this.$store.getters["User/creditor"];
      const language = this.$store.getters["User/getLanguage"];
      const unstructuredMessage =
        this.$store.getters["AI/unstructuredMessage"].plain || "";
      const structuredMessage =
        this.$store.getters["AI/structuredMessage"].plain || "";
      const structuredMap = this.$store.getters["AI/structuredMap"];
      const reference = this.$store.state.QR.reference;
      const currency = this.$store.state.QR.currency;
      const amount = this.$store.state.QR.amount;
      let products;

      const data = {
        currency,
        reference,
        unstructuredMessage,
        amount,
        AdditionalInformationString: structuredMessage,
        language,
        creditor: {
          name: creditor.name,
          last_name: creditor.last_name,
          street: creditor.address.street,
          address: `${creditor.address.street} ${creditor.address.number} `,
          number: creditor.address.number,
          zip: creditor.address.zip,
          city: creditor.address.city,
          country: creditor.address.country,
          account: creditor.account.replace(/\s+/g, ""),
          qr_account: creditor.qr_account,
        },
        debitor: {
          name: debitor.name,
          last_name: debitor.last_name,
          address: `${debitor.address.street} ${debitor.address.number} `,
          street: debitor.address.street,
          number: debitor.address.number,
          zip: debitor.address.zip,
          city: debitor.address.city,
          country: debitor.address.country,
        },
        additionalInformation: structuredMap,
      };

      //we need check if user has product that contains Wir percentage
      const debtor = this.debtors[this.currentIndex];
      if (debtor && debtor.products_json) {

        products = debtor ? JSON.parse(debtor.products_json) : [];

        let amountCHW = 0;
        let amountCHF = 0;

        //Calculated the amounts
        for (let i = 0; i < products.length; i++) {

          //if the product not contains the property amount_CHF we need to take the value of property grossPriceProduct
          amountCHF += Number(products[i].amount_CHF || Number(products[i].grossPriceProduct || 0));
          amountCHW += Number(products[i].amount_CHW || 0);

        }

        //verify if there are producto with wir o chf
        let everyProductsAreCHW = products.every(element => {
          return element.percentage_wir && Number(element.percentage_wir) == 100
        });

        let everyProductsAreCHF = products.every(element => {
          return !element.percentage_wir || Number(element.percentage_wir) == 0;
        });

        if (everyProductsAreCHF) {
          data.currency = "CHF"
        }

        if (this.userHasWir && !everyProductsAreCHF) {

          if (!everyProductsAreCHW) {

            data.wir = {
              amount: amountCHW,
              currency: "CHW"
            }

            data.amount = amountCHF;
            data.creditor.account_wir = creditor.accountWir;

          } else {

            data.creditor.account = creditor.accountWir;
            data.currency = "CHW"
            data.amount = amountCHW;
          }


        }

      }


      if (!this.checkboxAddress) {
        Object.assign(data.debitor, {
          name: "",
          last_name: "",
          address: "",
          street: "",
          number: "",
          zip: "",
          city: "",
          country: "",
        });
      }

      if (!this.checkboxAmount) {
        data.amount = null;
      }

      if (!this.checkboxReference) {
        data.reference = "";
      }

      if (!this.checkboxMessage) {
        data.unstructuredMessage = "";
      }

      if (!this.checkboxAddData) {
        data.addData = false;
      }

      if (!this.checkboxBilling) {
        Object.assign(data.additionalInformation, {
          VAT_Number: undefined,
          VAT_Date: {
            start_date: null,
            end_date: null,
          },
          VAT_Details: [],
          VAT_Import: [],
          VAT_Conditions: [],
          bill_number: "",
          customerReference: undefined,
          voucherDate: undefined,
        });

        data.AdditionalInformationString = "";
      }

      // Create an array with the values to check if they have a invalid character
      let validationArray = [
        data.reference,
        data.creditor.name,
        data.creditor.last_name,
        data.creditor.street,
        data.creditor.address,
        data.creditor.number,
        data.creditor.city,
        data.creditor.account,

        data.debitor.name,
        data.debitor.last_name,
        data.debitor.address,
        data.debitor.street,
        data.debitor.number,
        data.debitor.city,

        data.unstructuredMessage
      ];

      const validationArrayIdentify = [
        this.$t("ErrorIdentify.reference"),
        this.$t("ErrorIdentify.creditor_name"),
        this.$t("ErrorIdentify.creditor_lname"),
        this.$t("ErrorIdentify.creditor_street"),
        this.$t("ErrorIdentify.creditor_address"),
        this.$t("ErrorIdentify.creditor_number"),
        this.$t("ErrorIdentify.creditor_city"),
        this.$t("ErrorIdentify.creditor_account"),
        this.$t("ErrorIdentify.debtor_name"),
        this.$t("ErrorIdentify.debtor_lname"),
        this.$t("ErrorIdentify.debitor_address"),
        this.$t("ErrorIdentify.debtor_street"),
        this.$t("ErrorIdentify.debtor_number"),
        this.$t("ErrorIdentify.debtor_city"),
        this.$t("ErrorIdentify.unstructuredMessage_field"),
        this.$t("ErrorIdentify.product_name"),
      ];

      // Check all the products name and add it on the validationArray
      for (let productActual of products) {
        validationArray.push(productActual.name);
      }

      // If we have a invalid character don't allow to create the invoice
      // console.log('array desde el QR get code: ', validationArray, validateCharac(validationArray))

      const respValidateCharact = validateCharac(validationArray)
      if (respValidateCharact.status) {
        return this.$store.commit('global/setSnackbar',
          {
            message: `${this.$t("Globals.error_character")} \n
          ${this.$t("Globals.error_text")} ${respValidateCharact.errorIndexArray >= 16 ? validationArrayIdentify[16] : validationArrayIdentify[respValidateCharact.errorIndexArray]}
          ` });
      }

      const params = encodeURIComponent(JSON.stringify(data));
      try {
        const reqQR = await fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/premium/qr/massive/payment-part?data=${params}`,
          { credentials: "include" }
        );

        if (!reqQR.ok) {
          throw new Error(`HTTP error! Status: ${reqQR.status}`);
        }

        const resQR = await reqQR.json();

        if (resQR.data) {
          return `data:application/pdf;base64,${resQR.data}`;
        } else {
          throw new Error("Bad response from server: " + JSON.stringify(resQR));
        }
      } catch (error) {
        this.noAllow = true;
        return this.$store.commit('global/setSnackbar', {
          message: `${this.$t("Globals.error_character")}: ${error.message}`
        });
      }


    },
    reRenderDebtor() {
      this.keyDebtor = this.keyDebtor++;
    },
    saveProducts() {
      const products = this.$refs.debtorProductTable.productItems;
      if (products.length === 0) {
        return this.$store.commit(
          "global/setSnackbar",
          "You have to at least add one item"
        );
      }

      this.debtors[this.currentIndex].products_json = JSON.stringify(
        this.$refs.debtorProductTable.productItems
      );
      this.reRenderViewer();
      this.showProductDialog = false;
    },
    reRenderViewer() {
      this.renderViewer++;
    },
    onClickShowProductDialog() {
      // this.showProductsDialog = true;
      this.showProductDialog = true;
      const products_json = this.currentDebtor.products_json;

      this.$nextTick(() => {
        this.$refs.debtorProductTable.productItems = JSON.parse(products_json);
      });
    },
    handleInvoiceDate(date) {
      this.$refs.templateViewer.invoice_date = date;
      this.$refs.templateViewer.init();
    },
    handlePayableUntil(date) {
      this.$refs.templateViewer.payable_until = date;
      this.$refs.templateViewer.init();
    },
    formatReference(typeRefernce, reference) {
      if (reference && typeRefernce) {
        let newReference = reference.replace(/\s/g, "");
        if (typeRefernce === "QRR") {
          if (newReference.length === 27) {
            let responseReference = `${newReference.substring(0, 2)} `;
            responseReference += ` ${newReference.substring(2, 7)} `;
            responseReference += ` ${newReference.substring(7, 12)} `;
            responseReference += ` ${newReference.substring(12, 17)} `;
            responseReference += ` ${newReference.substring(17, 22)} `;
            responseReference += ` ${newReference.substring(22, 27)} `;
            return responseReference;
          }
        } else if (typeRefernce === "SCOR") {
          if (newReference.length === 25) {
            let responseReference = `${newReference.substring(0, 4)} `;
            responseReference += ` ${newReference.substring(4, 8)} `;
            responseReference += ` ${newReference.substring(8, 12)} `;
            responseReference += ` ${newReference.substring(12, 16)} `;
            responseReference += ` ${newReference.substring(16, 20)} `;
            responseReference += ` ${newReference.substring(20, 24)} `;
            responseReference += ` ${newReference.substring(24, 25)} `;
            return responseReference;
          }
        }
      }
      return "";
    },
    runValidations() {
      let validation1 = this.$refs.qrCreditor.$refs.form.validate();
      let validation2 = this.$refs.qrDebtor.$refs.form.validate();
      let validation3 = this.$refs.dateForm.validate();
      return validation1 && validation2 && validation3;
    },
    async generatePrintOrder() {
      let settings = this.$refs.PrintInvoice.$data.settingsObj;
      let isItSlips =
        this.$store.state.templateEditor.templateType == "parts" ? true : false;
      let orderId = this.$store.state.qrSeries.OrderID;
      let LUCCId = this.$store.state.qrSeries.LUCCToken;

      // Run validations in order to ensure all info needed is available
      if (
        (isItSlips && !!settings.paper && !!settings.priority) ||
        (!isItSlips &&
          !!settings.paper &&
          !!settings.envelope &&
          !!settings.priority)
      ) {
        // Gather the print data
        let data = JSON.stringify({
          settings: settings,
          numberInvoices: this.$store.state.Debtor.debtors_selected.length,
          generateSeparatePaymentPart: this.$store.state.qrSeries.optionGeneratePaymentPart == 'separate',
          collectiveAddress: this.$store.state.addressInfo.collectiveAdress,
          specialInstructions:
            this.$store.state.addressInfo.specialInstructions,
          envelope: {
            address: this.$store.state.addressInfo.addressArea,
            font: this.$store.state.addressInfo.fontSelected,
            size: this.$store.state.addressInfo.fontSize,
          },
          climateSelected: this.$store.state.addressInfo.climateSelected,
          isItSlips: isItSlips,
          orderId: orderId,
          LUCCId: LUCCId,
          hideQRLogo: this.$store.state.addressInfo.hideQRLogo,
        });
        var info = {};
        info["data"] = data;

        // Check if there is envelope info to pass
        if (
          settings.envelope == "color" ||
          settings.envelope == "black_and_white"
        ) {
          let logoBase64String = null;
          let logo = this.$store.state.addressInfo.logoArea;
          if (logo instanceof File) {
            logoBase64String = await getBase64String(logo);
          }
          let _logo = null;
          if (logoBase64String) {
            _logo = logoBase64String.split(";base64,")[1];
          }
          info["logo"] = _logo || logo;
          info["logo_name"] = logo?.name;
        }

        // Check if there is insert file to process
        if (settings.inserts) {
          let insertBase64String = null;
          let insert = this.$refs.PrintInvoice.$data.insertFile;
          if (insert instanceof File) {
            insertBase64String = await getBase64String(insert);
          }
          let _insert = null;
          if (insertBase64String) {
            _insert = insertBase64String.split(";base64,")[1];
          }
          info["insert"] = _insert || insert;
          info["insert_name"] = insert?.name;
        }

        this.showPrintProgress = true;
        try {
          let response = await this.$fetch(
            `${process.env.VUE_APP_SERVER_URL}/api/premium/generatePrintOrder`,
            {
              method: "POST",
              body: JSON.stringify(info),
              headers: {
                "X-CSRF-Token": `${this.$store.getters["global/_csrf"]} `,
              },
            }
          );
          this.showPrintProgress = false;
          if (response) {
            if (response.status == 200) {
              this.$store.commit(
                "global/setSnackbar",
                this.$t("snack_confirm.success")
              );
              return true;
            } else {
              this.$store.commit("global/setSnackbar", response.message);
              return false;
            }
          } else {
            this.$store.commit(
              "global/setSnackbar",
              this.$t("Globals.unable_server")
            );
            return false;
          }
        } catch (err) {
          this.showPrintProgress = false;
          this.$store.commit("global/setSnackbar", `${err} `);
          return false;
        }
      } else {
        this.$store.commit(
          "global/setSnackbar",
          "Please check that all of the print specifications are filled."
        );
        return false;
      }
    },
  },
  watch: {
    async currentIndex() {
      this.qrCode = await this.getQRCode();
    },
    typeOfReference(newValue, oldValue) {
      const debtor = this.debtors[this.currentIndex];
      if (debtor) {
        let getTypeReference = detector.detectTypeReference(debtor.reference);
        this.reference = "";
        if (getTypeReference === newValue) {
          this.reference = this.formatReference(
            getTypeReference,
            debtor.reference
          );
        }
        this.runValidations();
      }
    },
    selected_template(newValue, oldValue) {
      if (newValue) {
        this.templateString = {
          subject: newValue.subject || "",
          message: newValue.greeting || "",
          contact: newValue.contactDetails || "",
          comment: newValue.closing || "",
        };
      }
      this.reRenderViewer();
    },
  },
  computed: {
    selected_template() {
      return this.$store.state.qrSeries.selected_template_settings;
    },
    debtorsFail: {
      get() {
        return this.$store.state.QR.debtorsFail;
      },
      set(value) {
        this.$store.state.QR.debtorsFail = value;
      },
    },

    userHasWir() {
      return this.$store.state.User.wir_active;
    },

    payable_until: {
      get() {
        return this.$store.state.QR.payable_until;
      },
      set(value) {
        this.$store.state.QR.payable_until = value;
      },
    },
    invoice_date: {
      get() {
        return this.$store.state.QR.invoice_date;
      },
      set(value) {
        this.$store.state.QR.invoice_date = value;
      },
    },
    currency() {
      return this.$store.state.QR.currency;
    },
    ...mapGetters({
      user: "User/user",
    }),
    ...mapMutations({
      setDebitor: "QR/setDebitor",
    }),
    currentDebtor() {
      return this.getDebtorData(this.currentIndex);
    },
    diplayDebtorOnTemplate() {
      const debtor = this.getDebtorData(this.currentIndex) || {};
      return {
        client_number: debtor.client_number,
        invoice_number: debtor.billing_information
          ? debtor.billing_information.invoice_no
          : "",
        company: debtor.company,
        first_name: debtor.name,
        last_name: debtor.last_name,
        address_suffix: debtor.address_suffix,
        street: debtor.street,
        street_number: debtor.number,
        zip: debtor.zip,
        city: debtor.city,
        language: debtor.language,
        salutation: debtor.salutation,
      };
    },
    profile() {
      return this.$store.getters["qrSeries/getProfileData"];
    },
    getItems() {
      // const debtor = this.debtors[this.currentIndex];
      // if(!debtor) return [];
      // const items = debtor ? JSON.parse(debtor.products_json) : [];
      // if(items === 0) return [];
      const debtor = this.debtors[this.currentIndex];
      if (!debtor) return [];
      if (!debtor.products_json) return [];
      const items = debtor ? JSON.parse(debtor.products_json) : [];
      if (!items) return [];

      this.calculations.total = 0;
      this.calculations.vatTotal = 0;
      this.calculations.subTotal = 0;
      const _items = [];
      items.forEach((item) => {
        const quantity = Number(item.quantity) || 0;
        const unitPrice = Number(item.unitPrice) || 0;
        const vatProduct = Number(item.vatProduct) || 0;
        const vat = Number(item.vat) || 0;
        const vatMethod = item.vatMethod || 'deduct';
        let total = 0;
        if (vatMethod == 'add') {
          total = quantity * unitPrice + vatProduct;
        } else {
          total = quantity * unitPrice;
        }
        // const total = quantity * unitPrice;

        const obj = {
          item: item.name,
          quantity,
          prize: unitPrice,
          vatPercent: vat,
          vat: vatProduct,
          percentage_wir: item.percentage_wir,
          amountCHW: item.amount_CHW,
          amountCHF: item.amount_CHF,
          total,
        };

        _items.push(obj);
      });

      const total = _items.reduce((acc, curr) => acc + curr.total, 0);
      const getVat = _items.reduce((acc, curr) => acc + curr.vat, 0);
      const totalAmountCHF = _items.reduce((acc, curr) => acc + Number(curr.amountCHF || 0), 0);
      const totalAmountCHW = _items.reduce((acc, curr) => acc + Number(curr.amountCHW || 0), 0);

      const getSub = total - getVat;

      if (!debtor.amount) {
        this.$store.state.QR.amount = total;
        debtor.amount = total;
      } else {
        this.$store.state.QR.amount = debtor.amount;
      }

      this.calculations.total = total;
      this.calculations.subTotal = getSub;
      this.calculations.vatTotal = getVat;

      this.calculations.amountCHW = totalAmountCHW;
      this.calculations.amountCHF = totalAmountCHF;



      return _items;
    },
    payable_until: {
      get() {
        return this.$store.state.QR.payable_until;
      },
      set(value) {
        this.$store.state.QR.payable_until = value;
      },
    },
    renderQR() {
      return this.$store.getters["templateEditor/renderQR"];
    },
    typeOfReference: {
      get() {
        return this.$store.state.User.creditor.typeReference;
      },
      set(value) {
        return (this.$store.state.User.creditor.typeReference = value);
      },
    },
    reference: {
      get() {
        return this.$store.state.QR.reference;
      },
      set(value) {
        return (this.$store.state.QR.reference = value);
      },
    },
    onlyQR() {
      if (this.$store.state.templateEditor.templateType === "parts") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>