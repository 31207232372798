/**
 * 
 * @param value without white spaces
 */
function validateCharac(value) {
    const characters = [
        '¡', '¢', '¤', '¥', '§', '¨', '©', 'ª', '«', '¬', '®', '¯', '°', '±',
        '²', '³', 'µ', '·', '¸', '¹', 'º', '»', '¼', '½', '¾', '¿', 'Ã', 'Å',
        'Æ', 'Ð', 'Õ', '×', 'Ø', 'Þ', 'ã', 'å', 'æ', 'ð', 'õ', 'ø', 'þ', 'ÿ',
        'ć', '¦', '¶', '­', '⌂', 'ž'
    ];

    let onlySpace = /^[\s]+$/;

    // If the sendend value is an array execute the array method version
    if (Array.isArray(value)) {
        //console.log("Array Version");
        for (const [index, actualValue] of value.entries()) {

            if (actualValue != null || actualValue != undefined) {
                for (const char of characters) {
                    // We convert the value into a string, this is for the case that if the value 
                    //we read is a number, 
                    // it does not create an error in the analyzer
                    // onlySpace.test(actualValue)
                    if (actualValue.toString().includes(char) || onlySpace.test(actualValue)) {
                        return { status: true, errorIndexArray: index };
                    }
                }

            }
        }
        return { status: false, errorIndexArray: null }


        // Else execute the one value method version
    } else {
        // console.log("Normal Version");
        if (value != null || value != undefined) {
            for (const char of characters) {
                // We convert the value into a string, this is for the case that if the value we read is a number, 
                // it does not create an error in the analyzer
                if (value.toString().includes(char) || onlySpace.test(value)) {
                    return true
                }
            }
        }
        return false
    }

}


module.exports = {
    validateCharac
}