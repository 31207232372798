<template>
  <v-row>
    <v-col cols="6">
      <v-card class height="100%">
        <!-- PAYABLE TO SECTION !-->
        <v-card-title>{{
          $t("qrGeneratorFree.payableTo.main_title")
        }}</v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableTo.IBAN_field')"
                  v-model="creditor.account"
                  :rules="[
                    rules.isIBAN,
                    rules.ibanCounter,
                    rules.isValidIBANNumber
                  ]"
                  v-mask="'AA## #### #XXX XXXX XXXX X'"
                  @input="IBANValidation"
                  counter="26"
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableTo.firstName_field')"
                  v-model="creditor.name"
                  :disabled="edit"
                  :rules="[rules.isName, rules.creditorFullName]"
                  :counter="35"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableTo.lastName_field')"
                  v-model="creditor.last_name"
                  :disabled="edit"
                  :rules="[rules.creditorFullName]"
                  :counter="35"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="4" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableTo.street_field')"
                  col="8"
                  v-model="creditor.address.street"
                  :disabled="edit"
                  :rules="[rules.address]"
                  @input="debitorAddressType"
                  :counter="70"
                  maxLength="70"
                ></v-text-field>
              </v-col>
              <v-col col="4" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableTo.houseNumber_field')"
                  col="4"
                  v-model.number="creditor.address.number"
                  :disabled="edit"
                  :counter="debitor.numberCounter"
                  :maxLength="debitor.numberCounter"
                  @input="debitorAddressType"
                ></v-text-field>
              </v-col>
              <v-col col="4" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableTo.postalCode_field')"
                  v-model="creditor.address.zip"
                  :disabled="edit"
                  :rules="[rules.zip, rules.nameMissingCreditorZip]"
                  :counter="16"
                  maxLength="16"
                  @input="debitorAddressType"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="6" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableTo.city_field')"
                  v-model="creditor.address.city"
                  :disabled="edit"
                  :rules="[rules.city, rules.nameCreditorMissingCity]"
                  @input="debitorAddressType"
                  :counter="35"
                  maxLength="35"
                ></v-text-field>
              </v-col>
              <v-col col="6" class="py-0">
                <v-select
                  :no-data-text="$t('qrGenerator.productsTable.data_available')"
                  :items="this.$t('countryList')"
                  item-text="name"
                  item-value="code"
                  :label="$t('qrGeneratorFree.payableTo.country_field')"
                  v-model="creditor.address.country"
                  :disabled="edit"
                  :rules="[rules.country]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="pa-0">
                <v-card-title class="pl-0">{{
                  $t("qrGeneratorFree.payableTo.reference_title")
                }}</v-card-title>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-select
                  :no-data-text="$t('qrGenerator.productsTable.data_available')"
                  :items="typeReference"
                  item-text="type"
                  item-value="value"
                  :label="$t('qrGeneratorFree.payableTo.typeOfReference_field')"
                  v-model="creditor.typeReference"
                  :error="errorState"
                  :disabled="edit"
                  :error-messages="errorMessage"
                  @change="setInputReference"
                  :rules="[rules.typeReferenceRules]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableTo.reference_field')"
                  v-model="reference"
                  :value="e => $event.target.value"
                  v-mask="referenceMask"
                  :disabled="edit"
                  :rules="[rules.referenceLength, rules.reference]"
                  @input="onReferenceInput"
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card height="100%">
        <!-- PAYABLE BY SECTION !-->
        <v-card-title>{{ $t("qrGeneratorFree.payableBy.main_title") }}</v-card-title>
        <v-card-text>
          <v-form ref="form2">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.firstName_field')"
                  v-model="debitor.name"
                  :disabled="edit"
                  :rules="[rules.debitorFullName]"
                  :counter="35"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.lastName_field')"
                  v-model="debitor.last_name"
                  :disabled="edit"
                  :rules="[rules.debitorFullName]"
                  :counter="35"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="3" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.street_field')"
                  col="8"
                  v-model="debitor.address.street"
                  :disabled="edit"
                  :rules="[rules.address]"
                  @input="debitorAddressType"
                  :counter="70"
                  maxLength="70"
                ></v-text-field>
              </v-col>
              <v-col col="2" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.houseNumber_field')"
                  col="4"
                  v-model="debitor.address.number"
                  :disabled="edit"
                  :counter="debitor.numberCounter"
                  :maxLength="debitor.numberCounter"
                  @input="debitorAddressType"
                ></v-text-field>
              </v-col>
              <v-col col="2" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.postalCode_field')"
                  v-model="debitor.address.zip"
                  :disabled="edit"
                  :rules="[rules.zip, rules.nameMissingZip]"
                  :counter="16"
                  maxLength="16"
                  @input="debitorAddressType"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="6" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.city_field')"
                  v-model="debitor.address.city"
                  :disabled="edit"
                  :rules="[rules.city, rules.nameMissingCity]"
                  @input="debitorAddressType"
                  :counter="35"
                  maxLength="35"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-select
                  :no-data-text="$t('qrGenerator.productsTable.data_available')"
                  :items="this.$t('countryList')"
                  item-text="name"
                  item-value="code"
                  :disabled="edit"
                  :label="$t('qrGeneratorFree.payableBy.country_field')"
                  v-model="debitor.address.country"
                  :rules="[rules.debitorCountryRules]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-card-title>{{ $t("qrGeneratorFree.payableBy.currency_title") }}</v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  :items="[
                    { currency: 'CHF', code: 'CHF' },
                    { currency: 'EUR', code: 'EUR' }
                  ]"
                  item-text="currency"
                  item-value="code"
                  :disabled="edit"
                  :label="$t('qrGeneratorFree.payableBy.currency_field')"
                  v-model="currency"
                  :value="e => $event.target.value"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0 d-flex justify-center align-center">
                <v-checkbox
                  :disabled="edit"
                  v-model="amountStatus"
                ></v-checkbox>
                <p>{{ $t("qrGeneratorFree.payableBy.disableAmount_field") }}</p>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.amount_field')"
                  v-show="!amountStatus"
                  v-model.lazy="amount"
                  :value="e => $event.target.value"
                  v-money="money"
                  :rules="[rules.amount]"
                  counter="12"
                  maxLength="12"
                  :disabled="edit || amountStatus"
                ></v-text-field>
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.amount_field')"
                  v-show="amountStatus"
                  :disabled="edit || true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  :label="$t('qrGeneratorFree.payableBy.unstructuredMessage_field')"
                  v-model="unstructuredMessage"
                  :value="message => $event.target.value"
                  counter="70"
                  :disabled="edit"
                  :rules="[rules.unstructuredMessage]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-expansion-panels
        :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile"
      >
        <v-expansion-panel>
          <!-- BILLING INFORMATION SECTION !-->
          <v-expansion-panel-header>{{ $t("qrGeneratorFree.billingInformation.main_title") }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form>
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    :label="$t('qrGeneratorFree.billingInformation.billNumber_field')"
                    v-model="$store.state.AI.structuredMessage.bill_number"
                    :disabled="edit"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-dialog
                    ref="billDate"
                    v-model="$store.state.AI.datePickers.billDate.active"
                    :return-value.sync="$store.state.AI.structuredMessage.voucherDate"
                    :disabled="edit"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="$store.state.AI.structuredMessage.voucherDate"
                        :label="$t('qrGeneratorFree.billingInformation.documentDate_field.field_text')"
                        :disabled="edit"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="$store.state.AI.structuredMessage.voucherDate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        :color="$vuetify.theme.themes.light.blue.primary"
                        :disabled="edit"
                        @click="$store.state.AI.datePickers.billDate.active = false"
                        dark
                        >{{$t("qrGeneratorFree.billingInformation.documentDate_field.cancel_button")}}</v-btn
                      >
                      <v-btn
                        text
                        :color="$vuetify.theme.themes.light.blue.primary"
                        :disabled="edit"
                        @click="$refs.billDate.save($store.state.AI.structuredMessage.voucherDate)"
                        dark
                        >{{$t("qrGeneratorFree.billingInformation.documentDate_field.ok_button")}}</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    :label="$t('qrGeneratorFree.billingInformation.customerReference_field')"
                    v-model="$store.state.AI.structuredMessage.customerReference"
                    :disabled="edit"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    :label="$t('qrGeneratorFree.billingInformation.UIDNumber_field')"
                    v-model="$store.state.AI.structuredMessage.UID_Number"
                    :disabled="edit"
                    prefix="CHE-"
                    v-mask="'###.###.###'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-card-title class="pa-0">{{ $t("qrGeneratorFree.billingInformation.vatDate_title") }}</v-card-title>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-dialog
                        ref="mwstStartDate"
                        v-model="datePickers.VAT_Date.start_date"
                        :return-value.sync="$store.state.AI.structuredMessage.VAT_Date.start_date"
                        :disabled="edit"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="$store.state.AI.structuredMessage.VAT_Date.start_date"
                            :label="$t('qrGeneratorFree.billingInformation.vatStartDate_field.field_text')"
                            prepend-icon="event"
                            readonly
                            :disabled="edit"
                            clearable
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="$store.state.AI.structuredMessage.VAT_Date.start_date"
                          :disabled="edit"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            :color="$vuetify.theme.themes.light.blue.primary"
                            :disabled="edit"
                            @click="datePickers.VAT_Date.start_date = false"
                            >{{ $t("qrGeneratorFree.billingInformation.vatStartDate_field.cancel_button") }}</v-btn
                          >
                          <v-btn
                            text
                            :color="$vuetify.theme.themes.light.blue.primary"
                            :disabled="edit"
                            @click="$refs.mwstStartDate.save($store.state.AI.structuredMessage.VAT_Date.start_date)"
                            >{{ $t( "qrGeneratorFree.billingInformation.vatStartDate_field.ok_button" ) }}</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-dialog
                        ref="mwstEndDate"
                        v-model="datePickers.VAT_Date.end_date"
                        :return-value.sync="$store.state.AI.structuredMessage.VAT_Date.end_date"
                        :disabled="edit"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="$store.state.AI.structuredMessage.VAT_Date.end_date5"
                            :label="$t( 'qrGeneratorFree.billingInformation.vatEndDate_field.field_text' )"
                            prepend-icon="event"
                            :disabled="edit"
                            readonly
                            clearable
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="$store.state.AI.structuredMessage.VAT_Date.end_date"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            :color="$vuetify.theme.themes.light.blue.primary"
                            :disabled="edit"
                            @click="datePickers.VAT_Date.end_date = false"
                            >{{ $t("qrGeneratorFree.billingInformation.vatEndDate_field.cancel_button") }}</v-btn
                          >
                          <v-btn
                            text
                            :color="$vuetify.theme.themes.light.blue.primary"
                            :disabled="edit"
                            @click="$refs.mwstEndDate.save( $store.state.AI.structuredMessage.VAT_Date.end_date )"
                            >{{
                              $t("qrGeneratorFree.billingInformation.vatEndDate_field.ok_button")
                            }}</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-card-title class="pa-0">{{
                        $t("qrGeneratorFree.billingInformation.vatDetails_title")
                      }}</v-card-title>
                    </v-col>
                  </v-row>
                  <v-data-table
                    hide-default-footer
                    :headers="headers.VAT_Details"
                    :disabled="edit"
                    :items="items.VAT_Details"
                  :no-data-text="$t('qrGenera|torFree.miscellaneous.table.notDataAvailable')"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialogs.VAT_Details"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              :disabled="edit"
                              v-on="on"
                              dark
                              >{{ $t("qrGeneratorFree.billingInformation.vatDetails_table.add_button") }}</v-btn
                            >
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-select
                                    :items="['0%', '2.8%', '7.7%']"
                                    item-text="currency"
                                    item-value="code"
                                    :label="$t('qrGeneratorFree.billingInformation.vatDetails_table.rate_title')"
                                    :disabled="edit"
                                    v-model="editedItems.VAT_Details.rate"
                                  ></v-select>
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    v-model="editedItems.VAT_Details.amount"
                                    type="number"
                                    :disabled="edit"
                                    :label="$t('qrGeneratorFree.billingInformation.vatDetails_table.totalAmount_title')"
                                  ></v-text-field>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :color="$vuetify.theme.themes.light.blue.primary"
                                :disabled="edit"
                                @click="closeVatDetailsDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.vatDetails_table.cancel_button")}}</v-btn
                              >
                              <v-btn
                                :color="$vuetify.theme.themes.light.blue.primary"
                                :disabled="edit"
                                @click="saveVatDetailsDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.vatDetails_table.save_button")}}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editVatDetailsDialog(item)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon small @click="deleteVatDetailsItem(item)"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-card-title class="pa-0">{{ $t("qrGeneratorFree.billingInformation.vatImportTax_title") }}</v-card-title>
                    </v-col>
                  </v-row>
                  <v-data-table
                    hide-default-footer
                    :headers="headers.VAT_Import"
                    :items="items.VAT_Import"
                    :no-data-text="$t('qrGeneratorFree.miscellaneous.table.notDataAvailable')"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialogs.VAT_Import"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="edit"
                              :color="$vuetify.theme.themes.light.blue.primary"
                              v-on="on"
                              dark
                              >{{$t("qrGeneratorFree.billingInformation.vatImportTax_table.add_button")}}</v-btn
                            >
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-select
                                    :items="['0%', '2.8%', '7.7%']"
                                    :label="$t('qrGeneratorFree.billingInformation.vatImportTax_table.rate_title')"
                                    :disabled="edit"
                                    v-model="editedItems.VAT_Import.rate"
                                  ></v-select>
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    v-model="editedItems.VAT_Import.discount"
                                    :disabled="edit"
                                    :label="$t('qrGeneratorFree.billingInformation.vatImportTax_table.discount_title')"
                                    type="number"
                                  ></v-text-field>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :disabled="edit"
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="closeVatImportDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.vatImportTax_table.cancel_button")}}</v-btn
                              >
                              <v-btn
                                :disabled="edit"
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="saveVatImportDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.vatImportTax_table.save_button")}}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        :disabled="edit"
                        class="mr-2"
                        @click="editVatImportDialog(item)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon
                        small
                        :disabled="edit"
                        @click="deleteVatImportItem(item)"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-card-title class="pa-0">{{$t("qrGeneratorFree.billingInformation.conditions_title")}}</v-card-title>
                    </v-col>
                  </v-row>
                  <v-data-table
                    hide-default-footer
                    :headers="headers.VAT_Conditions"
                    :items="items.VAT_Conditions"
                    :no-data-text="$t('qrGeneratorFree.miscellaneous.table.notDataAvailable')"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialogs.VAT_Conditions"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="edit"
                              :color="$vuetify.theme.themes.light.blue.primary"
                              v-on="on"
                              dark
                              >{{$t("qrGeneratorFree.billingInformation.conditions_table.add_button")}}</v-btn
                            >
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-text-field
                                    :label="$t('qrGeneratorFree.billingInformation.conditions_table.discount_title')"
                                    type="number"
                                    :disabled="edit"
                                    v-model="editedItems.VAT_Conditions.discount"
                                    suffix="%"
                                  ></v-text-field>
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    v-model="editedItems.VAT_Conditions.days"
                                    :disabled="edit"
                                    :label="$t('qrGeneratorFree.billingInformation.conditions_table.days_title')"
                                  ></v-text-field>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :disabled="edit"
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="closeVatConditionsDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.conditions_table.cancel_button")}}</v-btn
                              >
                              <v-btn
                                :disabled="edit"
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="saveVatConditionsDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.conditions_table.save_button")}}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        :disabled="edit"
                        small
                        class="mr-2"
                        @click="editVatConditionsDialog(item)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon
                        :disabled="edit"
                        small
                        @click="deleteVatConditionsItem(item)"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";
import { mapGetters } from "vuex";
import ISO11649 from "iso-11649";
import { QRR } from "@/functions/reference";
import rulesMixin from '@/mixins/rules';
export default {
  mixins: [rulesMixin],
  name: "QRForm",
  directives: {
    mask,
    money: VMoney
  },
  props: {
    edit: Boolean
  },
  created() {
    this.currency = "CHF";
    // if (!this.$store.state.User.languageChangePermission) {
    //   this.amount = "0";
    // }
  },
  beforeMount() {
    //Setting up old reference
    let QRRMask = "## ##### ##### ##### ##### #####";
    let SCORMask = "AA## XXXX XXXX XXXX XXXX XXXX X";
    if (this.$store.state.User.oldTypeReference == "QRR") {
      this.referenceMask = QRRMask;
      this.reference = this.$store.state.QR.oldReference;
    } else if (this.$store.state.User.oldTypeReference == "SCOR") {
      this.referenceMask = SCORMask;
      this.reference = this.$store.state.QR.oldReference;
    } else if (this.$store.state.User.oldTypeReference == "NON") {
      this.reference = "";
    }
  },
  data: function() {
    return {
      referenceMask: "", // this mask will be replace depending on setInputReference method --> XXXX - XXXXX - XXXX
      referenceCounter: 32,

      typeReference: [
        {
          type: this.$t("qrGeneratorFree.payableTo.typeOfReferece_values.QRR"),
          value: "QRR"
        },
        {
          type: this.$t("qrGeneratorFree.payableTo.typeOfReferece_values.SCOR"),
          value: "SCOR"
        },
        {
          type: this.$t("qrGeneratorFree.payableTo.typeOfReferece_values.NON"),
          value: "NON"
        }
      ],

      // This header property will be used by the tables on billing information section
      headers: {
        VAT_Date: [
          { text:this.$t("qrGeneratorFree.billingInformation.start_date"), value: "start_date" },
          { text: this.$t("qrGeneratorFree.billingInformation.end_date"), value: "end_date" },
          { text: this.$t("qrGeneratorFree.billingInformation.actions"), value: "actions", sortable: false }
        ],
        VAT_Details: [
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.vatDetails_table.rate_title"
            ),
            value: "rate"
          },
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.vatDetails_table.totalAmount_title"
            ),
            value: "amount"
          },
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.vatDetails_table.actions_title"
            ),
            value: "actions",
            sortable: false
          }
        ],
        VAT_Import: [
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.vatImportTax_table.rate_title"
            ),
            value: "rate"
          },
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.vatImportTax_table.discount_title"
            ),
            value: "discount"
          },
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.vatImportTax_table.actions_title"
            ),
            value: "actions",
            sortable: false
          }
        ],
        VAT_Conditions: [
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.conditions_table.discount_title"
            ),
            value: "discount"
          },
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.conditions_table.days_title"
            ),
            value: "days"
          },
          {
            text: this.$t(
              "qrGeneratorFree.billingInformation.conditions_table.actions_title"
            ),
            value: "actions",
            sortable: false
          }
        ]
      },

      // these items will store all the data that the user fills in the billing information
      items: {
        VAT_Date: [],
        VAT_Details: [],
        VAT_Import: [],
        VAT_Conditions: []
      },

      // these edited items will store the values that will be edited and maybe will replace the old ones
      editedItems: {
        VAT_Details: {
          rate: null,
          amount: null
        },
        VAT_Import: {
          rate: null,
          discount: null
        },
        VAT_Conditions: {
          discount: null,
          days: null
        }
      },

      defaultItems: {
        VAT_Details: {
          rate: null,
          amount: null
        },
        VAT_Import: {
          rate: null,
          discount: null
        },
        VAT_Conditions: {
          discount: null,
          days: null
        }
      },

      // these are used to save the calendar information in billing information
      datePickers: {
        billDate: {
          active: false,
          date: null
        },
        billDate2: {
          active: false,
          date: null
        },
        VAT_Date: {
          start_date: false,
          end_date: false
        }
      },

      // these are used to show the correct modal/dialog of property in billing information
      dialogs: {
        VAT_Date: false,
        VAT_Import: false,
        VAT_Conditions: false
      },

      // this property is used to store the correct index that is being edited in billing information
      editedItem: -1,
      money: {
        decimal: ".",
        thousands: " ",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      },

      //Billing information hover data
      accordion: false,
      popout: false,
      inset: false,
      multiple: false,
      disabled: false,
      readonly: false,
      focusable: false,
      flat: false,
      hover: false,
      tile: false,
      errorState: false,
      errorMessage: "",
      referenceErrorMessage: "",
      typeReferenceStatus: true,
      rules: {
        ibanRules: value => {
          if (this.errorState) {
            return false;
          } else {
            return true;
          }
        },
        isIBAN: value => !!value || this.$t("qrGeneratorFree.rules.isIBAN"),
        ibanCounter: value => value.length <= 26 || this.$t("qrGeneratorFree.rules.ibanCounter"),
        isName: value => !!value || this.$t("qrGeneratorFree.rules.isName"),
        creditorFullName: value =>
          this.creditor.name.length + this.creditor.last_name.length <= 70 ||
          this.$t("qrGeneratorFree.rules.isName"),
        referenceLength: value => {
          let reference = this.reference.replace(/ /g, "");
          if (this.creditor.typeReference === "QRR") {
            if (reference.length !== 27) {
              return this.$t("qrGeneratorFree.rules.referenceLength.QRR");
            } else {
              return true;
            }
          } else if (this.creditor.typeReference === "SCOR") {
            if (!(reference.length >= 5 && reference.length <= 25)) {
              return this.$t("qrGeneratorFree.rules.referenceLength.SCOR");
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
        reference: value => {
          let reference = this.reference.replace(/ /g, "");
          if (this.creditor.typeReference === "QRR") {
            // Get the digit that should be the last digit in the reference
            // TODO: send from 0 - 26 characters
            let lastDigit = QRR(reference);
            if (reference.charAt(27) != lastDigit) {
              return this.$t("qrGeneratorFree.rules.reference.QRR");
            } else {
              return true;
            }
          } else if (this.creditor.typeReference === "SCOR") {
            if (reference.length >= 5 && reference.length <= 25) {
              return ISO11649.validate(reference)
                ? true
                : this.language.rules.reference.SCOR.invalid;
              // if(ISO11649.validate(reference))return true
              // return this.$t('qrGeneratorFree.rules.reference.SCOR.invalid')
            } else {
              return this.$t(
                "qrGeneratorFree.rules.reference.SCOR.invalidLength"
              );
            }
          }

          return true;
        },
        address: value =>
          value.length <= 70 || this.$t("qrGeneratorFree.rules.address"),
        zip: value =>
          value.length <= 16 || this.$t("qrGeneratorFree.rules.zip"),
        city: value =>
          value.length <= 35 || this.$t("qrGeneratorFree.rules.city"),
        country: value => !!value || this.$t("qrGeneratorFree.rules.country"),
        debitorCountryRules: value => {
          if (this.debitor.name.length > 0) {
            return (
              !!value || this.$t("qrGeneratorFree.rules.debitorCountryRules")
            );
          } else {
            return true;
          }
        },
        amount: value =>
          value.length <= 12 || this.$t("qrGeneratorFree.rules.amount"),
        debitorFullName: value =>
          this.debitor.name.length + this.debitor.last_name.length <= 70 ||
          this.$t("qrGeneratorFree.rules.debitorFullName"),
        unstructuredMessage: value => {
          value = value || "";
          return (
            value.length <= 70 ||
            this.$t("qrGeneratorFree.rules.unstructuredMessage")
          );
        },
        typeReferenceRules: value => {
          if (value === "") {
            return this.$t("qrGeneratorFree.rules.typeReferenceRules");
          } else {
            if (this.typeReferenceStatus) return true;
            return false;
          }
        },
        nameMissingZip: value => {
          if (this.debitor.name.length > 0) {
            return (
              !!this.debitor.address.zip ||
              this.$t("qrGeneratorFree.rules.zipRequired")
            );
          } else {
            return true;
          }
        },
        nameMissingCreditorZip: value => {
          if (this.creditor.name.length > 0) {
            return (
              !!this.creditor.address.zip ||
              this.$t("qrGeneratorFree.rules.zipRequired")
            );
          } else {
            return true;
          }
        },
        nameMissingCity: value => {
          if (this.debitor.name.length > 0) {
            return (
              !!this.debitor.address.city ||
              this.$t("qrGeneratorFree.rules.cityRequired")
            );
          } else {
            return true;
          }
        },
        nameCreditorMissingCity: value => {
          if (this.creditor.name.length > 0) {
            return (
              !!this.creditor.address.city ||
              this.$t("qrGeneratorFree.rules.cityRequired")
            );
          } else {
            return true;
          }
        },
      }
    };
  },
  errorCaptured(err, vm, info) {
    console.log(`${err.stack}\n\nfound in ${info} of component`);
  },
  computed: {
    ...mapGetters({
      debitor: "QR/debitor",
      global: "QR/global",
      creditor: "User/creditor",
      getLanguage: "User/getLanguage"
    }),

    reference: {
      get() {
        return this.$store.state.QR.reference;
      },
      set(reference) {
        this.$store.commit("QR/setReference", reference);
      }
    },
    currency: {
      get() {
        return this.$store.state.QR.currency;
      },
      set(currency) {
        this.$store.commit("QR/setCurrency", currency);
      }
    },
    amount: {
      get() {
        return this.$store.state.QR.amount;
      },
      set(amount) {
        this.$store.commit("QR/setAmount", amount);
      }
    },
    unstructuredMessage: {
      get() {
        return this.$store.state.AI.unstructuredMessage;
      },
      set(message) {
        this.$store.commit("AI/unstructuredMessage", {
          unstructuredMessage: message
        });
      }
    },
    amountStatus: {
      get() {
        return this.$store.state.QR.stateAmount;
      },
      set(state) {
        this.$store.commit("QR/setStateAmount", state);
      }
    }
  },
  methods: {
    /**
     * these repetitive methods correspond to the section
     * "billing information" on the QR Form.
     */
    closeVatDetailsDialog() {
      this.dialogs.VAT_Details = false;
      this.editedItems.VAT_Details = Object.assign(
        {},
        this.defaultItems.VAT_Details
      );
      this.editedItem = -1;
    },
    saveVatDetailsDialog() {
      let rate = this.editedItems.VAT_Details.rate;
      let amount = this.editedItems.VAT_Details.amount;
      // if its a new value
      if (this.editedItem == -1) {
        this.items.VAT_Details.push({
          rate,
          amount
        });
        // otherwise if it already exists, just will replace the data
      } else {
        Object.assign(this.items.VAT_Details[this.editedItem], {
          rate,
          amount
        });
      }
      (this.editedItems.VAT_Details.amount = null),
        (this.editedItems.VAT_Details.rate = null),
        (this.editedItem = -1);
      // the informaton its saved in the store
      this.$store.commit("AI/setVAT_Details", {
        VAT_Details: this.items.VAT_Details
      });
      // the form/dialog closes
      this.closeVatDetailsDialog();
    },
    /**
     * i really dont know how this method gets the item information
     * i suppouse that it can be obtained from the v-slot property in the template
     */
    editVatDetailsDialog(item) {
      let { rate } = item;
      let { amount } = item;
      // Set the index of the element that its being edited
      this.editedItem = this.items.VAT_Details.indexOf(item);
      // Set the data to edit
      this.editedItems.VAT_Details.rate = rate;
      this.editedItems.VAT_Details.amount = amount;
      // finally opens the dialog to let the user modify the data
      this.dialogs.VAT_Details = true;
    },

    deleteVatDetailsItem(item) {
      // Get the index of the item
      const index = this.items.VAT_Details.indexOf(item);
      // if the user agrees will be deleted
      // TODO: Phase 2, this confirm would be cool if we use v-dialog to replace the native confirm
      this.$root
        .$confirm(this.$t("Globals.delete"), this.$t("Globals.ask_delete"), {
          color: "red"
        })
        .then(confirm => {
          if (confirm) {
            this.items.VAT_Details.splice(index, 1);
          }
        });
    },

    // VAT Import CRUD:
    closeVatImportDialog() {
      this.dialogs.VAT_Import = false;
      this.editedItems.VAT_Import = Object.assign(
        {},
        this.defaultItems.VAT_Import
      );
      this.editedItem = -1;
    },

    saveVatImportDialog() {
      let rate = this.editedItems.VAT_Import.rate;
      let discount = this.editedItems.VAT_Import.discount;
      // if its a new value
      if (this.editedItem == -1) {
        this.items.VAT_Import.push({
          rate,
          discount
        });
        // otherwise if it already exists, just will replace the data
      } else {
        Object.assign(this.items.VAT_Import[this.editedItem], {
          rate,
          discount
        });
      }
      (this.editedItems.VAT_Import.discount = null),
        (this.editedItems.VAT_Import.rate = null),
        (this.editedItem = -1);
      // the informaton its saved in the store
      this.$store.commit("AI/setVAT_Import", {
        VAT_Import: this.items.VAT_Import
      });
      // the form/dialog closes
      this.closeVatImportDialog();
    },

    editVatImportDialog(item) {
      let { rate } = item;
      let { discount } = item;
      // Set the index of the element that its being edited
      this.editedItem = this.items.VAT_Import.indexOf(item);
      // Set the data to edit
      this.editedItems.VAT_Import.rate = rate;
      this.editedItems.VAT_Import.discount = discount;
      // finally opens the dialog to let the user modify the data
      this.dialogs.VAT_Import = true;
    },

    deleteVatImportItem(item) {
      // Get the index of the item
      const index = this.items.VAT_Import.indexOf(item);
      // if the user agrees will be deleted
      this.$root
        .$confirm(this.$t("Globals.delete"), this.$t("Globals.ask_delete"), {
          color: "red"
        })
        .then(confirm => {
          if (confirm) {
            this.items.VAT_Import.splice(index, 1);
          }
        });
    },

    // VAT Conditions CRUD:
    closeVatConditionsDialog() {
      this.dialogs.VAT_Conditions = false;
      this.editedItems.VAT_Conditions = Object.assign(
        {},
        this.defaultItems.VAT_Conditions
      );
      this.editedItem = -1;
    },

    saveVatConditionsDialog() {
      let discount = "";
      if (
        this.editedItems.VAT_Conditions.discount !== undefined &&
        this.editedItems.VAT_Conditions.discount !== "" &&
        this.editedItems.VAT_Conditions.discount !== null
      ) {
        if (this.editedItems.VAT_Conditions.discount.match(/\%/g) !== null) {
          this.editedItems.VAT_Conditions.discount = this.editedItems.VAT_Conditions.discount.replace(
            /\%/g,
            ""
          );
        }
        discount = `${this.editedItems.VAT_Conditions.discount}%`;
      }
      let days = this.editedItems.VAT_Conditions.days;
      // if its a new value
      if (this.editedItem == -1) {
        this.items.VAT_Conditions.push({
          days,
          discount
        });
        // otherwise if it already exists, just will replace the data
      } else {
        Object.assign(this.items.VAT_Conditions[this.editedItem], {
          days,
          discount
        });
      }
      (this.editedItems.VAT_Conditions.discount = null),
        (this.editedItems.VAT_Conditions.days = null),
        (this.editedItem = -1);
      // the informaton its saved in the store
      this.$store.commit("AI/setVAT_Conditions", {
        VAT_Conditions: this.items.VAT_Conditions
      });
      // the form/dialog closes
      this.closeVatConditionsDialog();
    },

    editVatConditionsDialog(item) {
      let { days } = item;
      let { discount } = item;
      // Set the index of the element that its being edited
      this.editedItem = this.items.VAT_Conditions.indexOf(item);
      // Set the data to edit
      this.editedItems.VAT_Conditions.days = days;
      if (discount !== undefined) {
        discount = discount.replace(/\%/g, "");
      }
      this.editedItems.VAT_Conditions.discount = discount;
      // finally opens the dialog to let the user modify the data
      this.dialogs.VAT_Conditions = true;
    },

    deleteVatConditionsItem(item) {
      // Get the index of the item
      const index = this.items.VAT_Conditions.indexOf(item);
      // if the user agrees will be deleted
      this.$root
        .$confirm(this.$t("Globals.delete"), this.$t("Globals.ask_delete"), {
          color: "red"
        })
        .then(confirm => {
          if (confirm) {
            this.items.VAT_Conditions.splice(index, 1);
          }
        });
    },

    updateStore() {
      this.$store.commit("AI/");
    },

    creditorAdressType() {
      // if the user hasnt entered all these information will be considered as combined address
      let structured =
        this.creditor.address.steet != "" &&
        this.creditor.address.number != "" &&
        this.creditor.address.zip != "" &&
        this.creditor.address.city != "";
      if (structured) {
        this.creditor.addressType = "S";
        this.creditor.numberCounter = 16;
      } else {
        (this.creditor.addressType = "K"), (this.creditor.numberCounter = 70);
      }
    },

    debitorAddressType() {
      // if the user hasnt entered all these information will be considered as combined address
      let structured =
        this.debitor.address.steet != "" &&
        this.debitor.address.number != "" &&
        this.debitor.address.zip != "" &&
        this.debitor.address.city != "";
      if (structured) {
        this.debitor.addressType = "S";
        this.debitor.numberCounter = 16;
      } else {
        (this.debitor.addressType = "K"), (this.debitor.numberCounter = 70);
      }
    },

    /**
     * Basically this method will set the correct mask for the input reference
     * the mask should change depending in the type of IBAN used.
     */
    setInputReference() {
      // the mask/format that its used for each type of reference
      let QRRMask = "## ##### ##### ##### ##### #####";
      let SCORMask = "AA## XXXX XXXX XXXX XXXX XXXX X";
      this.$refs.form.resetValidation();
      // // if the user select QRR - Reference
      if (this.$store.state.User.creditor.typeReference == "QRR") {
        this.referenceMask = QRRMask;
        this.referenceCounter = 32;
        ////////////////////////////
        if (this.creditor.account.length > 0) {
          if (this.creditor.account.charAt(5) != 3) {
            this.errorState = true;
            this.errorMessage = this.language.miscellaneous.error_messages.typeOfReference_field;
            this.typeReferenceStatus = false;
          } else {
            this.errorState = false;
            this.errorMessage = "";
            this.typeReferenceStatus = true;
          }
        }

        // if the user select SCOR - Reference
      } else if (this.$store.state.User.creditor.typeReference == "SCOR") {
        this.referenceMask = SCORMask;
        this.referenceCounter = 25;
        if (this.creditor.account.length > 0) {
          if (this.creditor.account.charAt(5) == 3) {
            this.errorState = true;
            this.errorMessage = this.language.miscellaneous.error_messages.typeOfReference_field;
            this.typeReferenceStatus = false;
          } else {
            this.errorState = false;
            this.errorMessage = "";
            this.typeReferenceStatus = true;
          }
        }
        // if the user select NON - Reference
      } else if (this.$store.state.User.creditor.typeReference == "NON") {
        this.referenceMask = "";
        this.referenceCounter = 0;
        if (this.creditor.account.length > 0) {
          if (this.creditor.account.charAt(5) == 3) {
            this.errorState = true;
            this.errorMessage = this.language.miscellaneous.error_messages.typeOfReference_field;
            this.typeReferenceStatus = false;
          } else {
            this.errorState = false;
            this.errorMessage = "";
            this.typeReferenceStatus = true;
          }
        }
      }
    },

    IBANValidation() {
      if (this.creditor.account.length > 4) {
        if (this.creditor.account.charAt(5) == 3) {
          this.creditor.typeReference = "QRR";
          if (this.creditor.typeReference === "QRR") {
            this.errorState = false;
            this.errorMessage = "";
            this.typeReferenceStatus = true;
          } else {
            this.errorState = true;
            this.errorMessage = this.language.miscellaneous.error_messages.typeOfReference_field;
            this.typeReferenceStatus = false;
          }
        } else {
          this.creditor.typeReference = "NON";
          if (this.creditor.typeReference === "QRR") {
            this.errorState = true;
            this.errorMessage = this.language.miscellaneous.error_messages.typeOfReference_field;
            this.typeReferenceStatus = false;
          } else {
            this.errorState = false;
            this.errorMessage = "";
            this.typeReferenceStatus = true;
          }
        }
        this.setInputReference();
      } else if (this.creditor.account.length === 0) {
        this.errorState = false;
        this.errorMessage = "";
      }
    },
    onReferenceInput() {
      this.$store.commit("QR/setOldReference", this.reference);
      this.$store.commit(
        "User/setOldTypeReference",
        this.creditor.typeReference
      );
    }
  }
};

</script>

<style scoped>
.crumbs {
  color: #fff;
}
</style>
