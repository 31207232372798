const steps = {
    namespaced: true,
    state: {
        step1: {
            terms_and_conditions: false,
        },
        step2: {
            plan: '',
        }
    },
    mutations: {
        setTermsAndConditions(state, payload) {
            state.step1.terms_and_conditions = payload
        },
    }
}

export default steps;