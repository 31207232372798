<template>
  <v-row>
      <v-col class="py-0" no-gutters>
          <v-card-title>{{ $t("qrGenerator.payableBy.main_title") }}</v-card-title>
        <p style="color:green;" v-if="tab" class="pl-3">
          {{ $t("qrGenerator.payableBy.debtorSelector_tab.tab1.text") }}
        </p>
        <v-tabs
          v-model="tabdebitor"
          background-color="transparent"
          color="black"
          centered
          icons-and-text
          @change="changeGenButtonState"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1" @click="tab = true">
            {{ $t("qrGenerator.payableBy.debtorSelector_tab.tab1.main_title") }}
            <v-icon>mdi-grease-pencil</v-icon>
          </v-tab>
          <v-tab href="#tab-2" @click="tab = false">
            {{ $t("qrGenerator.payableBy.debtorSelector_tab.tab2.main_title") }}
            <v-icon>mdi-account-multiple</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabdebitor" class="pb-0">
          <v-tab-item v-for="ii in 2" :key="ii" :value="'tab-' + ii">
            <v-card-text class="pb-0 pt-1">
              <v-row v-if="ii == 2" cols="12">
                <v-col class="pb-0 pt-0" no-gutters>
                  <v-autocomplete
                    autowidth
                    :items="debtors"
                    item-text="fullName"
                    :label="$t('qrGenerator.payableBy.debtorSelector_field')"
                    item-value="id"
                    v-model="select_debitor"
                    @change="info_debitor"
                    return-object
                    outlined
                    dense
                  >
                    <template v-slot:item="{item}">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >{{item.fullName}} - {{(item.amount).toFixed(2)}}</span>
                        </template>
                        <span>Last Modified Date: {{formatDate(item.last_modified_date)}}</span>
                      </v-tooltip>                   
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
</template>

<script>
import userMixin from '@/mixins/user.mixin';
import { detectTypeReference } from './../../../detectTypeReference.js';
export default {
    mixins: [userMixin],
    data() {
      return {
        tab: "tab-1",
        tabdebitor: "tab-1",
        debitorData: [],
        debtors: [],
        select_debitor: 0,
      }
    },
    async beforeMount() {
      await this.getDebtors();
    },
    computed:{
      reference: {
        get() {
          return this.$store.state.QR.reference;
        },
        set(reference) {
          this.$store.commit("QR/setReference", reference);
        }
      },
      userHasWir(){
          return this.$store.state.User.wir_active;
      },
      debtor: {
        get() {
            return this.$store.state.QR.debitor
        }, 
        set(value){
          this.$store.state.QR.debitor = value;
        }
      },
      percentageWir:{
        set(value){
            return this.$store.commit("QR/setPercentage", value ) ;
        },
        get(){
            return this.$store.state.QR.wirPercentage;
        }
      },
    },
    methods: {
      changeGenButtonState() {
        //Depending on which tab is selected the invoce dates fields & the generate button are disabled
        if (this.tabdebitor === "tab-1") {
          this.invoiceDatesStatus = false;
          this.filePermission = true;
          this.debtor = {
            name: "",
            last_name: '',
            address: {
                street: '',
                number: '',
                zip: '',
                city: "",
                country: ""
            },
          },
          this.percentageWir = 0;
          this.message = '';
          this.$store.commit("QR/setCurrency", 'CHF');
          this.$store.commit("QR/setAmount", 0);
          this.$store.commit("AI/unstructuredMessage",{ unstructuredMessage: '' });
          this.currency = 'CHF';
          this.message = '';
          this.select_debitor = '';
          this.reference = '';
          this.$store.state.AI.structuredMessage.voucherDate = '';
          this.$store.state.AI.structuredMessage.bill_number = '';
        } else {
          this.invoiceDatesStatus = true;
          if (this.pdfFile) {
            this.filePermission = true;
          } else {
            this.filePermission = false;
          }
        }
      },
      info_debitor() {
        //loads into the debtor's fields the data corresponding to the selected debitor
        let debitor = this.debtors.find(element => {
          return this.select_debitor.id == element.id;
        });
        if (debitor) {
          let getTypeOfReference = detectTypeReference(debitor.reference);
          this.$store.commit('User/setTypeReference',getTypeOfReference);
    
          let referenceMask = '';

          const ibansToValidate = []
          if(getTypeOfReference === 'QRR'){
            referenceMask = '## ##### ##### ##### ##### #####';
            

           
            if(this.userHasWir){
               ibansToValidate.push(this.$store.state.User.user.qr_account_wir);
               ibansToValidate.push(this.$store.state.User.user.qr_account);
            }else{
               ibansToValidate.push(this.$store.state.User.user.qr_account);
            }

          }else if(getTypeOfReference === 'SCOR'){
            referenceMask = 'AAXX XXXX XXXX XXXX XXXX XXXX X';
          }
        
          if(getTypeOfReference == 'SCOR' || getTypeOfReference == 'NON' || !getTypeOfReference){
              if(this.userHasWir){
                ibansToValidate.push(this.$store.state.User.user.account_wir);
                ibansToValidate.push(this.$store.state.User.user.account);
              }else{
                ibansToValidate.push(this.$store.state.User.user.account);
              } 
          }
          
          this.$store.commit('User/setReferenceMask',referenceMask);

          if(this.userHasWir){

             this.$store.state.User.creditor.accountWir = ibansToValidate[0];
             this.$store.state.User.creditor.account    = ibansToValidate[1];

          }else{
             this.$store.state.User.creditor.account = ibansToValidate[0];
          }
          
          
          this.debitor.name = debitor.name ? debitor.name : '';
          this.debitor.last_name = debitor.lastname ? debitor.lastname : '';
          this.debitor.address.street = debitor.street ? debitor.street : '';
          this.debitor.address.number = debitor.number ? debitor.number : '';
          this.debitor.address.zip = debitor.zip ? debitor.zip : '';
          this.debitor.address.city = debitor.city ? debitor.city : '';
          this.debitor.address.country = debitor.country ? debitor.country : '';
          this.debitor.id = debitor.id_c ? debitor.id_c : '';
          this.$store.commit("AI/unstructuredMessage",{ unstructuredMessage: debitor.message });
          this.$store.state.AI.structuredMessage.voucherDate = debitor.billing_invoice_date;
          this.$store.state.AI.structuredMessage.bill_number = debitor.billing_invoice_number;
          
          if(this.userHasWir){
             
            let products =  JSON.parse(debitor.products_JSON);
            let amounts_CHW = products.map(element => Number(element.amount_CHW || 0));
             
            let total_CHW = amounts_CHW.reduce((prev,current)=> {
                  return prev + current;
            }, 0 );

            let porcentage = (( total_CHW / debitor.amount ) * 100);

            let formatPorcentage = String(porcentage);

            formatPorcentage = formatPorcentage.indexOf('.') >= 0 ? formatPorcentage.substring(0,formatPorcentage.indexOf('.')+2): formatPorcentage+".0";

            this.percentageWir = Number(formatPorcentage);
             
          }

          this.$store.commit("QR/setCurrency", debitor.currency);
          this.$store.commit("QR/setAmount", debitor.amount);
          
          this.$nextTick(()=>{
            this.reference =  (getTypeOfReference == "QRR" || getTypeOfReference == "SCOR")  &&  debitor.reference ? debitor.reference : '';
          })
        }
      },
      async getDebtors() {
        try{
          let response = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/debitorsData`);
          if(response){
            if(response.status == 200){
              let tempArray = response.data;
              for(let i = 0;i<tempArray.length;i++){
                let currentDebtor = tempArray[i];
                let currentName = '';
                let SumAmount = 0;
                currentName += currentDebtor.lastname ? `${currentDebtor.lastname}` : '';
                currentName += currentName !== '' && currentDebtor.name ? ' ' : '';
                currentName += currentDebtor.name ? `${currentDebtor.name}` : '';
                currentName += currentName !== '' && currentDebtor.client_number ? ' ' : '';
                currentName += currentDebtor.client_number ? `${currentDebtor.client_number}` : '';
                if(currentDebtor.products_JSON){
                  let products = JSON.parse(currentDebtor.products_JSON);
                  if(products){
                    for(let j = 0;j < products.length;j++){
                      SumAmount = SumAmount +  Number(products[j].grossPriceProduct)
                    }
                  }
                }
                await this.debtors.push({
                  id: i + 1,
                  ...currentDebtor,
                  fullName:currentName,
                  amount:SumAmount !== undefined && SumAmount !== null && !isNaN(SumAmount) ? SumAmount : 0
                });
              }
              this.debtors.sort((a,b)=>{
                let aElement = a.fullName ? `${a.fullName}` : '';
                let bElement = b.fullName ? `${b.fullName}` : '';
                return aElement.toLowerCase().localeCompare(bElement.toLowerCase());
              });
            }
          }
        }catch(err){
          return this.$store.commit(`global/setSnackbar`,{message:err});
        }
      },
      formatDate(date){
        let formattedDate = '';
        if(date){
          let newDate = new Date(date);
          let getTime = newDate.getTime();
          if(!isNaN(getTime)){
            let currentDay = `${newDate.getDate()}`.padStart(2,'0');
            let currentMonth = `${newDate.getMonth() + 1}`.padStart(2,'0');
            let currentYear = `${newDate.getFullYear()}`;
            formattedDate = `${currentDay}-${currentMonth}-${currentYear}`;
          }

        }
        return formattedDate
      }
    }
}
</script>

<style>

</style>