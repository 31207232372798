module.exports = {
  detectTypeReference: function (reference){
    if(reference){
      let spacelessReference = reference.replace(/\s/g,'');
      let isSCOR = /([a-zA-z]){2}([0-9]){2}([0-9a-zA-z]){21}$/.test(spacelessReference);
      let isQRR = /([0-9]){2}([0-9]){25}$/.test(spacelessReference);
      if(isSCOR) return 'SCOR';
      if(isQRR) return 'QRR';
    }
    return 'NON'
  },
  detectTypeReferencePlus: (reference)=>{
    if(reference !== null && reference !== undefined){
      let spacelessReference = reference.replace(/\s/g,'');
      let isSCOR = /([a-zA-z]){2}([0-9]){2}([0-9a-zA-z]){21}$/.test(spacelessReference);
      let isQRR = /([0-9]){2}([0-9]){25}$/.test(spacelessReference);
      if(isSCOR) return 'SCOR';
      if(isQRR) return 'QRR';
      if(spacelessReference === '') return 'NON'
      return null;
    }else{
      return 'NON'
    }
  },
  detectValidIBAN: (iban)=>{
    if(iban){
      let spacelessIBAN = iban.replace(/\s/g,'');
      let isValid = /([a-zA-Z]){2}([0-9]){2}(([0-9]){4}){4}([0-9]){1}/.test(spacelessIBAN);
      if(isValid) return true;
      return false;
    }
    return false;
  }
}