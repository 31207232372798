<template  >
    <v-main >
        <v-container>
            <v-row class="d-flex justify-center" >
                <v-col cols="6" >
                    <v-card>
                        <v-progress-linear indeterminate :active="processing" color="green"></v-progress-linear>
                        <v-card-title class="d-flex justify-center" >{{$t('login.main_title')}}</v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-text-field :label="$t('login.username_field')"
                                 v-model="username" ></v-text-field>
                                <v-text-field :label="$t('login.password_field')" :type="showChars ? 'text' : 'password'" v-model="password"  ></v-text-field>
                                <v-btn 
                                    :color="$vuetify.theme.themes.light.blue.primary" 
                                    dark 
                                    block 
                                    small 
                                    @click="login"
                                    :loading="DOMElements.login.loading"
                                    >{{$t('login.btn_login')}}</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
      <!-- *******************************************POP UP********************************************** -->
        <v-dialog  width="500" v-model="popup"  transition="dialog-top-transition"
        max-width="600">
        <v-card flat  width="500"  >
       
       <v-toolbar
              :color="$vuetify.theme.themes.dark.green.secondary"
              dark
            > <h3>{{$t("login.locked_profile")}}</h3></v-toolbar>
        <!-- <v-card-title   class="headline grey lighten-2"><h2> Account {{statusAccount}}</h2></v-card-title> -->
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            <v-card-text class="text-h5 pa-12"> {{messageStatus}}  </v-card-text>
          <v-col cols="6" offset=3 >  <v-card-text>  <a class="justify-center text-h5 " v-if="mails" href="mailto:">info@qrmodul.ch </a></v-card-text> </v-col>
           <v-card-actions class="justify-end">
           <v-btn  v-if="btnActivation"
            @click="sendReactivation()"
            :disabled="disabledbtn"
            :dark="activateNewProfileDark"
            :color="$vuetify.theme.themes.light.green.primary"
            :loading="DOMElements.activate.loading"
            
           > {{$t("login.order_link")}}</v-btn> 
          <v-btn  :color="$vuetify.theme.themes.dark.brown.secondary" :dark="activateNewProfileDark" @click="popup = false" > {{$t("Globals.btn_close")}}</v-btn> 
           </v-card-actions>
    <v-spacer></v-spacer>
        </v-card>
    </v-dialog>
    <!-- ************************************************************************************************ -->
        </v-container>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            disabledbtn: true,
            mails:true,
            activateNewProfileDark: true,
            btnActivation:false,
            popup:false,
            messageStatus:"",
            name: '',
            showChars: false,
            processing: false,
            username: '',
            password: '',
            DOMElements: {
                login: {
                    loading: false,
                }
            }
        }
    },
    mounted() {
        window.addEventListener('keypress', this.onKeypress,false);
        return this.$store.commit(`global/setPageTitle`,'Admin Login');
    },
    async destroyed(){
        window.removeEventListener('keypress',this.onKeypress,false);
    },
    methods: {
       async login() {
           this.processing = true;
           this.DOMElements.login.loading = true;
           await fetch(`${process.env.VUE_APP_SERVER_URL}/api/login`,{
               method: 'POST',
               credentials: 'include',
               body:JSON.stringify({
                username:this.username,
                password:this.password,
               }),
           })
           .then((result) => result.json())
           .then(async(response) => {
               this.processing = false;
                if(response.status === 200) {
                    this.$store.commit('User/setLogin', { code: response.status, user: response.user }); 
                    this.$store.commit(`global/setPageTitle`,'Admin Dashboard');
                    try{
                        let result = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/csrfToken`);
                        document.cookie = `_csrf=${result._csrf};`;
                    }catch(err){
                        return this.$store.commit(`global/setSnackbar`,err);
                    }
                    return this.$router.push({name:'users'});    
                } else {
                    if(response.accountStatus == "locked"){
                    this.popup = true;
                    this.messageStatus = response.message;
                    }else {
                        return this.$store.commit('global/setSnackbar', {message: response.message});
                    }
                }
           })
           .catch((err) => {
               this.processing = false;
               return this.$store.commit('global/setSnackbar', {message: 'Something went wrong when you tried to log in'});
           });

            this.DOMElements.login.loading = false;
        },
        onKeypress(e){
            if(e.key === "Enter"){
                return this.login();
            }
        }
   
    }
}
</script>

<style scoped>

</style>