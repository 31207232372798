<template>
    <div>
        <v-card color="blue-grey lighten-5">
            <v-card-title>{{title}}</v-card-title>
            <v-card-text>
                <span>{</span><br>
                <div
                v-for="item in items"
                :key="item.key"
                >
                    <div v-if="!item.children">
                        <span class="firstLvl">
                            "<span class="keyAtt">{{item.name}}</span>":"<span class="valueAtt">{{item.value}}</span>",<br>
                        </span>                                        
                    </div>
                    <div v-else>
                        <div v-if="item.type === 'Array'">
                            <span class="firstLvl">
                                "<span class="keyAtt">{{item.name}}</span>": [<br>
                            </span>
                            <span class="secondLvl">{</span><br>
                        </div>
                        <div v-else>
                            <span class="firstLvl">
                                "<span class="keyAtt">{{item.name}}</span>": {<br>
                            </span>
                        </div>
                        <div
                        v-for="child in item.children"
                        :key="child.key"
                        >
                            <div v-if="item.type === 'Array'">
                                <span class="subSecondLvl">
                                    "<span class="keyAtt">{{child.name}}</span>":"<span class="valueAtt">{{child.value}}</span>",<br>
                                </span>    
                            </div>
                            <div v-else>
                                <div v-if="!child.children">
                                    <span class="secondLvl">
                                        "<span class="keyAtt">{{child.name}}</span>":"<span class="valueAtt">{{child.value}}</span>",<br>
                                    </span>                                        
                                </div>
                                <div v-else>
                                    <div v-if="child.type === 'Array'">
                                        <span class="secondLvl">
                                            "<span class="keyAtt">{{child.name}}</span>": [<br>
                                        </span>
                                        <span class="thirdLvl">{</span><br>
                                    </div>
                                    <div v-else>
                                        <span class="secondLvl">
                                            "<span class="keyAtt">{{child.name}}</span>": {<br>
                                        </span>
                                    </div>
                                    <div
                                    v-for="grandChild in child.children"
                                    :key="grandChild.key"
                                    >
                                        <div v-if="!grandChild.children">
                                            <span class="thirdLvl">
                                                "<span class="keyAtt">{{grandChild.name}}</span>":"<span class="valueAtt">{{grandChild.value}}</span>",<br>
                                            </span>
                                        </div>
                                        <div v-else>
                                            <div v-if="grandChild.type === 'Array'">
                                                <span class="thirdLvl">
                                                    "<span class="keyAtt">{{grandChild.name}}</span>": [<br>
                                                </span>
                                                <span class="fourthLvl">{</span><br>
                                            </div>
                                            <div v-else>
                                                <span class="thirdLvl">
                                                    "<span class="keyAtt">{{grandChild.name}}</span>": {<br>
                                                </span>
                                            </div>
                                            <div>
                                                Not available
                                            </div>
                                            <div v-if="grandChild.type === 'Array'">
                                                <span class="fourthLvl">},</span><br>  
                                                <span class="thirdLvl">],</span><br>                                                
                                            </div>
                                            <div v-else>
                                                <span class="thirdLvl">},</span><br> 
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="child.type === 'Array'">
                                        <span class="thirdLvl">},</span><br>  
                                        <span class="secondLvl">],</span><br>
                                    </div>
                                    <div v-else>
                                        <span class="secondLvl">},</span><br>                         
                                    </div>        
                                </div>
                            </div>
                        </div>
                    <div>
                    </div>
                    <div v-if="item.type === 'Array'">
                        <span class="secondLvl">}</span><br>
                        <span class="firstLvl">],</span><br>
                    </div>
                    <div v-else>
                        <span class="firstLvl">},</span><br>                                        
                    </div>
                    </div>
                </div>
                <span>}</span>
            </v-card-text>
        </v-card>
    </div>    
</template>

<script>
export default {
    props:{
        items:{
            type:Array,
            default:[]
        },
        title:{
            type:String,
            default:''
        }
    },
}
</script>

<style>
    .firstLvl{
        padding-left: 9.5px;
    }
    .secondLvl{
        padding-left: 20px;
    }
    .subSecondLvl{
        padding-left: 23px;
    }
    .thirdLvl{
        padding-left: 30px;
    }
    .subThirdLvl{
        padding-left: 33px;
    }
    .fourthLvl{
        padding-left: 40px;
    }
    .keyAtt{
        color:rgb(48, 131, 48);
        padding-right:2px;
        padding-left: 2px;
    }
    .valueAtt{
        color:dodgerblue;
        padding-right:2px;
        padding-left: 2px;
    }
</style>